import {
  CampaignPreviewType,
  UserPostGridTemplateFallback,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/postgrid/models';
import { useEffect, useState } from 'react';
import { LISTING_IMAGE_KEY, listingImageVars } from '@/components/custom-modals/modal-fallbacks/types';
import { useCampaignManagerStore } from '@/components/dashboard/hooks/useCampaignManager';
import CampaignService from '@/services/campaign';
import { formatFallback, generateDefaultFallbacksByHtmlString } from '@/utils/utils';
import { useShallow } from 'zustand/react/shallow';
import { RootState } from '@/reducers/model';
import { useSelector } from 'react-redux';

const useFetchDefaultTemplateFallback = (templateId: string | null, disabled?: boolean, htmlString?: string) => {
  const { agentIdCampaign: agentIdSelectOfManager } = useCampaignManagerStore(
    useShallow((state) => ({
      agentIdCampaign: state.agentIdCampaign,
    })),
  );
  const loggedInAgentId = useSelector((state: RootState) => state?.home?.userAgent?.id);

  const agentIdCampaign = agentIdSelectOfManager || loggedInAgentId;

  const [templateFallback, setTemplateFallback] = useState<UserPostGridTemplateFallback>();

  const fetchDefaultFallbackOfTemplate = async (templateId: string, htmlString = '', type: CampaignPreviewType) => {
    let templateFallback: UserPostGridTemplateFallback = {
      template: templateId,
      default_fallbacks: {},
      mls_data: {},
      type,
    };
    if (!templateId) return templateFallback;
    const newDefaultFallback = generateDefaultFallbacksByHtmlString(htmlString || '');
    const listVariableInHTML = Object.keys(newDefaultFallback);
    if (listVariableInHTML.some((key) => listingImageVars.some((imageVar) => key.includes(imageVar)))) {
      newDefaultFallback[LISTING_IMAGE_KEY] = [];
    }
    try {
      const data = await CampaignService.getUserPostGridTemplateFallback(templateId, agentIdCampaign);
      const globalFallback = await CampaignService.getGlobalFallbacks(Object.keys(newDefaultFallback), agentIdCampaign);

      templateFallback = {
        template: templateId,
        default_fallbacks: globalFallback || {},
        mls_data: data?.mls_data || {},
        mailing_list_data: data?.mailing_list_data || {},
        type: CampaignPreviewType.POSTGRID,
      };
      if (!disabled) setTemplateFallback(templateFallback);
    } catch (error) {
      if (!disabled) setTemplateFallback(templateFallback);
    }
    return templateFallback;
  };

  const updateFallbackOfTemplate = async (currentTemplateId, fallbackValue) => {
    //convert empty value key to null
    const convertFallback = Object.keys(fallbackValue || {})?.reduce((acc, key) => {
      const value = fallbackValue?.[key] !== '' ? fallbackValue?.[key] : null;
      acc[key] = value;
      return acc;
    }, {});
    const newFallback = await CampaignService.setGlobalFallbacks(
      {
        default_fallbacks: formatFallback(convertFallback),
      } as UserPostGridTemplateFallback,
      agentIdCampaign,
    );
    if (currentTemplateId === templateId) {
      setTemplateFallback((prev) => ({
        ...prev,
        template: currentTemplateId,
        default_fallbacks: newFallback || {},
        type: CampaignPreviewType.POSTGRID,
      }));
    }
    return newFallback;
  };

  useEffect(() => {
    if (
      disabled ||
      !templateId ||
      (!agentIdCampaign && templateFallback?.template && templateId === templateFallback?.template)
    )
      return;
    fetchDefaultFallbackOfTemplate(templateId, htmlString, templateFallback?.type || CampaignPreviewType.POSTGRID);
  }, [templateId, disabled, agentIdCampaign]);

  return {
    templateFallback,
    setTemplateFallback,
    fetchDefaultFallbackOfTemplate,
    updateFallbackOfTemplate,
  };
};

export default useFetchDefaultTemplateFallback;
