import React from 'react';
import { reduxStore, sagaMiddleware } from './src/config/storeRedux';
import { rootSaga } from './src/reducers';
import initI18nService from './src/config/i18n';
import { initSDKService } from './src/config/apiClient';
import MainApp from './main-app';
import NProgress from 'nprogress';

// import 'antd/dist/antd.css';
import 'antd/dist/antd.variable.min.css';
import 'minireset.css/minireset.min.css';

import 'react-loading-skeleton/dist/skeleton.css';
import 'nprogress/nprogress.css';

import 'react-calendar/dist/Calendar.css';

import '@/styles/global.scss';
import 'simplebar-react/dist/simplebar.min.css';

if (typeof window !== 'undefined') {
  NProgress.configure({
    minimum: 0.1,
    easing: 'ease',
    speed: 500,
    showSpinner: false,
    parent: 'body',
  });

  // Override console.log in production
  if (process.env.GATSBY_APP_ENV === 'production') {
    console.log = function () {};
  }
}

export const wrapRootElement = ({ element }) => {
  sagaMiddleware.run(rootSaga);
  initI18nService();
  initSDKService();
  return <MainApp reduxStore={reduxStore} element={element} />;
};

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  if (location?.pathname !== prevLocation?.pathname) {
    NProgress.start();
  }
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  const newPath = location?.pathname;
  const oldPath = prevLocation ? prevLocation?.pathname : null;

  if (newPath !== oldPath) {
    NProgress.done();
  }
};

export const onRouteUpdateDelayed = () => {
  NProgress.set(0.9);
};
