import { cloneDeep, omit } from 'lodash';

export enum FallbackTypeEnum {
  EMAIL = 'EMAIL',
  POSTGRID = 'POSTGRID',
}

export interface FallbackVariableOnUI {
  value: string;
  label: string;
}

export interface VariableOnUITemplate {
  defaultVariableOnUI?: string[];
  onVariableOnUIUpdate?: (key: string[]) => void;
}
export const LISTING_IMAGE_KEY = 'listing_image';

export const listingImageVars = [
  'listing_image_1',
  'listing_image_2',
  'listing_image_3',
  'listing_image_4',
  'listing_image_5',
];

export const especialVars = ['landing_page_url', 'qr_code'];

export const currentDatetimeVars = ['current_year', 'date_issued', 'date_expire'];

export const agentInfoVars = [
  'agent_logo',
  'agent_image',
  'agent_name',
  'agent_phone_number',
  'agent_dre',
  'agent_email',
  'listing_agent_name_and_license',
];

export const haveDefaultValuesVars = [
  'qr_code',
  'broker_logo',
  'broker_logo_white',
  'broker_logo_lpi',
  'broker_logo_lpi_white',
  'broker_image',
  'broker_image_white',
  'broker_image_lpi',
  'agent_image',
  'agent_logo',
];

export const hiddenVarOnFallbackFormUI = [
  ...agentInfoVars,
  ...currentDatetimeVars,
  ...currentDatetimeVars,
  ...haveDefaultValuesVars,
  ...especialVars,
];

export const landing_page_var_key = 'landing_page_url';

export const phoneFormatVarKeys = ['agent_phone_number'];

export const handleExcludedPostgridHiddenVars = (variables: any) => omit(cloneDeep(variables), []);
