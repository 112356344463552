import { MAX_LENGTH_TEMPLATE_NAME } from '@/utils/constant';
import validation from '@/utils/validation';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import ModalCreateTemplateInfoView from './CreateTemplateInfoView';

interface CreateLetterModalProps {
  visible: boolean;
  onCancel: () => void;
  onConfirm: (name: string, campainType: string) => void;
  editData?: any;
}

export interface CreateTemplateInfoFormValues {
  name: string;
  campaignType: string;
  agent_id?: string;
}

const ModalCreateTemplateInfo: React.FunctionComponent<CreateLetterModalProps> = ({
  visible,
  onCancel,
  onConfirm,
  editData,
}) => {
  const validationSchema = React.useRef(() =>
    Yup.object().shape({
      name: Yup.string()
        .required(validation.common.required)
        .max(MAX_LENGTH_TEMPLATE_NAME, `Limit ${MAX_LENGTH_TEMPLATE_NAME} characters`),
      campaignType: Yup.string().required(validation.campaign_type.requiredCategory),
    }),
  );
  const [fieldChange, setFieldChange] = useState<any>(null);
  const formik = useFormik<CreateTemplateInfoFormValues>({
    initialValues: {
      name: editData?.name || '',
      campaignType: editData?.campaign_type || '',
    },
    validationSchema: validationSchema.current,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values) => {
      onConfirm(values.name, values.campaignType?.trim());
    },
  });

  useEffect(() => {
    formik.setValues({ name: editData?.name, campaignType: editData?.campaign_type });
  }, [editData]);

  return (
    <ModalCreateTemplateInfoView
      fieldChange={fieldChange}
      formik={formik}
      onCancel={onCancel}
      visible={visible}
      editData={editData}
      onConfirm={onConfirm}
      setFieldChange={setFieldChange}
    />
  );
};

export default ModalCreateTemplateInfo;
