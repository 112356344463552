import {
  CardTypeEnum,
  HeaderTypeEnum,
  WritingStyleEnum,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/handwriting/models';
import { useFormik } from 'formik';
import { SelectedFileNameDigitalMediaType } from '@/components/UploadDigitalMedia';
import { MAX_LENGTH_TEMPLATE_NAME } from '@/utils/constant';
import validation from '@/utils/validation';
import * as Yup from 'yup';

export const handwrittenCardTypes = [
  { label: '"5x7" Flat', value: CardTypeEnum.Flat },
  { label: '"5x7" Folded', value: CardTypeEnum.Fold },
];

export const handwrittenFontStyles = Object.values(WritingStyleEnum).map((v) => ({
  value: v,
  label: v,
}));

export const HANDWRITTEN_MESSAGE_LIMIT = 450;
export const HANDWRITTEN_SIGNATURE_LIMIT = 50;
export const HANDWRITTEN_HEADER_TEXT_LIMIT = 18;

export type HandwrittenCreatingType = {
  templateName?: string;
  cardType?: CardTypeEnum;
  fontStyle?: WritingStyleEnum;
  landingPage?: string;
  message?: string;
  htmlMessage?: string;
  frontImage?: SelectedFileNameDigitalMediaType | null;
  backImage?: SelectedFileNameDigitalMediaType | null;
  headerImage?: SelectedFileNameDigitalMediaType | null;
  signature?: string;
  campaignType?: string;
  headerType?: HeaderTypeEnum;
  headerText?: any;
  mlsData?: {
    [key: string]: string;
  };
  mailingListData: {
    [key: string]: string;
  };

  isUseSVImgAsBack: boolean;
  isUseSVImgAsFront: boolean;
};
const validationSchema = Yup.object().shape({
  cardType: Yup.string().nullable().required('Card type is required.'),
  templateName: Yup.string()
    .nullable()
    .required('Template name is required.')
    .max(MAX_LENGTH_TEMPLATE_NAME, `Limit ${MAX_LENGTH_TEMPLATE_NAME} characters.`),
  message: Yup.string()
    .nullable()
    .required('Message is required.')
    .test('limit', '', (value) => {
      if (!value) return false;
      return value?.replaceAll('\n', '')?.length <= HANDWRITTEN_MESSAGE_LIMIT;
    }),
  signature: Yup.string()
    .nullable()
    .required('Signature is required.')
    .test('limit', '', (value) => {
      if (!value) return false;
      return value?.replaceAll('\n', '')?.length <= HANDWRITTEN_SIGNATURE_LIMIT;
    }),
  frontImage: Yup.object().nullable().required('Front Image is required.'),
  backImage: Yup.object()
    .nullable()
    .when('cardType', {
      is: CardTypeEnum.Flat,
      then: Yup.object().nullable().notRequired(),
      otherwise: Yup.object().nullable().required('Back Image is required.'),
    }),
  headerImage: Yup.object()
    .nullable()
    .when('cardType', {
      is: CardTypeEnum.Fold,
      then: Yup.object().nullable().notRequired(),
      otherwise: Yup.object()
        .nullable()
        .when('headerType', {
          is: HeaderTypeEnum.Text,
          then: Yup.object().nullable().notRequired(),
          otherwise: Yup.object().nullable().required('Header Image is required.'),
        }),
    }),
  headerText: Yup.object()
    .nullable()
    .when('cardType', {
      is: CardTypeEnum.Fold,
      then: Yup.object().nullable().notRequired(),
      otherwise: Yup.object()
        .nullable()
        .when('headerType', {
          is: HeaderTypeEnum.Text,
          then: Yup.object().shape({
            data: Yup.string().required('Header text is required.').max(HANDWRITTEN_HEADER_TEXT_LIMIT, ``),
            message: Yup.string(),
          }),
          otherwise: Yup.object().nullable().notRequired(),
        }),
    }),

  landingPage: Yup.string().nullable().required(validation.requiredLandingPage),
});
const useCreateHandwritten = () => {
  const formik = useFormik<HandwrittenCreatingType>({
    initialValues: {
      cardType: CardTypeEnum.Flat,
      fontStyle: WritingStyleEnum.WRITING_STYLE_TARZAN,
      templateName: '',
      landingPage: '',
      message: '',
      htmlMessage: '',
      frontImage: null,
      backImage: null,
      headerImage: null,
      signature: '',
      campaignType: '',
      headerType: HeaderTypeEnum.Image,
      headerText: '',
      mlsData: {},
      mailingListData: {},
      isUseSVImgAsBack: false,
      isUseSVImgAsFront: false,
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    onSubmit() {
      // TODO: submit
    },
  });
  return formik;
};

export default useCreateHandwritten;
