import { RefSelectProps } from 'antd';
import React, { FC, ReactNode } from 'react';
import SelectImageView from './SelectImageView';

interface SelectImageProps {
  className: HTMLElement['className'];
  label: string;
  placeholder: string;
  idParent: string;
  itemSelect: string[];
  handleChange: (selected: string) => void;
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  value?: string | null;
  isGetContainer: boolean;
  disabled: boolean;
  selectedValue: string[];
  disclaimer: ReactNode;
}

const SelectImage: FC<SelectImageProps> = (props) => {
  const {
    label,
    className,
    handleChange,
    handleBlur,
    value,
    itemSelect,
    placeholder,
    isGetContainer,
    idParent = 'market',
    selectedValue,
    disclaimer,
    disabled,
  } = props;
  const inputRef = React.useRef<RefSelectProps | null>(null);

  const onInputRefFocus = () => {
    inputRef.current?.focus();
  };

  return (
    <SelectImageView
      ref={inputRef}
      className={className}
      handleBlur={handleBlur}
      handleChange={handleChange}
      onInputRefFocus={onInputRefFocus}
      disabled={disabled}
      disclaimer={disclaimer}
      idParent={idParent}
      isGetContainer={isGetContainer}
      itemSelect={itemSelect}
      label={label}
      placeholder={placeholder}
      selectedValue={selectedValue}
      value={value}
    />
  );
};

export default SelectImage;
