import { CaretDownOutlined } from '@ant-design/icons';
import { Select, RefSelectProps } from 'antd';
import React, { forwardRef, memo, ReactNode } from 'react';
import SelectImageStyled from './SelectImageStyled';
const { Option } = Select;

interface SelectImageViewProps {
  requiredMark?: boolean;
  label?: string;
  itemSelect?: string[];
  isGetContainer?: boolean;
  idParent?: string;
  selectedValue?: string[];
  disclaimer?: ReactNode;
  onInputRefFocus: () => void;
  className: HTMLElement['className'];
  value?: string | null;
  placeholder?: string;
  handleChange: (selected: any) => void;
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const SelectImageView = memo(
  forwardRef<RefSelectProps, SelectImageViewProps>((props, inputRef) => {
    const {
      requiredMark,
      label,
      className,
      value,
      itemSelect,
      isGetContainer,
      idParent = 'market',
      selectedValue,
      disclaimer,
      onInputRefFocus,
      handleChange,
      handleBlur,
      disabled,
      placeholder,
    } = props;

    return (
      <SelectImageStyled className={className}>
        {label && (
          <label className="s-label" onClick={onInputRefFocus}>
            {label}
            {requiredMark ? <span className="mark">*</span> : ''}
            {disclaimer}
          </label>
        )}
        <SelectImageStyled.CustomSelectStyled
          suffixIcon={<CaretDownOutlined className="icon ant-select-suffix" />}
          ref={inputRef}
          value={value && itemSelect?.includes(value) ? value : null}
          getPopupContainer={
            isGetContainer ? () => document.getElementById(idParent) ?? document.body : () => document.body
          }
          onSelect={handleChange}
          dropdownRender={(menu) => (
            <SelectImageStyled.DropdownRenderStyled className="field select-field">
              {menu}
            </SelectImageStyled.DropdownRenderStyled>
          )}
          onBlur={handleBlur}
          placeholder={placeholder}
        >
          {itemSelect?.map((item) => {
            return (
              <Option
                key={item}
                className="option"
                value={item}
                disabled={
                  disabled ||
                  selectedValue?.some((v) => {
                    let isIncludeSelected = false;
                    if (typeof v === 'string') isIncludeSelected = v?.includes(item);
                    return isIncludeSelected && value !== item;
                  })
                }
              >
                <SelectImageStyled.OptionsStyled>
                  <img src={item} alt="listing_image" className="upload-image" />
                </SelectImageStyled.OptionsStyled>
              </Option>
            );
          })}
        </SelectImageStyled.CustomSelectStyled>
      </SelectImageStyled>
    );
  }),
);

export default SelectImageView;
