"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.varRegex = exports.dateTimeVarKeys = exports.currencyVarKeys = exports.numericVarKeys = exports.includedImageKeywords = exports.includedEquityKeyVariables = exports.includedCurrencyKeyVariables = exports.includedPhoneKeyVariables = exports.OwnerAddressType = exports.dateTime = void 0;
exports.dateTime = {
    dateTimeUSFormat: 'MM-DD-YYYY',
    dateExpiredMonth: 3,
    lastSoldDateDataFormat: 'YYYY-MM-DD',
};
var OwnerAddressType;
(function (OwnerAddressType) {
    OwnerAddressType["OWNER"] = "owner";
    OwnerAddressType["RENTER"] = "renter";
})(OwnerAddressType = exports.OwnerAddressType || (exports.OwnerAddressType = {}));
exports.includedPhoneKeyVariables = 'phone';
exports.includedCurrencyKeyVariables = [
    'last_sold_price',
    'avm_value',
    'assessed_or_active_value',
    'remodeling_value',
];
exports.includedEquityKeyVariables = 'gross_equity';
exports.includedImageKeywords = ['image', 'images', 'logo', 'avatar', 'qr_code'];
exports.numericVarKeys = ['average_years_owned', 'num_of_bathrooms', 'num_of_bedrooms', 'year_built', 'stories'];
exports.currencyVarKeys = [
    'listing_price_per_square_foot',
    'years_owned',
    'listed_price',
    'equity_percent',
    'remodel_upside_of_home',
    'remodeling_value_of_home',
    'last_sold_price',
    'avm_value',
    'assessed_or_active_value',
    'assessed_home_value',
    'square_feet',
    'home_equity',
    'home_equity_percentage',
    'turn_over_rate',
    'remodeling_value',
];
exports.dateTimeVarKeys = ['last_sold_date', 'years_since_purchased', 'date_issued', 'date_expire'];
exports.varRegex = {
    currencyRegexPattern: /^[0-9.,]+$/,
    numericRegexPattern: /^[0-9]+$/,
};
