"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTokenDecoded = exports.tryParseJson = void 0;
const jwt_decode_1 = require("jwt-decode");
exports.tryParseJson = (jsonString) => {
    try {
        return JSON.parse(jsonString);
    }
    catch (error) {
        return jsonString;
    }
};
exports.getTokenDecoded = (accessToken) => {
    const decoded = jwt_decode_1.default(accessToken);
    return decoded;
};
