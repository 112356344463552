import React from 'react';
import { IGiftCard } from '@goldfishcode/first-team-real-estate-sdk/libs/api/handwriting/models';
import HandwrittenService from '@/services/handwriting';
import { create } from 'zustand';
interface GiftCardStore {
  giftCardList: Array<
    IGiftCard & {
      gift_value_label?: any;
    }
  >;
  giftCardTypeList: string[];
  fetchGiftCardList: () => Promise<void>;
}

const useGiftCardStore = create<GiftCardStore>((set, get) => ({
  giftCardList: [],
  giftCardTypeList: [],
  fetchGiftCardList: async () => {
    // Check if giftCardList is already available in the store
    const cachedGiftCardList = get().giftCardList;

    if (cachedGiftCardList.length > 0) {
      return;
    }

    try {
      const response = await HandwrittenService.listGiftCard(1, 1000);
      const data = (response.results || []).map((e) => ({
        ...e,
        gift_value_label: (
          <>
            {e.card_type} - ${e.gift_value} for <span style={{ fontWeight: 'bold' }}>{e.price}</span> campaign credits
          </>
        ),
      }));
      const types = data.map((v) => v.card_type);
      set({ giftCardList: data, giftCardTypeList: [...new Set(types)] });
    } catch (error) {
      // Do nothing
    }
  },
}));

export default useGiftCardStore;
