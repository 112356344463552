// import UploadService from '@/services/upload';

import { navigate } from 'gatsby';

import { AlertMailingError } from '@/components/alert/SweetAlert';
import MailingService from '@/services/mailing';
import OverviewService from '@/services/overview';
import UserService from '@/services/user';
import RoutePaths from './routes';

const getBase64 = (img: any, callback: any): void => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const getFileUrl = async (
  file: File | null,
  folder_name = 'photos',
  cbError?: (v) => void,
  getUrl?: boolean,
  type_media?: string,
): Promise<any> => {
  if (!file) return undefined;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('folder_name', folder_name);
  if (type_media) {
    formData.append('digital_ad_type', type_media);
  }
  try {
    const response = await UserService.upload(formData);
    if (getUrl) return response;
    return response.file_path;
  } catch (error) {
    cbError && cbError(error as Error);
  }
};
const getFileUrlWithAuthentication = async (
  file: File | null,
  folder_name = 'photos',
  cbError?: (v) => void,
  getUrl?: boolean,
  type_media?: string,
  isRequestAdmin?: boolean,
): Promise<any> => {
  if (!file) return undefined;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('folder_name', folder_name);
  if (type_media) {
    formData.append('digital_ad_type', type_media);
  }
  if (isRequestAdmin) {
    formData.append('is_request_admin', 'true');
  }
  try {
    const response = await UserService.uploadWithAuthenication(formData);
    if (getUrl) return response;
    return response.file_path;
  } catch (error) {
    cbError && cbError(error as Error);
  }
};
const getFileUrlEditor = async (file: File | null, folder_name = 'photos'): Promise<any> => {
  if (!file) return undefined;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('folder_name', folder_name);
  try {
    const response = await UserService.upload(formData);
    return response.url;
  } catch (error) {
    // Do nothing
  }
};
const getFileAttachmentEditor = async (file: File | null, folder_name = 'photos'): Promise<any> => {
  if (!file) return undefined;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('folder_name', folder_name);
  try {
    const response = await UserService.upload(formData);
    return response;
  } catch (error) {
    // Do nothing
  }
};
const getListImportSubmission = async (file: File | null, cbError?: (v) => void): Promise<any> => {
  if (!file) return undefined;
  const formData = new FormData();
  formData.append('agents_list_csv', file);
  try {
    const response = await OverviewService.importAgent(formData);
    return response;
  } catch (error) {
    cbError && cbError((error as Error).message);
  }
};
const getListImportProspects = async (file: File | null, cbError?: (v) => void): Promise<any> => {
  if (!file) return undefined;
  const formData = new FormData();
  formData.append('agents_list_csv', file);
  try {
    const response = await OverviewService.importAgentProspect(formData);
    return response;
  } catch (error) {
    cbError && cbError((error as Error).message);
  }
};
const importMaillingList = async (file: File | null, name: string, cbError?: (v) => void): Promise<any> => {
  if (!file) return undefined;
  const formData = new FormData();
  formData.append('mailing_list', file);
  formData.append('name', name);
  try {
    const res = await MailingService.importMailingList(formData);
    if (res?.error_rows && res?.error_rows.length > 0) {
      const err = res?.error_rows.sort().join(', ');
      AlertMailingError('Upload Error', err, res?.error_rows_total);
    } else {
      navigate(RoutePaths.MAILING_LISTS);
    }
  } catch (error) {
    cbError && cbError((error as Error).message);
  }
};
//parse base64 to file
const parseBase64ToFile = (url: string, name: string) => {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], name);
    });
};

const validateVideoDuration = (file: File): Promise<boolean | undefined> =>
  new Promise((resolve) => {
    try {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        resolve(video.duration <= 31);
      };

      video.onerror = function () {
        resolve(undefined);
      };

      video.src = window.URL.createObjectURL(file);
    } catch (e) {
      resolve(undefined);
    }
  });

export {
  getBase64,
  parseBase64ToFile,
  getFileUrl,
  getListImportSubmission,
  getListImportProspects,
  importMaillingList,
  getFileUrlEditor,
  getFileAttachmentEditor,
  getFileUrlWithAuthentication,
  validateVideoDuration,
};
