import { JwtToken } from '@goldfishcode/first-team-real-estate-sdk/libs/api/auth/models';
import {
  BrokerAgent,
  BrokerAgentArgs,
  BrokerAgentConfirmation,
  TeamMember,
  TeamMemberList,
  brokerAddAgentArgs,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/slipstream/models';
import { UploadFile } from '@goldfishcode/first-team-real-estate-sdk/libs/api/upload/models';
import {
  RegisterUserRegisterArgs,
  UpdateUserProfileArgs,
  User,
  UserProfile,
  ValidAgentParams,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/user/models';
import { Pagination } from '@goldfishcode/first-team-real-estate-sdk/libs/type';
import { debounce } from 'lodash';
import { apiIns } from '@/config/apiClient';
import { StorageKeys } from '@/utils/enum';

class UserService {
  static getMyProfile(): Promise<User> {
    return apiIns.user.me();
  }
  static register(args: RegisterUserRegisterArgs): Promise<User> {
    return apiIns.user.register(args);
  }

  static existsEmail = debounce(async (email: string, brokerUserId?: string): Promise<boolean> => {
    try {
      const result = await apiIns.user.existsEmail(email, brokerUserId || '');
      return result;
    } catch (error) {
      return false;
    }
  }, 200);

  static existsDRE = (dre: string) => apiIns.user.existsDRE(dre);

  static validDRE = (agent: ValidAgentParams) => apiIns.user.validAgent(agent);

  static upload(params: FormData): Promise<UploadFile> {
    return apiIns.uploadFile.upload(params);
  }
  static updateProfile(data: UpdateUserProfileArgs): Promise<UserProfile> {
    return apiIns.user.updateProfile(data);
  }
  static createTeamMember(data: TeamMember): Promise<TeamMember> {
    return apiIns.overview.createTeamMember(data);
  }
  static deleteTeamMember(agent_id: string): Promise<any> {
    return apiIns.overview.deleteTeamMember(agent_id);
  }
  static existTeamMember(data: TeamMember): Promise<any> {
    return apiIns.overview.existTeamMember(data);
  }
  static listTeamMember(page?: number, limit?: number): Promise<Pagination<TeamMemberList>> {
    return apiIns.overview.listTeamMember(page, limit);
  }
  static uploadWithAuthenication(params: FormData): Promise<UploadFile> {
    return apiIns.uploadFile.upload(params, undefined, true);
  }
  static listBrokerUser(page?: number, limit?: number) {
    return apiIns.user.listBrokerUser(page, limit);
  }

  static listBrokerAgents(page?: number, limit?: number): Promise<Pagination<BrokerAgent>> {
    return apiIns.overview.listBrokerAgents(page, limit);
  }
  static async loginAsAgent(agent_id: string): Promise<JwtToken> {
    const token = await apiIns.overview.loginAsAgent(agent_id);
    localStorage.setItem(StorageKeys.BROKERAGE_TOKEN, localStorage.getItem(StorageKeys.SESSION_KEY) ?? '');
    apiIns.auth.setAuthToken(token);
    return token;
  }

  static brokerAddAgent(data: brokerAddAgentArgs): Promise<BrokerAgent> {
    return apiIns.overview.brokerAddAgent(data);
  }
  static removeSubBrokerAgent(broker_agent_id: string): Promise<any> {
    return apiIns.overview.removeSubBrokerAgent(broker_agent_id);
  }
  static resendAgentInvitation(broker_agent_id: string): Promise<any> {
    return apiIns.overview.resendAgentInvitation(broker_agent_id);
  }
  static paySubBrokerAgent(broker_agent_id: string, params: BrokerAgentArgs): Promise<any> {
    return apiIns.overview.paySubBrokerAgent(broker_agent_id, params);
  }
  static confirmBrokerAgent(broker_agent_id: string): Promise<BrokerAgentConfirmation> {
    return apiIns.overview.confirmBrokerAgent(broker_agent_id);
  }
}

export default UserService;
