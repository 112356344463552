import { UserPostGridTemplateFallback } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postgrid/models';
import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import MailingAction from '@/reducers/mailing/action';
import useConvertPostgridFallback from './useConvertPostgridFallback';
import useFallbackPostgridFormStore, {
  fallbackPrefixes,
} from '@/components/custom-modals/modal-fallbacks/hooks/useFallbackPostgridFormStore';
import ToolsService from '@/services/tools';
import { LISTING_IMAGE_KEY } from '@/components/custom-modals/modal-fallbacks/types';

const useHandlePreviewTemplateWithMultipleAddresses = () => {
  const dispatch = useDispatch();

  const { getMailingsWithFilledFallbackVariable } = useConvertPostgridFallback();
  const { initialValues, fallbacks } = useFallbackPostgridFormStore();

  const runInBackgroundFallbackFrom = debounce((convertedMailings, htmlString, selectedTemplate) => {
    const isHaveFallbackCampaignKeys = Object.keys(fallbacks).some((key) =>
      key.startsWith(`${fallbackPrefixes.PREVIEW_CAMPAIGN_WITH_MAILING_LIST}`),
    );

    if (isHaveFallbackCampaignKeys) {
      const listVariablesInHTML = ToolsService.extractVariablesFromHtmlString(htmlString).concat(LISTING_IMAGE_KEY);

      for (const mailing of convertedMailings) {
        initialValues(
          listVariablesInHTML,
          mailing?.fallback,
          selectedTemplate?.id,
          `${fallbackPrefixes.PREVIEW_CAMPAIGN_WITH_MAILING_LIST}${mailing?.id}`,
        );
      }
    }
  }, 1000);

  const runInBackgroundFallbackForAllAddresses = debounce(
    async (allAddresses, templateFallback: UserPostGridTemplateFallback, selectedTemplate: any) => {
      if (!templateFallback) return;
      const htmlString =
        selectedTemplate?.front_template +
        selectedTemplate?.back_template +
        selectedTemplate?.custom_template +
        selectedTemplate?.custom_title +
        selectedTemplate?.content +
        selectedTemplate?.title;
      const convertedMailings = await getMailingsWithFilledFallbackVariable(
        templateFallback,
        allAddresses,
        templateFallback.type,
        htmlString,
      );

      dispatch({
        type: MailingAction.SET_ADDRESSES_COUNT_CAMPAIGN_NOT_RENDER,
        payload: convertedMailings,
      });

      dispatch({ type: MailingAction.SET_ADDRESSES_COUNT_CAMPAIGN_NOT_RENDER_LOADING, payload: false });

      runInBackgroundFallbackFrom(convertedMailings, htmlString, selectedTemplate);
    },
    3000,
  );

  const getPaginatedMailingsFallback = async (
    paginatedAddresses,
    templateFallback: UserPostGridTemplateFallback,
    selectedTemplate: any,
  ) => {
    if (!templateFallback || !selectedTemplate || !paginatedAddresses) return [];
    const htmlString =
      selectedTemplate?.front_template +
      selectedTemplate?.back_template +
      selectedTemplate?.custom_template +
      selectedTemplate?.custom_title +
      selectedTemplate?.content +
      selectedTemplate?.title;
    const convertedMailings = await getMailingsWithFilledFallbackVariable(
      templateFallback,
      paginatedAddresses,
      templateFallback.type,
      htmlString,
    );
    return convertedMailings;
  };

  return {
    runInBackgroundFallbackForAllAddresses,
    getPaginatedMailingsFallback,
  };
};

export default useHandlePreviewTemplateWithMultipleAddresses;
