import theme from '@/styles/theme';
import { withProperties } from '@/utils/withProperties';
import styled from '@emotion/styled';
import { Select } from 'antd';

const SelectImageStyled = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  .ant-select-arrow .anticon:not(.ant-select-suffix) {
    pointer-events: none;
  }
  .s-label {
    top: 7px;
    font-size: 16px;
    opacity: 1;
    font-weight: 700;
    transition-property: top, font-size, opacity;
    transition-duration: 0.1s;
    transition-timing-function: linear;
    margin-bottom: 5px;
    display: inline-block;
  }
  .eyes {
    position: absolute;
    bottom: 13px;
    right: 15px;
    .anticon {
      font-size: 24px;
    }
  }
  div {
    border-radius: 0;
    font-size: 14px;
    align-items: center;
    background-color: ${theme.color.WHITE} !important;
    border-color: ${theme.text.blackColor}!important;
    height: 38px;
    font-weight: 500;
    border-radius: 3px;
    width: 100%;
    &:hover {
      border-color: ${theme.text.blackColor};
    }
    &:focus {
      border: 1px solid ${theme.text.blueColor};
      box-shadow: none;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:active,
    &:-webkit-autofill:focus {
      -webkit-background-clip: text !important;
    }
  }
  .forgot-pwd-input-base,
  .bg-input-base {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      border: 1px solid rgba(255, 255, 255, 0.2) !important;
      -webkit-text-fill-color: ${theme.color.WHITE} !important;
      -webkit-box-shadow: 0 0 0px 1000px ${theme.color.DARKBLUE} inset;
    }
  }

  .ant-select-arrow {
    color: ${theme.color.BLACK};
  }
`;

const CustomSelectStyled = styled(Select)`
  height: 20px;
`;

const DropdownRenderStyled = styled.div`
  background-color: ${theme.color.WHITE_1} !important;
  .ant-empty {
    .ant-empty-image {
      width: 100% !important;
    }
  }
  .option {
    color: ${theme.color.DARKBLUE} !important;
    &:hover {
      background-color: ${theme.color.BLUE_3} !important;
      color: ${theme.color.DARKBLUE} !important;
    }
  }
`;

const OptionsStyled = styled.div`
  width: 70px;
  height: 100% !important;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
`;

export default withProperties(SelectImageStyled, {
  CustomSelectStyled,
  DropdownRenderStyled,
  OptionsStyled,
});
