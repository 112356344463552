export enum BrokerLandingPageVariables {
  HomeCredit = '{{h_c}}',
  MinHomes = '{{m_h}}',
  CampaignPrice = '{{c_p}}',
}

export enum VariableType {
  POSTGRID = 'POSTGRID',
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  BROKER_LP = 'BROKER_LP',
}

export const brokerLPVariables = [
  {
    label: '{{home_credit}}',
    value: BrokerLandingPageVariables.HomeCredit,
  },
  {
    label: '{{campaign_price}}',
    value: BrokerLandingPageVariables.CampaignPrice,
  },
  {
    label: '{{min_homes}}',
    value: BrokerLandingPageVariables.MinHomes,
  },
];
