import React, { useState } from 'react';
import PictureWallView from './PictureWallView';

export interface PictureWallProps {
  listPicture: [string];
  handleRemoveImage: (url: string) => void;
}

const PictureWall: React.FC<PictureWallProps> = (props) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const handlePreviewImg = (url: string) => {
    setPreviewImage(url);
    setPreviewOpen(true);
  };
  const handleCancel = () => setPreviewOpen(false);

  return (
    <PictureWallView
      {...props}
      handleCancel={handleCancel}
      handlePreviewImg={handlePreviewImg}
      previewImage={previewImage}
      previewOpen={previewOpen}
    />
  );
};

export default PictureWall;
