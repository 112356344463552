import { ModalProps } from 'antd';
import React, { ReactNode } from 'react';
import ConfirmModalView from './ModalConfirmView';

export interface ConfirmModalProps extends ModalProps {
  title?: string;
  visible: boolean;
  btnSubmitText?: string;
  btnCancelText?: string;
  onCancel?: () => void;
  onSubmit?: () => void;
  errMsg?: string | React.ReactNode;
  className?: string;
  isLoading?: boolean;
  body?: ReactNode;
  modalWidth?: number;
}

const ConfirmModal: React.FunctionComponent<ConfirmModalProps> = (props) => {
  return <ConfirmModalView {...props} />;
};

export default ConfirmModal;
