import {
  DigitalAdTypes,
  DigitalCampaignAudience,
  DigitalCampaignDuration,
  DigitalCampaignTemplate,
  DigitalCampaignType,
  ICustomTemplate,
  IEmailTemplate,
  SortDigitalCampaignTemplateEnum,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';
import {
  OrderByEnum,
  PostgridTemplateType,
  TemplateViewSide,
  UserPostGridTemplateFallback,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/postgrid/models';
import { MailingExtra } from '@goldfishcode/first-team-real-estate-sdk/libs/api/tools/variable/models';
import _, { cloneDeep, isBoolean } from 'lodash';
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/reducers/model';
import UserAction from '@/reducers/user/action';
import CampaignService from '@/services/campaign';
import { POSTGRID_DEFAULT_VALUES, POSTGRID_TEMPLATE_LIMIT, qrCodeVars } from '@/utils/constant';
import regex from '@/utils/regularExpression';
import {
  convertThumbnailHandWritten,
  fetchDataFromAllPages,
  generateDefaultFallbacksByHtmlString,
  getEmailHtmlString,
  getResolutionSize,
  replaceEmptyImageByBase64,
  updateClipValues,
} from '@/utils/utils';
import { usePostgridFilter } from './usePostgridFilter';
import { PostgridEditorActionType, ResolutionTemplate, TemplateITemEnum } from '@/utils/enum';
import { useEmailTemplateFilter } from './useEmailFilter';
import HandwrittenService from '@/services/handwriting';
import {
  CardTypeEnum,
  ICreateHandWritingTemplate,
  IHandWritingTemplateResponse,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/handwriting/models';
import { AlertTextError } from '@/components/alert/SweetAlert';
import { useCampaignManagerStore } from '@/components/dashboard/hooks/useCampaignManager';
import { useShallow } from 'zustand/react/shallow';
import { useUserRole } from '@/hooks/useUserRole';
import ToolsService from '@/services/tools';
import { TEMPLATE_PREVIEW_TYPE } from '@goldfishcode/first-team-real-estate-sdk/libs/api/template_preview/model';

export interface HandwrittenTemplateCustom extends IHandWritingTemplateResponse {
  type: PostgridTemplateType;
  front_template: string | null;
  back_template: string | null;
  header_template: string | null;
  message_template: string | null;
  user_id: string;
  name: string;
  campaign_type: string;
  modified: string;
  is_in_use?: boolean;
  is_enabled?: boolean;
  created: string;
  side?: TemplateViewSide;
  user_template_fallback?: UserPostGridTemplateFallback;
}

interface ConvertPostgridHtmlToRenderDOMType {
  id?: string;
  agentImage?: string;
  agentLogo?: string;
  brokerLogo?: string;
  mailing?: MailingExtra;
  isPreviewRawTemplate?: boolean;
  listingImage1?: string;
  listingImage2?: string;
  listingImage3?: string;
  listingImage4?: string;
  listingImage5?: string;
  isHandwritten?: boolean;
}

export const convertPostgridHtmlToRenderDOM = (
  htmlString: string | null,
  {
    id,
    agentImage,
    agentLogo,
    brokerLogo,
    mailing,
    isPreviewRawTemplate,
    listingImage1,
    listingImage2,
    listingImage3,
    listingImage4,
    listingImage5,
    isHandwritten,
  }: ConvertPostgridHtmlToRenderDOMType,
) => {
  if (!htmlString) return '';
  const NO_DATA_IMAGE = isPreviewRawTemplate
    ? POSTGRID_DEFAULT_VALUES.NO_DATA_PREVIEW_ONLY_IMAGE
    : POSTGRID_DEFAULT_VALUES.NO_DATA_IMAGE;
  const altRegex = /alt="\{{.*?image_[1-6]\}}" /g;
  const srcsetRegex = /srcset="([^"]*)"/g;
  let processedHtmlString = htmlString;
  if (!isPreviewRawTemplate) processedHtmlString = processedHtmlString.replace(srcsetRegex, `srcset = ""`);
  processedHtmlString = `${processedHtmlString}`
    .replace('<body>', `<div id="postgird-fake-body_${id}" class="postgrid-fake-body">`)
    .replace('</body', '</div')
    .replace('body {', `#postgird-fake-body_${id} {`);
  if (isPreviewRawTemplate) return processedHtmlString;

  processedHtmlString = processedHtmlString
    .replaceAll(
      `<img src="{{broker_image}}"`,
      `<img src="${POSTGRID_DEFAULT_VALUES.broker_image}" class="postgrid-img"`,
    )
    .replaceAll(
      `<img src="{{broker_image_white}}"`,
      `<img src="${POSTGRID_DEFAULT_VALUES.broker_image_white}" class="postgrid-img"`,
    )
    .replaceAll(
      `<img src="{{broker_image_lpi}}"`,
      `<img src="${POSTGRID_DEFAULT_VALUES.broker_image_lpi}" class="postgrid-img"`,
    )
    .replaceAll(
      `<img src="{{broker_logo}}"`,
      `<img src="${POSTGRID_DEFAULT_VALUES.broker_logo(brokerLogo || NO_DATA_IMAGE)}"${brokerLogo ? 'srcset=""' : ''}`,
    )
    .replaceAll(
      `<img src="{{broker_logo_white}}"`,
      `<img src="${POSTGRID_DEFAULT_VALUES.broker_logo_white}" class="postgrid-img"`,
    )
    .replaceAll(
      `<img src="{{broker_logo_lpi}}"`,
      `<img src="${POSTGRID_DEFAULT_VALUES.broker_logo_lpi}" class="postgrid-img"`,
    )
    .replaceAll(
      `<img src="{{broker_logo_lpi_white}}"`,
      `<img src="${POSTGRID_DEFAULT_VALUES.broker_logo_lpi_white}" class="postgrid-img"`,
    )
    .replaceAll(`<img src="{{sboar_logo}}"`, `<img src="${POSTGRID_DEFAULT_VALUES.sboar_logo}" class="postgrid-img"`)
    .replaceAll(`<img src="{{crmls_logo}}"`, `<img src="${POSTGRID_DEFAULT_VALUES.crmls_logo}" class="postgrid-img"`)
    .replaceAll(
      `<img src="{{san_diego_logo}}"`,
      `<img src="${POSTGRID_DEFAULT_VALUES.san_diego_logo}" class="postgrid-img"`,
    )
    .replaceAll(`<img src="{{claw_logo}}"`, `<img src="${POSTGRID_DEFAULT_VALUES.claw_logo}" class="postgrid-img"`)
    .replaceAll(
      `<img src="{{dessert_area_logo}}"`,
      `<img src="${POSTGRID_DEFAULT_VALUES.dessert_area_logo}" class="postgrid-img"`,
    )
    .replaceAll(
      `<img src="{{agent_image}}"`,
      `<img src="${POSTGRID_DEFAULT_VALUES.agent_image(agentImage || NO_DATA_IMAGE)}"${agentImage ? 'srcset=""' : ''}`,
    )
    .replaceAll(
      `<img src="{{agent_logo}}"`,
      `<img src="${POSTGRID_DEFAULT_VALUES.agent_logo(agentLogo || NO_DATA_IMAGE)}"${agentLogo ? 'srcset=""' : ''}`,
    )
    .replaceAll(`class="circle-image"`, `class="postgrid-img circle-image"`)
    .replace(regex.regexClip, (match) => {
      return match + ' overflow:hidden !important;';
    })
    .replaceAll(regex.clipValuesRegex, updateClipValues) // Round Decimals for Clip CSS Support
    .replaceAll(`<img src="{{listing_image_1}}"`, `<img src="${listingImage1 || NO_DATA_IMAGE}" class="postgrid-img"`)
    .replaceAll(`<img src="{{listing_image_2}}"`, `<img src="${listingImage2 || NO_DATA_IMAGE}" class="postgrid-img"`)
    .replaceAll(`<img src="{{listing_image_3}}"`, `<img src="${listingImage3 || NO_DATA_IMAGE}" class="postgrid-img"`)
    .replaceAll(`<img src="{{listing_image_4}}"`, `<img src="${listingImage4 || NO_DATA_IMAGE}" class="postgrid-img"`)
    .replaceAll(`<img src="{{listing_image_5}}"`, `<img src="${listingImage5 || NO_DATA_IMAGE}" class="postgrid-img"`)
    .replace(altRegex, '');

  // Handle multiple qr_code vars
  qrCodeVars.forEach((qrCodeKey) => {
    if (!isHandwritten) {
      processedHtmlString = processedHtmlString.replaceAll(
        `<img src="{{${qrCodeKey}}}"`,
        `<img src="${POSTGRID_DEFAULT_VALUES.qr_code}" class="postgrid-img"`,
      );
    } else {
      processedHtmlString = processedHtmlString.replaceAll(`{{${qrCodeKey}}}`, `${POSTGRID_DEFAULT_VALUES.qr_code}`);
    }
  });

  //Handle mls image vars
  if (mailing && mailing.fallback) {
    const imageVariables = ['image_1', 'image_2', 'image_3', 'image_4', 'image_5', 'image_6', 'street_view_image'];
    const mlsImageVariables = Object.keys(mailing.fallback).filter((key) =>
      imageVariables.some((v) => key.includes(v)),
    );
    mlsImageVariables?.forEach((imgKey) => {
      processedHtmlString = processedHtmlString.replaceAll(
        `{{${imgKey}}}`,
        `${mailing.fallback[imgKey] || NO_DATA_IMAGE}`,
      );
    });
  }

  // Replace all remaining unhandled images with NO_DATA_IMAGE without srcset attribute
  processedHtmlString = processedHtmlString.replace(
    /<img src="{{.*image_[1-6]}}"(?!.*srcset=)/g,
    `<img src="${NO_DATA_IMAGE}"`,
  );
  processedHtmlString = processedHtmlString.replace(
    /<img src="{{.*street_view_image}}"(?!.*srcset=)/g,
    `<img src="${NO_DATA_IMAGE}"`,
  );
  return processedHtmlString;
};

const useScrollTemplate = (isDigitalCampaign = false) => {
  const isHandwrittenRef = useRef(null);
  const [isAutoplay, setIsAutoplay] = React.useState<boolean>(false);
  const campaignTemplateRef = React.useRef<any[] | null>(null);
  const emailTemplateRef = React.useRef<any[] | null>(null);
  const postgridFilterRef = React.useRef<any>();
  const emailTemplateFilterRef = React.useRef<any>();
  const dispatch = useDispatch();

  campaignTemplateRef.current = useSelector((state: RootState) => state?.user?.campaignTemplate);
  emailTemplateRef.current = useSelector((state: RootState) => state?.user?.emailTemplateList);

  const { postgridFilter, setPostgridFilter, postgridCount, setPostgridCount } = usePostgridFilter((state) => ({
    postgridFilter: state.postgridFilter,
    setPostgridFilter: state.setPostgridFilter,
    postgridCount: state.postgridCount,
    setPostgridCount: state.setPostgridCount,
  }));
  postgridFilterRef.current = postgridFilter;

  const {
    emailTemplateFilter,
    setEmailTemplateFilter,
    emailTemplateCount,
    setEmailTemplateCount,
    emailAdminTemplateCount,
    setAdminEmailTemplateCount,
  } = useEmailTemplateFilter((state) => ({
    emailTemplateFilter: state.emailTemplateFilter,
    setEmailTemplateFilter: state.setEmailTemplateFilter,
    emailTemplateCount: state.emailTemplateCount,
    setEmailTemplateCount: state.setEmailTemplateCount,
    emailAdminTemplateCount: state.emailAdminTemplateCount,
    setAdminEmailTemplateCount: state.setAdminEmailTemplateCount,
  }));

  const { agentIdCampaign } = useCampaignManagerStore(
    useShallow((state) => ({
      agentIdCampaign: state.agentIdCampaign,
    })),
  );
  const [isLoadingTemplate, setIsLoadingTemplate] = useState(false);
  const { isManager } = useUserRole();

  emailTemplateFilterRef.current = emailTemplateFilter;

  const getDataDigitalCampaignAssets = async () => {
    const defaultAsset = {
      listDigitalAudience: [],
      listDigitalDuration: [],
      listDigitalType: [],
    };
    if (!isDigitalCampaign) return defaultAsset;
    try {
      const [listDigitalAudience, listDigitalDuration, listDigitalType] = await Promise.all([
        fetchDataFromAllPages(CampaignService.listDigitalCampaignAudience),
        fetchDataFromAllPages(CampaignService.listDigitalCampaignDuration),
        CampaignService.listDigitalCampaignType(),
      ]);
      const dataDigital = {
        listDigitalAudience: cloneDeep(listDigitalAudience) as DigitalCampaignAudience[],
        listDigitalDuration: cloneDeep(listDigitalDuration) as DigitalCampaignDuration[],
        listDigitalType: cloneDeep(listDigitalType?.results) as DigitalCampaignType[],
      };
      return dataDigital;
    } catch (error) {
      return defaultAsset;
    }
  };

  const getListDigitalCampaignType = async () => {
    if (!isDigitalCampaign) return [];
    try {
      const digitalCampaignTypes = await CampaignService.listDigitalCampaignType();
      return digitalCampaignTypes.results as DigitalCampaignType[];
    } catch (error) {
      return [];
    }
  };

  const getListDigitalTemplate = async (params?: {
    digital_ad_type?: DigitalAdTypes;
    ordering?: SortDigitalCampaignTemplateEnum;
    isUseFromGlobal?: boolean;
  }) => {
    if (!isDigitalCampaign) return [];
    try {
      const digitalTemplateList = await fetchDataFromAllPages(CampaignService.listDigitalCampaignTemplate, params);

      if (typeof params?.isUseFromGlobal === 'undefined' || params?.isUseFromGlobal) {
        dispatch({
          type: UserAction.DIGITAL_TEMPLATES,
          payload: digitalTemplateList,
        });
      }

      return digitalTemplateList as DigitalCampaignTemplate[];
    } catch (error) {
      return [];
    }
  };

  const searchSlideIndex = (templateList: any, selectTemplate: any) => {
    let slideIndex = 0;
    if (!templateList) return slideIndex;
    templateList.forEach((v, index) => {
      v?.forEach((m) => {
        if (m?.id === selectTemplate?.id) {
          slideIndex = index;
          return;
        }
      });
    });
    return slideIndex;
  };

  const convertSwiper = (templateList: any, swiperSlide: any, itemPerSlide: number, selectedItem?: any) => {
    if (templateList.length <= itemPerSlide) {
      swiperSlide.push(templateList);
      if (
        selectedItem &&
        Object.keys(selectedItem)?.length &&
        swiperSlide?.flat()?.some((v) => v.id === selectedItem?.id)
      ) {
        const selectItem = swiperSlide?.flat()?.find((v) => v.id === selectedItem?.id);
        const initSlide = searchSlideIndex(swiperSlide, selectItem);
        return { swiperSlide, initSlide, selectedTemplate: { ...selectItem } };
      } else {
        const firstAvailableTemplate = Object.keys(swiperSlide[0][0] || {}).includes('is_premium')
          ? swiperSlide?.flat()?.find((v) => !v.is_premium || (v.is_premium && v.is_purchased))
          : swiperSlide[0][0];
        const initSlide = searchSlideIndex(swiperSlide, firstAvailableTemplate);
        return { swiperSlide, initSlide, selectedTemplate: { ...firstAvailableTemplate } };
      }
    } else {
      const oneSlide = templateList?.slice(0, itemPerSlide);
      swiperSlide.push(oneSlide);
      templateList?.splice(0, itemPerSlide);
      return convertSwiper(templateList, swiperSlide, itemPerSlide, selectedItem);
    }
  };

  const formatCampaignTypeField = (value) => {
    if (value === PostgridTemplateType.ANY) return undefined;
    else return value;
  };

  const generatePreviewTemplateImage = async (
    template: any,
    type: TEMPLATE_PREVIEW_TYPE,
    previewTemplateImage: string,
    htmlTemplate: string,
    templateId: string,
    isPostgrid: boolean,
  ) => {
    const getResolution = (template) => {
      if (template.type === PostgridTemplateType.LETTER) return getResolutionSize(ResolutionTemplate.Letter);
      if (template.type === PostgridTemplateType.POSTCARD) {
        return getResolutionSize(template.postcard_size);
      }
    };
    const width = (getResolution(template) as any)?.width;
    const height = (getResolution(template) as any)?.height;
    // fetching or startWith https://... => No need to fetch anymore
    const isShouldFetchPreviewFrontImage = htmlTemplate && (!previewTemplateImage || previewTemplateImage === 'error');
    if (isShouldFetchPreviewFrontImage) {
      const htmtString = await replaceEmptyImageByBase64({
        htmlString: htmlTemplate,
        isPostgrid,
        isReplaceDefaultImgToSrc: true,
      });
      ToolsService.createTeplatePreview(type, htmtString, templateId, width, height);
    }
  };

  const getListPostgridTemplate = useCallback(
    async (
      page = 1,
      limit = POSTGRID_TEMPLATE_LIMIT,
      campaignType?: string,
      name?: string,
      sortBy?: OrderByEnum,
      isDefault = false,
      agentId?: string,
      remove_premium?: boolean,
    ) => {
      try {
        if (isLoadingTemplate || (isBoolean(isHandwrittenRef.current) && isHandwrittenRef.current)) return;
        setIsLoadingTemplate(true);
        const listTemplate = await CampaignService.getPostGridTemplateList(
          page,
          limit,
          undefined,
          false,
          undefined,
          agentId || undefined, //agent_id
          isDefault
            ? undefined
            : campaignType
            ? formatCampaignTypeField(campaignType)
            : formatCampaignTypeField(postgridFilterRef.current.campaignType),
          isDefault ? undefined : name ?? postgridFilterRef.current.search,
          isDefault ? undefined : sortBy ?? postgridFilterRef.current.sortBy,
          remove_premium,
        );

        const campaignTemplateNew =
          Array.isArray(campaignTemplateRef.current) && page !== 1
            ? [..._.uniqBy(campaignTemplateRef.current?.concat(listTemplate.results), 'id')]
            : listTemplate.results;

        const finalTemplates = await Promise.all(
          campaignTemplateNew.map(async (template) => {
            generatePreviewTemplateImage(
              template,
              TEMPLATE_PREVIEW_TYPE.POSTGRID_FRONT,
              template.preview_front_image,
              template?.front_template,
              template.id,
              true,
            );

            generatePreviewTemplateImage(
              template,
              TEMPLATE_PREVIEW_TYPE.POSTGRID_BACK,
              template.preview_back_image,
              template?.back_template,
              template.id,
              true,
            );

            const convertFrontTemplate = await replaceEmptyImageByBase64({
              htmlString: template?.front_template,
              isPostgrid: true,
            });

            return {
              ...template,
              front_template: convertFrontTemplate,
              preview_front_image: `${template.preview_front_image}?timestamp=${new Date().getTime()}`,
            };
          }),
        );
        if (isBoolean(isHandwrittenRef.current) && isHandwrittenRef.current) return;
        dispatch({
          type: UserAction.CAMPAIGN_TEMPLATE,
          payload: finalTemplates,
        });
        if (listTemplate.count !== postgridCount) setPostgridCount(listTemplate.count);
        return campaignTemplateNew;
      } catch (error) {
        dispatch({
          type: UserAction.CAMPAIGN_TEMPLATE,
          payload: [],
        });
      } finally {
        setIsLoadingTemplate(false);
      }
    },
    [campaignTemplateRef, postgridFilterRef, dispatch, setPostgridFilter, isLoadingTemplate],
  );
  const getListHandWritingTemplate = useCallback(
    async (page = 1, limit = POSTGRID_TEMPLATE_LIMIT, search = '', cardType) => {
      try {
        if (isLoadingTemplate || (isBoolean(isHandwrittenRef.current) && !isHandwrittenRef.current)) return;
        setIsLoadingTemplate(true);
        const listTemplate = await HandwrittenService.listHandWritingTemplate(
          page,
          limit,
          search,
          formatCampaignTypeField(cardType) || undefined,
        );
        const campaignTemplateNew: IHandWritingTemplateResponse[] =
          Array.isArray(campaignTemplateRef.current) && page !== 1
            ? [..._.uniqBy(campaignTemplateRef.current?.concat(listTemplate.results), 'id')]
            : listTemplate.results;

        const convertedTemplates: HandwrittenTemplateCustom[] = campaignTemplateNew.map((val) => {
          return convertThumbnailHandWritten(val, false);
        });
        if (isBoolean(isHandwrittenRef.current) && !isHandwrittenRef.current) return;
        dispatch({
          type: UserAction.CAMPAIGN_TEMPLATE,
          payload: convertedTemplates,
        });
        if (listTemplate.count !== postgridCount) setPostgridCount(listTemplate.count);
        return campaignTemplateNew;
      } catch (error) {
        // Do nothing
      } finally {
        setIsLoadingTemplate(false);
      }
    },
    [campaignTemplateRef, postgridFilterRef, dispatch, setPostgridFilter, isLoadingTemplate],
  );

  const handleCreateHandwrittenTemplate = async (template: ICreateHandWritingTemplate, cardType?: CardTypeEnum) => {
    try {
      await HandwrittenService.createHandWritingTemplate(template);
      await getListHandWritingTemplate(1, POSTGRID_TEMPLATE_LIMIT, '', cardType);
    } catch (error) {
      AlertTextError('Action Failed', (error as Error).message);
    }
  };
  const handleUpdateHandwrittenTemplate = async (
    id: string,
    template: ICreateHandWritingTemplate,
    cardType?: CardTypeEnum,
  ) => {
    try {
      await HandwrittenService.updateHandWritingTemplate(id, template);
      await getListHandWritingTemplate(1, POSTGRID_TEMPLATE_LIMIT, '', cardType);
    } catch (error) {
      AlertTextError('Action Failed', (error as Error).message);
    }
  };

  const handleSyncTemplateFromPostgridDashboard = async (
    templateId: string,
    mls_data = {},
    mailing_list_data = {},
    agent_id = '',
  ) => {
    if (!templateId) return;
    try {
      await CampaignService.fetchTemplate(templateId, false, false, mls_data, mailing_list_data, agent_id);
    } catch (error) {
      // Do nothing
    }
  };

  const handleGetPostgridTemplateDetail = async (
    templateId: string,
    is_draft = false,
    mlsData = {},
    mailing_list_data = {},
    agent_id = '',
  ) => {
    try {
      if (!templateId) throw new Error('Template is not found');

      const detailTemplate = await CampaignService.fetchTemplate(
        templateId,
        true,
        is_draft,
        mlsData,
        mailing_list_data,
        agent_id,
      );

      const front_template = !detailTemplate?.front_template
        ? ''
        : await replaceEmptyImageByBase64({ htmlString: detailTemplate?.front_template, isPostgrid: true });
      const back_template = !detailTemplate?.back_template
        ? ''
        : await replaceEmptyImageByBase64({ htmlString: detailTemplate?.back_template, isPostgrid: true });
      detailTemplate.front_template = front_template;
      detailTemplate.back_template = back_template;
      const templateData = detailTemplate;
      const htmlString = (templateData?.front_template || '') + '&&&' + (templateData?.back_template || '');
      const newDefaultFallback = generateDefaultFallbacksByHtmlString(htmlString);

      (templateData as any).user_template_fallback = {
        ...(templateData?.user_template_fallback || {}),
        default_fallbacks: newDefaultFallback,
      };
      return templateData;
    } catch (error) {
      return null;
    }
  };

  const handleUpdatePostgridTemplateList = async (
    templateId: string,
    mls_data = {},
    mailing_list_data = {},
    actionType?: PostgridEditorActionType,
    selectedTemplateAgent?: string,
  ) => {
    try {
      setPostgridFilter({
        ...postgridFilter,
        page: 1,
      });
      if (actionType && actionType === PostgridEditorActionType.CLONE)
        await handleSyncTemplateFromPostgridDashboard(templateId, mls_data, mailing_list_data, selectedTemplateAgent);
      dispatch({
        type: UserAction.CAMPAIGN_TEMPLATE,
        payload: null,
      });
      //UPDATE agent id for role manager
      await getListPostgridTemplate(
        1,
        postgridFilter.limit,
        postgridFilter.campaignType,
        postgridFilter.search,
        postgridFilter.sortBy,
        true,
        agentIdCampaign ?? '',
      );
    } catch (error) {
      // Do nothing
    }
  };

  const handleDeletePostgridTemplate = async (templateId, refetch = true, selectedTemplateAgent?: string) => {
    try {
      await CampaignService.deleteTemplate(templateId, selectedTemplateAgent);
      if (refetch)
        await getListPostgridTemplate(
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          false,
          agentIdCampaign ?? '',
        );
    } catch (error) {
      AlertTextError('Action Failed', (error as Error).message);
    }
  };
  const handleDeleteHandwrittenTemplate = async (templateId, refetch = true, cardType?: CardTypeEnum) => {
    try {
      await HandwrittenService.deleteHandwritingTemplate(templateId);
      if (refetch) await getListHandWritingTemplate(1, POSTGRID_TEMPLATE_LIMIT, '', cardType);
    } catch (error) {
      AlertTextError('Action Failed', (error as Error).message);
    }
  };

  const getListEmailTemplate = async ({
    page,
    search,
    agent_id,
  }: {
    page?: number;
    search?: string;
    agent_id?: string;
  }) => {
    try {
      let adminTemplatePage = 1;
      const adminTemplate: IEmailTemplate[] = [];
      const customTemplate: ICustomTemplate[] = [];
      let continueAdminLoop = true;
      const activatedPage = page ?? emailTemplateFilterRef?.current?.page;

      if (isManager && activatedPage === 1) {
        setIsLoadingTemplate(true);
      }
      while (continueAdminLoop && emailTemplateFilterRef?.current?.page === 1) {
        const data = await CampaignService.listEmailTemplate(
          adminTemplatePage,
          POSTGRID_TEMPLATE_LIMIT,
          search || emailTemplateFilterRef?.current?.search,
        );
        if (data.results) {
          const adminTemplates = await Promise.all(
            data.results.map(async (template) => {
              const newContent = await replaceEmptyImageByBase64({ htmlString: template?.content });
              const htmlString = await getEmailHtmlString(template);
              return {
                ...template,
                content: newContent,
                type: TemplateITemEnum.EMAIL,
                htmlString: htmlString,
              };
            }),
          );
          adminTemplate.push(...adminTemplates);
        }
        if (data.links && data.links.next) {
          adminTemplatePage++;
        } else {
          continueAdminLoop = false;
        }
      }
      const data = await CampaignService.listCustomtemplate(
        activatedPage,
        POSTGRID_TEMPLATE_LIMIT,
        search || emailTemplateFilterRef?.current?.search,
        agent_id ?? emailTemplateFilterRef?.current?.agent_id,
      );
      if (isManager && activatedPage === 1) {
        setIsLoadingTemplate(false);
      }
      if (activatedPage === 1) {
        setAdminEmailTemplateCount(adminTemplate?.length);
        setEmailTemplateCount(data.count);
      }
      if (data.results) {
        const newData: any[] = [];
        for (const template of data.results) {
          const customTemplate = await replaceEmptyImageByBase64({ htmlString: template?.custom_template });
          const htmlString = await getEmailHtmlString(template);

          newData.push({
            ...template,
            custom_template: customTemplate,
            type: TemplateITemEnum.EMAIL,
            htmlString: htmlString,
          });
        }
        customTemplate.push(...newData);
      }
      const emailTemplates =
        emailTemplateFilterRef?.current?.page === 1
          ? adminTemplate?.concat(customTemplate as any[])
          : emailTemplateRef.current?.concat(customTemplate as any[]);
      dispatch({
        type: UserAction.EMAIL_TEMPLATE_LIST,
        payload: emailTemplates,
      });
      return emailTemplates;
    } catch (error) {
      // Do nothing
    }
  };

  return {
    isAutoplay,
    setIsAutoplay,
    getDataDigitalCampaignAssets,
    getListDigitalTemplate,
    getListDigitalCampaignType,
    convertSwiper,
    searchSlideIndex,
    getListPostgridTemplate,
    getListEmailTemplate,
    emailTemplateFilter,
    setEmailTemplateFilter,
    postgridFilter,
    setPostgridFilter,
    postgridCount,
    setPostgridCount,
    handleUpdatePostgridTemplateList,
    handleDeletePostgridTemplate,
    handleSyncTemplateFromPostgridDashboard,
    handleGetPostgridTemplateDetail,
    getListHandWritingTemplate,
    handleCreateHandwrittenTemplate,
    handleUpdateHandwrittenTemplate,
    handleDeleteHandwrittenTemplate,
    isLoadingTemplate,
    isHandwrittenRef,
    emailTemplateCount,
    setEmailTemplateCount,
    emailAdminTemplateCount,
    setAdminEmailTemplateCount,
  };
};

export default useScrollTemplate;
