import { color } from '@/styles/theme';
import styled from '@emotion/styled';

interface ListingImagesVarStyledProps {
  isPostGrid: boolean;
}
export const ListingImagesVarStyled = styled.div<ListingImagesVarStyledProps>`
  margin-left: 5px;
  position: relative;
  .image-field {
    margin-top: 13px;
    text-align: left;
  }
  .ant-select,
  .ant-select-selector {
    border-radius: 5px !important;
    border-color: transparent !important;
    height: 100%;
    min-height: ${({ isPostGrid }) => (isPostGrid ? '50px' : '80px !important')};
  }
  .ant-select-selection-item {
    max-height: 70px;
    height: ${({ isPostGrid }) => (isPostGrid ? '40px' : '70px')};
  }
  img {
    max-width: ${({ isPostGrid }) => (isPostGrid ? '50px' : '70px')};
    max-height: 70px;
  }
  .ant-select-item-option {
    max-height: 70px;
    height: ${({ isPostGrid }) => (isPostGrid ? '50px' : '70px')};
  }
  .s-label {
    margin-left: 0 !important;
  }
  .ant-select-selector {
    border-color: ${({ isPostGrid }) => (isPostGrid ? `${color.GRAY_5} !important` : '')};
  }
  .ant-select-item-option-disabled {
    opacity: 0.5;
  }
  .ant-select-arrow {
    svg {
      width: ${({ isPostGrid }) => (isPostGrid ? '12px' : '19px')};
      height: ${({ isPostGrid }) => (isPostGrid ? '12px' : '19px')};
    }
  }
`;

export const SetFallbackPostgridFormStyled = styled.form`
  &.hide-form {
    display: none;
  }
  .form-wrapper {
    max-height: 470px;
    overflow: auto;
    margin-bottom: 5px;
    .form-item {
      padding: 0 !important;
    }
    .s-label {
      font-size: 14px !important;
      opacity: 1;
      font-weight: 700;
      transition-property: top, font-size, opacity;
      transition-duration: 0.1s;
      transition-timing-function: linear;
      margin-bottom: 5px;
      display: inline-block;
      color: ${color.WHITE};
    }

    .name-field {
      color: ${color.WHITE};
      label {
        width: 100%;
        text-align: left;
        font-size: 17px;
        margin-top: 20px;
      }
      input {
        background-color: ${color.WHITE};
      }
      .ant-input-affix-wrapper {
        height: 100%;
        input {
          border: none !important;
          &::placeholder {
            color: ${color.GRAY_9};
          }
        }
      }
      .ant-input-affix-wrapper-disabled .ant-input[disabled] {
        background-color: ${color.WHITE_1} !important;
      }
    }

    &.drip-postgrid-fallback {
      width: 90%;
      padding: 5px;
      padding-right: 0;
      border: 1px solid;
      border-top: none;
      .s-label {
        color: ${color.BLACK};
        margin-top: 0px;
        margin-bottom: 3px;
      }
      .form-item {
        margin-bottom: 10px;
      }
      .name-field {
        input {
          min-height: 42px !important;
        }
      }
    }
    &.drip-email-fallback {
      width: 100%;
      border: none;
    }
  }

  .error-message {
    .error {
      margin-top: 0;
    }
  }
  .btn-field {
    button {
      height: 55px !important;
      margin: 15px 0;
      text-transform: uppercase;
      font-size: 17px !important;
      width: 100%;
    }
  }

  .listing-image {
    margin-top: 13px;
  }
  .upload-wrapper {
    display: flex;
  }
  .list-img-wrapper {
    overflow-y: hidden;
    overflow-x: auto;
    max-width: 100%;
    display: -webkit-box;
    &::-webkit-scrollbar {
      height: 15px;
    }
  }
  .img-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 102px;
    height: 102px;
    margin-block: 0 8px;
    margin-inline: 0 8px;
    vertical-align: top;
    border: 1px solid ${color.WHITE};
    border-radius: 10px;
    .img-item {
      cursor: pointer;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        object-fit: cover;

        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
    .anticon-close-circle {
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
      background-color: transparent;
      border-radius: 50%;
      background-color: #7f8285;
      color: ${color.WHITE};
    }
  }
  .ant-upload-picture-card-wrapper {
    width: fit-content;
    .ant-upload-select-picture-card {
      border-radius: 10px;
    }
  }
  .fallback-form {
    overflow-x: hidden !important;
  }
  .title {
    font-family: FiraSans, sans-serif;
    font-size: 16px !important;
    margin-bottom: 0px !important;
    color: ${color.PINK} !important;
    text-align: left;
    padding: 5px 0;
    width: 90%;
  }
`;
