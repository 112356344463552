import { User } from '@goldfishcode/first-team-real-estate-sdk/libs/api/user/models';
import { navigate } from 'gatsby';
import RoutePaths from './routes';
import { clearCache } from './utils';

export const forceLogout = (redirectToLogin = true): void => {
  localStorage.clear();
  clearCache();

  if (redirectToLogin && typeof window !== 'undefined' && !window.location.pathname.includes('/login')) {
    navigate(`${RoutePaths.LOGIN}/?redirect_url=${window.location.pathname}${window.location.search}`);
  }
};

export const checkingProfileAvailable = (myProfile?: User): void => {
  if (!window) return;
  const pathName = window.location.pathname;
  if (typeof window !== 'undefined' && (pathName === '/' || pathName.includes('/login')) && myProfile?.id) {
    navigate(RoutePaths.MAILING_LISTS);
  }
};
