"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.authFactory = void 0;
const config_1 = require("../../http/config");
const jwt_1 = require("./adapter/jwt");
const oauth2_1 = require("./adapter/oauth2");
const token_1 = require("./adapter/token");
exports.authFactory = (client) => {
    const type = client.getApiConfig().authType;
    switch (type) {
        case config_1.AuthType.OAUTH2:
            return new oauth2_1.Oauth2Auth(client);
        case config_1.AuthType.TOKEN:
            return new token_1.TokenAuth(client);
        default:
            return new jwt_1.JwtAuth(client);
    }
};
