import { OrderByEnum, PostgridTemplateType } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postgrid/models';
import { POSTGRID_TEMPLATE_LIMIT } from '@/utils/constant';
import { create } from 'zustand';

export interface PostgridFilterType {
  search?: string;
  campaignType?: string;
  sortBy?: OrderByEnum;
  page?: number;
  limit?: number;
  agentId?: string;
}

interface PostgridScrollTemplateFilter {
  postgridFilter: Partial<PostgridFilterType>;
  setPostgridFilter: (filter: Partial<PostgridFilterType>) => void;

  postgridCount?: number;
  setPostgridCount: (count: number) => void;
}

export const initialPostgridFilter = {
  page: 1,
  limit: POSTGRID_TEMPLATE_LIMIT,
  campaignType: PostgridTemplateType.ANY,
  sortBy: undefined,
  search: '',
  agentId: undefined,
};

export const usePostgridFilter = create<PostgridScrollTemplateFilter>((set) => {
  return {
    postgridFilter: initialPostgridFilter,
    setPostgridFilter: (filter) =>
      set((state) => {
        const newFilter = { ...state.postgridFilter, ...filter };
        if (!newFilter.campaignType) newFilter.campaignType = PostgridTemplateType.ANY;
        return { postgridFilter: newFilter };
      }),
    postgridCount: undefined,
    setPostgridCount(count) {
      set(() => ({ postgridCount: count }));
    },
  };
});
