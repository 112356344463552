"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenAuth = void 0;
const adapter_1 = require("./adapter");
const string_1 = require("../../../utils/string");
class TokenAuth extends adapter_1.AbstractAuthAdapter {
    login(username, password) {
        return __awaiter(this, void 0, void 0, function* () {
            const data = { email: username, password };
            const r = yield this.client.post('/auth/login/', data, {}, false);
            yield this.setAuthToken(r);
            return r;
        });
    }
    getAuthToken() {
        return __awaiter(this, void 0, void 0, function* () {
            const config = this.client.getApiConfig();
            if (!config.session || !config.authSessionKey)
                return {
                    token_type: 'Bearer',
                    access_token: config.accessTokenAdmin || '',
                    refresh_token: '',
                    expires_in: new Date().valueOf(),
                };
            const json = yield config.session.get(config.authSessionKey);
            const token = string_1.tryParseJson(json);
            if (!token || !token.key) {
                return null;
            }
            return {
                token_type: 'Bearer',
                access_token: token.key,
            };
        });
    }
    refreshToken() {
        return __awaiter(this, void 0, void 0, function* () {
            return;
        });
    }
    facebookLogin(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = `/social-auth/facebook/`;
            const r = yield this.client.post(uri, data, {}, false);
            yield this.setAuthToken(r);
            return r;
        });
    }
    connectFacebook(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = `/social-auth/facebook/connect/`;
            return this.client.post(uri, data);
        });
    }
    googleLogin(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = `/social-auth/google/`;
            const r = yield this.client.post(uri, data, {}, false);
            yield this.setAuthToken(r);
            return r;
        });
    }
    twitterLogin(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = `/social-auth/twitter/`;
            const r = yield this.client.post(uri, data, {}, false);
            yield this.setAuthToken(r);
            return r;
        });
    }
    linkedInLogin(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = `/social-auth/linkedin/`;
            const r = yield this.client.post(uri, data, {}, false);
            yield this.setAuthToken(r);
            return r;
        });
    }
}
exports.TokenAuth = TokenAuth;
