import { SlipStreamAgent } from '@goldfishcode/first-team-real-estate-sdk/libs/api/slipstream/models';
import { create } from 'zustand';
interface CampaignManagerStoreType {
  listAgent: SlipStreamAgent[];
  isLoadingAgent: boolean;
  agentIdCampaign?: string;
  agentIdPastOrder?: string;
  setListAgent: (agents: SlipStreamAgent[]) => void;
  setIsLoadingAgent: (bool: boolean) => void;
  setAgentIdCampaignStore: (agentId?: string) => void;
  setAgentIdPastOrderStore: (agentId?: string) => void;
  resetStates: () => void;
}

const initialStates = {
  agentIdCampaign: undefined,
  agentIdPastOrder: undefined,
  isLoadingAgent: false,
  listAgent: [],
};

export const useCampaignManagerStore = create<CampaignManagerStoreType>((set) => ({
  ...initialStates,
  setListAgent: (agents: SlipStreamAgent[]) => set((state) => ({ ...state, listAgent: agents })),
  setIsLoadingAgent: (bool: boolean) => set((state) => ({ ...state, isLoadingAgent: bool })),
  setAgentIdCampaignStore: (agentId?: string) => {
    const updatedAgentId = agentId || undefined;
    return set((state) => ({ ...state, agentIdCampaign: updatedAgentId }));
  },
  setAgentIdPastOrderStore: (agentId?: string) => {
    const updatedAgentId = agentId || undefined;
    return set((state) => ({ ...state, agentIdPastOrder: updatedAgentId }));
  },
  resetStates: () => set(() => initialStates),
}));
// for MANAGER role
