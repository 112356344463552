import { color } from '@/styles/theme';
import { ButtonProps } from 'antd/es/button';
import { css, SerializedStyles } from '@emotion/react';

export type ButtonColor = 'Pin' | 'White' | 'Outline' | 'WhiteTransparent' | 'DarkBlue';
export type SizeHaveNone = Size | 'none';
export type Size = ButtonProps['size'];

export const style = new Map<ButtonColor, SerializedStyles>([
  [
    'Pin',
    css`
      color: ${color.WHITE} !important;
      background-color: ${color.PINK} !important;
      border: 1px solid ${color.PINK} !important;
    `,
  ],
  [
    'White',
    css`
      color: ${color.BLACK_5} !important;
      background-color: ${color.WHITE} !important;
      border: 1px solid ${color.BLACK_5} !important;
    `,
  ],
  [
    'Outline',
    css`
      color: ${color.DARKBLUE} !important;
      background-color: ${color.WHITE_TRANS_0} !important;
      border: 1px solid ${color.DARKBLUE} !important;
    `,
  ],
  [
    'WhiteTransparent',
    css`
      color: ${color.WHITE} !important;
      background-color: ${color.WHITE_TRANS_1} !important;
      &:hover {
        background-color: ${color.WHITE_TRANS_2} !important;
      }
    `,
  ],
  [
    'DarkBlue',
    css`
      color: ${color.WHITE} !important;
      background-color: ${color.DARKBLUE} !important;
      &:hover {
        background-color: ${color.DARKBLUE_LIGHT} !important;
      }
    `,
  ],
]);

export const letters = new Map<SizeHaveNone, string | undefined>([
  ['small', '0.5px'],
  ['large', '1.5px'],
  ['middle', '1px'],
  ['none', undefined],
]);

export const fontWeight = new Map<SizeHaveNone, number>([
  ['small', 700],
  ['large', 700],
  ['middle', 600],
  ['none', 400],
]);
