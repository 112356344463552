"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CampaignNFTApi = void 0;
class CampaignNFTApi {
    constructor(client) {
        this.client = client;
    }
    listCampaignNFT(page = 1, limit = 20, name, start_date, end_date, status) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { page, limit, name, start_date, end_date, status };
            return this.client.get(`/nfts/`, params);
        });
    }
    mintCampaignNFT(args) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/nfts/`, args);
        });
    }
    getCampaignNFT(campaign_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.get(`/nfts/${campaign_id}/`);
        });
    }
    connectWallet(args) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/nfts/connectWallet/`, args);
        });
    }
    disconnectWallet() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/nfts/disconnectWallet/`);
        });
    }
    blurImage(args) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/nfts/blurImage/`, args);
        });
    }
    getEnvConfig() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.get(`/nfts/envConfig/`);
        });
    }
}
exports.CampaignNFTApi = CampaignNFTApi;
