import querystring from 'query-string';
import * as CryptoJS from 'crypto-js';
import { FilterHashKey } from './enum';

export function getQueryParam<T>(search?: string): T {
  if (typeof window === 'undefined') return {} as T;
  return querystring.parse(search || window.location.search) as any;
}

export const getParamsURL = <T extends unknown>(param?: string, search = window.location.search) => {
  const result = getQueryParam<T>(search);
  return (param ? (result ? result[param] : '') : result) as any as T;
};

export const hashFilterHelper = {
  encrypt(data) {
    const encJson = CryptoJS.AES.encrypt(JSON.stringify(data), 'xxxxxx').toString();
    const encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson));
    return encData;
  },
  decrypt(encryptedData) {
    if (!encryptedData) return;
    try {
      const decData = CryptoJS.enc.Base64.parse(encryptedData.replaceAll('"', '')).toString(CryptoJS.enc.Utf8);
      const bytes = CryptoJS.AES.decrypt(decData, 'xxxxxx').toString(CryptoJS.enc.Utf8);
      return JSON.parse(bytes);
    } catch (error) {
      // Do nothing
    }
  },
};

export const encryptAndUpdateToParams = (queryKey: FilterHashKey, data: any, replace = false) => {
  const url = new URL(window.location.href);
  if (replace) {
    const queryParamsHas = hashFilterHelper.encrypt(data);
    const newURL = `${url.origin}${url.pathname}?${queryKey}=${queryParamsHas}`;
    window.history.replaceState({}, '', newURL);
  } else {
    const params = url.searchParams.get(queryKey) ?? '';

    url.searchParams.set(queryKey, hashFilterHelper.encrypt({ ...hashFilterHelper.decrypt(params), ...data }));
    const newURL = url.toString();
    window.history.replaceState({}, '', newURL);
  }
};

export const replaceParams = async (key: string, value?: string) => {
  const url = new URL(window.location.href);
  if (!value) {
    url.searchParams.delete(key);
  } else {
    url.searchParams.set(key, value);
  }
  const newURL = url.toString();
  window.history.replaceState({}, '', newURL);
};

export function getQueryHash<T>(): T {
  if (typeof window === 'undefined') return {} as T;
  return querystring.parse(window.location.hash.substring(1)) as any;
}

export function createUrl(urlData: any) {
  const keys = Object.keys(urlData);
  let search = '?';
  keys.forEach((key) => {
    if (urlData[key] !== null && urlData[key] !== '') {
      search += `${key}=${urlData[key]}&`;
    }
  });
  return search.substring(0, search.length - 1);
}

export function deleteUrlParams(field_name: string) {
  if (typeof window === 'undefined') return {};
  const parsed = querystring.parse(location.search);

  delete parsed[field_name];
  const url = querystring.stringifyUrl({
    url: `${window.location.pathname}`,
    query: parsed,
  });
  window.history.pushState(null, '', url);
}
