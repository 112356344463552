import { Provider } from 'react-redux';
import { ThemeProvider } from '@emotion/react';
import appTheme from '@/styles/theme';
import { withTranslation } from 'react-i18next';
import { Web3ReactProvider } from '@web3-react/core';
import { getLibrary } from '@/utils/getLibrary';
import { SkeletonTheme } from 'react-loading-skeleton';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import globalVariable from '@/config/env';

const stripePromise = loadStripe(globalVariable.GATSBY_STRIPE_PUBLISH_KEY);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      retry: 0,
      keepPreviousData: true,
      retryOnMount: false,
    },
  },
});

const MainApp = ({ reduxStore, element }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={reduxStore}>
        <ThemeProvider theme={appTheme}>
          <Web3ReactProvider getLibrary={getLibrary}>
            <Elements stripe={stripePromise}>
              <SkeletonTheme height={50} borderRadius={4}>
                {element}
              </SkeletonTheme>
            </Elements>
          </Web3ReactProvider>
        </ThemeProvider>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default withTranslation()(MainApp);
