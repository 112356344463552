"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MailingListApi = void 0;
const models_1 = require("./models");
const caching_1 = require("../caching");
class MailingListApi {
    constructor(client) {
        this.client = client;
        this.staleTimes = {
            listMailings: 3,
            getCampaignHistory: 3,
            getMailingSkipTrace: 3,
            listMailingList: 3,
            getREANeighborhoods: 10,
            getLoanTypes: 10,
        };
        this.caching = new caching_1.Caching();
    }
    listMailingList(page = 1, limit = 20, name, mailing_list_type, is_report = false, is_dc_order = false, // true: filter MailingList when create order for DigitalCampaign
    is_exclude_leads, cachebuster, agent_id, is_manager_report, is_exclude_my_listing, mailing_list_ids) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = {
                page,
                limit,
                name,
                mailing_list_type,
                is_report,
                is_dc_order,
                is_exclude_leads,
                cachebuster,
                agent_id,
                is_manager_report,
                is_exclude_my_listing,
                mailing_list_ids,
            };
            const endpoint = `/mailing-list/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.listMailingList,
                });
            return response;
        });
    }
    addHome(data) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/mailing-list/add-home/`, data);
        });
    }
    getMailingList(mailingList_id, is_report, agent_id, order_by) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { is_report };
            if (agent_id && agent_id !== '') {
                params['agent_id'] = agent_id;
            }
            if (order_by) {
                params['order_by'] = order_by;
            }
            return this.client.get(`/mailing-list/${mailingList_id}/`, params);
        });
    }
    updateMailingList(mailingList_id, args) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.patch(`/mailing-list/${mailingList_id}/`, args);
        });
    }
    deleteMailingList(mailingList_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.delete(`/mailing-list/${mailingList_id}/`);
        });
    }
    updatePastClient(past_client_id, args) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.patch(`/mailing-list/past-client/${past_client_id}/`, args);
        });
    }
    deletePastClient(past_client_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.delete(`/mailing-list/past-client/${past_client_id}/`);
        });
    }
    getCountPastClient() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.get(`/mailing-list/past-client/count/`);
        });
    }
    fetchPastClient() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/mailing-list/past-client/fetch/`);
        });
    }
    fetchPremiumDataCount(data) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/mailings/fetch-premium-count/`, data);
        });
    }
    importMailingList(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/mailing-list/import/`, params);
        });
    }
    mailingListComparingRootCompetitor(mailingList_id, agent_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { agent_id };
            return this.client.get(`/mailing-list/${mailingList_id}/comparing/root-competitor/`, params);
        });
    }
    mailingListCompare(mailingList_id, agent_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { agent_id };
            return this.client.get(`/mailing-list/${mailingList_id}/mailing-compare/`, params);
        });
    }
    listMailings(page = 1, limit = 20, args) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = Object.assign({ page, limit }, args);
            const endpoint = `/mailings/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.listMailings,
                });
            return response;
        });
    }
    retrieveMailing(mailing_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.get(`/mailings/${mailing_id}/`);
        });
    }
    refreshReportForMailingList(agent_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.get(`/mailing-list/refresh-report/`, { agent_id });
        });
    }
    updateMailing(mailing_id, mailingParams) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.patch(`/mailings/${mailing_id}/`, mailingParams);
        });
    }
    createDrawDetail(args) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/mailing-list/draw-detail/`, args);
        });
    }
    countREAPropertiesDrawDetail(polygon) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/realeastateapi/count/`, { polygon });
        });
    }
    fetchMLSStatus(polygon) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/mailing-list/draw-detail/mls-status/`, { polygon });
        });
    }
    countCompetitors(polygon) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/mailing-list/draw-detail/competitors/`, { polygon });
        });
    }
    bulkVerify(data) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/mailing-list/bulk-verify/`, data);
        });
    }
    retrieveDrawDetail(mailing_list_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.get(`/mailing-list/draw-detail/${mailing_list_id}/`);
        });
    }
    checkNameAlreadyExist(args) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/mailing-list/is-existed-name/`, args);
        });
    }
    ExportCSVTemplateView() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.get(`/mailing-list/export-csv-template/`);
        });
    }
    SendEmailSmartAction(data) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/mailings/smart-action/send/`, Object.assign(Object.assign({}, data), { action: models_1.SendActionEnum.SendEmail }));
        });
    }
    SendSMSSmartAction(data) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/mailings/smart-action/send/`, Object.assign(Object.assign({}, data), { action: models_1.SendActionEnum.SendSMS }));
        });
    }
    SendMailSmartAction(data) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/mailings/smart-action/send/`, Object.assign(Object.assign({}, data), { action: models_1.SendActionEnum.SendMail }));
        });
    }
    DownloadSmartAction(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.get(`/mailings/smart-action/filter-download/`, params);
        });
    }
    ReadNotification(mailing_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/mailings/smart-action/read/`, { mailing_id });
        });
    }
    createTag(name, agent_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/mailing/segmentation/`, { name, agent_id });
        });
    }
    listTags(type, agent_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.get(`/mailing/segmentation/`, { type, agent_id });
        });
    }
    deleteTag(tag_id, agent_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.delete(`/mailing/segmentation/${tag_id}/`, { agent_id });
        });
    }
    fetchMailingPlunk(mailing_id, from_lp = false) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.get(`/mailing/plunk/refresh/`, { mailing_id, from_lp });
        });
    }
    getMailingPlunk(mailing_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.get(`/mailing/plunk/info/`, { mailing_id });
        });
    }
    enhancedMailingList(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/mailing-list/enhanced/`, params);
        });
    }
    premiumDataMailingList(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/mailing-list/premium/`, params);
        });
    }
    checkMailingAddress(mailing_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.get(`/mailing/${mailing_id}/address-checking/`);
        });
    }
    getCampaignHistory(mailing_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `/mailings/${mailing_id}/campaign-history/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, {});
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.getCampaignHistory,
                });
            return response;
        });
    }
    getREANeighborhoods(page = 1, limit = 20, agent_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `/mailings/neighborhoods/`;
            const params = { page, limit, agent_id };
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.getREANeighborhoods,
                });
            return response;
        });
    }
    getLoanTypes(page = 1, limit = 20, agent_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `/mailings/loan-types/`;
            const params = { page, limit, agent_id };
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.getLoanTypes,
                });
            return response;
        });
    }
    saveMailingFallback(mailing_id, params, is_owner, mls_data, mailing_list_data) {
        return __awaiter(this, void 0, void 0, function* () {
            if (is_owner) {
                params['is_owner'] = true;
            }
            params['mls_data'] = mls_data;
            params['mailing_list_data'] = mailing_list_data;
            return this.client.post(`/mailings/${mailing_id}/save-fallback/`, params);
        });
    }
    fetchMailingSkipTrace(mailing_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.get(`/mailing/skip-trace/refresh/`, { mailing_id });
        });
    }
    getMailingSkipTrace(mailing_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `/mailing/skip-trace/info/`;
            const params = { mailing_id };
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.getMailingSkipTrace,
                });
            return response;
        });
    }
    exportMailingList(mailing_list_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const param = { mailing_list_id };
            return this.client.get(`/mailing-list/export/`, param);
        });
    }
}
exports.MailingListApi = MailingListApi;
