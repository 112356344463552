import { Tooltip } from 'antd';
import classNames from 'classnames';
import { Dispatch, FC, SetStateAction } from 'react';
import { color } from '@/styles/theme';
import { imageKeywords } from '@/utils/constant';
import { variableInstruction } from '../../helpers';
import { VariableType } from '../../hooks/useFetchVariablesByType';
import { VariableTag } from '../../styles/message-variable.style';

interface RenderVariablesProps {
  variableKey?: string;
  variableType?: string;
  isNotAvailable?: any;
  availablePostgridVar?: any;
  isEmailVariable?: boolean;
  preprocessCopyValue?: (variableKey: string, value: string) => string;
  onSelectVariable?: any;
  onCopyVariable?: any;
  isHandwritten?: boolean;
  uniqueArray: Array<any>;
  variableTooltip: any;
  disabledImageList: Array<any>;
  setVariableTooltip: Dispatch<SetStateAction<string>>;
}

const RenderVariablesView: FC<RenderVariablesProps> = (props) => {
  const {
    variableKey,
    variableType,
    isNotAvailable,
    availablePostgridVar,
    isEmailVariable,
    preprocessCopyValue,
    onSelectVariable,
    onCopyVariable,
    isHandwritten,
    uniqueArray,
    variableTooltip,
    disabledImageList,
    setVariableTooltip,
  } = props;

  return (
    <div className="variable-list">
      {uniqueArray?.map((v) => {
        const isImageVariable = imageKeywords.some((imgKey) => v.label?.includes(imgKey));

        return (
          <Tooltip
            key={v.value}
            title={
              variableTooltip && !isEmailVariable
                ? variableTooltip
                : isImageVariable && disabledImageList?.includes(v.label)
                ? 'The image of this address is unavailable'
                : isNotAvailable?.(v.label, availablePostgridVar)
                ? 'Coming Soon'
                : isImageVariable && !isEmailVariable
                ? variableInstruction?.()
                : isEmailVariable
                ? ''
                : 'Copy to clipboard'
            }
            color={color.WHITE}
            overlayStyle={{
              border: 'none',
            }}
            overlayInnerStyle={{
              fontSize: '10px',
              fontWeight: 600,
              color: color.DARKBLUE,
              borderRadius: '4px',
              border: 'none',
              transform: 'translateY(2px)',
            }}
            align={{ offset: [0, 4] }}
            destroyTooltipOnHide={{ keepParent: false }}
            autoAdjustOverflow={false}
            mouseLeaveDelay={0}
            mouseEnterDelay={0}
          >
            <VariableTag
              className={classNames({
                'email-variable': isEmailVariable,
                'not-available': isNotAvailable?.(v.label, availablePostgridVar),
                'image-variable': isImageVariable,
                'hide-image-variable': isImageVariable && (variableType === VariableType.SMS || isHandwritten),
                'disable-image-var': isImageVariable && disabledImageList?.includes(v.label),
              })}
              onClick={() => {
                if (isImageVariable && disabledImageList?.includes(v.label)) return;
                if (isNotAvailable?.(v.label, availablePostgridVar)) return;
                const copiedValue =
                  variableKey && preprocessCopyValue ? preprocessCopyValue(variableKey, v.value) : v.value;
                isEmailVariable && onSelectVariable
                  ? onSelectVariable?.(copiedValue, isImageVariable)
                  : onCopyVariable?.(copiedValue);
                !isEmailVariable && setVariableTooltip('Copied!');
              }}
              onMouseEnter={() => setVariableTooltip('')}
              onMouseLeave={() => setVariableTooltip('')}
            >
              {v.label}
            </VariableTag>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default RenderVariablesView;
