import {
  Mailing,
  MailingListStatusEnum,
  MailingListTypeEnum,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/mailing/models';
import { SEND_ADDRESS } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';
import { User } from '@goldfishcode/first-team-real-estate-sdk/libs/api/user/models';
import { Pagination } from '@goldfishcode/first-team-real-estate-sdk/libs/type';
import _ from 'lodash';
import { all, call, CallEffect, ForkEffect, put, select, takeLatest } from 'redux-saga/effects';
import { AlertTextInfo } from '@/components/alert/NotificationAlert';
import { MLSShowAddressEventData, ShowAddressesMode } from '@/models';
import AppAction from '@/reducers/app/action';
import MailingService from '@/services/mailing';
import { limit_mailing_list } from '@/utils/constant';
import RoutePaths from '@/utils/routes';
import { ActionBase } from '..';
import { RootState } from '../model';
import MailingAction from './action';
import { filterSearch, getDuplicatedHouses, uniqMailings } from './helper';
import { DuplicatedMailingListEventData } from '@/models/DuplicatedMailingListEventData';
import { showPopup } from '@/components/alert/SweetAlert';
import { navigate } from 'gatsby';

function* watchListMailingList(action: ActionBase) {
  const { agent_id, page, limit, name = '' } = action.payload;
  try {
    const user: User = yield select((state: RootState) => state.auth.myProfile);

    yield put({
      type: MailingAction.UPDATE_MAILING_LOADING,
      payload: true,
    });
    const mailingList = yield MailingService.listMailingList(
      page,
      limit,
      name,
      undefined,
      undefined,
      undefined,
      undefined,
      Date.now(),
      agent_id,
    );
    yield put({
      type: MailingAction.LIST_MAILING_LIST_SUCCESS,
      payload: mailingList,
    });

    if (!user.is_first_mailing_list && !mailingList?.count) {
      yield put({
        type: AppAction.VISIBLE_MODAL_ADD_MAILING,
        payload: true,
      });
    }
  } catch (err) {
    // Do nothing
  } finally {
    yield put({
      type: MailingAction.UPDATE_MAILING_LOADING,
      payload: false,
    });
  }
}

function* watchListMailingListCombine(action) {
  const { page = 1, ssid } = action.payload;
  try {
    yield put({
      type: MailingAction.UPDATE_FETCHING,
      payload: true,
    });
    const resMailingList = yield call(
      MailingService.listMailingList,
      page,
      limit_mailing_list,
      undefined,
      undefined,
      true,
      undefined,
      undefined,
      Date.now(),
      ssid,
    );

    if (!resMailingList?.results?.length && window.location.pathname?.includes(RoutePaths.AGENT_REPORT)) {
      yield put({
        type: AppAction.VISIBLE_MESSAGE_NODATA_MODAL,
        payload: true,
      });
      return;
    }
    resMailingList.override = page === 1;
    yield put({
      type: MailingAction.UPDATE_MAILING_LIST_COMBINE,
      payload: resMailingList,
    });
  } catch (error: any) {
    // Do nothing
  } finally {
    yield put({
      type: MailingAction.UPDATE_FETCHING,
      payload: false,
    });
  }
}

function* watchListPastClient(action: ActionBase) {
  try {
    yield put({
      type: MailingAction.UPDATE_MAILING_LOADING,
      payload: true,
    });

    const mailingList = yield MailingService.listMailingList(
      action.payload.page,
      action.payload.limit,
      action.payload?.name,
      MailingListTypeEnum.Past_Client,
      undefined,
      undefined,
      undefined,
      Date.now(),
    );
    yield put({
      type: MailingAction.LIST_PAST_CLIENTS_SUCCESS,
      payload: mailingList,
    });
  } catch (err) {
    // Do nothing
  } finally {
    yield put({
      type: MailingAction.UPDATE_MAILING_LOADING,
      payload: false,
    });
  }
}
function* watchListLead(action: ActionBase) {
  try {
    yield put({
      type: MailingAction.UPDATE_MAILING_LOADING,
      payload: true,
    });
    const leadList = yield MailingService.listMailingList(
      action.payload.page,
      action.payload.limit,
      action.payload?.name,
      MailingListTypeEnum.Lead,
      undefined,
      undefined,
      undefined,
      Date.now(),
    );
    yield put({
      type: MailingAction.LIST_LEADS_SUCCESS,
      payload: leadList,
    });
  } catch (err) {
    // Do nothing
  } finally {
    yield put({
      type: MailingAction.UPDATE_MAILING_LOADING,
      payload: false,
    });
  }
}

function* watchFetchMailingAddresses(action: ActionBase) {
  const { type, params } = action.payload;
  // Put some logic temporary to solved right now. Need to remove in the future
  if (typeof params?.is_realtor === 'boolean') {
    params.isRealtor = params?.is_realtor
      ? SEND_ADDRESS.SEND_ADDRESSES_REALTOR
      : SEND_ADDRESS.SEND_ADDRESSES_NOT_REALTOR;
  } else if (typeof params.isRealtor === 'undefined') {
    params.isRealtor = params.send_to || SEND_ADDRESS.SEND_ADDRESSES_ALL;
  }

  if (typeof params.send_to === 'undefined') {
    params.send_to = params.isRealtor || SEND_ADDRESS.SEND_ADDRESSES_ALL;
  }
  params['ownerOccupied'] = params?.ownerOccupied ?? params?.owner_occupied;
  params['ownerOutOfState'] = params?.ownerOutOfState ?? params?.owner_out_of_state;
  //================================================

  yield put({
    type: MailingAction.SET_ADDRESSES_COUNT_LOADING,
    payload: true,
  });
  if (type === ShowAddressesMode.CAMPAIGN_BUILDER) {
    // Break call API when user exit UI of Create Campaign Builder tab
    const isLoading = yield select((state: RootState) => state.mailing.addressesFoundCountLoading);
    if (!isLoading) return;

    // Make the initial API call to get the total count
    const initialRes: Pagination<Mailing> = yield call(MailingService.listAddressesCampaign, {
      ...params,
      page: 1,
      limit: 1,
    });

    const totalCount = initialRes.count;

    // Default limit to chunk
    const limit = 900;
    // Calculate the total number of pages
    const totalPages = Math.ceil(totalCount / limit);

    let allAddresses: Mailing[] = [];

    // Use Saga effects to call APIs in parallel
    const calls: Array<CallEffect> = [];
    for (let currentPage = 1; currentPage <= totalPages; currentPage++) {
      calls.push(call(MailingService.listAddressesCampaign, { ...params, page: currentPage, limit }));
    }
    const results = yield all(calls);
    results.forEach((res: Pagination<Mailing>) => {
      const addresses = res.results;
      allAddresses = allAddresses.concat(addresses);
    });

    let mailings = uniqMailings(allAddresses);
    // Has duplicated homes
    mailings = mailings
      .map((v) => {
        // const isDuplicated = duplicates.includes(v.id);
        return {
          ...v,
          selectedToSend: v.is_available_for_campaign,
          is_available_for_campaign: v.is_available_for_campaign,
          isDuplicated: false,
        };
      })
      .sort((a: any, b: any) => a.isDuplicated - b.isDuplicated);
    // }
    const sentListFilter = mailings.filter(
      (v) =>
        v.is_available_for_campaign &&
        (typeof params?.is_realtor === 'undefined' || v?.is_realtor === params?.is_realtor) &&
        (typeof params?.owner_occupied === 'undefined' || v?.owner_occupied === params?.owner_occupied) &&
        (typeof params?.owner_out_of_state === 'undefined' || v?.owner_out_of_state === params?.owner_out_of_state),
    );
    let updatedCount = sentListFilter.length;
    if (params?.textSearch) {
      updatedCount = filterSearch(sentListFilter, params?.textSearch).length;
    }

    yield put({
      type: MailingAction.SET_ADDRESSES_COUNT_CAMPAIGN,
      payload: {
        count: totalCount,
        updatedCount,
        params,
        mailings,
      },
    });
  } else {
    let totalCount = 0;
    let page = 1;
    const limit = 900; // default limit to chunk

    let allAddresses: Mailing[] = [];
    while (true) {
      // Break call API when user exit UI of MLS tab
      const isLoading = yield select((state: RootState) => state.mailing.addressesFoundCountLoading);
      if (!isLoading) break;

      try {
        Object.keys(params).forEach((key) => {
          if (params[key] === null) {
            delete params[key];
          }
        });
        const res: Pagination<Mailing> = yield call(MailingService.listAddressesMLS, { ...params, page, limit });
        totalCount = res.count;

        const addresses = res.results;

        allAddresses = allAddresses.concat(addresses);
        if (addresses.length < limit) {
          break;
        }
        page++;
      } catch (error) {
        break;
      }
    }
    let mailings = uniqMailings(allAddresses);
    // let mailings = _.uniqBy(allAddresses, (v) => v.id);

    const isCreateMLSCase = !params?.smart_trigger_action_id;

    const duplicates = getDuplicatedHouses(mailings);
    const isChangedMailingList = typeof params.isMailingListNotChanged === 'boolean' && !params.isMailingListNotChanged;

    if (mailings.length !== allAddresses.length) {
      // Has duplicated homes
      mailings = mailings
        .map((v) => {
          return {
            ...v,
            selectedToSend: v.is_available_for_campaign,
            is_available_for_campaign: v.is_available_for_campaign,
            isDuplicated: false,
          };
        })
        .sort((a: any, b: any) => a.isDuplicated - b.isDuplicated);
      if (isChangedMailingList) {
        // Filter states
        params.textSearch = '';
        params.filterState = undefined;

        // For only case edit mls, when change another mailing list. All mailings show to be sent by owner address.
        params.send_to_owner = mailings.map((v) => v.id);
        params.exclude_mailings = duplicates;

        delete params.isMailingListNotChanged;
      }
    } else {
      // Has not duplicated homes
      if (isChangedMailingList) {
        // Filter states
        params.textSearch = '';
        params.filterState = undefined;

        // For only case edit mls, when change another mailing list. All mailings show to be sent by owner address.
        params.send_to_owner = mailings.map((v) => v.id);
        params.exclude_mailings = [];

        delete params.isMailingListNotChanged;
      }
    }

    let sentList = mailings.filter((v) => {
      if (isCreateMLSCase) {
        return v.is_available_for_campaign;
      }
      return params?.exclude_mailings?.length > 0 ? !params?.exclude_mailings?.includes(v.id) : true;
    });
    if (params.isRealtor === SEND_ADDRESS.SEND_ADDRESSES_REALTOR) {
      sentList = sentList.filter((v) => v.is_realtor);
    } else if (params.isRealtor === SEND_ADDRESS.SEND_ADDRESSES_NOT_REALTOR) {
      sentList = sentList.filter((v) => !v.is_realtor);
    }
    //============================================
    const sentListFilter = sentList.filter(
      (v) =>
        (typeof params?.is_realtor === 'undefined' || v?.is_realtor === params?.is_realtor) &&
        (typeof params?.owner_occupied === 'undefined' || v?.owner_occupied === params?.owner_occupied) &&
        (typeof params?.owner_out_of_state === 'undefined' || v?.owner_out_of_state === params?.owner_out_of_state),
    );
    let updatedCount = sentListFilter.length;
    if (params?.textSearch) {
      updatedCount = filterSearch(sentListFilter, params?.textSearch).length;
    }

    // Only for create/edit MLS
    const sendListIds = sentList.map((v) => v.id);

    params.send_to_mailings = sendListIds;

    yield put({
      type: MailingAction.SET_ADDRESSES_COUNT_MLS,
      payload: {
        count: totalCount,
        updatedCount,
        params,
        mailings,
      },
    });
  }
  yield put({
    type: MailingAction.SET_ADDRESSES_COUNT_LOADING,
    payload: false,
  });
}
function* watchFetchMailingAddressesListenEvent(action: ActionBase) {
  const data: MLSShowAddressEventData = action.payload;

  yield AlertTextInfo(`${data.smart_trigger_action_name} just updated list of send mailings`);

  const isOpenAddressPopup = yield select((state: RootState) => state?.mailing.isOpenAddressPopup);

  if (!isOpenAddressPopup) return;
  const addressesFoundCountForMLS = yield select((state: RootState) => state?.mailing.addressesFoundCountForMLS);
  if (addressesFoundCountForMLS?.params?.smart_trigger_action_id !== data?.smart_trigger_action_id) return;
}

function* watchFetchOnDemandMailingAddressesListenEvent(action: ActionBase) {
  const data = action.payload;
  yield put({
    type: MailingAction.SET_MLS_UPDATED_FROM_EVENT,
    payload: data,
  });
}

function* mailingSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(MailingAction.LISTEN_TOGGLE_ON_DEMAND_MLS_DATA, watchFetchOnDemandMailingAddressesListenEvent);
  yield takeLatest(MailingAction.LISTEN_SHOW_ADDRESS_MLS_EVENT_DATA, watchFetchMailingAddressesListenEvent);
  yield takeLatest(MailingAction.LIST_MAILING_LIST_COMBINE, watchListMailingListCombine);
  yield takeLatest(MailingAction.LIST_MAILING_LIST, watchListMailingList);
  yield takeLatest(MailingAction.LIST_PAST_CLIENTS, watchListPastClient);
  yield takeLatest(MailingAction.LIST_LEADS, watchListLead);
  yield takeLatest(MailingAction.UPDATE_PARAMS_MAILING_ADDRESS_SENT, watchFetchMailingAddresses);
}

export default mailingSaga;
