"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubStatus = void 0;
var SubStatus;
(function (SubStatus) {
    SubStatus["UNPAID"] = "unpaid";
    SubStatus["CANCELED"] = "canceled";
    SubStatus["INCOMPLETE"] = "incomplete";
    SubStatus["INCOMPLETE_EXPIRED"] = "incomplete_expired";
})(SubStatus = exports.SubStatus || (exports.SubStatus = {}));
