import { SELECT_RULE } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';
import { SliderMarks } from 'antd/lib/slider';
import { times } from 'lodash';
import globalVariable from '@/config/env';

export const limitStep = 3;
export const limit_agent = 20;
export const limit_mailing = 1000;
export const limit_mailing_list = 50;
export const limit_mailing_list_all = 1000;
export const limit_smart_trigger = 10;
export const limit_past_order = 10;
export const team_member_page_size = 10;
export const limit_drip_campaign = 30;
export const limit_char_editor = 1500;
export const limit_row_editor = 40;
export const DEFAULT_PAGE_SIZE = 6;
export const PRIVATE_DATA_DEFAULT_PAGE_SIZE = 12;
export const MINIMUM_HOME_LIMIT_BOUNDARY = 200;
export const HOME_LIMIT_OFFSET = 500;
export const METAMASK_DOWNNLOAD_LINK = 'https://metamask.io/download';
export const SMART_ACTION_LIMIT = 20;
export const DRIP_CAMPAIGN_LIMIT = 10;

export const HIDDEN_VARIABLE = [
  '{Street Name Only Of Just Sold}',
  '{Street Name Only Of Just Listed}',
  '{Street Name Only Of Expired}',
  '{Street Name Only Of Coming Soon}',
];
// Default date for premium data to get all data
export const EMPTY_DATE_DEFAULT = '1900/01/01';

export const firstLoanType = [
  { label: 'Conventional', value: 'CLT' },
  { label: 'FHA', value: 'F' },
  { label: 'FHA Reverse Mortgage', value: 'F_RM' },
  { label: 'VA', value: 'V' },
];
export const firstLoanInterestRateType = [
  { label: 'Fixed', value: 'F' },
  { label: 'Graduated', value: 'G' },
  { label: 'Unknown', value: 'U' },
  { label: 'Variable', value: 'V' },
];

export const bedsMarks: SliderMarks = {
  0: '0',
  10: '10+',
};
export const bathsMarks: SliderMarks = {
  0: '0',
  10: '10+',
};
export const sqfeetMarks: SliderMarks = {
  100: '100',
  7500: '7500+',
};
export const equityMarks: SliderMarks = {
  0: '0%',
  100: '100%',
};
export const interestRateMarks: SliderMarks = {
  0: '0%',
  20: '20%',
};

export const avmMarks: SliderMarks = {
  200000: '200,000',
  50000000: '50,000,000+',
};
export const pricePerSqftMarks: SliderMarks = {
  200000: '200,000',
  50000000: '50,000,000+',
};

export const avgYearsOwnedMarks: SliderMarks = {
  0: '0',
  60: '60+',
};

export const domMarks: SliderMarks = {
  0: '0',
  365: '365+',
};
export const yearsOwnedMarks: SliderMarks = {
  0: '0',
  60: '60+',
};

export const storyMarks: SliderMarks = {
  1: '1',
  2: '2+',
};
export const ITEM_PER_SLIDE = 6;
export const ITEM_PER_SLIDE_DIGITAL = 4;
export const POSTGRID_TEMPLATE_LIMIT = ITEM_PER_SLIDE * 2;
export const QR_CODE_VAR_LIMIT = 4;
export const MAX_LENGTH_TEMPLATE_NAME = 200;
export const qrCodeVars = ['qr_code', ...times(QR_CODE_VAR_LIMIT).map((v) => `qr_code_${v + 1}`)];
const webappUrl = globalVariable.WEB_APP_URL || process.env.GATSBY_SITE_MAP_URL;

export const POSTGRID_DEFAULT_VALUES = {
  qr_code: `${webappUrl}/qr_code_fake.png`,
  broker_logo_white: 'https://production-homemeta-api-media.s3.amazonaws.com/media/images/FirstTeam+Logo+White.png',
  broker_logo_lpi: 'https://production-homemeta-api-media.s3.amazonaws.com/media/images/FT+LPI+Logo+Black.png',
  broker_logo_lpi_white: 'https://production-homemeta-api-media.s3.amazonaws.com/media/images/FT+LPI+Logo+White.png',
  broker_image: 'https://production-homemeta-api-media.s3.amazonaws.com/media/images/FirstTeam+Stack+Logo+Black.png',
  broker_image_white:
    'https://production-homemeta-api-media.s3.amazonaws.com/media/images/FirstTeam+Stack+Logo+White.png',
  broker_image_lpi: 'https://production-homemeta-api-media.s3.amazonaws.com/media/images/FT+LPI+Logo+Black.png',
  agent_image: (url: string) => url,
  agent_logo: (url: string) => url,
  broker_logo: (url: string) => url,
  NO_DATA_IMAGE:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
  NO_DATA_PREVIEW_ONLY_IMAGE: `${webappUrl}/NO_DATA_PREVIEW_ONLY_IMAGE.png`,
  crmls_logo: 'https://production-homemeta-api-media.s3.amazonaws.com/media/images/crmls_new.png',
  san_diego_logo: 'https://production-homemeta-api-media.s3.amazonaws.com/media/images/SanDiegoMLS.png',
  claw_logo: `https://production-homemeta-api-media.s3.amazonaws.com/media/images/claw.png`,
  dessert_area_logo: 'https://production-homemeta-api-media.s3.amazonaws.com/media/images/dessertarea.png',
  sboar_logo: 'https://production-homemeta-api-media.s3.amazonaws.com/media/images/sbaor.png',
};

export const LETTER_TEMPLATE_SIZE = '(8.5x11)';

export const imageRatio = {
  '1:1': (1 / 1) * 100,
  '3:2': (2 / 3) * 100,
  '4:3': (3 / 4) * 100,
  '16:9': (9 / 16) * 100,
};

export const startEmailFooter =
  '<img alt="crmls" height="40" src="https://production-homemeta-api-media.s3.amazonaws.com/media/images/crmls.png" style="float: left !important; margin-right: 16px !important; width: 100px; height: 40px;" width="100" />';
export const endEmailFooter = '<emailfooterend style="display:none"></emailfooterend>';

export const EMAIL_FOOTER_IMAGE_URL = 'https://production-homemeta-api-media.s3.amazonaws.com/media/images/crmls.png';

export const EMAIL_FOOTER = `<br /><br /><img alt="crmls" height="40" src="${EMAIL_FOOTER_IMAGE_URL}" style="float: left !important; margin-right: 16px !important; width: 100px; height: 40px;" width="100" /><span>
        Based on information from California Regional Multiple Listing Service, Inc. as of {{date_issued}} and/or
        other sources. All data, including all measurements and calculations of area, is obtained from various sources
        and has not been, and will not be, verified by broker or MLS. All information should be independently reviewed
        and verified for accuracy. Properties may or may not be listed by the office/agent presenting the information.
        </span><emailfooterend style="display:none"></emailfooterend>`;

export const selectedRuleListingFoundBroker = [
  { value: SELECT_RULE.NEAREST_HOMES, label: 'Nearest Homes to the one of the Listing found' },
  { value: SELECT_RULE.SIMILAR_HOMES, label: 'Nearest Homes with the similar features' },
];

export const imageKeywords = ['image', 'images', 'logo', 'avatar', 'qr'];

export const TRANSPARENT_IMAGE =
  'https://production-homemeta-api-media.s3.amazonaws.com/media/one_pixel_transparent.png';

export const CRMLS_IMAGE = `/CRMLS_IMAGE.png`;
export const SCAN_NOW_QR_CODE = `/scannow_qrcode.png`;

export const propertyMailingKeys = [
  'age',
  'gross_equity',
  'avm_value',
  'last_sold_date',
  'years_owned',
  'property_nbr_bedrooms',
  'property_nbr_bathrooms',
  'property_sqft',
  'property_stories',
];

export const premiumDataName = ['Divorce', 'Notice of Default', 'Affidavits of Death'];

export const SymplynoteFont = {
  Tarzan: 'astTracy',
  Stitch: 'astSpahn',
  Pinocchio: 'astPea',
  Simba: 'astSarah',
  Roo: 'astRobinl',
  Nemo: 'astNelson',
  Lumiere: 'astLukens',
  Kaa: 'UntitledTTF',
  Dumbo: 'astDunn',
  Bolt: 'astBowen',
  Belle: 'astBecca',
  Cinderella: 'Cinderella',
  Hercules: 'Hercules',
  Merlin: 'Merlin',
  Rapunzel: 'Rapunzel',
  Scar: 'Scar',
};

export const NOT_EXIST_ML_CODE = 11024;
export const acceptImageType = ['image/png', 'image/jpeg', 'image/jpg'];
export const acceptImageFileExtension = ['.png', '.jpeg', '.jpg'];

export const _ALL_HOMES_LABEL = 'All Homes';
export const _12_MONTH_TOTAL_TRANS__LABEL = '12 Mo Total Transactions';
