const regex = {
  password: new RegExp(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d[\]{};:=<>_+^#$@!%*?&]{8,}$/),
  phoneNumber: new RegExp(/^\+?1(\s?\([0-9]{3}\)|\s?[0-9]{3})[-\s]?[0-9]{3}[-\s]?[0-9]{4}$/),
  phoneNoFormat2: new RegExp(/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3} [0-9]{4}$/),
  phoneNo: new RegExp(/^\+[0-9][-. ]\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/),
  alphabet: new RegExp(/^[a-zA-Z ]*$/),
  alphabetAndNumber: new RegExp(/^[A-Za-z0-9_ ]*$/),
  mailingName: new RegExp(/[-@_!#$%^&*()<>?/[|\\\]`"+;.,'}{~:=]/),
  integerNumber: new RegExp(/^[0-9]+$/),
  integerNumberWithoutZero: new RegExp(/[^1-9]/g),
  regexIntegerAndFloat: new RegExp(/^[0-9]+(\.[0-9]+)?$/),
  regexSlug: new RegExp(/^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/),
  regexZipCodeDigital: new RegExp(/^\d{5}(,\d{5})*$/),
  clipValuesRegex: /clip: rect\(([^,]+), ([^,]+), ([^,]+), ([^)]+)\);/g,
  regexClip: /clip: rect\([^)]*\);/,
  emailRegex: new RegExp(/^[\w.-]+@([\w-]+\.)+[\w-]{1,4}$/),
};
export default regex;
