import { Empty, ModalProps } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { TemplateITemEnum } from '@/utils/enum';
import {
  PostGridTemplate,
  TemplateViewSide,
  UserPostGridTemplateFallback,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/postgrid/models';
import { cloneDeep, isEmpty } from 'lodash';
import FileImageOutLinedSvg from '@/assets/images/file-image-outline.svg';
import { replaceDataVariablesInHtml } from '@/utils/format';
import { PreviewModalBaseStyle } from '..';
import useToggleClass from '@/hooks/useToggleClass';
import {
  convertPostgridHtmlToRenderDOM,
  HandwrittenTemplateCustom,
} from '@/components/dashboard/campaign/ScrollTemplate/hooks/useScrollTemplate';
import { PreviewOnHomeItem } from '../modal-preview-template-with-mailing-list';
import { MailingExtra } from '@goldfishcode/first-team-real-estate-sdk/libs/api/tools/variable/models';
import { LETTER_TEMPLATE_SIZE } from '@/utils/constant';
import { getListingImageUrl } from '@/utils/utils';
import { CardTypeEnum } from '@goldfishcode/first-team-real-estate-sdk/libs/api/handwriting/models';

interface ModalPreviewTemplateWithMailingProps extends ModalProps {
  visible: boolean;
  className?: string;
  onCancel: () => void;
  data: PostGridTemplate | HandwrittenTemplateCustom;
  mailing: MailingExtra;
  fallbacks?: { [key: string]: string };
  templateFallback: UserPostGridTemplateFallback;
  isReplaceEmptyVar?: boolean;
}

const ModalPreviewTemplateWithMailing: FC<ModalPreviewTemplateWithMailingProps> = ({
  visible,
  data,
  onCancel,
  className,
  mailing,
  fallbacks = {},
  templateFallback,
  isReplaceEmptyVar,
  ...rest
}) => {
  const type = Object.keys(TemplateITemEnum).includes(data?.type)
    ? TemplateITemEnum[data?.type]
    : TemplateITemEnum.POSTCARD;

  const isHandwritten = type === TemplateITemEnum.HANDWRITTEN;

  const mailingFallback = cloneDeep({ ...mailing, fallback: fallbacks });

  const [previewThumbnailSide, setPreviewThumbnailSide] = useState<TemplateViewSide>(
    data?.side || TemplateViewSide.FRONT,
  );

  const renderPreviewData = (
    data: PostGridTemplate | HandwrittenTemplateCustom,
    mailing: MailingExtra,
    side?: TemplateViewSide,
  ) => {
    if (!data) return '';

    if (type === TemplateITemEnum.EMAIL) {
      return (
        (data as any).htmlString ||
        `<div class="no-template-img"><picture><img src="${FileImageOutLinedSvg}" alt="preview-template" class="no-template-img" /></picture></div>`
      );
    }

    if (
      (side === TemplateViewSide.FRONT && !(data as PostGridTemplate).front_template) ||
      (side === TemplateViewSide.BACK && !(data as PostGridTemplate).back_template)
    )
      return `<div class="no-template-img"><img src="${FileImageOutLinedSvg}" alt="preview-template" class="no-template-img" /></div>`;
    // Handle for Postgrid case - PHYSICAL MAIL CAMPAIGN
    const fallbackVariables = fallbacks;
    if (side === TemplateViewSide.FRONT) {
      const htmlString = convertPostgridHtmlToRenderDOM((data as PostGridTemplate).front_template || '', {
        id: mailing.id,
        mailing,
        isPreviewRawTemplate: false,
        agentImage: fallbackVariables.agent_image,
        agentLogo: fallbackVariables.agent_logo,
        brokerLogo: fallbackVariables.broker_logo,
        listingImage1: getListingImageUrl(fallbackVariables['listing_image'], fallbackVariables['listing_image_1']),
        listingImage2: getListingImageUrl(fallbackVariables['listing_image'], fallbackVariables['listing_image_2']),
        listingImage3: getListingImageUrl(fallbackVariables['listing_image'], fallbackVariables['listing_image_3']),
        listingImage4: getListingImageUrl(fallbackVariables['listing_image'], fallbackVariables['listing_image_4']),
        listingImage5: getListingImageUrl(fallbackVariables['listing_image'], fallbackVariables['listing_image_5']),
      });
      return replaceDataVariablesInHtml(htmlString, fallbackVariables, isReplaceEmptyVar);
    }
    if (side === TemplateViewSide.BACK) {
      const htmlString = convertPostgridHtmlToRenderDOM(
        ((data as HandwrittenTemplateCustom)?.card_type === CardTypeEnum.Flat
          ? (data as HandwrittenTemplateCustom)?.message_template
          : (data as HandwrittenTemplateCustom).back_template) || '',
        {
          id: mailing.id,
          mailing,
          isPreviewRawTemplate: false,
          agentImage: fallbackVariables.agent_image,
          agentLogo: fallbackVariables.agent_logo,
          brokerLogo: fallbackVariables.broker_logo,
          listingImage1: getListingImageUrl(fallbackVariables['listing_image'], fallbackVariables['listing_image_1']),
          listingImage2: getListingImageUrl(fallbackVariables['listing_image'], fallbackVariables['listing_image_2']),
          listingImage3: getListingImageUrl(fallbackVariables['listing_image'], fallbackVariables['listing_image_3']),
          listingImage4: getListingImageUrl(fallbackVariables['listing_image'], fallbackVariables['listing_image_4']),
          listingImage5: getListingImageUrl(fallbackVariables['listing_image'], fallbackVariables['listing_image_5']),
          isHandwritten,
        },
      );

      return replaceDataVariablesInHtml(htmlString, fallbackVariables, isReplaceEmptyVar);
    }

    if (side === TemplateViewSide.INSIDE) {
      const htmlString = convertPostgridHtmlToRenderDOM((data as HandwrittenTemplateCustom)?.message_template || '', {
        id: mailing.id,
        mailing,
        isPreviewRawTemplate: false,
        agentImage: fallbackVariables.agent_image,
        agentLogo: fallbackVariables.agent_logo,
        brokerLogo: fallbackVariables.broker_logo,
        listingImage1: getListingImageUrl(fallbackVariables['listing_image'], fallbackVariables['listing_image_1']),
        listingImage2: getListingImageUrl(fallbackVariables['listing_image'], fallbackVariables['listing_image_2']),
        listingImage3: getListingImageUrl(fallbackVariables['listing_image'], fallbackVariables['listing_image_3']),
        listingImage4: getListingImageUrl(fallbackVariables['listing_image'], fallbackVariables['listing_image_4']),
        listingImage5: getListingImageUrl(fallbackVariables['listing_image'], fallbackVariables['listing_image_5']),
        isHandwritten,
      });
      return replaceDataVariablesInHtml(htmlString, fallbackVariables, isReplaceEmptyVar);
    }
  };
  const getAddressHome = (mailing: MailingExtra) => {
    if (mailing.is_send_owner_address) {
      return (
        <>
          {mailing?.owner_address ? `${mailing?.owner_address}, ` : null}
          {mailing?.owner_city ? `${mailing?.owner_city}, ` : null}
          {mailing?.owner_state ? `${mailing?.owner_state} ` : null}
          {mailing?.owner_zip_code ? `${mailing?.owner_zip_code}` : null}
          {!mailing?.owner_address && !mailing?.owner_city && !mailing?.owner_state && !mailing?.owner_zip_code
            ? 'Owner Address: N/A'
            : null}
        </>
      );
    }
    if (mailing.id === 'fake_mailing_id') {
      return (
        <p className="template-type">
          {type === TemplateITemEnum.POSTCARD
            ? (data as PostGridTemplate)?.postcard_size
              ? `POSTCARD (${(data as PostGridTemplate)?.postcard_size})`
              : 'POSTCARD'
            : type === TemplateITemEnum.LETTER
            ? `LETTER ${LETTER_TEMPLATE_SIZE}`
            : type === TemplateITemEnum.EMAIL
            ? data?.name
            : type === TemplateITemEnum.HANDWRITTEN
            ? `HANDWRITTEN LETTER (${
                (data as HandwrittenTemplateCustom)?.card_type === 'folded5x7' ? 'FOLDED 5x7' : 'FLAT 5x7'
              })`
            : null}
        </p>
      );
    }
    return (
      <>
        {mailing?.address ? `${mailing?.address}, ` : null}
        {mailing?.city ? `${mailing?.city}, ` : null}
        {mailing?.state ? `${mailing?.state} ` : null}
        {mailing?.zip_code ? `${mailing?.zip_code}` : null}
        {!mailing?.address && !mailing?.city && !mailing?.state && !mailing?.zip_code ? 'Address: N/A' : null}
      </>
    );
  };
  useToggleClass(visible);
  useEffect(() => {
    const side = data?.side || TemplateViewSide.FRONT;
    setPreviewThumbnailSide(side);
  }, [visible]);

  return (
    <PreviewModalBaseStyle
      visible={visible && !isEmpty(data)}
      width={[TemplateITemEnum.DIGITAL_AD_VIDEO, TemplateITemEnum.POSTCARD].includes(type) ? 1000 : ''}
      {...rest}
      footer={false}
      destroyOnClose={true}
      centered
      closable={false}
      onCancel={onCancel}
      getContainer={false}
      className={className}
    >
      {mailingFallback ? (
        <PreviewOnHomeItem
          key={mailingFallback.id}
          isFirst={true}
          item={mailingFallback}
          type={type}
          address={getAddressHome(mailingFallback)}
          htmlStringConverted={renderPreviewData(data, mailingFallback, previewThumbnailSide)}
          previewThumbnailSide={previewThumbnailSide}
          setPreviewThumbnailSide={setPreviewThumbnailSide}
          isUseFallbackButton={false}
          templateHtmlString={
            ((data as PostGridTemplate)?.front_template || '') + ((data as PostGridTemplate)?.back_template || '')
          }
          templateFallback={templateFallback}
          templateData={data}
        />
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </PreviewModalBaseStyle>
  );
};

export default ModalPreviewTemplateWithMailing;
