import { POSTGRID_TEMPLATE_LIMIT } from '@/utils/constant';
import { create } from 'zustand';

export interface EmailFilterType {
  search?: string;
  page?: number;
  limit?: number;
  agent_id?: string;
}

interface EmailScrollTemplateFilter {
  emailTemplateFilter: Partial<EmailFilterType>;
  setEmailTemplateFilter: (filter: Partial<EmailFilterType>) => void;
  emailTemplateCount?: number;
  setEmailTemplateCount: (count: number) => void;
  emailAdminTemplateCount?: number;
  setAdminEmailTemplateCount: (count: number) => void;
}

export const initialEmailTemplateFilter = { page: 1, limit: POSTGRID_TEMPLATE_LIMIT, search: '', agent_id: undefined };

export const useEmailTemplateFilter = create<EmailScrollTemplateFilter>((set) => {
  return {
    emailTemplateFilter: initialEmailTemplateFilter,
    setEmailTemplateFilter(filter) {
      set((state) => ({ emailTemplateFilter: { ...state.emailTemplateFilter, ...filter } }));
    },

    emailTemplateCount: undefined,
    setEmailTemplateCount(count) {
      set(() => ({ emailTemplateCount: count }));
    },

    emailAdminTemplateCount: 0,
    setAdminEmailTemplateCount(count) {
      set(() => ({ emailAdminTemplateCount: count }));
    },
  };
});
