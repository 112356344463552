"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Oauth2Auth = void 0;
const adapter_1 = require("./adapter");
const string_1 = require("../../../utils/string");
class Oauth2Auth extends adapter_1.AbstractAuthAdapter {
    login(username, password) {
        return __awaiter(this, void 0, void 0, function* () {
            const data = { username, password };
            const r = yield this.client.post('/o/token/', data, {}, false);
            yield this.setAuthToken(r);
            return r;
        });
    }
    getAuthToken() {
        return __awaiter(this, void 0, void 0, function* () {
            const config = this.client.getApiConfig();
            if (!config.session || !config.authSessionKey)
                return {
                    token_type: 'Bearer',
                    access_token: config.accessTokenAdmin || '',
                    refresh_token: '',
                    expires_in: new Date().valueOf(),
                };
            const json = yield config.session.get(config.authSessionKey);
            const token = string_1.tryParseJson(json);
            if (!token || !token.access_token) {
                return null;
            }
            if (!token || !token.access_token) {
                return null;
            }
            return token;
        });
    }
    refreshToken() {
        return __awaiter(this, void 0, void 0, function* () {
            const token = yield this.getAuthToken();
            if (!token) {
                return;
            }
            const data = { refresh_token: token.refresh_token };
            const r = yield this.client.post('/o/token/', data, {}, false);
            yield this.setAuthToken(r);
            return r;
        });
    }
    facebookLogin(_params) {
        throw new Error('Method not implemented.');
    }
    connectFacebook(_params) {
        throw new Error('Method not implemented.');
    }
    googleLogin(_params) {
        throw new Error('Method not implemented.');
    }
    twitterLogin(_params) {
        throw new Error('Method not implemented.');
    }
    linkedInLogin(_params) {
        throw new Error('Method not implemented.');
    }
}
exports.Oauth2Auth = Oauth2Auth;
