import { InnerLoading } from '@/components/loading/loadingBase';
import Show from '@/components/show';
import { color } from '@/styles/theme';
import React, { memo } from 'react';
import { AppButtonStyled, ButtonLargeProps } from './AppButtonStyled';
import Tooltip from 'antd/es/tooltip';

const AppButtonView = memo((props: ButtonLargeProps) => {
  const { children, inferLoading, size = 'large', tooltipProps } = props;

  const renderButton = (
    <AppButtonStyled {...props} size={size}>
      <Show>
        <Show.When isTrue={inferLoading}>
          <InnerLoading iconStyle={{ color: color.WHITE }} />
        </Show.When>
        <Show.When isTrue={!inferLoading}>{children}</Show.When>
      </Show>
    </AppButtonStyled>
  );

  if (!tooltipProps) return renderButton;

  return (
    <Tooltip {...tooltipProps}>
      <div>{renderButton}</div>
    </Tooltip>
  );
});

export default AppButtonView;
