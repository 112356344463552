import { createStore, applyMiddleware, AnyAction, CombinedState, Store, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from '@/reducers';
import reduxReset from 'redux-reset';
import AppAction from '@/reducers/app/action';
import { RootState } from '@/reducers/model';

const sagaMiddleware = createSagaMiddleware();
const middleWares = [sagaMiddleware];

type StoreFunc = Store<CombinedState<RootState>, AnyAction> & {
  dispatch: unknown;
};

const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    typeof window !== 'undefined' &&
    window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const middleware = applyMiddleware(...middleWares);
const enhanceCreateStore = (composeEnhancers(middleware, reduxReset(AppAction.RESET_ROOT_STATE)) as any)(createStore);

const store = (): StoreFunc => {
  return enhanceCreateStore(rootReducer); // enhanceCreateStore(rootReducer, middleware);
};

const reduxStore = store();

export { sagaMiddleware, reduxStore };
