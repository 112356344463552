import loaderGif from '@/assets/gif/loading.gif';
import BaseModal from '@/components/Modal';
import { color } from '@/styles/theme';
import styled from '@emotion/styled';

export const CreateTemplateStyled = styled(BaseModal)`
  .ant-modal-content,
  .ant-modal-body,
  .content,
  .section-modal,
  .wrapper-modal {
    height: 100%;
  }
  .ant-modal-content {
    background: transparent;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    margin-bottom: 10px;
    height: 100%;
    .ant-modal-body {
      padding: 0;
      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .section-modal {
          width: 100%;
          font-weight: 300;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .wrapper-modal {
            padding: 10px 30px;
            border-radius: 15px;
            background-color: ${color.DARKBLUE};
            background-image: none;
            backdrop-filter: blur(14px);
            color: ${color.WHITE};
            width: 100%;
            overflow: auto;
            .editor-section {
              height: 100%;
              width: 100%;
              display: flex;
              max-height: 100vh;
              overflow: auto;
              .variable {
                position: sticky;
                top: 0;
                overflow: auto;
                overflow-x: hidden;
                width: 30%;
              }
              .handwritten-creator,
              .postgrid-creator {
                width: 68%;
              }
            }
            iframe {
              height: calc(100% - 32px);
            }
            .errorMsg {
              font-weight: 700;
            }
            .template-title {
              text-align: center;
              margin-bottom: 15px;
              .title {
                font-family: FiraSans;
                font-size: 32px;
                font-weight: 700;
                text-align: center;
                color: ${color.WHITE} !important;
                margin: 0 6px;
              }
              .sub-title {
                font-weight: 600;
                color: ${color.WHITE} !important;
              }
            }
            .template-editor {
              position: relative;
              height: 100%;
              max-height: 880px;
              background: ${`transparent url(${loaderGif}) center center no-repeat`};
              .empty {
                height: 10px;
              }
            }
            .title-side {
              display: flex;
              gap: 10px;
              margin-bottom: 5px;
              & p {
                font-size: 20px;
                font-weight: 700;
                text-align: left;
                color: ${color.WHITE} !important;
              }
            }
            .mr-10 {
              margin: 10px;
            }
            .modal-header {
              display: flex;
              justify-content: space-between;
            }
            .notice,
            .notice > p {
              font-style: italic;
              font-weight: 500;
              font-size: 14px;
              color: ${color.WHITE} !important;
              margin-bottom: 5px;
            }
            .var-title {
              font-weight: 700 !important;
              text-align: center;
              font-style: normal !important;
              font-size: 20px !important;
            }
          }
          .invisible,
          .template-title,
          .button-group {
            width: 430px;
          }
          .button-group {
            max-width: 552px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 5px;
            background: ${color.DARKBLUE};
            margin-bottom: 10px;
            border-radius: 15px;
            p {
              font-style: italic;
              font-weight: 500;
              font-size: 14px;
              color: ${color.WHITE} !important;
            }
            .button-field {
              display: flex;
              justify-content: end;
              gap: 10px;
              button {
                text-transform: uppercase;
                font-weight: 700;
                &:hover,
                &:focus {
                  letter-spacing: 1px;
                }
                .anticon-loading {
                  top: -8px;
                }
              }
            }
          }
          .side-btn {
            display: flex;
            gap: 5px;
            .ant-btn {
              background-color: transparent;
              border-color: ${color.PINK};
              color: ${color.WHITE};
              font-weight: 600;
              &.active {
                background-color: ${color.PINK};
              }
            }
          }
        }
      }
    }
  }
`;
