import styled from '@emotion/styled';

const UploadDigitalMediaViewContainer = styled.div<{ buttonColor?: string }>`
  .digital-btn_label_group {
    & .digital-btn_label {
      display: flex;
      gap: 6px;
      align-items: center;
      label {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .upload-file {
      display: flex;
      margin-top: 2px;
      margin-bottom: 24px;
      position: relative;
      gap: 10px;
      .selected-file {
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${(props) => (props.buttonColor === 'black' ? '#2e0249' : '#fff')};
        font-size: 14px;
        h3 {
          color: ${(props) => (props.buttonColor === 'black' ? '#2e0249' : '#fff')};
          font-size: 14px;
          text-align: left;
          margin: 0;
        }
        p {
          color: ${(props) => (props.buttonColor === 'black' ? '#2e0249' : '#fff')};
          font-size: 14px;
          margin-top: 5px;
        }
        img {
          margin-left: 50px;
          cursor: pointer;
        }
      }
      .file-name-preview-container {
        display: flex;
        flex-direction: column;
        align-items: end;
      }
    }
  }

  &.handwritten-upload-image {
    .upload-file {
      display: flex;
      flex-direction: column;
      align-items: baseline;
    }
  }
  .media-container {
    display: flex;
    gap: 10px;
  }
`;
export default UploadDigitalMediaViewContainer;
