import { formatCommas } from './formatNumber';

export const formatCurrency = (value: number | string, removeDecimalZero = false): number | string => {
  const convertedValue = +value;
  if (removeDecimalZero && +(convertedValue % 1).toFixed(2) === 0) {
    return `${formatCommas(convertedValue.toFixed(0))}`;
  }
  return formatCommas((convertedValue || 0).toFixed(2));
};

export enum CurrencySupport {
  USD = 'USD',
}

const currencyList = [
  {
    name: CurrencySupport.USD,
    value: '$',
  },
];

export const currencySymbol = (name: string): string => {
  const syb = currencyList.filter((val) => {
    return val.name === name.toUpperCase();
  });

  if (syb) {
    return syb[0].value;
  }
  return '';
};

export const formatCurrencySymbol = (value: number | string, currency: string, removeDecimalZero = false): string => {
  const symbol = currencySymbol(currency);
  if (+value < 0) {
    return `-${symbol}${formatCurrency(Math.abs(+value), removeDecimalZero)}`;
  } else {
    return `${symbol}${formatCurrency(+value, removeDecimalZero)}`;
  }
};

export const nFormatter = (value, digits = 1, currency = '') => {
  const prefix = currencySymbol(currency);
  let newValue = value;
  if (value >= 1000) {
    const suffixes = ['', 'k', 'm', 'b', 't'];
    const suffixNum = Math.floor(('' + value).length / 3);
    let shortValue: any = '';
    for (let precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat((suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(precision));
      const dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
      if (dotLessShortValue.length <= 2) {
        break;
      }
    }
    if (shortValue % 1 != 0) shortValue = shortValue.toFixed(digits);
    newValue = shortValue + suffixes[suffixNum];
  }
  return `${prefix}${newValue}`;
};
