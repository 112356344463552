import { useState } from 'react';
import { LatestFilters, MLS_STATUS_MAPPING, PC_PREFIX } from '../helpers';
import CampaignService from '@/services/campaign';
import { MLS_STATUS } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postgrid/models';
import MailingService from '@/services/mailing';
import { GetAutomateDataParams } from '../components/message-variable/MessageVariableView';

const useHandleAutomateVariables = () => {
  const [automateFilterData, setAutomateFilterData] = useState<LatestFilters>({});

  const getLatestMailings = async ({ isPastClient, mailingListId, variable }) => {
    const storeKey = isPastClient ? PC_PREFIX : variable;
    if (isPastClient) {
      const latestPcMailing = await CampaignService.getLatestMailingFromMailingListId(mailingListId);
      const isSameMLSStatus = latestPcMailing.mls_status === MLS_STATUS_MAPPING[status];
      if (latestPcMailing && isSameMLSStatus) {
        setAutomateFilterData((prev) => {
          return { ...prev, [storeKey]: { ...prev[storeKey], mailing: latestPcMailing } };
        });
      }
    } else {
      const latestMlsMailings = await MailingService.getNewestMlsMailings(
        mailingListId,
        variable === MLS_STATUS.MY_LISTINGS || variable === MLS_STATUS.LISTING_OF_MY_MAILING_LIST,
      );
      if (latestMlsMailings && latestMlsMailings[MLS_STATUS_MAPPING[status]]) {
        const latestMailing = latestMlsMailings[MLS_STATUS_MAPPING[status]];
        setAutomateFilterData((prev) => {
          return { ...prev, [storeKey]: { ...prev[storeKey], mailing: latestMailing } };
        });
      }
    }
  };

  const handleGetAutomateData = async (variable: string, params: GetAutomateDataParams) => {
    const isPastClient = variable?.startsWith(PC_PREFIX);
    let status = automateFilterData[variable]?.status;
    let mailingListId = automateFilterData[variable]?.mailingListId;

    if (Object.getOwnPropertyDescriptor(params, 'status')) {
      status = params.status || '';
      setAutomateFilterData((prev) => ({ ...prev, [variable]: { ...prev[variable], status, mailing: undefined } }));
    }
    if (Object.getOwnPropertyDescriptor(params, 'mailingListId')) {
      mailingListId = Array.isArray(params.mailingListId) ? params.mailingListId[0] : params.mailingListId || '';
      const mailingListLabel = params?.mailingListLabel || '';

      if (isPastClient) {
        const pastClientType = params?.pastClientType || null;
        setAutomateFilterData((prev) => ({
          ...prev,
          [variable]: {
            ...prev[variable],
            pastClientType,
            mailingListId,
            mailingListLabel,
            mailing: undefined,
          },
        }));
      } else {
        setAutomateFilterData((prev) => ({
          ...prev,
          [variable]: { ...prev[variable], mailingListId, mailingListLabel, mailing: undefined },
        }));
      }
    }

    if ((isPastClient || status) && mailingListId) getLatestMailings({ isPastClient, mailingListId, variable });
  };
  return {
    handleGetAutomateData,
    automateFilterData,
  };
};

export default useHandleAutomateVariables;
