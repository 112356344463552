import { css } from '@emotion/react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import useScaleTemplate from '@/hooks/useScaleTemplate';
import { RootState } from '@/reducers/model';
import { TemplateITemEnum } from '@/utils/enum';
import { convertPostgridHtmlToRenderDOM } from './hooks/useScrollTemplate';

interface RenderHTMLProps {
  id: string;
  className?: string;
  htmlString: string;
  width?: string; // Calculate by px
  height?: string; // Calculate by px
  type?: TemplateITemEnum;
  scaleMultiplier?: number;
  scale?: number;
  descaleX?: number;
  descaleY?: number;
  isPreviewRawTemplate?: boolean;
  isImageThumbnail?: boolean;
}

const RenderHTML: React.FC<RenderHTMLProps> = ({
  id,
  htmlString,
  width = '100%',
  height = '100%',
  className,
  type = TemplateITemEnum.LETTER,
  scaleMultiplier = 1,
  scale = 1,
  descaleX,
  descaleY,
  isPreviewRawTemplate,
  isImageThumbnail = false,
}) => {
  const profile = useSelector((state: RootState) => state.auth.myProfile);

  const containerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const elementRef = useRef<HTMLDivElement>(null);
  const [loadedTemplate, setLoadedTemplate] = useState(false);
  const { scaleX, scaleY } = useScaleTemplate(
    wrapperRef,
    elementRef,
    descaleX ?? scale,
    descaleY ?? scale,
    loadedTemplate || id,
  );
  useEffect(() => {
    if (!htmlString || !profile) return;
    const agentLogo = profile.logo;
    const agentImage = profile.avatar;
    const brokerLogo = profile.broker_logo;
    const isHandwritten = type === TemplateITemEnum.HANDWRITTEN;

    if (containerRef.current && containerRef.current.innerHTML.length > 0) {
      containerRef.current.innerHTML = '';
    }
    const tempContainer = document.createElement('div');
    const convertedHtmlString = convertPostgridHtmlToRenderDOM(htmlString, {
      id,
      agentLogo,
      agentImage,
      brokerLogo,
      isPreviewRawTemplate,
      isHandwritten,
    });
    tempContainer.innerHTML = convertedHtmlString;
    containerRef.current && containerRef.current?.appendChild(tempContainer);
    setLoadedTemplate(true);
  }, [htmlString, profile, id]);

  return (
    <div
      ref={wrapperRef}
      css={generateStyle({ scaleX, scaleY, width, height, type, scaleMultiplier })}
      className={className}
    >
      <div
        className={`${
          [TemplateITemEnum.DIGITAL_AD_IMAGE, TemplateITemEnum.DIGITAL_AD_VIDEO].includes(type)
            ? 'digital-scale-style'
            : isImageThumbnail
            ? 'postgrid-style'
            : 'postgrid-style postgrid-scale-style'
        }`}
        ref={elementRef}
      >
        {/* <div style={{ width: '100%' }} ref={containerRef} /> */}
        <div ref={containerRef} />
      </div>
    </div>
  );
};

const generateStyle = ({ scaleX, scaleY, width, height, type, scaleMultiplier }) => css`
  width: ${width};
  height: ${height};
  .postgrid-style {
    transform-origin: top left;
    position: absolute;
    top: ${type === TemplateITemEnum.POSTCARD ? '21%' : 'initial'};
    & > div {
      max-width: 100%;
      width: 100%;
    }

    &.postgrid-scale-style {
      transform: scale(${scaleX}, ${type === TemplateITemEnum.POSTCARD ? scaleY * scaleMultiplier : scaleY});
    }
  }
  .digital-scale-style {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .postgrid-fake-body {
    .page {
      height: 100%;
      width: 100%;
      ol {
        text-align: left;
        li {
          color: #000;
        }
      }
    }
  }
  .no-template-img {
    width: 700px;
    height: 420px;
    display: flex;
    justify-content: center;
    background-color: #fff;
    img {
      width: 220px;
    }
  }
  img {
    max-width: unset !important;
  }
`;

export default RenderHTML;
