import { CloseCircleOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { Modal } from 'antd';
import React, { FC, memo } from 'react';
import { PictureWallProps } from './PictureWallContainer';

interface PictureWallViewProps extends PictureWallProps {
  handleCancel: () => void;
  previewImage: string;
  previewOpen: boolean;
  handlePreviewImg: (url: string) => void;
}

const PictureWallView: FC<PictureWallViewProps> = memo((props) => {
  const { listPicture, handleRemoveImage, handleCancel, previewOpen, handlePreviewImg, previewImage } = props;
  return (
    <>
      <div className="list-img-wrapper">
        {Array.isArray(listPicture) &&
          listPicture?.map((img) => (
            <div className="img-container" key={img}>
              <div className="img-item" onClick={() => handlePreviewImg(img)}>
                <img src={img} alt="listing_image" className="upload-image" />
              </div>
              <CloseCircleOutlined onClick={() => handleRemoveImage(img)} />
            </div>
          ))}
      </div>
      <Modal
        open={previewOpen}
        centered
        footer={null}
        onCancel={handleCancel}
        css={css`
          .ant-modal-content {
            border-radius: 5px;
            .ant-modal-close-x {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 34px;
              height: 34px;
            }
          }
        `}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
});

export default PictureWallView;
