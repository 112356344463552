"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlatForm = exports.YesNo = exports.Months = void 0;
var Months;
(function (Months) {
    Months["January"] = "January";
    Months["February"] = "February";
    Months["March"] = "March";
    Months["April"] = "April";
    Months["May"] = "May";
    Months["June"] = "June";
    Months["July"] = "July";
    Months["August"] = "August";
    Months["September"] = "September";
    Months["October"] = "October";
    Months["November"] = "November";
    Months["December"] = "December";
})(Months = exports.Months || (exports.Months = {}));
var YesNo;
(function (YesNo) {
    YesNo["YES"] = "Y";
    YesNo["NO"] = "N";
})(YesNo = exports.YesNo || (exports.YesNo = {}));
var PlatForm;
(function (PlatForm) {
    PlatForm["IOS"] = "ios";
    PlatForm["ANDDROID"] = "android";
    PlatForm["WEB"] = "web";
})(PlatForm = exports.PlatForm || (exports.PlatForm = {}));
