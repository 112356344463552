"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentApi = void 0;
const caching_1 = require("../caching");
class PaymentApi {
    constructor(client) {
        this.client = client;
        this.staleTimes = {
            getStripeCardList: 5,
        };
        this.caching = new caching_1.Caching();
    }
    getStripeCardList(page = 1, limit = 20) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { page, limit };
            const endpoint = `/payment/cards/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.getStripeCardList,
                });
            return response;
        });
    }
    createStripeCard(args) {
        return __awaiter(this, void 0, void 0, function* () {
            this.caching.deleteCache('/payment/cards/');
            return this.client.post(`/payment/cards/`, args);
        });
    }
    deleteStripeCard(card_id) {
        return __awaiter(this, void 0, void 0, function* () {
            this.caching.deleteCache('/payment/cards/');
            return this.client.delete(`/payment/cards/${card_id}/`);
        });
    }
    setDefaultStripeCard(card_id) {
        return __awaiter(this, void 0, void 0, function* () {
            this.caching.deleteCache('/payment/cards/');
            return this.client.put(`/payment/cards/${card_id}/`, {
                is_default: true,
            });
        });
    }
}
exports.PaymentApi = PaymentApi;
