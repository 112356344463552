"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SocketIO = exports.SocketEventName = void 0;
const io = require("socket.io-client");
var SocketEventName;
(function (SocketEventName) {
    SocketEventName["Join"] = "join";
    SocketEventName["Connect"] = "connect";
    SocketEventName["Disconnect"] = "disconnect";
    SocketEventName["Unauthorized"] = "unauthorized";
    SocketEventName["Error"] = "error";
    SocketEventName["Close"] = "close";
    SocketEventName["Userstate"] = "userstate";
    SocketEventName["Subscribe"] = "channel:subscribe";
    SocketEventName["Unsubscribe"] = "channel:unsubscribe";
    SocketEventName["Typing"] = "typing";
    // Smart action
    SocketEventName["SmartActionInteract"] = "sa:interact";
    // Smart trigger
    SocketEventName["MLSTriggerSchedule"] = "mls:triger_schedule";
    SocketEventName["MLSTriggerOnDemand"] = "mls:ondemand_success";
    SocketEventName["DuplicatedMailings"] = "ml:duplicated_mailings";
})(SocketEventName = exports.SocketEventName || (exports.SocketEventName = {}));
class SocketIO {
    constructor() {
        this.socket = null;
    }
    static instance() {
        if (!SocketIO._instance) {
            SocketIO._instance = new SocketIO();
        }
        return SocketIO._instance;
    }
    get id() {
        if (!this.connected || !this.socket) {
            return null;
        }
        return this.socket.id;
    }
    get connected() {
        if (this.socket && this.socket.connected) {
            return true;
        }
        return false;
    }
    isConnected() {
        return this.connected;
    }
    close() {
        if (this.socket) {
            this.socket.close();
            this.socket = null;
        }
    }
    disconnect() {
        this.close();
    }
    connect(url, jwt) {
        if (this.isConnected()) {
            this.close();
        }
        this.socket = io(url, {
            transports: ['websocket'],
        });
        this.socket.on(SocketEventName.Connect, () => this.onConnect(jwt));
        this.socket.on(SocketEventName.Error, (e) => this.onError(e));
        this.socket.on(SocketEventName.Disconnect, () => this.onDisconnect());
    }
    onConnect(jwt) {
        var _a;
        (_a = this.socket) === null || _a === void 0 ? void 0 : _a.emit(SocketEventName.Join, jwt);
    }
    onDisconnect() {
        var _a;
        (_a = this.socket) === null || _a === void 0 ? void 0 : _a.removeAllListeners();
        this.socket = null;
    }
    onError(err) {
        console.log(`Error: ${this.id}`, err);
    }
    on(eventName, listener) {
        if (!this.socket) {
            return;
        }
        this.socket.on(eventName, listener);
    }
    emit(eventName, payload) {
        var _a;
        if (!this.connected) {
            return this;
        }
        (_a = this.socket) === null || _a === void 0 ? void 0 : _a.emit(eventName, payload);
        return this;
    }
    /**
     * Add an event handler for given eventName
     *
     * @param {string} eventName
     * @param {function|object} listener a function or an instance of ISocketEventListener
     */
    addEventListener(eventName, listener) {
        if (!eventName || !this.socket) {
            return this;
        }
        this.socket.addEventListener(eventName, listener);
        return this;
    }
    removeEventListener(eventName, listener) {
        var _a;
        if ((_a = this.socket) === null || _a === void 0 ? void 0 : _a.hasListeners(eventName)) {
            this.socket.removeListener(eventName, listener);
        }
        return this;
    }
    /**
     * Subscriber to get user state (Online/Offline, Last active time)
     * @param userId string
     * @param listener EventListener
     */
    addUserStateListener(userId, listener) {
        const event = `${SocketEventName.Userstate}:${userId}`;
        this.on(event, listener);
        this.emit(SocketEventName.Userstate, userId);
        return this;
    }
    removeUserStateListener(userId, listener) {
        const event = `${SocketEventName.Userstate}:${userId}`;
        return this.removeEventListener(event, listener);
    }
    subscribe(channelId) {
        this.emit(SocketEventName.Subscribe, channelId);
        return this;
    }
    unsubscribe(channelId) {
        this.emit(SocketEventName.Unsubscribe, channelId);
        return this;
    }
}
exports.SocketIO = SocketIO;
