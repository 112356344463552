import { create } from 'zustand';

interface UseLandingPageStore {
  landingPages: { [key: string]: any };
  isLoading: boolean;
  setOptionLandingPage: (landingPageType, values: any[]) => void;
  setIsLoading: (value: boolean) => void;
}

const useLandingPageStore = create<UseLandingPageStore>((set) => ({
  isLoading: false,
  landingPages: {},
  setIsLoading: (loading: boolean) => set((state) => ({ ...state, isLoading: loading })),
  setOptionLandingPage: (landingPageType, values: any[]) =>
    set((state) => ({
      ...state,
      landingPages: {
        ...state.landingPages,
        [landingPageType]: values,
      },
    })),
}));

export default useLandingPageStore;
