import { RefObject, useCallback } from 'react';

import { PREFIX, ROTATION_INITIAL } from '../constants';
import { EasyCropRef } from '../types';

const useCrop = (easyCropRef: RefObject<EasyCropRef>, rotationSlider: boolean, fillColor: string) => {
  const getCropCanvas = useCallback(
    (target: ShadowRoot) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
      const context = (target?.getRootNode?.() as ShadowRoot) || document;

      type ImgSource = CanvasImageSource & {
        naturalWidth: number;
        naturalHeight: number;
      };

      const imgSource = context.querySelector(`.${PREFIX}-media`) as ImgSource;

      if (easyCropRef.current && easyCropRef.current.cropPixelsRef.current) {
        const { width: cropWidth, height: cropHeight, x: cropX, y: cropY } = easyCropRef.current.cropPixelsRef.current;

        if (rotationSlider && easyCropRef.current.rotation !== ROTATION_INITIAL) {
          const { naturalWidth: imgWidth, naturalHeight: imgHeight } = imgSource;
          const angle = easyCropRef.current.rotation * (Math.PI / 180);

          // get container for rotated image
          const sine = Math.abs(Math.sin(angle));
          const cosine = Math.abs(Math.cos(angle));
          const squareWidth = imgWidth * cosine + imgHeight * sine;
          const squareHeight = imgHeight * cosine + imgWidth * sine;

          canvas.width = squareWidth;
          canvas.height = squareHeight;
          ctx.fillStyle = fillColor;
          ctx.fillRect(0, 0, squareWidth, squareHeight);

          // rotate container
          const squareHalfWidth = squareWidth / 2;
          const squareHalfHeight = squareHeight / 2;
          ctx.translate(squareHalfWidth, squareHalfHeight);
          ctx.rotate(angle);
          ctx.translate(-squareHalfWidth, -squareHalfHeight);

          // draw rotated image
          const imgX = (squareWidth - imgWidth) / 2;
          const imgY = (squareHeight - imgHeight) / 2;
          ctx.drawImage(imgSource, 0, 0, imgWidth, imgHeight, imgX, imgY, imgWidth, imgHeight);

          // crop rotated image
          const imgData = ctx.getImageData(0, 0, squareWidth, squareHeight);
          canvas.width = cropWidth;
          canvas.height = cropHeight;
          ctx.putImageData(imgData, -cropX, -cropY);
        } else {
          canvas.width = cropWidth;
          canvas.height = cropHeight;
          ctx.fillStyle = fillColor;
          ctx.fillRect(0, 0, cropWidth, cropHeight);

          ctx.drawImage(imgSource, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
        }
      }

      return canvas;
    },
    [fillColor, rotationSlider],
  );

  return { getCropCanvas };
};

export default useCrop;
