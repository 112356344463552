import { AppSizeType, color, heightSize } from '@/styles/theme';
import styled from '@emotion/styled';
import { CSSProperties } from 'react';

export type SelectColor = 'PINK' | 'DARKBLUE' | 'WHITE' | 'PINK_TRANSPARENT';
export type SelectSize = 'small' | 'middle' | 'large' | 'huge';
export interface SelectV2WrapperProps {
  horizontal?: boolean;
  size?: SelectSize;
  color?: SelectColor;
  valueIsNull?: boolean;
  noBorder?: boolean;
  squaredBorder?: boolean;
  heightExpanded?: boolean;
}

type ColorStyle = {
  color: CSSProperties['color'];
  backgroundColor: CSSProperties['backgroundColor'];
  borderColor?: CSSProperties['borderColor'];
};

type StyleWithSize = {
  borderWidth: CSSProperties['borderWidth'];
  borderStyle: CSSProperties['borderStyle'];
  borderRadius: CSSProperties['borderRadius'];
  height?: CSSProperties['height'];
};

type SelectionItemStyle = {
  lineHeight: CSSProperties['lineHeight'];
  fontSize: CSSProperties['fontSize'];
  fontWeight?: CSSProperties['fontWeight'];
};

type SelectionArrowStyle = {
  top: CSSProperties['top'];
};

const styleWithColor = new Map<SelectColor, ColorStyle>([
  [
    'PINK',
    {
      color: `${color.WHITE} !important`,
      backgroundColor: `${color.PINK} !important`,
      borderColor: `${color.PINK} !important`,
    },
  ],
  [
    'WHITE',
    {
      color: `${color.DARKBLUE} !important`,
      backgroundColor: `${color.WHITE} !important`,
      borderColor: `${color.DARKBLUE} !important`,
    },
  ],
  [
    'DARKBLUE',
    {
      color: `${color.WHITE} !important`,
      backgroundColor: `${color.DARKBLUE} !important`,
      borderColor: `${color.WHITE} !important`,
    },
  ],
  [
    'PINK_TRANSPARENT',
    {
      color: `${color.DARKBLUE} !important`,
      backgroundColor: `transparent !important`,
      borderColor: `${color.DARKBLUE} !important`,
    },
  ],
]);

const styleArrowWithSize = new Map<AppSizeType, SelectionArrowStyle>([
  ['small', { top: 16 }],
  ['middle', { top: 21 }],
  ['large', { top: 26 }],
  ['huge', { top: 37.5 }],
]);

const styleWithSize = new Map<AppSizeType, StyleWithSize>([
  [
    'small',
    {
      borderWidth: '2px !important',
      borderStyle: 'solid !important',
      borderRadius: '15px !important',
      ...heightSize.get('small'),
    },
  ],
  [
    'middle',
    {
      borderWidth: '1px !important',
      borderStyle: 'solid !important',
      borderRadius: '8px !important',
      ...heightSize.get('middle'),
    },
  ],
  [
    'large',
    {
      borderWidth: '1px !important',
      borderStyle: 'solid !important',
      borderRadius: '5px !important',
      ...heightSize.get('large'),
    },
  ],
  [
    'huge',
    {
      borderWidth: '1px !important',
      borderStyle: 'solid !important',
      borderRadius: '5px !important',
      ...heightSize.get('huge'),
    },
  ],
]);

const styledWithItem = new Map<AppSizeType, SelectionItemStyle>([
  ['small', { lineHeight: '30px !important', fontSize: '12px !important', fontWeight: 600 }],
  ['middle', { lineHeight: '40px !important', fontSize: '13px' }],
  ['large', { lineHeight: '48px !important', fontSize: '14px' }],
  ['huge', { lineHeight: '72px !important', fontSize: '15px' }],
]);

const Wrapper = styled.div<SelectV2WrapperProps>`
  display: flex;
  flex-direction: ${(props) => (props.horizontal ? 'row' : 'column')};
  .load-more-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
  }
  .ant-spin-nested-loading {
    ${({ size, squaredBorder }) => {
      if (size === 'small') {
        return {
          borderRadius: squaredBorder ? '5px' : '15px',
          overflow: 'hidden',
        };
      }
    }}
  }
  .ant-select-dropdown {
    .ant-select-item-option {
      text-align: start;
    }
  }
  .select-v2 {
    width: 100%;
    &.ant-select-disabled {
      .ant-select-selector {
        opacity: 0.8;
        border-color: ${color.BLACK_O30} !important;
        .ant-select-selection-item {
          color: ${color.BLACK_O30} !important;
        }
      }
    }
    .ant-select-arrow {
      ${({ size }) => styleArrowWithSize.get(size || 'large')};
    }
    .ant-select-selector {
      ${({ size = 'large', color: colorProps = 'WHITE', valueIsNull, noBorder, heightExpanded, squaredBorder }) => {
        const result = {
          ...styleWithSize.get(size),
          ...styleWithColor.get(colorProps === 'PINK' && valueIsNull ? 'PINK_TRANSPARENT' : colorProps),
        };
        if (noBorder) {
          result.borderWidth = '0px !important';
        }

        if (heightExpanded) {
          result.height = 'fit-content !important';
        }

        if (squaredBorder) {
          result.borderRadius = '5px !important';
          result.borderWidth = '1px !important';
        }

        return result;
      }}
      word-wrap: normal;
      width: 100%;
      text-align: start;
      &:after {
        display: inline-block;
        width: 0;
        visibility: hidden;
        content: '\a0';
      }
      input {
        cursor: pointer;
      }
      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        ${({ size }) => styledWithItem.get(size || 'large')};
      }
      .ant-select-selection-placeholder {
        ${({ color: colorProps, valueIsNull, squaredBorder }) => {
          let result: Partial<CSSProperties> = {};
          if (colorProps === 'PINK' && valueIsNull) {
            result = {
              color: color.DARKBLUE,
              fontWeight: 700,
            };
          }
          if (squaredBorder) {
            result.fontWeight = 400;
          }
          return result;
        }}
      }
    }
    &.ant-select-multiple {
      .ant-select-selector {
        padding-left: 4px;
        .ant-select-selection-item {
          line-height: 22px !important;
          height: 22px;
          ${({ color: colorProps }) => {
            if (colorProps === 'PINK') {
              return {
                backgroundColor: color.NONE,
                color: color.WHITE,
                borderColor: color.NONE,
              };
            }
          }}
        }
        .ant-select-selection-item-remove {
          ${({ color: colorProps }) => {
            if (colorProps === 'PINK') {
              return {
                color: color.WHITE,
              };
            }
          }}
        }
      }
    }

    .ant-select-arrow {
      .up-down-arrow {
        margin-top: -2px;
      }
    }
    &:not(.ant-select-disabled) {
      .ant-select-arrow {
        .icon.ant-select-suffix,
        .suffix-icon {
          color: ${({ color: colorProps, valueIsNull }) =>
            styleWithColor.get(colorProps === 'PINK' && valueIsNull ? 'WHITE' : colorProps || 'WHITE')?.color};
        }
      }
    }
    .ant-select-selection-search-input {
      height: 100% !important;
    }
    .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
      position: static !important;
    }
    .ant-select-clear {
      border-radius: 50%;
      ${({ color: colorProps, valueIsNull }) => {
        if (colorProps === 'PINK' && !valueIsNull) {
          return {
            color: `${color.WHITE} !important`,
            background: `${color.PINK} !important`,
          };
        }
      }}
    }
  }
`;

export const SelectV2Styled = {
  Wrapper,
};
