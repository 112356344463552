"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeCountryCode = exports.removePhoneNumberFormat = exports.formatPhoneNumber = void 0;
exports.formatPhoneNumber = (value, separator = '-') => {
    if (!(value === null || value === void 0 ? void 0 : value.length))
        return '';
    value = value.trim().replace(/([^0-9+])/g, '');
    if (!value.startsWith('+1')) {
        if (!value.startsWith('+'))
            value = '+1' + value;
        else if (value.length > 1)
            value = '+1' + value.slice(1);
    }
    const formatted = value.replace(/(\+?1)?( ?\d{1,3})?( ?-?\d{1,3})?( ?-?\d{1,4})?(\d)?/, function (_, p0, p1, p2, p3) {
        let output = '';
        if (p0) {
            output += '+1';
        }
        if (p1) {
            if (p1.match(/[^A-Z0-9]/gi)) {
                output += `(${p1})`;
            }
            else {
                p1 = p1.replace(/[^A-Z0-9]/gi, '');
                output += `(${p1})`;
            }
        }
        if (p2) {
            if (p2.match(/[^A-Z0-9]/gi)) {
                output += ` ${p2}`;
            }
            else {
                p2 = p2.replace(/[^A-Z0-9]/gi, '');
                output += ` ${p2}`;
            }
        }
        if (p3) {
            if (p3.match(/[^A-Z0-9]/gi)) {
                output += `${separator}${p3}`;
            }
            else {
                p3 = p3.replace(/[^A-Z0-9]/gi, '');
                output += `${separator}${p3}`;
            }
        }
        return output;
    });
    return formatted;
};
// Define the function to remove phone number formatting
exports.removePhoneNumberFormat = (phoneNumber) => {
    // Remove non-digit characters and ensure the country code is '+1'
    const digitsOnly = phoneNumber.replace(/\D/g, '');
    return '+' + digitsOnly;
};
exports.removeCountryCode = (phoneNumber) => {
    return phoneNumber.replace('+1(', '(');
};
