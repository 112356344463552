"use strict";
/* eslint-disable @typescript-eslint/no-explicit-any */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CampaignPreviewType = exports.MLS_STATUS = exports.OrderByEnum = exports.PostgridTemplateType = exports.TemplateViewSide = void 0;
var TemplateViewSide;
(function (TemplateViewSide) {
    TemplateViewSide["FRONT"] = "front";
    TemplateViewSide["BACK"] = "back";
    TemplateViewSide["INSIDE"] = "inside";
})(TemplateViewSide = exports.TemplateViewSide || (exports.TemplateViewSide = {}));
var PostgridTemplateType;
(function (PostgridTemplateType) {
    PostgridTemplateType["ANY"] = "ANY";
    PostgridTemplateType["POSTCARD"] = "POSTCARD";
    PostgridTemplateType["LETTER"] = "LETTER";
    PostgridTemplateType["HANDWRITTEN"] = "HANDWRITTEN";
})(PostgridTemplateType = exports.PostgridTemplateType || (exports.PostgridTemplateType = {}));
var OrderByEnum;
(function (OrderByEnum) {
    OrderByEnum["MIN_INTERACTION_RATE"] = "min_interaction_rate";
    OrderByEnum["MIN_INTERACTION_RATE_DESC"] = "-min_interaction_rate";
    OrderByEnum["MAX_INTERACTION_RATE"] = "max_interaction_rate";
    OrderByEnum["MAX_INTERACTION_RATE_DESC"] = "-max_interaction_rate";
    OrderByEnum["QR_CODE_INTERACTION"] = "qr_code_interactions";
    OrderByEnum["QR_CODE_INTERACTION_DESC"] = "-qr_code_interactions";
    OrderByEnum["CALL_INTERACTION"] = "call_interactions";
    OrderByEnum["CALL_INTERACTION_DESC"] = "-call_interactions";
    OrderByEnum["SMS_INTERACTION"] = "sms_interactions";
    OrderByEnum["SMS_INTERACTION_DESC"] = "-sms_interactions";
    OrderByEnum["CALENDAR_APPOINTMENT"] = "calendar_appointment_interactions";
    OrderByEnum["CALENDAR_APPOINTMENT_DESC"] = "-calendar_appointment_interactions";
})(OrderByEnum = exports.OrderByEnum || (exports.OrderByEnum = {}));
var MLS_STATUS;
(function (MLS_STATUS) {
    MLS_STATUS["LISTING_OF_MY_MAILING_LIST"] = "listing_of_my_mailing_list";
    MLS_STATUS["NOT_MY_LISTINGS"] = "not_my_listings";
    MLS_STATUS["MY_LISTINGS"] = "my_listings";
    MLS_STATUS["MY_BUYERS"] = "my_buyers";
    MLS_STATUS["DOUBLE_ENDED"] = "double_ended";
})(MLS_STATUS = exports.MLS_STATUS || (exports.MLS_STATUS = {}));
var CampaignPreviewType;
(function (CampaignPreviewType) {
    CampaignPreviewType["EMAIL"] = "EMAIL/SMS";
    CampaignPreviewType["POSTGRID"] = "POSTGRID";
    CampaignPreviewType["HANDWRITING"] = "HANDWRITING";
})(CampaignPreviewType = exports.CampaignPreviewType || (exports.CampaignPreviewType = {}));
