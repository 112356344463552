import { CKEditor, CKEditorConfig, CKEditorInstance } from 'ckeditor4-react';
import React from 'react';
import { BsImageFill } from 'react-icons/bs';
import { TextEditorStyled } from './TextEditorStyled';

interface TextEditorProps {
  onChange: (value) => void;
  isEditorEmail?: boolean;
  onFocus?: () => void;
  isModalEditor?: boolean;
  handleImage?: (file: any) => void;
  handleEditorReady?: (editor: any) => void;
  editorHeight?: string;
  isFileAttachment?: boolean;
  toolbarConfig: CKEditorConfig;
  customizeEditorToolbar: (editor: CKEditorInstance | null) => void;
}

const TextEditorView: React.FC<TextEditorProps> = (props) => {
  const {
    onChange,
    isEditorEmail,
    onFocus,
    isModalEditor,
    handleImage,
    handleEditorReady,
    editorHeight,
    isFileAttachment = true,
    customizeEditorToolbar,
    toolbarConfig,
  } = props;
  if (typeof window === 'undefined') return null;

  return (
    <TextEditorStyled editorHeight={editorHeight} className="main-editor">
      <CKEditor
        initData={<p>Editor is loading...</p>}
        onInstanceReady={({ editor }) => {
          handleEditorReady && handleEditorReady(editor);
          customizeEditorToolbar(editor);
        }}
        config={isEditorEmail ? toolbarConfig : { ...toolbarConfig, toolbar: [] }}
        onChange={(evt) => {
          const editor = evt.editor;
          onChange(editor);
        }}
        onFocus={onFocus}
        onBeforeLoad={(CKEDITOR) => {
          CKEDITOR.addCss('.cke_editable p { margin: 0 !important;}');
          CKEDITOR.addCss('body { margin: 13px; }');
          CKEDITOR.disableAutoInline = true;
        }}
      />
      {isModalEditor && isEditorEmail && isFileAttachment ? (
        <div className="image-upload">
          <label htmlFor="file-input">
            <BsImageFill />
          </label>
          <input
            id="file-input"
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            onChange={(v) => {
              if (!v?.target?.files) return;

              handleImage && handleImage(v?.target?.files[0]);
            }}
          />
        </div>
      ) : null}
    </TextEditorStyled>
  );
};

export default TextEditorView;
