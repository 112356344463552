import styled from '@emotion/styled';

const ModalConfirmViewStyled = {
  Message: styled.div`
    font-size: 18px;
    text-align: center;
    font-weight: 500;
    letter-spacing: -0.5px;
  `,
  Children: styled.div``,
};

export default ModalConfirmViewStyled;
