import { DripResponseType } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';
import {
  MLS_STATUS,
  SessionsVarsBody,
  SessionVar,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/postgrid/models';
import copy from 'copy-to-clipboard';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { listingImageVars } from '@/components/custom-modals/modal-fallbacks/types';
import { RootState } from '@/reducers/model';
import { imageKeywords, qrCodeVars } from '@/utils/constant';
import { useShallow } from 'zustand/react/shallow';
import { useCampaignManagerStore } from '../../../dashboard/hooks/useCampaignManager';
import { MailingListData, MlsData, PC_PREFIX, SearchKeywords } from '../../helpers';
import { VariableType } from '../../hooks/useFetchVariablesByType';
import useHandleAutomateVariables from '../../hooks/useHandleAutomateVariables';
import useHandleSelectAddressVariables from '../../hooks/useHandleSelectAddressVariables';
import MessageVariableView from './MessageVariableView';
import useGetConstantValueQuery from '@/queries/settings/useGetConstantValue';

interface MessageVariableContainerProps {
  isHandwritten?: boolean;
  hiddenVariables?: Array<string>;
  onSelectVariable?: (value: string, isImageVar?: boolean) => void;
  onSelectMailingListData?: (mailingListData: MailingListData) => void;
  onSelectMailingMLSData?: (mlsData: MlsData) => void;
  variableType?: VariableType;
  dripType?: DripResponseType;
  isMlsVariablesUsed?: boolean;
  initialMailingListData?: { [key: string]: string };
}

const sortVarSection = (sectionVars) => {
  const sectionSortedOrder = [
    'agent',
    'mailing_list_and_owner',
    'listing_of_my_mailing_list',
    'my_listings',
    'not_my_listings',
    'past_clients',
    'neighborhood_mls',
    'image_variables',
    'mls_status',
  ];

  // Sort the keys of the object based on sectionSortedOrder
  const sortedKeys = Object.keys(sectionVars).sort((a, b) => {
    return sectionSortedOrder.indexOf(a) - sectionSortedOrder.indexOf(b);
  });

  // Get the sorted key-value pairs using Object.entries
  const sortedEntries = sortedKeys.map((key) => [key, sectionVars[key]]);

  return sortedEntries;
};

const MessageVariableContainer: React.FC<MessageVariableContainerProps> = (props) => {
  const {
    onSelectVariable,
    onSelectMailingListData,
    onSelectMailingMLSData,
    variableType = VariableType.EMAIL,
    dripType,
    initialMailingListData = {},
    isHandwritten,
  } = props;

  const [searchKeywords, setSearchKeywords] = useState<SearchKeywords>({});
  // Used data: mlsData
  const [mlsData, setMlsData] = useState<MlsData>({});

  // Used data: mailingListData
  const [mailingListData, setMailingListData] = useState<MailingListData>({});

  const { agentIdCampaign } = useCampaignManagerStore(
    useShallow((state) => ({
      agentIdCampaign: state.agentIdCampaign,
    })),
  );

  const postgridSessionVar = useSelector((state: RootState) => state.campaign.postgridSessionVar);
  const { data: userAssets } = useGetConstantValueQuery();
  const availablePostgridVar = useMemo(
    () => (userAssets?.drip_campaign as any)?.postgrid_fallback_variables?.map((v) => v?.value),
    [(userAssets?.drip_campaign as any)?.postgrid_fallback_variables],
  );

  const { handleGetAutomateData, automateFilterData } = useHandleAutomateVariables();
  const { handleGetSelectedMailingData, setMlsFilters, isSearching, mailingData, mlsFilters } =
    useHandleSelectAddressVariables({
      agentIdCampaign,
    });
  const hiddenVarsPostgrid = ['landing_page_url'];
  const hiddenVars = ['address_2'];
  const allHiddenVars =
    (variableType === VariableType.POSTGRID && !isHandwritten) || dripType === DripResponseType.CALL_SCHEDULING
      ? hiddenVars.concat(hiddenVarsPostgrid)
      : hiddenVars;
  const imageVariables = [
    'agent_image',
    'agent_logo',
    'broker_logo',
    'broker_logo_white',
    'crmls_logo',
    'san_diego_logo',
    'claw_logo',
    'dessert_area_logo',
    'sboar_logo',
  ].concat(listingImageVars);
  // Only apply qr codes for Postgrid
  if (variableType === VariableType.POSTGRID && !isHandwritten) {
    imageVariables.unshift(...qrCodeVars);
  }

  const postgridSessionVarWithImageVar: SessionsVarsBody & { image_variables: SessionVar } = {
    ...postgridSessionVar,
    image_variables: {
      original_vars: imageVariables,
    },
  };

  const isEmailVariable = [VariableType.EMAIL, VariableType.SMS].includes(variableType);

  const isNotAvailable = (value: string, availableVariable?: string[]) => {
    if (availableVariable) {
      switch (variableType) {
        case VariableType.EMAIL:
        case VariableType.SMS:
        case VariableType.POSTGRID:
          return !availableVariable.includes(value) && !imageVariables.includes(value);
        case VariableType.BROKER_LP:
          return false;
        default:
          return true;
      }
    }
    return false;
  };

  const processMlsType = (mls: string) => {
    switch (mls) {
      case MLS_STATUS.NOT_MY_LISTINGS:
        return 'nml';
      case MLS_STATUS.MY_LISTINGS:
      case MLS_STATUS.LISTING_OF_MY_MAILING_LIST:
        return '';
      default:
        return mls;
    }
  };

  const processMlsVariable = (mlsType: string, v: string) => {
    const mlsTypePrefixed = processMlsType(mlsType) ? processMlsType(mlsType) + '_' : '';

    const useManualSelect = mlsFilters[mlsType]?.useManualSelect;
    const selectAddressVar = `${mlsTypePrefixed}${mlsFilters[mlsType]?.status}_${v}`;

    const automateVar = `${mlsTypePrefixed}${automateFilterData[mlsType]?.status}_${v}`;

    return useManualSelect ? selectAddressVar : automateVar;
  };

  const processPCVariable = (v: string) => {
    const useManualSelect = mlsFilters[PC_PREFIX]?.useManualSelect;

    const pastClientTypePrefix = useManualSelect
      ? mlsFilters?.[PC_PREFIX]?.pastClientType?.toLowerCase()
      : automateFilterData?.[PC_PREFIX]?.pastClientType?.toLowerCase();

    const selectAddressVar = `${PC_PREFIX}_${pastClientTypePrefix}_${mlsFilters?.[PC_PREFIX]?.status}_${v}`;

    const automateVar = `${PC_PREFIX}_${pastClientTypePrefix}_${automateFilterData?.[PC_PREFIX]?.status}_${v}`;

    return useManualSelect ? selectAddressVar : automateVar;
  };

  const onCopyVariable = (value: string) => {
    const copiedValue =
      variableType === VariableType.POSTGRID
        ? imageVariables.includes(value) ||
          imageKeywords.some((kw) => value.split('_').some((splitWord) => splitWord === kw))
          ? `${value}`
          : `{{${value}}}`
        : value;
    copy(copiedValue);
  };

  const getCurrentMailing = (variable: string) => {
    return mlsFilters[variable]?.useManualSelect
      ? mlsFilters[variable]?.mailing?.value
        ? mailingData[variable]?.data.find((home) => home.id === mlsFilters[variable].mailing.value)
        : undefined
      : automateFilterData[variable]?.mailing;
  };

  const preprocessCopyValue = (variableKey: string, value: string) => {
    if (variableKey) {
      const selectedMailing = getCurrentMailing(variableKey);
      if (mlsFilters[variableKey]?.useManualSelect) {
        // Remove special characters & space
        const copiedAddress = selectedMailing?.address?.replace(/[^a-zA-Z0-9]/g, '');

        const sameAddressData = Object.fromEntries(
          Object.entries(mlsData).filter(([key]) => key?.startsWith(`${copiedAddress}-`)),
        );

        const usedMailing = Object.entries(sameAddressData).find(([_, mailingId]) => mailingId === selectedMailing?.id);
        let uniqueId = '00001';
        if (Object.keys(sameAddressData).length > 0 && usedMailing) {
          // Don't store any more mlsData!
          const [apartmentId] = usedMailing[0].split(/_(.*)/s);
          uniqueId = apartmentId.split('-')[1];
        } else {
          if (Object.keys(sameAddressData).length > 0) {
            // TODO: Normalize uniqueId, just in case we receive any handicapped uniqueIds :(((
            const [latestApartmentId] = [...new Set(Object.keys(sameAddressData))]
              .sort()
              .reverse()?.[0]
              .split(/_(.*)/s);
            const latestUniqueId = latestApartmentId.split('-')[1];
            if (latestUniqueId) {
              uniqueId = String(parseInt(latestUniqueId) + 1).padStart(5, '0');
            }
          }
        }
        const mlsKey = `${copiedAddress}-${uniqueId}_${value}`;
        setMlsData((prev) => {
          return {
            ...prev,
            [mlsKey]: selectedMailing?.id || '',
          };
        });
        return mlsKey;
      } else {
        // Case MLS variables, using the auto-latest data
        // Remove special characters & space
        const copiedMailingList = automateFilterData[variableKey]?.mailingListLabel?.replace(/[^a-zA-Z0-9]/g, '');
        const mlsKey = `${copiedMailingList}_${value}`;

        setMailingListData((prev) => {
          return {
            ...prev,
            [mlsKey]: automateFilterData[variableKey]?.mailingListId || '',
          };
        });
        return mlsKey;
      }
    }
    return value;
  };

  useEffect(() => {
    if (!Object.keys(mailingListData).length && Object.keys(initialMailingListData).length) {
      setMailingListData(initialMailingListData);
    }
  }, [initialMailingListData]);

  useEffect(() => {
    // TODO: Clean up unused data in both mlsData and mailingListData before returning them back to the parent.
    // My other heirloom, bear with me :)))
    if (onSelectMailingListData) onSelectMailingListData(mailingListData);
  }, [mailingListData]);

  useEffect(() => {
    // TODO: Clean up unused data in both mlsData and mailingListData before returning them back to the parent.
    if (onSelectMailingMLSData) onSelectMailingMLSData(mlsData);
  }, [mlsData]);

  return (
    <MessageVariableView
      agentIdCampaign={agentIdCampaign}
      allHiddenVars={allHiddenVars}
      automateFilterData={automateFilterData}
      availablePostgridVar={availablePostgridVar}
      getCurrentMailing={getCurrentMailing}
      handleGetAutomateData={handleGetAutomateData}
      handleGetSelectedMailingData={handleGetSelectedMailingData}
      isEmailVariable={isEmailVariable}
      isNotAvailable={isNotAvailable}
      isSearching={isSearching}
      mailingData={mailingData}
      mlsFilters={mlsFilters}
      onCopyVariable={onCopyVariable}
      postgridSessionVarWithImageVar={postgridSessionVarWithImageVar}
      preprocessCopyValue={preprocessCopyValue}
      processMlsVariable={processMlsVariable}
      processPCVariable={processPCVariable}
      searchKeywords={searchKeywords}
      setMlsFilters={setMlsFilters}
      setSearchKeywords={setSearchKeywords}
      sortVarSection={sortVarSection}
      isHandwritten={isHandwritten}
      onSelectVariable={onSelectVariable}
      variableType={variableType}
    />
  );
};

export default MessageVariableContainer;
