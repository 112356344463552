import {
  NFTMetadataCampaigns,
  NFTMetadataCampaignsHomeowners,
  NFTMetadataCampaignsInteraction,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/campaign_metadata/models';
import {
  BlurImage,
  BlurImageArgs,
  CampaignNFT,
  CampaignNFTArgs,
  ConnectWallet,
  ConnectWalletArgs,
  EnvConfig,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/nfts/models';
import { Pagination } from '@goldfishcode/first-team-real-estate-sdk/libs/type';
import { apiIns } from '@/config/apiClient';
import { ListCampaignNftParams } from '@/models/ListCampaignNftParams';

export default class CampaignNftService {
  static connectWallet({ wallet_address }: ConnectWalletArgs): Promise<ConnectWallet> {
    return apiIns.campaignNFT.connectWallet({ wallet_address });
  }

  static disconnectWallet(): Promise<ConnectWallet> {
    return apiIns.campaignNFT.disconnectWallet();
  }

  static getCampaignNFT(campaign_id: string): Promise<CampaignNFT> {
    return apiIns.campaignNFT.getCampaignNFT(campaign_id);
  }

  static listCampaignNFT({
    page,
    limit,
    name,
    start_date,
    end_date,
  }: ListCampaignNftParams): Promise<Pagination<CampaignNFT>> {
    return apiIns.campaignNFT.listCampaignNFT(page, limit, name, start_date, end_date, 1);
  }

  static mintCampaignNFT(args: CampaignNFTArgs): Promise<CampaignNFT> {
    return apiIns.campaignNFT.mintCampaignNFT(args);
  }

  static getNFTMetadata(campaign_order_id: string): Promise<NFTMetadataCampaigns> {
    return apiIns.campaignMetadata.getNFTMetadata(campaign_order_id);
  }

  static getHomeownersMailedDetails(
    page: number,
    limit: number,
    campaign_order_id: string,
  ): Promise<Pagination<NFTMetadataCampaignsHomeowners>> {
    return apiIns.campaignMetadata.getHomeownersMailedDetails(page, limit, campaign_order_id);
  }

  static getCampaignInteractions(
    page: number,
    limit: number,
    campaign_order_id: string,
  ): Promise<Pagination<NFTMetadataCampaignsInteraction>> {
    return apiIns.campaignMetadata.getCampaignInteractions(page, limit, campaign_order_id);
  }

  static blurImage(args: BlurImageArgs): Promise<BlurImage> {
    return apiIns.campaignNFT.blurImage(args);
  }

  static getConfigVariables(): Promise<EnvConfig> {
    return apiIns.campaignNFT.getEnvConfig();
  }
}
