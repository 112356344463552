import styled from '@emotion/styled';
import { color } from '@/styles/theme';

interface TextEditorStyledProps {
  editorHeight?: string;
}
export const TextEditorStyled = styled.div<TextEditorStyledProps>`
  border-radius: 5px !important;
  overflow: hidden;
  .cke_contents {
    color: ${color.BLACK} !important;
    iframe {
      transform: translateY(2px);
    }
    .cke_reset {
      p {
        margin: 0 !important;
      }
    }
  }
  .cke_path {
    display: none;
  }
  .cke_chrome {
    border: none;
    min-height: ${({ editorHeight }) => editorHeight || '150px'};
  }
  .cke_toolbar {
    height: 33px;
  }
`;
