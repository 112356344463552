enum HomeAction {
  UPDATE_SOLD_HOUSE = '@home/UPDATE_SOLD_HOUSE',
  UPDATE_COMPARE_AREA = '@home/UPDATE_COMPARE_AREA',
  UPDATE_USER_AGENT = '@home/UPDATE_USER_AGENT',
  UPDATE_USER_AGENT_SSID = '@home/UPDATE_USER_AGENT_SSID',
  UPDATE_DOMAIN_WEBSITE = '@home/UPDATE_DOMAIN_WEBSITE',
  UPDATE_LIST_AGENT = '@home/UPDATE_LIST_AGENT',
  IS_REFRESHED = '@home/IS_REFRESHED',
  GET_DATA_SOLD_HOUSES = '@home/GET_DATA_SOLD_HOUSES',
}
export default HomeAction;
