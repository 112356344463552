import { ForkEffect, put, takeLatest } from 'redux-saga/effects';
import OverviewService from '@/services/overview';
import AppAction from '../app/action';
import HomeAction from './action';

function* watchDataSoldHouses(action) {
  const { agentId } = action?.payload;

  try {
    yield put({
      type: AppAction.APP_LOADING,
      payload: 'INCREMENT',
    });
    const soldHouses = yield OverviewService.getAgentSoldHouses(agentId ?? undefined);
    yield put({
      type: HomeAction.UPDATE_SOLD_HOUSE,
      payload: soldHouses || [],
    });
  } catch (error) {
    yield put({
      type: HomeAction.UPDATE_SOLD_HOUSE,
      payload: [],
    });
  } finally {
    yield put({
      type: AppAction.APP_LOADING,
      payload: 'DECREMENT',
    });
  }
}

function* homeSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(HomeAction.GET_DATA_SOLD_HOUSES, watchDataSoldHouses);
}

export default homeSaga;
