import { combineReducers } from 'redux';
import { fork, ForkEffect } from 'redux-saga/effects';
import { RootState } from './model';

/*** Importing reducers ***/
import app from '@/reducers/app/reducer';
import auth from '@/reducers/auth/reducer';
import socket from '@/reducers/socket/reducer';
import user from '@/reducers/user/reducer';
import home from '@/reducers/home/reducer';
import mailing from '@/reducers/mailing/reducer';
import campaignNft from '@/reducers/campaign-nft/reducer';
import credit from '@/reducers/credit/reducer';
import homeCredit from '@/reducers/home-credit/reducer';
import campaign from '@/reducers/campaign/reducer';

/*** Importing reducers ***/

/*** Importing sagas ***/
import socketSaga from '@/reducers/socket/saga';
import authSaga from '@/reducers/auth/saga';
import homeSaga from '@/reducers/home/saga';
import userSaga from '@/reducers/user/saga';
import mailingSaga from '@/reducers/mailing/saga';
import campaignNftSaga from './campaign-nft/saga';
import creditSaga from './credit/saga';
import homeCreditSaga from './home-credit/saga';
/*** Importing sagas ***/

export interface ActionBase {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

const rootReducer = combineReducers<RootState, ActionBase>({
  app,
  auth,
  socket,
  user,
  home,
  mailing,
  campaignNft,
  credit,
  homeCredit,
  campaign,
});

function* rootSaga(): Generator<ForkEffect<void>, void, unknown> {
  yield fork(authSaga);
  yield fork(homeSaga);
  yield fork(socketSaga);
  yield fork(userSaga);
  yield fork(mailingSaga);
  yield fork(campaignNftSaga);
  yield fork(creditSaga);
  yield fork(homeCreditSaga);
}

export { rootReducer, rootSaga };
