"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Subscription = void 0;
class Subscription {
    constructor(client) {
        this.client = client;
    }
    getMySub() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.get(`/subscription/me/`);
        });
    }
    sub(sub_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/subscription/`, { sub_id });
        });
    }
    changeSub(sub_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/subscription/change-sub/`, { sub_id });
        });
    }
    subAgain(sub_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/subscription/sub-again/`, { sub_id });
        });
    }
    buyExtra(amount, sub_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/subscription/buy-extra/`, { amount, sub_id });
        });
    }
    buyExtraAgent(amount, sub_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/subscription/buy-extra-agent/`, { amount, sub_id });
        });
    }
    cancel() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.delete(`/subscription/cancel/`);
        });
    }
    subSetting(is_manager = false) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.get(`/subscription/setting/`, { is_manager });
        });
    }
    getUpcomingInvoice() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/subscription/upcoming-invoice/`, {});
        });
    }
    previewChangeSub(sub_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/subscription/preview-change-sub/`, { sub_id });
        });
    }
}
exports.Subscription = Subscription;
