"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PostGridApi = void 0;
const caching_1 = require("../caching");
class PostGridApi {
    constructor(client) {
        this.client = client;
        this.staleTimes = {
            getUserPostGridTemplateFallback: 2,
            fetchTemplate: 2,
            getPostGridTemplateList: 5,
            sessionsAndVars: 60,
        };
        this.caching = new caching_1.Caching();
    }
    getPostGridTemplateList(page = 1, limit = 20, me, use_for_create, type, agent_id, campaign_type, search, order_by, remove_premium = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { page, limit, me, type, search, use_for_create, campaign_type, order_by, agent_id, remove_premium };
            const endpoint = `/postgrid/templates/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.getPostGridTemplateList,
                });
            return response;
        });
    }
    cloneTemplate(templateId, data) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.client.post(`/postgrid/templates/${templateId}/clone/`, data);
        });
    }
    getWarningVariables(templateId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.client.get(`/postgrid/templates/${templateId}/warning/`);
        });
    }
    deleteTemplate(templateId, agent_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { agent_id };
            yield this.client.delete(`/postgrid/templates/${templateId}/`, params);
        });
    }
    fetchTemplate(templateId, view = false, is_draft = false, mls_data = {}, mailing_list_data = {}, agent_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { view, is_draft, agent_id };
            const endpoint = `/postgrid/templates/${templateId}/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, Object.assign({ mls_data, mailing_list_data }, params));
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.post(endpoint, { mls_data, mailing_list_data }, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.fetchTemplate,
                });
            return response;
        });
    }
    saveUpdateToTemplate(templateId, mls_data = {}, mailing_list_data = {}, update_to_template_id, agent_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.client.post(`/postgrid/templates/${templateId}/`, {
                mls_data,
                mailing_list_data,
                update_to_template_id,
                agent_id,
            });
        });
    }
    buyPremiumTemplate(templateId, agent_id) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.client.put(`/postgrid/templates/${templateId}/purchase/`, { agent_id });
        });
    }
    getEditorSession(templateId, agent_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.client.get(`/postgrid/templates/${templateId}/edit-session/`, { agent_id });
        });
    }
    getUserPostGridTemplateFallback(templateId, agent_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `/postgrid/user-fallback/template/${templateId}/`;
            const params = { agent_id };
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.getUserPostGridTemplateFallback,
                });
            return response;
        });
    }
    sessionsAndVars() {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `/postgrid/sessions-and-vars/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, {});
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.sessionsAndVars,
                });
            return response;
        });
    }
}
exports.PostGridApi = PostGridApi;
