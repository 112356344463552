"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreditTransactionStatus = exports.CreditTransactionAction = exports.CreditTransactionType = void 0;
var CreditTransactionType;
(function (CreditTransactionType) {
    CreditTransactionType["home_credit"] = "home_credit";
    CreditTransactionType["campaign_credit"] = "campaign_credit";
    CreditTransactionType["credit_card"] = "credit_card";
})(CreditTransactionType = exports.CreditTransactionType || (exports.CreditTransactionType = {}));
var CreditTransactionAction;
(function (CreditTransactionAction) {
    CreditTransactionAction["DEPOSIT"] = "DEPOSIT";
    CreditTransactionAction["AUTO_DEPOSIT"] = "AUTO_DEPOSIT";
    CreditTransactionAction["PAY"] = "PAY";
    CreditTransactionAction["UPLOAD_CSV_TO_POSTA_PAY"] = "UPLOAD_CSV_TO_POSTA_PAY";
    CreditTransactionAction["SMART_ACTION_SEND_MAIL_PAY"] = "SMART_ACTION_SEND_MAIL_PAY";
    CreditTransactionAction["DRIP_ORDER_PAY"] = "DRIP_ORDER_PAY";
    CreditTransactionAction["CUSTOMER_SUBSCRIPTION_CREATED"] = "CUSTOMER_SUBSCRIPTION_CREATED";
    CreditTransactionAction["CUSTOMER_SUBSCRIPTION_UPDATED"] = "CUSTOMER_SUBSCRIPTION_UPDATED";
    CreditTransactionAction["CUSTOMER_SUBSCRIPTION_DELETED"] = "CUSTOMER_SUBSCRIPTION_DELETED";
    CreditTransactionAction["CUSTOMER_SUBSCRIPTION_EXTRA_SUCCEEDED"] = "CUSTOMER_SUBSCRIPTION_EXTRA_SUCCEEDED";
    CreditTransactionAction["CUSTOMER_SUBSCRIPTION_EXTRA_AGENT_SUCCEEDED"] = "CUSTOMER_SUBSCRIPTION_EXTRA_AGENT_SUCCEEDED";
    CreditTransactionAction["MAIL_CAMPAIGN_REFUND"] = "MAIL_CAMPAIGN_REFUND";
    CreditTransactionAction["GET_PLUNK_PAY"] = "GET_PLUNK_PAY";
    CreditTransactionAction["GET_SKIP_TRACE_PAY"] = "GET_SKIP_TRACE_PAY";
    CreditTransactionAction["SEND_HANDWRITING"] = "SEND_HANDWRITING";
    CreditTransactionAction["DIGITAL_CAMPAIGN_REQUEST_ADMIN"] = "DIGITAL_CAMPAIGN_REQUEST_ADMIN";
    CreditTransactionAction["DIGITAL_CAMPAIGN_CREATE_ORDER"] = "DIGITAL_CAMPAIGN_CREATE_ORDER";
    CreditTransactionAction["PREMIUM_CAMPAIGN"] = "PREMIUM_CAMPAIGN";
    CreditTransactionAction["BROKER_AUTOMATION"] = "BROKER_AUTOMATION";
    CreditTransactionAction["CREATE_PHYSICAL_CAMPAIGN"] = "CREATE_PHYSICAL_CAMPAIGN";
    CreditTransactionAction["CUSTOMER_SUBSCRIPTION_CREDIT_DEPOSIT"] = "CUSTOMER_SUBSCRIPTION_CREDIT_DEPOSIT";
    CreditTransactionAction["MANUAL_ADD_CREDIT"] = "MANUAL_ADD_CREDIT";
    CreditTransactionAction["AUTO_ADD_CREDIT"] = "AUTO_ADD_CREDIT";
    CreditTransactionAction["EMAIL_CAMPAIGN"] = "EMAIL_CAMPAIGN";
    CreditTransactionAction["MAIL_CAMPAIGN"] = "MAIL_CAMPAIGN";
    CreditTransactionAction["PREMIUM_DATA"] = "PREMIUM_DATA";
    CreditTransactionAction["POSTGRID_REFUND"] = "POSTGRID_REFUND";
    CreditTransactionAction["ADD_TEAM_MEMBER"] = "ADD_TEAM_MEMBER";
    CreditTransactionAction["BROKER_CAMPAIGN"] = "BROKER_CAMPAIGN";
    CreditTransactionAction["NFT_MINT"] = "NFT_MINT";
    CreditTransactionAction["EXTRA_HOME"] = "EXTRA_HOME";
    CreditTransactionAction["OTHER"] = "OTHER";
})(CreditTransactionAction = exports.CreditTransactionAction || (exports.CreditTransactionAction = {}));
var CreditTransactionStatus;
(function (CreditTransactionStatus) {
    CreditTransactionStatus["completed"] = "completed";
    CreditTransactionStatus["failed"] = "failed";
})(CreditTransactionStatus = exports.CreditTransactionStatus || (exports.CreditTransactionStatus = {}));
