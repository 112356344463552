import AppButtonV2 from '@/components/AppButtonV2';
import AppInputV2 from '@/components/AppInputV2';
import AppSelectV2 from '@/components/AppSelectV2';
import ErrorMessage from '@/components/error-message';
import Modal from '@/components/Modal';
import { RootState } from '@/reducers/model';
import { css } from '@emotion/react';
import { Col, Row, Space } from 'antd';
import { FormikProps } from 'formik';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { CreateTemplateInfoFormValues } from './CreateTemplateInfoContainer';

interface CreateLetterModalProps {
  visible: boolean;
  onCancel: () => void;
  onConfirm: (name: string, campainType: string) => void;
  editData?: any;
  setFieldChange: (value: any) => void;
  fieldChange: any;
  formik: FormikProps<CreateTemplateInfoFormValues>;
}

const ModalCreateTemplateInfoView: React.FunctionComponent<CreateLetterModalProps> = memo((props) => {
  const { visible, onCancel, editData, setFieldChange, formik, fieldChange } = props;
  const campaignTypeList = useSelector((state: RootState) => state.app?.campaignTypeList);

  return (
    <Modal
      getContainer={false}
      visible={visible}
      footer={false}
      centered
      closable={false}
      destroyOnClose={true}
      isTransparent
      width={520}
      afterClose={() => {
        setFieldChange(null);
        formik.resetForm();
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Modal.Title>{(editData ? 'Edit' : 'Create') + ' Template'}</Modal.Title>
            </Col>
            <Col span={24}>
              <AppInputV2
                className="field firstName-field"
                requiredMark
                label="Template Name"
                name="name"
                type="string"
                placeholder="Template Name"
                value={formik.values.name}
                onChange={(e) => {
                  formik.setFieldValue('name', e.target.value?.trimStart());
                  setFieldChange((prev) => ({ ...prev, name: true }));
                }}
                size="large"
                onBlur={formik.handleBlur}
              />
              {formik.errors.name?.length && fieldChange?.name ? (
                <ErrorMessage className="error">{formik.errors.name}</ErrorMessage>
              ) : null}
            </Col>
            <Col span={24}>
              <AppSelectV2
                fieldNames={{ label: 'label', value: 'id' }}
                options={campaignTypeList || []}
                value={formik.values.campaignType}
                requiredMark
                label="Categorize Your Template"
                placeholder="Select Template Category..."
                onChange={(value) => {
                  formik.setFieldValue('campaignType', value);
                  setFieldChange((prev) => ({ ...prev, campaignType: true }));
                }}
              />
              {formik.errors.campaignType?.length && fieldChange?.campaignType ? (
                <ErrorMessage className="error">{formik.errors.campaignType}</ErrorMessage>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Actions>
          <Space
            direction="vertical"
            size={20}
            css={css`
              width: 100%;
            `}
          >
            <AppButtonV2
              loading={formik.isSubmitting}
              block
              onClick={() => {
                setFieldChange({ name: true, campaignType: true, agent_id: true });
                formik.handleSubmit();
              }}
            >
              Confirm
            </AppButtonV2>
            <AppButtonV2
              color="WhiteTransparent"
              block
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </AppButtonV2>
          </Space>
        </Modal.Actions>
      </form>
    </Modal>
  );
});

export default ModalCreateTemplateInfoView;
