import { message } from 'antd';
import { IoMdClose } from 'react-icons/io';

type MessageType = 'success' | 'error' | 'info' | 'warning' | 'loading';

interface AlertOptions {
  isDismissAll?: boolean;
  duration?: number;
  closable?: boolean;
}

const commonAlertStyles = {
  className: 'error-msg',
  style: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

const createAlert = (type: MessageType, content: React.ReactNode, options?: AlertOptions) => {
  const { isDismissAll, duration = 5, closable } = options || {};

  if (isDismissAll) {
    message.destroy();
  }

  return message[type]({
    ...commonAlertStyles,
    content: (
      <div
        style={{
          maxWidth: 520,
          display: 'inline-flex',
          textAlign: 'justify',
          position: 'relative',
          paddingRight: closable ? 24 : undefined,
        }}
      >
        {content}
        {closable ? (
          <IoMdClose
            color="Outline"
            style={{ position: 'absolute', top: -4, right: -12, fontSize: 16, cursor: 'pointer' }}
            onClick={() => message.destroy()}
          />
        ) : null}
      </div>
    ),
    duration,
    getPopupContainer: () => document.body,
  });
};

export const AlertTextError = (title: React.ReactNode, isDismissAll?: boolean, duration?: number) =>
  createAlert('error', title, { isDismissAll, duration });

export const AlertTextSuccess = (title: React.ReactNode, isDismissAll?: boolean, duration?: number) =>
  createAlert('success', title, { isDismissAll, duration });

export const AlertTextInfo = (title: React.ReactNode, isDismissAll?: boolean, duration?: number, closable?: boolean) =>
  createAlert('info', title, { isDismissAll, duration, closable });

export const AlertTextWarning = (title: React.ReactNode, isDismissAll?: boolean, duration?: number) =>
  createAlert('warning', title, { isDismissAll, duration });
