import { useEffect, useState } from 'react';
function useScale(wrapperRef, elementRef, defaultScaleX, defaultScaleY, isTrigger) {
  const [scaleX, setScaleX] = useState(defaultScaleX);
  const [scaleY, setScaleY] = useState(defaultScaleY);

  useEffect(() => {
    if (elementRef.current && wrapperRef.current) {
      const elementRect = elementRef.current.getBoundingClientRect();
      const wrapperRect = wrapperRef.current.getBoundingClientRect();
      const scaleX = wrapperRect.width / elementRect.width;
      const scaleY = wrapperRect.height / elementRect.height;

      setScaleX(scaleX || defaultScaleX);
      setScaleY(scaleY || defaultScaleY);
    }
  }, [wrapperRef.current, elementRef.current, isTrigger]);

  return { scaleX, scaleY };
}

export default useScale;
