import React from 'react';
import { Children, ReactNode } from 'react';

interface ShowProps {
  children: ReactNode;
}

const Show = ({ children }: ShowProps) => {
  let when: ReactNode | null = null;
  let otherwise: ReactNode | null = null;

  Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      const { isTrue } = child.props;

      if (isTrue === undefined) {
        otherwise = child;
      } else if (!when && isTrue === true) {
        when = child;
      }
    }
  });

  return (
    <>
      {when}
      {otherwise}
    </>
  );
};

interface ShowWhenProps {
  isTrue?: boolean;
  children: ReactNode;
}

Show.When = React.memo(({ isTrue, children }: ShowWhenProps) => <>{isTrue && children}</>);

interface ShowElseProps {
  render?: ReactNode;
  children: ReactNode;
}

Show.Otherwise = React.memo(({ render, children }: ShowElseProps) => <>{render || children}</>);

export default Show;
