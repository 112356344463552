import MailingListSelect from '@/components/select/mailing-list-select';
import SelectV2 from '@/components/AppSelectV2';
import { ValueLabel } from '@/models';
import { FC } from 'react';
import { HomeSearchContainerProps } from './HomeSearchContainer';

interface HomeSearchViewProps extends Omit<HomeSearchContainerProps, 'variableType' | 'isHandwritten'> {
  mlsStatusOptions: ValueLabel[];
}

const HomeSearchView: FC<HomeSearchViewProps> = (props) => {
  const {
    handleMailingListChange,
    mailingListValue,
    handleMailingListSearch,
    mailingListSearchValue,
    parentId,
    agentIdCampaign,
    mlsStatusValue,
    handleMLSStatusChange,
    containerClassName,
    mailingListFieldName,
    isMyListing = false,
    isExcludeMyListing = false,
    mlsStatusOptions,
    isPastClient,
  } = props;

  return (
    <div className={containerClassName}>
      <MailingListSelect
        isMyListing={isMyListing}
        isExcludeMyListing={isExcludeMyListing}
        placeholder={isPastClient ? 'Select Past Client Type' : 'Select Mailing List'}
        isOnlyPastClient={isPastClient}
        name={mailingListFieldName}
        className="field mls-selector"
        colorPlaceholder="#bfbfbf"
        handleChange={handleMailingListChange}
        value={mailingListValue}
        onSearch={handleMailingListSearch}
        searchValue={mailingListSearchValue}
        isGetContainer
        parentId={parentId}
        requiredMark
        showSearch
        allowClear
        agentId={agentIdCampaign}
        size="small"
        color="DARKBLUE"
      />
      <SelectV2
        className="field mls-selector"
        placeholder="Select MLS Status"
        value={mlsStatusValue}
        onChange={handleMLSStatusChange}
        options={mlsStatusOptions}
        allowClear
        size="small"
        color="DARKBLUE"
      />
    </div>
  );
};

export default HomeSearchView;
