import { UserPostGridTemplateFallback } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postgrid/models';
import { MailingExtra } from '@goldfishcode/first-team-real-estate-sdk/libs/api/tools/variable/models';
import { useEffect, useMemo, useRef, useState } from 'react';
import useHandlePreviewTemplateWithMultipleAddresses from './useHandlePreviewTemplateWithMultipleAddresses';
import MailingAction from '@/reducers/mailing/action';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';

interface PreviewMultipleAddressCampaignPaginationOptions {
  data: MailingExtra[];
  pageSize: number;
  rawTemplateFallback: UserPostGridTemplateFallback;
  templatePreviewContentData: any;
}

const usePagination = (options: PreviewMultipleAddressCampaignPaginationOptions) => {
  const { data: allAddresses, pageSize, rawTemplateFallback, templatePreviewContentData } = options;
  const dispatch = useDispatch();
  const previousRawTemplateFallback = useRef<any>();
  const [paginatedData, setPaginatedData] = useState<MailingExtra[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [homeAddresses, setHomeAddresses] = useState<MailingExtra[]>([]);

  const { runInBackgroundFallbackForAllAddresses, getPaginatedMailingsFallback } =
    useHandlePreviewTemplateWithMultipleAddresses();

  const totalPages = useMemo(() => Math.ceil(homeAddresses.length / pageSize), [homeAddresses.length, pageSize]);

  const handleToShowHomePagination = async () => {
    const paginateData =
      homeAddresses.length > 0 ? homeAddresses.slice(currentPage * pageSize, currentPage * pageSize + pageSize) : [];
    setIsLoading(true);
    const datasource = await getPaginatedMailingsFallback(
      paginateData,
      rawTemplateFallback,
      templatePreviewContentData,
    );
    setPaginatedData(datasource);
    setIsLoading(false);
  };

  const handleConvertVariables = () => {
    if (!allAddresses || !rawTemplateFallback || !templatePreviewContentData) return;

    const availableAllAddresses = allAddresses?.filter((v: MailingExtra) => v.is_available_for_campaign) || [];
    setHomeAddresses(availableAllAddresses);
    dispatch({ type: MailingAction.SET_ADDRESSES_COUNT_CAMPAIGN_NOT_RENDER_LOADING, payload: true });

    runInBackgroundFallbackForAllAddresses(allAddresses, rawTemplateFallback, templatePreviewContentData);
  };

  useEffect(() => {
    // Case of change selected template on Scroll Template
    handleConvertVariables();
  }, [allAddresses, templatePreviewContentData?.id]);

  useEffect(() => {
    // Case of updating global fallback, content of mls_data, mailing_list_data variables
    if (isEqual(previousRawTemplateFallback.current, rawTemplateFallback)) return;
    previousRawTemplateFallback.current = rawTemplateFallback;
    handleConvertVariables();
  }, [allAddresses, rawTemplateFallback]);

  useEffect(() => {
    handleToShowHomePagination();
  }, [homeAddresses, currentPage]);

  useEffect(() => {
    document.getElementsByClassName('preview-pagination-modal')[0]?.scrollTo(0, 0);
  }, [currentPage]);

  return { isLoading, homeAddresses, paginatedData, currentPage, totalPages, setCurrentPage, setPaginatedData };
};

export default usePagination;
