"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeCurrencyFormat = exports.formatCurrencySymbol = exports.currencySymbol = exports.formatCurrency = exports.currencyList = void 0;
const number_1 = require("./number");
exports.currencyList = [
    {
        name: 'USD',
        value: '$',
    },
];
exports.formatCurrency = (value, removeDecimalZero = false) => {
    const convertedValue = +value;
    if (removeDecimalZero && +(convertedValue % 1).toFixed(2) === 0) {
        return `${number_1.formatCommas(convertedValue.toFixed(0))}`;
    }
    return number_1.formatCommas((convertedValue || 0).toFixed(2));
};
exports.currencySymbol = (name) => {
    const syb = exports.currencyList.find((val) => {
        return val.name === name.toUpperCase();
    });
    if (syb) {
        return (syb === null || syb === void 0 ? void 0 : syb.value) || '';
    }
    return '';
};
exports.formatCurrencySymbol = (value, currency, removeDecimalZero = false) => {
    const symbol = exports.currencySymbol(currency);
    if (+value < 0) {
        return `-${symbol}${exports.formatCurrency(Math.abs(+value), removeDecimalZero)}`;
    }
    else {
        return `${symbol}${exports.formatCurrency(+value, removeDecimalZero)}`;
    }
};
// Define the function to remove currency formatting
exports.removeCurrencyFormat = (currencyValue) => {
    // Remove currency symbols and commas, then parse as float
    return parseFloat(currencyValue.replace(/[$,]/g, ''));
};
