import { toLower, startCase } from 'lodash';
import moment, { Moment } from 'moment';

export const currency = {
  USD: '$0,000',
};

export const dateTimeUSFormat = 'MM-DD-YYYY';

export function formatPhoneNumber(value: string, separator = '-') {
  if (!value?.length) return;

  value = value.trim().replace(/([^0-9+])/g, '');

  if (!value?.startsWith('+1')) {
    if (!value?.startsWith('+')) value = '+1' + value;
    else if (value.length > 1) value = '+1' + value.slice(1);
  }

  const formatted = value.replace(
    /(\+?1)?( ?\d{1,3})?( ?-?\d{1,3})?( ?-?\d{1,4})?(\d)?/,
    function (_, p0: string, p1: string, p2: string, p3: string) {
      let output = '';
      if (p0) {
        output += '+1';
      }
      if (p1) {
        if (p1.match(/[^A-Z0-9]/gi)) {
          output += `(${p1})`;
        } else {
          p1 = p1.replace(/[^A-Z0-9]/gi, '');
          output += `(${p1})`;
        }
      }
      if (p2) {
        if (p2.match(/[^A-Z0-9]/gi)) {
          output += ` ${p2}`;
        } else {
          p2 = p2.replace(/[^A-Z0-9]/gi, '');
          output += ` ${p2}`;
        }
      }
      if (p3) {
        if (p3.match(/[^A-Z0-9]/gi)) {
          output += `${separator}${p3}`;
        } else {
          p3 = p3.replace(/[^A-Z0-9]/gi, '');
          output += `${separator}${p3}`;
        }
      }
      return output;
    },
  );

  return formatted;
}

export function formatPhoneNo(val: string, formatDefault = false): string {
  const cleaned = val
    .trim()
    .replace(/([^0-9+])/g, '')
    .replace(/^\+[0-9]/g, '');

  const match = cleaned.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
  if (match && formatDefault) {
    return '+1' + match[1] + match[2] + match[3];
    // ex:+14695893672
  }
  if (match) {
    return '+1 (' + match[1] + ') ' + match[2] + ' ' + match[3];
    //ex:+1 (469) 589 3672
  }

  return cleaned;
}
export const formatDateOnBoarding = (date: string | Moment): string => {
  return moment(date).format('MMM Do, YYYY');
};

export const formatDate = (date: string | Moment): string => {
  return moment(date).format('MM/DD/YYYY');
};

export const formatDateSmartFilter = (date: string | Moment): string => {
  return moment(date).format('YYYY-MM-DD');
};

export const formatDateAndHour = (date: string | Moment): string => {
  return moment(date).format('MM/DD/YYYY HH:mm:ss');
};

export const formatDateAndHourExcludeSecond = (date: string | Moment): string => {
  return moment(date).format('MM/DD/YYYY HH:mm');
};

export const formatStartDate = (date: string | Moment): string => {
  return `${moment(date).format('YYYY/MM/DD')} 00:00:00`;
};

export const formatEndDate = (date: string | Moment): string => {
  return `${moment(date).format('YYYY/MM/DD')} 23:59:59`;
};

const startCaseWithCommas = (str: string) => {
  const parts = str.split(',');
  const capitalizedParts = parts.map((part: string) => startCase(part.trim()));
  return capitalizedParts.join(', ');
};
export const sentenceCase = (text: string) => {
  return startCase(toLower(text));
};
export const sentenceCaseWithcommas = (text: string) => {
  return startCaseWithCommas(toLower(text));
};

export const replaceDataVariablesInHtml = (
  htmlString: string,
  variables: Record<string, string>,
  isReplaceEmptyVar?: boolean,
): string => {
  // Use a regular expression to find all occurrences of {{variable_name}}
  const regex = /{{(.*?)}}/g;

  // Replace each variable with its corresponding value
  const replacedString = htmlString?.replace(regex, (match: string, variableName: string) => {
    variableName = variableName.trim();
    const value = variables?.[variableName];
    const isValid = typeof value === 'number' || (typeof value === 'string' && value.length > 0);

    if (isValid || (!isValid && isReplaceEmptyVar)) {
      return value || '';
    } else {
      return match;
    }
  });

  return replacedString;
};

export const mergeVariables = (a = {}, b = {}) => {
  const c = { ...a };

  for (const key in b) {
    if (b[key] !== '' && (!(key in c) || c?.[key] === '')) {
      c[key] = b[key];
    }
  }

  return c;
};
