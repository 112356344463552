import { geojsonToWKT } from '@terraformer/wkt';
import { cloneDeep, get, isEmpty, omit, pick } from 'lodash';
import { formatCurrency, formatCurrencySymbol, nFormatter } from '@/utils/currency';
import { getErrorConnectMessage } from '@/config/connector';
import globalVariable from '@/config/env';
import { ResolutionTemplate, TemplateITemEnum, TypeMetaDataOther } from '@/utils/enum';
import moment from 'moment';
import * as turf from '@turf/turf';
import { ToolBoxPremiumData } from '@/components/custom-modals/modal-purchase-premium/models';
import CampaignService from '@/services/campaign';
import { formatPhoneNumber } from './format';
import { Pagination } from '@goldfishcode/first-team-real-estate-sdk/libs/type';
import wellknown from 'wellknown';
import { GeoJSONType } from '@/models';
import ToolsService from '@/services/tools';
import {
  _ALL_HOMES_LABEL,
  EMAIL_FOOTER_IMAGE_URL,
  POSTGRID_DEFAULT_VALUES,
  SCAN_NOW_QR_CODE,
  TRANSPARENT_IMAGE,
  imageKeywords,
} from './constant';
import { deleteUrlParams } from './query';
import { PostgridTemplateType } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postgrid/models';
import { CardTypeEnum, HeaderTypeEnum } from '@goldfishcode/first-team-real-estate-sdk/libs/api/handwriting/models';
import {
  FallbackTypeEnum,
  FallbackVariableOnUI,
  hiddenVarOnFallbackFormUI,
  landing_page_var_key,
  listingImageVars,
  LISTING_IMAGE_KEY,
} from '@/components/custom-modals/modal-fallbacks/types';
import { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
import validation from './validation';

const apiKeyMap = globalVariable.GOOGLE_MAP_KEY;

export const findLastIndex = (array: any[], searchKey: string, searchValue: number): number => {
  const index = array
    .slice()
    .reverse()
    .findIndex((x) => x[searchKey] === searchValue);
  const count = array.length - 1;
  const finalIndex = index >= 0 ? count - index : index;
  return finalIndex;
};

export const timeout = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const formatUrl = (url) => {
  if (!url) return '';
  try {
    if (new URL(url)) return url;
    return 'http://' + url?.replace(/(^\w+:|^)\/\//, '');
  } catch (error) {
    return 'http://' + url?.replace(/(^\w+:|^)\/\//, '');
  }
};

export const joinAgentName = (v) => {
  if (!v) return '';
  return [v?.first_name, v?.last_name].join(' ');
};

const createGeoJSONObject = (paths) => {
  const GeoJSON: GeoJSONType = {
    type: 'Polygon',
    coordinates: [],
  };

  for (const path of paths) {
    const pathArray: Array<[number, number]> = path.getArray().map((point) => [point.lng(), point.lat()]);
    // Ensure the path is closed by adding the first point again
    pathArray.push(pathArray[0]);

    GeoJSON.coordinates.push(pathArray);
  }
  return GeoJSON;
};

export const getGeoJSONPrototype = (google) => {
  google.maps.Polygon.prototype.getGeoJSON = function () {
    const paths = this.getPaths().getArray();
    const GeoJSON = createGeoJSONObject(paths);
    const geoJsonWKT = geojsonToWKT(GeoJSON);
    // Create the additional variable
    const polygonData = {
      match: 'polygon',
      value: {
        wkt: geoJsonWKT,
      },
    };

    return { GeoJSON, geoJsonWKT, polygonData };
  };
};

export const swapCoordinates = (wktString) => {
  const geojson = wellknown.parse(wktString);
  // Extract coordinates from the GeoJSON
  const coordinates = geojson.coordinates;
  // Convert the coordinates to LatLng objects
  const paths = coordinates[0]?.map((point) => ({
    lat: point[1],
    lng: point[0],
  }));
  return paths;
};

export const addListenerEventPolygon = (google) => {
  google.maps.Polygon.prototype.enableCoordinatesChangedEvent = function () {
    // eslint-disable-next-line
    let me = this,
      isBeingDragged = false,
      // eslint-disable-next-line prefer-const
      triggerCoordinatesChanged = function () {
        google.maps.event.trigger(me, 'coordinates_changed');
      };
    google.maps.event.addListener(me, 'dragstart', function () {
      isBeingDragged = true;
    });

    google.maps.event.addListener(me, 'dragend', function () {
      triggerCoordinatesChanged();
      isBeingDragged = false;
    });

    const paths = me.getPaths();
    paths.forEach(function (path) {
      google.maps.event.addListener(path, 'insert_at', function () {
        triggerCoordinatesChanged();
      });
      google.maps.event.addListener(path, 'set_at', function () {
        if (!isBeingDragged) {
          triggerCoordinatesChanged();
        }
      });
      google.maps.event.addListener(path, 'remove_at', function () {
        triggerCoordinatesChanged();
      });
    });
  };
};
export const clearListeners = (google) => {
  google.maps.event.clearListeners(google.map, 'coordinates_changed');
  google.maps.event.clearListeners(google.map, 'dragend');
  google.maps.event.clearListeners(google.map, 'insert_at');
  google.maps.event.clearListeners(google.map, 'set_at');
  google.maps.event.clearListeners(google.map, 'remove_at');
};

export const splitString = (str: string | null | undefined, numStart = 8, numEnd = 6): string => {
  if (!str?.length) return '';
  if (str?.length <= numStart + numEnd) return str;
  return str?.substring(0, numStart) + '...' + str.substring(str.length - numEnd);
};

export const shortString = (str: string | null | undefined, maxLength = 50): string => {
  if (!str?.length) return '';
  return str.slice(0, maxLength) + (str.length > maxLength ? '...' : '');
};

export const prototypeGetBounds = (google) => {
  if (!google) return;
  google.maps.Polygon.prototype.getBounds = function () {
    const bounds = new google.maps.LatLngBounds();
    const paths = this.getPaths();
    let path;
    for (let i = 0; i < paths.getLength(); i++) {
      path = paths.getAt(i);
      for (let ii = 0; ii < path.getLength(); ii++) {
        bounds.extend(path.getAt(ii));
      }
    }
    return bounds;
  };
};

export const getFullName = (firstName, lastName) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  } else {
    return `Current Resident`;
  }
};

export const convertTextSold = (arr) => {
  const foundElIndex = arr?.findIndex((v) => v?.label === 'Closed');
  if (foundElIndex > -1) {
    const obj = { ...arr[foundElIndex] };
    obj.label = 'Sold';
    arr.splice(foundElIndex, 1, obj);
  }
  return arr;
};

export const convertAddressWallet = (value: string | undefined | null): string => {
  if (value) {
    return value.length > 10 ? `${value.slice(0, 6)}...${value.slice(-4)}` : value;
  }
  return '';
};

export const switchNetwork = async (ethereum: any, callbackSwitchError: any, callbackAddError: any): Promise<void> => {
  try {
    await ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: globalVariable.POLYGON_CHAINID_HEX }],
    });
  } catch (switchError: any) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        await ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: globalVariable.POLYGON_CHAINID_HEX,
              nativeCurrency: {
                name: 'Matic Token',
                symbol: 'MATIC',
                decimals: 18,
              },
              chainName: globalVariable.POLYGON_CHAIN_NAME,
              rpcUrls: [globalVariable.POLYGON_RPC],
              blockExplorerUrls: [globalVariable.POLYGONSCAN],
            },
          ],
        });
      } catch (addError: any) {
        callbackAddError?.();
        getErrorConnectMessage(addError);
      }
    }
    callbackAddError && callbackSwitchError();
    getErrorConnectMessage(switchError);
  }
};

export const getPolygonscanTransactionLink = (txHash: string | undefined): string => {
  return `${globalVariable.POLYGONSCAN}tx/${txHash}`;
};

export const getPolygonscanContractLink = (contract: string | undefined): string => {
  return `${globalVariable.POLYGONSCAN}address/${contract}`;
};

export const getPolygonscanTokenLink = (contract: string | undefined, token: number | undefined): string => {
  return `${globalVariable.POLYGONSCAN}token/${contract}?a=${token}`;
};

export const getOpenseaLink = (contract: string | undefined, tokenId: number | undefined): string => {
  return `${globalVariable.POLYGON_OPENSEA}${contract}/${tokenId}`;
};

export const calculateMintFee = (flat_fee: number, fee_per_recipient: number, recipients: number): number => {
  const fee = (flat_fee + recipients * fee_per_recipient).toFixed(2);
  return parseFloat(fee);
};

export const formatRequestForm = (user_type) => {
  if (typeof window === 'undefined') return '';
  return `WEB - ${user_type} - ${window.location.pathname}`;
};

export const addEllipsisToLongWord = (str) => {
  let finalStr = str;
  if (!finalStr) return '';
  if (str?.length > 20 && str?.indexOf(' ') === -1) {
    finalStr = str?.slice(0, 20) + '...';
  }
  return finalStr;
};

const cachedData = {};

export const clearCache = () => {
  for (const key in cachedData) {
    delete cachedData[key];
  }
};

export const getHTmlContent = (html: string) => {
  if (!html) return '';
  const div = document.createElement('div');
  div.innerHTML = html?.trim();
  const hiddenElements = div?.querySelectorAll('[style*="display:none"]');
  hiddenElements?.forEach((element) => element.parentNode?.removeChild(element));
  return div.innerText;
};

export const calcRemodelingOptions = (home_value, item) => {
  const value_change_proportion = get(item, 'value_change_proportion', 0);
  const cost_estimate = get(item, 'cost_estimate', 0);
  const increaseValueOfPercent = (Math.abs(value_change_proportion) * 100).toFixed(1);
  const increaseValueOfDollar = formatCurrencySymbol(
    Number(Math.abs(home_value * value_change_proportion)).toFixed(),
    'USD',
    true,
  );
  const appreciationProjectTrend = value_change_proportion > 0;
  const appreciationHighValue = cost_estimate?.max > 0;
  const highValueCost = formatCurrencySymbol(Number(cost_estimate?.max).toFixed() || 0, 'USD', true);
  return {
    appreciationProjectTrend,
    increaseValueOfDollar,
    increaseValueOfPercent,
    highValueCost,
    appreciationHighValue,
  };
};

const handleCombineProject = (project_descriptions, component_projects) => {
  return project_descriptions
    .map((description) => {
      const valuation = component_projects.find((valuation) => valuation.project_id === description.project_id);
      if (!valuation) {
        return;
      }
      return { ...description, ...valuation };
    })
    .filter(Boolean);
};

export const getValuePlunkData = (plunkData) => {
  const value_change_proportion = get(plunkData, 'remodel_value.recommendation.value_change_proportion', 0);
  const component_projects = get(plunkData, 'remodel_value.recommendation.component_projects', []);
  const project_descriptions = get(plunkData, 'remodel_value.project_descriptions', []);
  const project_valuations = get(plunkData, 'remodel_value.project_valuations', []);
  const valuation_dollars = get(plunkData, 'home_value.valuation_dollars', 0);
  const appreciation_per_second = get(plunkData, 'home_value.appreciation_per_second', 0);
  const insight_value = get(plunkData, 'insight_value', {});

  const appreciationPerDayHomeValue = appreciation_per_second * 60 * 60 * 24;
  const remodelUpsideValue = valuation_dollars * value_change_proportion;
  const remodelValue = remodelUpsideValue + valuation_dollars;
  // const appreciationPerDayRemodelValue = remodelValue * value_change_proportion - remodelValue;
  const appreciationPerDayRemodelValue = 0; // TODO update value
  // get data for Remodeling options
  const combineDescriptionsAndValuations = handleCombineProject(project_descriptions, project_valuations);
  const combineDescriptionsAndRecommendation = handleCombineProject(project_descriptions, component_projects);
  const isValuesValid = !plunkData?.home_value && !plunkData?.remodel_value && !plunkData?.insight_value;
  return {
    valuation_dollars,
    appreciation_per_second,
    appreciationPerDayHomeValue,
    remodelValue,
    appreciationPerDayRemodelValue,
    insight_value,
    remodelUpsideValue,
    project_valuations,
    // get data for Remodeling options
    /* Don't show project that contained "second story" inside
     * https://app.asana.com/0/1201159044737828/1205245022412506/f
     */
    combineDescriptionsAndValuations: combineDescriptionsAndValuations.filter(
      (v) => !v.project_id.includes('second-story'),
    ),
    combineDescriptionsAndRecommendation: combineDescriptionsAndRecommendation.filter(
      (v) => !v.project_id.includes('second-story'),
    ),
    home_value: plunkData?.home_value,
    isValuesValid,
  };
};

export const convertAddress = (item) => {
  const { address = '', city = '', state = '', zip_code = '' } = item && item;
  const parts = [address, city, state, zip_code].filter(Boolean);
  return parts.join(', ');
};

const dataMarketGraph = [
  {
    caption: 'Sale vs. List Price',
    measure: 'sale_vs_list_price_percent',
    tooltipText: 'A measure of the magnitude by which homes are selling above or below list price.',
    unitType: '%',
    displayValueOperation: function (e) {
      return Math.round(e * 100);
    },
  },
  {
    caption: 'Median List Price',
    measure: 'median_list_price_dollars',
    tooltipText: 'The average asking price for homes on the market.',
    displayValueOperation: function (e) {
      return nFormatter(e, 1, 'USD');
    },
  },
  {
    caption: 'Price Per Sqft',
    measure: 'price_per_sqft_dollars',
    tooltipText: 'A measure of the typical price of a home, normalized by the size of the home.',
    unitText: 'Per Sqft',
    displayValueOperation: function (e) {
      return nFormatter(e, 1, 'USD');
    },
  },
  {
    caption: 'Inventory',
    measure: 'inventory_count',
    tooltipText: 'A measure of the active supply of homes on the market.',
    unitText: 'Homes',
    displayValueOperation: function (e) {
      return formatCurrency(e, true);
    },
  },
  {
    caption: 'Days of Inventory',
    measure: 'days_of_inventory_count',
    tooltipText:
      'A measure of how fast all the existing homes on the market would last assuming a) no more listings are added, and b) the rate at which homes sell is a constant figure based on the average of the last 12 months of pending sales.',
    unitText: 'Days',
    displayValueOperation: function (e) {
      return formatCurrency(e, true);
    },
  },
  {
    caption: 'Median Days on Market',
    measure: 'median_days_on_market',
    tooltipText: 'A measure of how long homes typically stay on the market.',
    unitText: 'Days',
    displayValueOperation: function (e) {
      return formatCurrency(e, true);
    },
  },
];
export const combineMarketGraph = (insight_value) => {
  if (!dataMarketGraph) return [];
  const combinedData = dataMarketGraph
    .map((item) => {
      const measure = item.measure;
      const measure90days = item.measure + '_90_days';
      const insightValue = insight_value[measure];
      const insightValue90Days = insight_value[measure90days];

      return insightValue ? { ...item, ...insightValue, values_90_days: insightValue90Days?.values || [] } : null;
    })
    .filter((item) => item !== null);
  return combinedData ?? [];
};

export const convertAndCalculationData = (item) => {
  const { end_date, start_date, values = [], values_90_days, measure, unitText, ...rest } = item;

  let endDate,
    unit_text = unitText || '';
  const startDate = moment(start_date).format('MMMM YYYY');
  const today = moment();
  const inputDate = moment(end_date);
  if (inputDate.isSame(today, 'day')) {
    endDate = 'Today';
  } else {
    endDate = inputDate.format('MMMM YYYY');
  }

  const lastIndex = values?.length - 1;
  const secondLastIndex = values?.length - 2;
  const secondEndValue = values[secondLastIndex]?.value;
  const endValue = values[lastIndex]?.value;
  const firstValue = values[0]?.value;
  const trend = Math.abs(((endValue - secondEndValue) / secondEndValue) * 100);
  const appreciationTrend = endValue - secondEndValue > 0 ? true : endValue - secondEndValue < 0 ? false : undefined;
  if (endValue - secondEndValue === 0) {
    unit_text += ' no Change';
  }
  // Update the `values` property
  let updatedValues = values || [];
  let updatedValues90Days = values_90_days || [];

  if (measure === 'sale_vs_list_price_percent') {
    updatedValues = updatedValues.map((value) => ({ ...value, value: Math.round(value.value * 100) }));
    updatedValues90Days = updatedValues90Days.map((value) => ({ ...value, value: Math.round(value.value * 100) }));
  }

  return {
    end_date: endDate,
    start_date: startDate,
    unitText: unit_text,
    values: updatedValues,
    values_90_days: updatedValues90Days,
    firstValue,
    endValue,
    trend,
    appreciationTrend,
    ...rest,
  };
};

export const checkPositiveOrNegative = (number): boolean => {
  return number >= 0;
};

interface Point {
  lat: number;
  lng: number;
}

export function getPolygonByCoordinate(point: Point, houseAreaInSquareFeet = 1000) {
  // Define the latitude and longitude coordinates of the house
  const houseCoordinates = [point.lng, point.lat];

  // Convert the coordinates to a Turf.js point geometry
  const housePoint = turf.point(houseCoordinates);

  // Calculate the radius of the circle
  const circleRadius = Math.sqrt(houseAreaInSquareFeet) / Math.PI;

  // Create a circle around the point geometry with the calculated radius
  const houseCircle = turf.circle(housePoint, circleRadius, { steps: 12, units: 'feet' });

  // Print the resulting polygon as GeoJSON
  const geoJsonWKT = geojsonToWKT(houseCircle?.geometry);
  let GeoJSON;
  if (geoJsonWKT) {
    GeoJSON = {
      match: 'polygon',
      value: { wkt: geoJsonWKT },
    };
  }
  return GeoJSON;
}

export const getGeoCodeFromAddress = (address: string, isGetLatLong = false) => {
  return fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKeyMap}`,
  )
    .then((response) => response.json())
    .then((data) => {
      const latLng = data?.results[0]?.geometry?.location || {};
      const { lat, lng } = latLng;

      if (lat && lng) {
        if (isGetLatLong) return { lat, long: lng, polygon: getPolygonByCoordinate({ lat, lng }) };
        else return getPolygonByCoordinate({ lat, lng });
      } else {
        throw new Error('Could not find latitude and longitude');
      }
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const hasChecked = (data: ToolBoxPremiumData): boolean => {
  return data.some((item) => item.checked === true);
};

export const hasRequiredFields = (item) => {
  const condition1 = item?.address && item?.city && item?.state && item?.zip_code;
  const condition2 = item?.lat && item?.long;
  return condition1 || condition2;
};

export const extractEssentialData = (item) => {
  const {
    address,
    city,
    state,
    lat,
    long,
    zip_code,
    id,
    property_sqft,
    state_abbrev,
    request_premium_data,
    premium_data_count_status,
    premium_data_count,
  } = item;
  return {
    address,
    city,
    state,
    lat,
    long,
    zip_code,
    id,
    property_sqft,
    state_abbrev,
    request_premium_data,
    premium_data_count_status,
    premium_data_count,
  };
};

export const getTurnoverRateVal = (metaData) => {
  let turnover_rate;
  if (isEmpty(metaData)) {
    turnover_rate = null;
  } else {
    const { home_count, mo_total_transactions_12 } = metaData;
    if (!home_count || !mo_total_transactions_12) {
      turnover_rate = !home_count ? null : 0;
    } else {
      turnover_rate = Math.round((mo_total_transactions_12 * 100) / home_count);
    }
  }
  return turnover_rate;
};

export const compareArrayPriority = (priorityText: string[], a: string, b: string) => {
  const aPriority = priorityText.findIndex((text) => a.toLowerCase().includes(text));
  const bPriority = priorityText.findIndex((text) => b.toLowerCase().includes(text));

  if (aPriority === bPriority) {
    // If both elements have the same priority, use their original order
    return 0;
  } else if (aPriority === -1) {
    // Move elements without priority to the bottom
    return 1;
  } else if (bPriority === -1) {
    // Move elements without priority to the bottom
    return -1;
  } else {
    // Sort elements based on their priority index
    return aPriority - bPriority;
  }
};

export const getEmailContent = (selectedTemplate) => {
  const content = selectedTemplate?.content || selectedTemplate?.custom_template;
  const title = selectedTemplate?.custom_title || selectedTemplate?.title;
  const emailContent = title
    ? `<p style="font-size:16px; font-weight:600">Title: ${title || ''}</p><br> ${content}`
    : content;
  return emailContent;
};

const listVariablesOnUIIncludeHiddenVarFields = (htmlString) => {
  const listVar = ToolsService.extractVariablesFromHtmlString(htmlString);
  if (listingImageVars.some((variable) => listVar.includes(variable))) return [...listVar, 'listing_image'];
  else return listVar;
};

export const formatFallback = (fallback, htmlString = '') => {
  if (!fallback) return {};
  const newFallback: Record<string, unknown> = cloneDeep(fallback);
  Object.keys(fallback).forEach((key) => {
    if (key === 'listing_image') {
      if (fallback[key]?.length)
        newFallback['listing_image'] = fallback[key].map((v) => (typeof v === 'object' ? v.url : v));
    } else if (typeof fallback[key] === 'object') newFallback[key] = fallback[key]?.url;
    newFallback[key] = typeof fallback[key] === 'string' ? fallback[key].trim() : fallback[key];
    if (listingImageVars.includes(key) && !fallback[LISTING_IMAGE_KEY]?.includes(newFallback[key])) {
      // If the {{listing_image}} does not exist, remove the {{listing_image_1->5}} images
      newFallback[key] = null;
    }
  });
  if (htmlString !== '') {
    const listVariableOnUI = listVariablesOnUIIncludeHiddenVarFields(htmlString);
    return pick(omit(newFallback, landing_page_var_key), listVariableOnUI);
  } else return omit(newFallback, landing_page_var_key);
};

export const checkExistedTemplateName = (templateName: string, emailTemplateList, editTemplateName?: string) => {
  if (!templateName?.trim().length) return false;
  if (
    templateName?.trim() !== editTemplateName &&
    emailTemplateList &&
    emailTemplateList.length > 0 &&
    emailTemplateList.find((v) => v?.name.trim().toLocaleLowerCase() === templateName.trim().toLocaleLowerCase())
  )
    return true;
  else return false;
};

export const checkTemplateChanged = (selectedEmailTemplate, editEmailTemplate) => {
  const selectedTemplate = cloneDeep(selectedEmailTemplate);
  const editTemplate = cloneDeep(editEmailTemplate);
  if (selectedTemplate?.name !== editTemplate?.name) return true;
  if (getHTmlContent(selectedTemplate?.custom_title)?.trim() !== getHTmlContent(editTemplate?.custom_title)?.trim())
    return true;
  if (
    selectedTemplate?.custom_template?.trim()?.replaceAll('"', "'") !==
    editTemplate?.custom_template?.trim()?.replaceAll('"', "'")
  )
    return true;

  if (!selectedTemplate?.custom_fallbacks) selectedTemplate.custom_fallbacks = {};
  if (!editTemplate?.custom_fallbacks) editTemplate.custom_fallbacks = {};
  Object.keys(selectedTemplate?.custom_fallbacks)
    .concat(Object.keys(editTemplate?.custom_fallbacks))
    .map((key) => {
      if (
        !selectedTemplate?.custom_fallbacks[key] ||
        selectedTemplate?.custom_fallbacks[key]?.toString()?.length === 0
      ) {
        delete selectedTemplate?.custom_fallbacks[key];
      }
      if (!editTemplate?.custom_fallbacks[key] || editTemplate?.custom_fallbacks[key]?.toString()?.length === 0) {
        delete editTemplate?.custom_fallbacks[key];
      }
    });
  selectedTemplate.custom_fallbacks = formatFallback(selectedTemplate?.custom_fallbacks);
  editTemplate.custom_fallbacks = formatFallback(editTemplate?.custom_fallbacks);
  let isFallbackChanged = false;
  Object.keys(selectedTemplate?.custom_fallbacks)
    .concat(Object.keys(editTemplate?.custom_fallbacks))
    .map((key) => {
      if (
        JSON.stringify(selectedTemplate?.custom_fallbacks[key]) !== JSON.stringify(editTemplate?.custom_fallbacks[key])
      ) {
        isFallbackChanged = true;
        return;
      }
    });
  return isFallbackChanged;
};

export const checkIsTemplateUsed = async (templateID: string, agentID: string, listDrip?: any) => {
  try {
    const res = await CampaignService.listTemplateDrips(templateID, agentID);
    if (!res) return false;
    if (listDrip) {
      const idx = listDrip?.findIndex((v) => v?.template_email_id === templateID);
      if (idx >= 0) return true;
    }
    if (res?.drip_campaigns?.length || res?.smart_triggers?.length) return true;
    else return false;
  } catch (error) {
    // Do nothing
  }
};

export const handleFilterMetadata = (metadata, premiumList) => {
  const filteredMetadata = {};
  for (const key in metadata) {
    const premiumItem = premiumList.find((item) => item.key === key);
    if (premiumItem) {
      if (premiumItem.checked) {
        filteredMetadata[key] = metadata[key];
      } else {
        delete filteredMetadata[premiumItem.key];
      }
    } else {
      filteredMetadata[key] = metadata[key];
    }
  }
  return filteredMetadata;
};

export const checkPurchasedPremiumData = (purchasedPremium, premiumData) => {
  const updatedData = premiumData?.map((data) => {
    const matchingItem = purchasedPremium?.find((item) => item.key === data.key);
    if (matchingItem) {
      return {
        ...data,
        disabled: true,
        checked: false,
      };
    }
    return data;
  });
  return updatedData;
};

export const getMailingsPayload = (selectedItems) => {
  if (isEmpty(selectedItems)) {
    return [];
  } else {
    return selectedItems?.map((item) => ({ id: item?.id, polygon: item?.polygon }));
  }
};

export const downloadCSVFile = (name, blobContent) => {
  const blob = new Blob([blobContent as string], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const currentDate = moment();
  const formattedDate = currentDate.format('YYYY_MM_DD');
  const fileName = `${name}_${formattedDate}.csv`;
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(url);
  link.remove();
};

export const setSessionStorageItem = (key, value) => {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    // Do nothing
  }
};

export const getSessionStorageItem = (key) => {
  try {
    const storedItem = sessionStorage.getItem(key);
    return storedItem ? JSON.parse(storedItem) : null;
  } catch (error) {
    return null;
  }
};

export const convertPixelsAndNumber = (pxString, numberToAdd) => {
  if (pxString.endsWith('px')) {
    const numericValue = parseFloat(pxString);
    if (!isNaN(numericValue)) {
      const convertedValue = numericValue + numberToAdd;
      return `${convertedValue}px`;
    }
  }

  return pxString;
};

export const handleOnChangePhoneNumber = (e, setFieldValue, type: 'formik' | 'state', fileName = 'phone') => {
  let result = '';
  const setValue = (value) => {
    if (type === 'formik') setFieldValue(fileName, value, false);
    else if (type === 'state') setFieldValue(value);
  };

  const regex = /[^\d()+\\-]/g; //remove input not number but include("(".")","-","+"")
  const testRegex = regex.test(e.target.value);
  if (testRegex) {
    setValue(formatPhoneNumber(e.target.value.replace(regex, '')) || '');
    result = formatPhoneNumber(e.target.value.replace(regex, '')) || '';
  } else {
    const prevLength = e.target.value?.length;
    let currentPos = e.target.selectionStart;
    const formattedPhone = formatPhoneNumber(e.target.value);
    if (e.target.value.replaceAll('(', '').replaceAll(')', '')?.length < 6) {
      setValue(e.target.value.trim());
      result = e.target.value;
    } else {
      setValue(formattedPhone);
      result = formattedPhone || '';
    }
    if (prevLength === currentPos) currentPos = formattedPhone?.length;
    e.target.value = formattedPhone;
    e.target.setSelectionRange(currentPos, currentPos);
  }
  return result;
};

export const fetchDataFromAllPages = async (apiCall, queryParams = {}) => {
  return new Promise((res) => {
    try {
      const pageSize = 100; // Number of items per page
      let currentPage = 1;
      let totalCount = 0;
      let allData = [];
      const fetchPageData = async (page, apiCall: (page, limit, queryParams) => Promise<Pagination<any>>) => {
        // Make your API call here with the appropriate page parameter
        const response = await apiCall(page, pageSize, queryParams);
        const { results, count } = response;
        allData = allData.concat(results as any);
        // Update the total count (if available)
        if (count) {
          totalCount = count;
        }
      };
      const fetchNextPage = async (apiCall) => {
        await fetchPageData(currentPage, apiCall);
        currentPage++;
        // Check if there are more pages to fetch
        if (allData.length < totalCount) {
          await fetchNextPage(apiCall);
        } else {
          res(allData);
        }
      };
      fetchNextPage(apiCall).then(() => {
        if (allData.length === totalCount) res(allData);
      });
      // Verify if the returned data length matches the totalCount
    } catch (error) {
      res([]);
    }
  });
};

export const mergeResultIntoInitialState = (initialState, result, extra_premium_price = 0) => {
  // Create a map for quick look-up based on the 'key' property in the result array
  const resultMap = {};
  result.forEach((item) => {
    resultMap[item.key] = item;
  });

  // Map the values from the result array to the initialState array
  const mappedInitialState = initialState.map((item) => {
    const resultItem = resultMap[item.key];
    if (resultItem) {
      const { price, matches } = resultItem;
      const totalPrice = extra_premium_price * (matches || 0) + (price || 0);
      return {
        ...item,
        price: totalPrice,
        matches: resultItem.matches,
        priceWithoutFee: price,
      };
    }
    return item;
  });

  return mappedInitialState;
};

export const updateOptionsAudience = (optionsAudience, value: string[]) => {
  if (value.length === 0) {
    return optionsAudience.map((option) => ({
      ...option,
      disabled: false,
    }));
  }

  const isAllHomesSelected = optionsAudience.find((option) => option?.value === value[0])?.label === _ALL_HOMES_LABEL;

  return optionsAudience.map((option) => {
    if (option.label === _ALL_HOMES_LABEL) {
      return { ...option, disabled: !isAllHomesSelected };
    } else if (isAllHomesSelected) {
      return { ...option, disabled: true };
    }
    return option;
  });
};

export const getResolutionSize = (resolution: ResolutionTemplate | string) => {
  switch (resolution) {
    case ResolutionTemplate.Letter:
      return { width: 826, height: 1056 };
    case ResolutionTemplate.Email:
      return { width: 826, height: 1056 };
    case ResolutionTemplate.Photo6x4:
      return { width: 600, height: 408 };
    case ResolutionTemplate.Photo9x6:
      return { width: 888, height: 600 };
    case ResolutionTemplate.Photo11x6:
      return { width: 1080, height: 600 };
    default:
      return { width: 0, height: 0 };
  }
};

export const calcResolutionSize = (postcard_size, type) => {
  const resolutionMapping = {
    [TemplateITemEnum.POSTCARD]: postcard_size || '',
    [TemplateITemEnum.LETTER]: ResolutionTemplate.Letter,
    [TemplateITemEnum.EMAIL]: ResolutionTemplate.Email,
  };
  const size = resolutionMapping[type] || '';
  return getResolutionSize(size);
};

export const createIconPins = (color = '#ed3724'): string => {
  const template = [
    '<?xml version="1.0"?>',
    '<svg xmlns="http://www.w3.org/2000/svg" width="31.084" height="37.325" viewBox="0 0 31.084 37.325">',
    '<g id="map-pin" transform="translate(-1.5 0.5)">',
    `<path id="Path_1005" data-name="Path 1005" d="M31.084,15.042c0,10.922-14.042,20.283-14.042,20.283S3,25.964,3,15.042a14.042,14.042,0,1,1,28.084,0Z" transform="translate(0 0)" fill="none" stroke="${color}" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>`,
    `<circle id="Ellipse_15" data-name="Ellipse 15" cx="5" cy="5" r="5" transform="translate(12 10)" fill="none"  stroke="${color}" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>`,
    '</g>',
    '</svg>',
  ].join('\n');
  return 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(template);
};

export const getColorForStatus = (status) => {
  switch (status) {
    case TypeMetaDataOther.ACTIVE:
      return 'Green';
    case TypeMetaDataOther.PENDING:
      return '#d48806';
    case TypeMetaDataOther.COMING_SOON:
      return '#ff00dd';
    case TypeMetaDataOther.SOLD:
      return 'Purple';
    default:
      return null;
  }
};

export const formatEmailHTMLString = (htmlString = '') => {
  const regexPattern = /srcset="([^"]*)"/g;
  if (!htmlString) return '';
  const modifiedHtmlString = htmlString.replace(regexPattern, `srcset="${TRANSPARENT_IMAGE}"`);
  return modifiedHtmlString;
};

export const removeCharAtIndex = (inputString, index) => {
  if (index < 0 || index >= inputString.length) {
    // Index out of bounds
    return inputString;
  }

  const stringWithoutChar = inputString.slice(0, index) + inputString.slice(index + 1);
  return stringWithoutChar;
};

export const findDifferenceIndex = (str1: string, str2: string) => {
  const minLength = Math.min(str1.length, str2.length);

  for (let i = 0; i < minLength; i++) {
    if (str1[i] !== str2[i]) {
      return i;
    }
  }

  // If the common prefix is the same, but one string is shorter
  if (str1.length !== str2.length) {
    return minLength;
  }

  // If no difference is found in the common prefix
  return -1;
};

export const formatObjectKeyToTitle = (key: string) => {
  const upperCaseWordList = ['nml', 'pc', 'dre', 'avg', 'url', 'mls', 'dom'];
  const lowerCaseWordList = ['in', 'on', 'of', 'and', 'to'];
  return key
    .split('_')
    .map((word) => {
      if (upperCaseWordList.includes(word)) return word.toUpperCase();
      if (lowerCaseWordList.includes(word.toLowerCase())) return word.toLowerCase();
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};

const isImageVariable = (variable: string) =>
  imageKeywords.some((v) => variable.includes(v)) && !listingImageVars.includes(variable);

export const getListVarsOnFallbackForm = (
  type: FallbackTypeEnum,
  htmlString: string,
  listAllVariable: string[],
  mapToFallbackUI = true,
) => {
  let data: string[] = [];
  data = ToolsService.extractVariablesFromHtmlString(htmlString).filter(
    (variableFromHtmlString) =>
      !hiddenVarOnFallbackFormUI.some((hiddenVar) => variableFromHtmlString.includes(hiddenVar)) &&
      listAllVariable.concat(listingImageVars).some((variable) =>
        // Can't use '===' here cuz there're some vars with prefix that need to be shown
        variableFromHtmlString.endsWith(variable),
      ) &&
      !isImageVariable(variableFromHtmlString),
  );
  if (!mapToFallbackUI) return data;
  return data.map((v) => ({ value: v, label: formatObjectKeyToTitle(v) })) as FallbackVariableOnUI[];
};

export const getListVarsOnHtmlUI = (htmlString: string, listAllVariable: string[], mapToFallbackUI = true) => {
  let data: string[] = [];
  data = ToolsService.extractVariablesFromHtmlString(htmlString).filter((variableFromHtmlString) =>
    listAllVariable.concat(listingImageVars).some((variable) => variableFromHtmlString.endsWith(variable)),
  );
  if (!mapToFallbackUI) return data;
  return data.map((v) => ({ value: v, label: formatObjectKeyToTitle(v) })) as FallbackVariableOnUI[];
};

export const updateClipValues = (match, left, top, right, bottom) => {
  // Round decimal values to 4 decimal places
  const roundedValues = (value) => parseFloat(value).toFixed(4);

  // Update the clip values with rounded decimals
  const updatedClip = `clip: rect(${roundedValues(left)}px, ${roundedValues(top)}px, ${roundedValues(
    right,
  )}px, ${roundedValues(bottom)}px);`;

  return updatedClip;
};
export const isMLSVariable = (variable: string) => {
  const regex = new RegExp(/^(.*)[-](\d{5})_/, 'g');
  return !!variable.match(regex)?.length;
};
const generateBase64Images = async (baseImage, variableList) => {
  const base64Images = {};
  // Wait for the base image to load
  await new Promise((resolve) => {
    baseImage.onload = resolve;
  });
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

  canvas.width = 300;
  canvas.height = 300;

  variableList.forEach((variableName) => {
    // Draw the base image
    ctx?.clearRect(0, 0, canvas.width, canvas.height);
    ctx?.drawImage(baseImage, 0, 0);

    // Draw the text in the middle
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = 'white';
    ctx.font = '30px Arial';

    const isMlsVariable = isMLSVariable(variableName);

    // set position of text
    const lineHeight = 30 + 5;
    const x = canvas.width / 2;
    let y = canvas.height / 2 - (isMlsVariable ? 75 : lineHeight);

    // Draw the text
    const lines = `{{${variableName}}}`.split('-').join('\n-').split('_').join('\n_').split('\n');

    lines?.forEach((line, index) => {
      let insertX = x;
      if (index === 0) {
        insertX = insertX - 10;
      }
      ctx.fillText(line, insertX, y);
      y += lineHeight;
    });

    // Convert canvas to base64
    const imageDataUrl = canvas.toDataURL('image/png');
    base64Images[variableName] = imageDataUrl;
  });

  return base64Images;
};

export const generateDefaultImageForVariables = async (listVariables) => {
  const basePath = '../../../listing-image/listing_image.png';
  const baseImage = new Image();
  baseImage.src = basePath;
  const listGeneratedImage = await generateBase64Images(baseImage, listVariables);
  return listGeneratedImage;
};

export const replaceEmptyImageByBase64 = async ({
  htmlString = '',
  isPostgrid = false,
  isReplaceDefaultImgToSrc = false,
}) => {
  let regexImageTag = new RegExp(`<img\\s+src="([^"]*)"\\s+srcset="${TRANSPARENT_IMAGE}"\\s*[^>]*>`, 'g');
  if (isPostgrid) {
    regexImageTag = new RegExp(`<img\\s+src="([^"]*)"\\s*[^>]*>`, 'g');
  }
  const variablesFormatRegex = /^{{.+}}$/;
  let match;
  let modifiedHtmlString = htmlString;

  while ((match = regexImageTag.exec(htmlString)) !== null) {
    const srcValue = match[1];
    if (
      isImageVariable(srcValue) &&
      !srcValue?.includes(EMAIL_FOOTER_IMAGE_URL) &&
      variablesFormatRegex.exec(srcValue)
    ) {
      const defaultImage = await Object.values(
        (await generateDefaultImageForVariables([srcValue.slice(2, srcValue?.length - 2)])) || {},
      )?.[0];
      modifiedHtmlString = modifiedHtmlString.replaceAll(
        isPostgrid ? `<img src="${srcValue}"` : `<img src="${srcValue}" srcset="${TRANSPARENT_IMAGE}"`,
        isReplaceDefaultImgToSrc ? `<img src="${defaultImage}" ` : `<img src="${srcValue}" srcset="${defaultImage}"`,
      );
    }
  }

  return modifiedHtmlString;
};

export const getEmailHtmlString = async (template) => {
  const content = await replaceEmptyImageByBase64({ htmlString: template?.custom_template || template?.content });

  const title = template?.custom_title || template?.title;
  const emailContent = `<html>
              <head>
                <style>

                    * {
                        box-sizing: border-box;
                        margin: 0;
                        padding: 0;
                    }

                    body {
                        width: 826px;
                        height: 1056px;
                      }
                    .page {
                        position: relative;
                        width: 826px;
                        height: 1056px;
                        overflow: hidden;
                        page-break-after: always;
                    }
                    .email-template-fake-postgrid {
                      ol{
                        padding-left: 30px;
                      }
                    }

                </style>
            </head>
            <body>
              <div class="page email-template-fake-postgrid">
                ${
                  title
                    ? `<p style="font-size: 16px; font-weight:600">Title: ${title || ''}</p><br> ${content}`
                    : content
                }
              </div>
            </body>
            </html>`;
  return emailContent;
};

export const getAllImageVariables = (sessionVars) => {
  const allVariables = ToolsService.getAllVariablesFromSectionVars(sessionVars);
  const listImageVariables = allVariables?.filter((variable) =>
    imageKeywords.some((keyWord) => variable?.includes(keyWord)),
  );
  return listImageVariables || [];
};

export const scrollIntoViewEle = async (id: string) => {
  const targetElement = document.getElementById(id);
  if (targetElement) {
    targetElement.scrollIntoView();
    deleteUrlParams('intoview');
  }
};

export const getListingImageUrl = (listingImageArray, listingImageNValue) => {
  const listingImageValue = typeof listingImageNValue === 'object' ? listingImageNValue?.url : listingImageNValue;
  return listingImageArray?.includes(listingImageValue) ? listingImageValue : '';
};

export const removeEmptyDataFieldNotSaveToDB = (fallback = {}, mlsData = {}, mailingListData = {}) => {
  //remove fields that have empty value and not included by mls field and mailing list field
  const updatedFallback = cloneDeep(fallback);
  const mlsAndMlKey = Object.keys({ ...mlsData, ...mailingListData });
  Object.keys(updatedFallback).forEach((key) => {
    if (mlsAndMlKey.includes(key)) return;
    if (updatedFallback[key] === '') delete updatedFallback[key];
  });
  return updatedFallback;
};

export const formatOrdinal = (number: number): string => {
  const lastDigit = number % 10;
  const secondLastDigit = Math.floor(number / 10) % 10;

  if (secondLastDigit === 1) {
    return number + 'th';
  }

  switch (lastDigit) {
    case 1:
      return number + 'st';
    case 2:
      return number + 'nd';
    case 3:
      return number + 'rd';
    default:
      return number + 'th';
  }
};

const DisplayFormat = {
  CURRENCY: 'CURRENCY',
  PERCENT: 'PERCENT',
};

export const displayValue = (value, displayFormat?: 'CURRENCY' | 'PERCENT', currencyPrefix = 'USD') => {
  if (value === null || value === undefined) return 'N/A';
  value = Number(value);
  let formattedValue;

  switch (displayFormat) {
    case DisplayFormat.CURRENCY:
      formattedValue = formatCurrencySymbol(value, currencyPrefix, true);
      break;
    case DisplayFormat.PERCENT:
      if (!isNaN(Number(value))) {
        formattedValue = formatCurrency(value, true) + '%';
      } else {
        formattedValue += '%';
      }
      break;
    default:
      formattedValue = formatCurrency(value, true);
  }

  return formattedValue;
};

export const getMinMaxObjectKey = (object: any) => {
  const keys = Object.keys(object || {});
  const numericKeys = keys.map(Number);
  const max = Math.max(...numericKeys);
  const min = Math.min(...numericKeys);
  return [min, max];
};

export const renderRangeValue = (value: string, fieldName: string, maxValue?: string) => {
  const percentageFieldName = ['home_equity_percentage', 'interest_rates'];
  const priceKeyWord = ['price', 'avm_values'];
  const suffixSign = percentageFieldName?.includes(fieldName) ? '%' : '';
  const prefixSign = priceKeyWord?.some((key) => fieldName?.includes(key)) ? '$' : '';
  const minMaxValue = value?.split(',');
  const min = minMaxValue?.[0]?.toString();
  const max = minMaxValue?.[1]?.toString();
  if (!min && max) return `<= ${prefixSign}${max}${suffixSign}`;
  if (min && (!max || max === maxValue?.toString())) return `>= ${prefixSign}${min}${suffixSign}`;
  if (min === max) return ` = ${prefixSign}${min}${suffixSign}`;

  return `>= ${prefixSign}${min}${suffixSign}, <= ${prefixSign}${max}${suffixSign}`;
};

export const getNameUserAgent = (obj): string => {
  const { first_name, last_name } = obj?.slipstream_submission?.user_agent ?? {};
  if (first_name && last_name) {
    return `${first_name} ${last_name}`;
  }

  if (obj.name) {
    return obj.name;
  }

  return '';
};

export const generateDefaultFallbacksByHtmlString = (htmlString: string) => {
  const listVariableInHTML = ToolsService.extractVariablesFromHtmlString(htmlString || '');
  const newDefaultFallback = {};
  listVariableInHTML.forEach((key) => {
    newDefaultFallback[key] = '';
  });
  return newDefaultFallback;
};

export const convertThumbnailHandWritten = (handwrittenTemplate, isThumbnailPreviewOnly = true) => {
  if (!handwrittenTemplate) return null;
  const headerText = JSON.parse(handwrittenTemplate?.header_text || '{}');
  const headerTextFont = headerText?.fontType?.split(' ')?.join('_');
  const footerContent = `<div class="simply-noted-API message-content-adjust" style="width: 100%; height: 90px; text-align: right;">
              <img src="${SCAN_NOW_QR_CODE}" alt="scannow_qrcode" style="height:90px; width: max-content;" />
            </div>`;

  const signature = `<div class="simply-noted-API ${handwrittenTemplate.font} message-content-adjust" style="padding:30px 10px 10px 50%; word-wrap:break-word">${handwrittenTemplate.signature}</div>`;
  const messageContent = `<div class="simply-noted-API ${handwrittenTemplate.font} message-content-adjust">${handwrittenTemplate.message}</div>`;
  const headerContent =
    handwrittenTemplate.header_type === HeaderTypeEnum.Image
      ? `<div class="simply-noted-API image-container" style="max-height: 80px; width: 100%;"><img class="image-adjust" src="${
          handwrittenTemplate.header_image || POSTGRID_DEFAULT_VALUES.NO_DATA_IMAGE
        }" alt="" style="height:80px; width: max-content;"/></div>`
      : `<div class="simply-noted-API ${headerTextFont} message-content-adjust" style="color: ${
          headerText?.fontColor
        }; font-size:${Number(headerText?.fontSize || 30) * 1.5}px; text-align:${headerText?.textAlign}">${
          headerText?.data
        }</div>`;
  const originBackImage = `<div class="simply-noted-API image-container">
          <div style="position: relative; width:100%;">
          <img class="image-adjust" src="${
            handwrittenTemplate.back_image || POSTGRID_DEFAULT_VALUES.NO_DATA_IMAGE
          }" alt=""/><img src="${SCAN_NOW_QR_CODE}" class="handwritten-qr-code simply-noted-API image-container" alt=""/>
          </div>
          </div>`;

  const bodyContent = `<div style="min-height:480px;">${headerContent + messageContent + signature}</div>`;
  const content =
    handwrittenTemplate.card_type === CardTypeEnum.Flat
      ? `${bodyContent + footerContent}`
      : `${messageContent + signature}`;
  const template_message = `<html>
              <head>
                <style>

                    * {
                        box-sizing: border-box;
                        margin: 0;
                        padding: 0;
                    }

                    body {
                        width: 768px;
                        height: ${isThumbnailPreviewOnly ? 'auto' : '590px'};
                      }
                    .page {
                        position: relative;
                        width: 768px;
                        height: ${isThumbnailPreviewOnly ? 'auto' : '590px'};
                        overflow: hidden;
                        page-break-after: always;
                    }

                </style>
            </head>
            <body>
              <div class="page">
                ${content}
              </div>
            </body>
            </html>`;
  return {
    ...handwrittenTemplate,
    type: PostgridTemplateType.HANDWRITTEN,
    front_template: `<div class="simply-noted-API image-container width:100%"><img class="image-adjust" src="${
      handwrittenTemplate.front_image || POSTGRID_DEFAULT_VALUES.NO_DATA_IMAGE
    }" alt=""/></div>`,
    back_template: originBackImage,
    header_template: headerContent,
    message_template: template_message,
    user_id: handwrittenTemplate.user,
    user_template_fallback: {
      default_fallbacks: handwrittenTemplate.custom_fallback,
      template: handwrittenTemplate.id,
      mls_data: handwrittenTemplate.mls_data,
      mailing_list_data: handwrittenTemplate.mailing_list_data,
    },
  };
};

export const arrayStrToValueLabel = (arrayStr: string[]) => {
  return arrayStr.map((str) => ({ value: str, label: str }));
};

export const filterOptionLocal = (input: string, option?: BaseOptionType | DefaultOptionType, fieldKey = 'label') =>
  (option?.[fieldKey] as string)?.toLowerCase().includes(input.toLowerCase().trim());

export const checkMinMaxValue = (newValue: (string | number | null)[]) => {
  if (
    newValue?.[1] !== null &&
    newValue?.[1] !== undefined &&
    newValue?.[1] !== '' &&
    Number(newValue?.[1] || 0) < Number(newValue?.[0] || 0)
  ) {
    return validation.filter.minMax;
  }
  return '';
};
