import { color as Color } from '@/styles/theme';
import { css } from '@emotion/react';
import { Typography } from 'antd';
import { ParagraphProps } from 'antd/lib/typography/Paragraph';
import { memo } from 'react';
const { Paragraph } = Typography;

interface SubTitleViewProps extends ParagraphProps {
  color?: Color;
  fz?: number;
  align?: 'left' | 'center' | 'right';
}

const SubTitleView = (props: SubTitleViewProps) => {
  const { color = Color.WHITE, fz = 16, align = 'left', ...rest } = props;
  return (
    <Paragraph
      css={css`
        font-size: ${fz}px !important;
        color: ${color} !important;
        text-align: ${align};
      `}
      {...rest}
    />
  );
};
export default memo(SubTitleView);
