import { InnerLoading } from '@/components/loading/loadingBase';
import { CaretDownOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { BaseOptionType, DefaultOptionType, SelectProps } from 'antd/lib/select';
import classNames from 'classnames';
import React, { forwardRef, memo, useMemo } from 'react';
import AppLabel from '../AppLabel';
import ErrorMessage from '../error-message';
import { SelectV2Styled } from './AppSelectV2Styled';
import { AppSelectV2Props } from './types';
const { Option } = Select;

const SelectV2View = <ValueType, OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType>(
  props: AppSelectV2Props<ValueType, OptionType>,
  ref?: React.Ref<React.ElementRef<typeof Select>>,
) => {
  const {
    label,
    requiredMark,
    horizontal,
    size = 'large',
    className,
    loading,
    showArrow = true,
    onLoadMore,
    options,
    fieldNames,
    loadMore,
    children,
    color = 'WHITE',
    noBorder,
    hasError,
    errorMessage,
    labelChildren,
    labelColor,
    labelAlign,
    squaredBorder,
    heightExpanded,
    ...rest
  } = props;

  const { maxTagCount, mode } = props;
  const maxTagCountWithMultiple = useMemo(() => {
    if (mode !== 'multiple' || typeof maxTagCount === 'number') return maxTagCount;
    return 'responsive';
  }, [maxTagCount, mode]);

  const id = useMemo(() => Math.random().toString(36).substring(7), []);
  const valueHasNull = useMemo(() => {
    const value = props?.value;

    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return !options?.map((v) => (fieldNames?.value ? v[fieldNames.value] : v.value)).includes(value);
  }, [props?.value, options, fieldNames]);

  return (
    <SelectV2Styled.Wrapper
      noBorder={noBorder}
      horizontal={horizontal}
      size={size}
      id={id}
      color={color}
      valueIsNull={valueHasNull}
      squaredBorder={squaredBorder}
      heightExpanded={heightExpanded}
      className="app-select-v2-wrapper"
    >
      <AppLabel
        label={label}
        requiredMark={requiredMark}
        paddingBottom={6}
        labelColor={labelColor}
        labelAlign={labelAlign}
      >
        {labelChildren}
      </AppLabel>

      <InnerLoading spinning={!!loading} iconStyle={{ color: '#2e0249', fontSize: '18px' }}>
        <Select
          status={hasError ? 'error' : undefined}
          ref={ref}
          suffixIcon={showArrow ? <CaretDownOutlined className="icon ant-select-suffix" color="#000" /> : null}
          size={size as SelectProps['size']}
          showArrow={showArrow}
          className={classNames(className, 'select-v2')}
          maxTagCount={maxTagCountWithMultiple}
          onPopupScroll={onLoadMore}
          getPopupContainer={() => document.getElementById(id) as HTMLElement}
          filterOption={false}
          {...rest}
        >
          {children ? children : null}
          {(options || [])?.map((option) => (
            <Option
              key={fieldNames?.value ? option[fieldNames?.value] : option?.value}
              value={fieldNames?.value ? option[fieldNames?.value] : option?.value}
              {...option}
            >
              {fieldNames?.label ? option[fieldNames?.label] : option?.label}
            </Option>
          ))}
          {loadMore && (
            <Option disabled value>
              <section className="load-more-section">
                <InnerLoading iconStyle={{ color: '#2e0249', fontSize: '18px' }} />
              </section>
            </Option>
          )}
        </Select>
      </InnerLoading>
      {hasError !== false && errorMessage && <ErrorMessage className="error">{errorMessage}</ErrorMessage>}
    </SelectV2Styled.Wrapper>
  );
};
const SelectForwardRef = forwardRef(SelectV2View) as <
  ValueType,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>(
  props: AppSelectV2Props<ValueType, OptionType> & { ref?: React.Ref<React.ElementRef<typeof Select>> },
) => ReturnType<typeof SelectV2View>;

export default memo(SelectForwardRef) as typeof SelectForwardRef; // with HOC memo not support generic type. must be force cast
