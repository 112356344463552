import { ForkEffect, put, takeLatest } from 'redux-saga/effects';
import CreditService from '@/services/credit';
import AppAction from '../app/action';
import HomeLimitAction from './action';

function* watchGetMySettingHomeCredit() {
  try {
    yield put({
      type: AppAction.APP_LOADING,
      payload: 'INCREMENT',
    });
    const mySetting = yield CreditService.subSetting();
    yield put({
      type: HomeLimitAction.SET_MY_SETTING_HOME_CREDIT,
      payload: mySetting,
    });
  } catch (error) {
    // Do nothing
  } finally {
    yield put({
      type: AppAction.APP_LOADING,
      payload: 'DECREMENT',
    });
  }
}

function* creditSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(HomeLimitAction.GET_MY_SETTING_HOME_CREDIT, watchGetMySettingHomeCredit);
}

export default creditSaga;
