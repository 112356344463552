import React from 'react';

interface WrapLinkProps {
  text: string;
  url: string;
  targetText: string;
}

const WrapLinkView: React.FC<WrapLinkProps> = ({ text, url, targetText }) => {
  const parts = text.split(targetText);

  if (parts.length < 2) return <div>{text}</div>;

  return (
    <div>
      {parts[0]}
      <a
        href={url}
        target="_blank"
        style={{
          color: 'white',
          textDecoration: 'underline',
        }}
        rel="noopener noreferrer"
      >
        {targetText}
      </a>
      {parts[1]}
    </div>
  );
};

export default React.memo(WrapLinkView);
