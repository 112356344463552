import { css, useTheme } from '@emotion/react';
import React, { CSSProperties } from 'react';
import isEqual from 'react-fast-compare';

interface ErrorMessageProps {
  className?: string;
  children?: React.ReactNode;
  color?: string;
  textAlign?: CSSProperties['textAlign'];
}
const ErrorMessage: React.FC<ErrorMessageProps> = React.memo(({ children, className, color, textAlign = 'left' }) => {
  const theme = useTheme();
  return children ? (
    <div
      className={className}
      css={css`
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: ${color ?? theme.text.errorColor};
        text-align: ${textAlign};
        margin-top: 5px;
      `}
    >
      {children}
    </div>
  ) : null;
}, isEqual);

export default ErrorMessage;
