import {
  Agent,
  inviteAgentArgs,
  manageAgentArgs,
  RefreshSoldHousesArgs,
  RefreshSubmissionArgs,
  SlipStreamAddAgent,
  SlipStreamAgent,
  SlipStreamAgentImportResult,
  SoldHousesResult,
  Submission,
  SubmissionArgs,
  UpdateSubmissionArg,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/slipstream/models';
import { Pagination } from '@goldfishcode/first-team-real-estate-sdk/libs/type';
import { apiIns } from '@/config/apiClient';

class OverviewService {
  static getAgentInfo(agent_id?: string): Promise<Agent> {
    return apiIns.overview.agentInfo(agent_id);
  }
  static postSubmission(args: SubmissionArgs): Promise<Submission> {
    return apiIns.overview.submit(args);
  }
  static refreshSubmission(args: RefreshSubmissionArgs): Promise<any> {
    return apiIns.overview.refreshSubmission(args);
  }
  static deleteSubmission(submission_id: string): Promise<any> {
    return apiIns.overview.deleteSubmission(submission_id);
  }
  static deleteAgentProspect(agent_id: string): Promise<any> {
    return apiIns.overview.deleteAgentProspect(agent_id);
  }
  static updateSubmission(submission_id: string, args: UpdateSubmissionArg): Promise<any> {
    return apiIns.overview.updateSubmission(submission_id, args);
  }
  static getAgentSoldHouses(agent_id?: string): Promise<SoldHousesResult> {
    return apiIns.overview.agentSoldHouses(agent_id);
  }
  static listAgent(
    page: number | undefined,
    limit: number | undefined,
    search_agent: string,
  ): Promise<Pagination<SlipStreamAgent>> {
    return apiIns.overview.listAgent(page, limit, search_agent);
  }
  static importAgent(params: FormData): Promise<SlipStreamAgentImportResult> {
    return apiIns.overview.importAgent(params);
  }
  static manageAgent(args: manageAgentArgs): Promise<SlipStreamAgent> {
    return apiIns.overview.manageAgent(args);
  }
  static inviteAgents(args: inviteAgentArgs): Promise<SlipStreamAgent> {
    return apiIns.overview.inviteAgents(args);
  }
  static importAgentProspect(params: FormData): Promise<SlipStreamAgentImportResult> {
    return apiIns.overview.importAgentProspect(params);
  }
  static listAgentProspect(
    page: number | undefined,
    limit: number | undefined,
    search_agent: string,
  ): Promise<Pagination<SlipStreamAgent>> {
    return apiIns.overview.listAgentProspect(page, limit, search_agent);
  }
  static readyAgentProspect(args: inviteAgentArgs): Promise<SlipStreamAgent> {
    return apiIns.overview.readyAgentProspect(args);
  }
  static addAgent(args: SlipStreamAddAgent): Promise<any> {
    return apiIns.overview.addAgent(args);
  }
  static listFollowerAgent(
    page: number | undefined,
    limit: number | undefined,
    search_agent: string,
  ): Promise<Pagination<SlipStreamAgent>> {
    return apiIns.overview.listFollowerAgent(page, limit, search_agent);
  }

  static listUserAgent(
    page: number | undefined,
    limit: number | undefined,
    search_agent: string,
  ): Promise<Pagination<SlipStreamAgent>> {
    return apiIns.overview.listUserAgent(page, limit, search_agent);
  }

  static refreshAgentSoldHouses(arg: RefreshSoldHousesArgs): Promise<SoldHousesResult> {
    return apiIns.overview.refreshAgentSoldHouses(arg);
  }
}

export default OverviewService;
