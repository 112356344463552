import { Input, InputProps } from 'antd';
import React, { memo, useCallback, useMemo } from 'react';
import AppLabel from '../AppLabel';
import { AppLabelStyledProps } from '../AppLabel/AppLabelStyled';
import ErrorMessage from '../error-message';
import { AppInputV2Styled } from './AppInputV2Styled';
import isEqual from 'react-fast-compare';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import theme from '@/styles/theme';
import { TextAreaProps } from 'antd/lib/input';

export interface AppInputV2Props extends InputProps, Pick<AppLabelStyledProps, 'labelColor'> {
  errorMessage?: string;
  hasError?: boolean;
  requiredMark?: boolean;
  isPassword?: boolean;
  label?: string;
  labelChildren?: React.ReactNode;
  labelPaddingBottom?: AppLabelStyledProps['paddingBottom'];
  isCardCvcElement?: boolean;
  isCardExpiryElement?: boolean;
  isCardNumberElement?: boolean;
  isTextArea?: boolean;
  textAreaProps?: TextAreaProps;
}

const AppInputV2View = memo((props: AppInputV2Props) => {
  const {
    label,
    errorMessage,
    name,
    requiredMark,
    hasError,
    labelColor,
    labelChildren,
    isPassword,
    isTextArea,
    labelPaddingBottom,
    isCardCvcElement,
    isCardExpiryElement,
    isCardNumberElement,
    textAreaProps,
    ...rest
  } = props;
  const isStripeElement = isCardCvcElement || isCardExpiryElement || isCardNumberElement;
  const Component = useMemo(() => (isPassword ? Input.Password : Input), [isPassword]);
  const PaymentComponent = useMemo(
    () =>
      isCardCvcElement
        ? CardCvcElement
        : isCardExpiryElement
        ? CardExpiryElement
        : isCardNumberElement
        ? CardNumberElement
        : null,
    [isCardCvcElement, isCardExpiryElement, isCardNumberElement],
  );
  const getValueProps = useCallback(() => ({ value: rest.value }), [rest.value]);
  return (
    <AppInputV2Styled.Container>
      {label && (
        <AppLabel
          paddingBottom={labelPaddingBottom}
          label={label}
          name={name}
          requiredMark={requiredMark}
          labelColor={labelColor}
        >
          {labelChildren}
        </AppLabel>
      )}
      <AppInputV2Styled.InputStyled
        noValidate
        id={name}
        name={name}
        size={props.size || 'middle'}
        rows={textAreaProps?.rows}
        getValueProps={getValueProps}
        validateStatus={hasError ? 'error' : undefined}
      >
        {isStripeElement && PaymentComponent ? (
          <PaymentComponent
            onChange={rest.onChange as any}
            onBlur={rest.onBlur as any}
            // onFocus={() => formik.setFieldTouched('card', false)}
            className={rest.className}
            options={{
              classes: {
                base: 'stripe-input',
              },
              placeholder: rest.placeholder,
              disabled: rest.disabled,
              style: {
                base: {
                  '::placeholder': {
                    color: theme.color.BLACK_6,
                  },
                },
              },
            }}
          />
        ) : isTextArea ? (
          <Input.TextArea name={name} {...textAreaProps} onChange={rest.onChange as any} onBlur={rest.onBlur as any} />
        ) : (
          <Component name={name} {...rest} data-cy={name} />
        )}
      </AppInputV2Styled.InputStyled>
      {hasError !== false && errorMessage && <ErrorMessage className="error">{errorMessage}</ErrorMessage>}
    </AppInputV2Styled.Container>
  );
}, isEqual);

AppInputV2View.displayName = 'AppInputV2View';
export default AppInputV2View;
