"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PostalyticsApi = void 0;
const caching_1 = require("../caching");
class PostalyticsApi {
    constructor(client) {
        this.client = client;
        this.staleTimes = {
            getBrokerListingFoundDetail: 2,
            getMyListingFoundDetail: 2,
            listAddress: 2,
            getSmartTriggerAction: 2,
            getSmartTriggerActionMailings: 2,
            listSmartTriggerAction: 2,
            getMilestones: 3,
            listBrokerLandingPage: 3,
            listLandingPageQRCode: 3,
            listTemplateDrips: 3,
            listPropertyType: 3,
            getDripCampaign: 3,
            getSmartTrigger: 3,
            listPastCampaignOrder: 3,
            listPastCampaignOrderForManager: 3,
            listEmailTemplate: 5,
            listCustomtemplate: 5,
            listDigitalCampaignAudience: 5,
            listDigitalCampaignDuration: 5,
            listDigitalCampaignType: 5,
            listDigitalCampaignTemplate: 5,
            listDigitalCampaignCumulative: 5,
            getPostalyticsAudience: 60,
            getCampaignRecipients: 60,
            getCampaignAllRecipients: 60,
            listLandingPageVariable: 10,
            listCampaignType: 60,
        };
        this.caching = new caching_1.Caching();
    }
    listPastCampaignOrder(agent_id, page = 1, limit = 20, ordering, status, search) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { agent_id, page, limit, ordering, status, search };
            const endpoint = `/postalytics/orders/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params, true, true);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.listPastCampaignOrder,
                });
            return response;
        });
    }
    getOrderMailingsWithFilledFallback(order_detail_id, landing_page_vars_slug) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { order_detail_id };
            if (landing_page_vars_slug) {
                params['landing_page_vars_slug'] = landing_page_vars_slug;
            }
            return this.client.get(`/postalytics/orders/mailing-with-fallack/`, params);
        });
    }
    createCampaignOrderFromMailingList(campaign_params) {
        return __awaiter(this, void 0, void 0, function* () {
            campaign_params.is_neighborhood = false;
            return this.client.post(`/postalytics/orders/`, campaign_params);
        });
    }
    campaignOverview(agent_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { agent_id };
            return this.client.get(`/postalytics/campaign-overview/`, params);
        });
    }
    listPastCampaignOrderForManager(agent_id, page = 1, limit = 20) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { agent_id, page, limit };
            const endpoint = `/postalytics/orders/manager`;
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.listPastCampaignOrderForManager,
                });
            return response;
        });
    }
    campaignOrderAmountFromMailingList(home_count, mailing_list_id, mailing_list_ids, audience_ids, employer, interest, industry, age_range) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = {
                home_count,
                audience_ids,
                mailing_list_id,
                mailing_list_ids,
                employer,
                interest,
                industry,
                age_range,
                is_neighborhood: false,
                is_smart_action: true,
            };
            return this.client.get(`/postalytics/orders/amount/`, params);
        });
    }
    campaignEmailOrderAmountFromMailingList(home_count, mailing_list_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = {
                home_count,
                mailing_list_id,
                is_neighborhood: false,
                is_smart_action: true,
            };
            return this.client.get(`/postalytics/orders/email-amount/`, params);
        });
    }
    getCampaignRecipients(campaign_order_id, page = 1, limit = 20) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { campaign_order_id, page, limit };
            const endpoint = `/postalytics/retarget-recipients/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.getCampaignRecipients,
                });
            return response;
        });
    }
    getCampaignAllRecipients(campaign_order_id, page = 1, limit = 20) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { campaign_order_id, all_recipients: 1, page, limit };
            const endpoint = `/postalytics/retarget-recipients/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.getCampaignAllRecipients,
                });
            return response;
        });
    }
    getDripCampaign(agent_id, page = 1, limit = 20, name, cachebuster) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { agent_id, page, limit, name, cachebuster };
            const endpoint = `/postalytics/drip-campaigns/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.getDripCampaign,
                });
            return response;
        });
    }
    createDripCampaign(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/postalytics/drip-campaigns/`, params);
        });
    }
    updateDripCampaign(agent_id, drip_campaign_id, data) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { agent_id };
            return this.client.patch(`/postalytics/drip-campaigns/${drip_campaign_id}/`, data, params);
        });
    }
    deleteDripCampaign(agent_id, drip_campaign_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { agent_id };
            return this.client.delete(`/postalytics/drip-campaigns/${drip_campaign_id}/`, params);
        });
    }
    createDrip(agent_id, drip_campaign_id, data) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { agent_id };
            return this.client.post(`/postalytics/drip-campaigns/${drip_campaign_id}/drip/`, data, params);
        });
    }
    updateDrip(agent_id, drip_campaign_id, drip_id, data) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { agent_id };
            return this.client.patch(`/postalytics/drip-campaigns/${drip_campaign_id}/drip/${drip_id}/`, data, params);
        });
    }
    deleteDrip(agent_id, drip_campaign_id, drip_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { agent_id };
            return this.client.delete(`/postalytics/drip-campaigns/${drip_campaign_id}/drip/${drip_id}/`, params);
        });
    }
    listEmailTemplate(page = 1, limit = 20, search = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { page, limit, search: search || undefined };
            const endpoint = `/postalytics/email-templates/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.listEmailTemplate,
                });
            return response;
        });
    }
    getPhoneNumberForCallScheduling(schedule_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { schedule_id };
            return this.client.get(`/postalytics/drip-campaigns/call-scheduling/`, params, false);
        });
    }
    createEmailCampaignOrderFromMailingList(campaign_params) {
        return __awaiter(this, void 0, void 0, function* () {
            campaign_params.is_neighborhood = false;
            return this.client.post(`/postalytics/email-campaign/`, campaign_params);
        });
    }
    retrieveLandingPageVariable(slug) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.get(`/postalytics/landing-page-var/${slug}/`);
        });
    }
    listLandingPageVariable(page = 1, limit = 20, type, agent_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { page, limit, type, agent_id };
            const endpoint = `/postalytics/landing-page-var/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.listLandingPageVariable,
                });
            return response;
        });
    }
    createLandingPageVariable(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/postalytics/landing-page-var/`, params);
        });
    }
    createCustomtemplate(custom_template_infos) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/postalytics/custom-template/`, custom_template_infos);
        });
    }
    listCustomtemplate(page = 1, limit = 20, search = null, agent_id = null, is_sms = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { page, limit, is_sms, search: search || undefined, agent_id: agent_id || undefined };
            const endpoint = `/postalytics/custom-template/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.listCustomtemplate,
                });
            return response;
        });
    }
    updateCustomtemplate(id, custom_template_infos) {
        return __awaiter(this, void 0, void 0, function* () {
            this.caching.deleteCacheByEndpoint(`/postalytics/custom-template/`);
            return this.client.put(`/postalytics/custom-template/${id}`, custom_template_infos);
        });
    }
    deleteCustomtemplate(id, agent_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { agent_id };
            this.caching.deleteCacheByEndpoint(`/postalytics/custom-template/`);
            return this.client.delete(`/postalytics/custom-template/${id}`, params);
        });
    }
    getSmartTrigger(agent_id, page = 1, limit = 20, name, cachebuster) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { agent_id, page, limit, name, cachebuster };
            const endpoint = `/postalytics/smart-triggers/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.getSmartTrigger,
                });
            return response;
        });
    }
    createSmartTrigger(params) {
        return __awaiter(this, void 0, void 0, function* () {
            this.caching.deleteCacheByEndpoint(`/postalytics/smart-triggers/`);
            return this.client.post(`/postalytics/smart-triggers/`, params);
        });
    }
    updateSmartTrigger(agent_id, smart_trigger_id, data) {
        return __awaiter(this, void 0, void 0, function* () {
            this.caching.deleteCacheByEndpoint(`/postalytics/smart-triggers/`);
            const params = { agent_id };
            return this.client.patch(`/postalytics/smart-triggers/${smart_trigger_id}/`, data, params);
        });
    }
    deleteSmartTrigger(agent_id, smart_trigger_id) {
        return __awaiter(this, void 0, void 0, function* () {
            this.caching.deleteCacheByEndpoint(`/postalytics/smart-triggers/`);
            const params = { agent_id };
            return this.client.delete(`/postalytics/smart-triggers/${smart_trigger_id}/`, params);
        });
    }
    createSmartTriggerDrip(agent_id, smart_trigger_id, data) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { agent_id };
            return this.client.post(`/postalytics/smart-triggers/${smart_trigger_id}/drips/`, data, params);
        });
    }
    updateSmartTriggerDrip(agent_id, smart_trigger_id, drip_id, data) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { agent_id };
            return this.client.patch(`/postalytics/smart-triggers/${smart_trigger_id}/drips/${drip_id}/`, data, params);
        });
    }
    deleteSmartTriggerDrip(agent_id, smart_trigger_id, drip_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { agent_id };
            return this.client.delete(`/postalytics/smart-triggers/${smart_trigger_id}/drips/${drip_id}/`, params);
        });
    }
    getPostalyticsAudience() {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `/postalytics/postalytics-audience/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, {});
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.getPostalyticsAudience,
                });
            return response;
        });
    }
    getMilestones() {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `/postalytics/milestones/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, {});
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.getMilestones,
                });
            return response;
        });
    }
    requestCreateMilestone(name) {
        return __awaiter(this, void 0, void 0, function* () {
            this.caching.deleteCacheByEndpoint(`/postalytics/milestones/`);
            return this.client.post(`/postalytics/request-create-milestone/`, { name });
        });
    }
    createSmartTriggerAction(agent_id, SmartTriggerParams) {
        return __awaiter(this, void 0, void 0, function* () {
            this.caching.deleteCacheByEndpoint(`/postalytics/smart-trigger-action/`);
            const params = { agent_id };
            return this.client.post(`/postalytics/smart-trigger-action/`, SmartTriggerParams, params);
        });
    }
    getSmartTriggerActionMailings(agent_id, page = 1, limit = 12, args, smartTriggerParams) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = Object.assign({ agent_id, page, limit }, args);
            const endpoint = `/postalytics/smart-trigger-action-mailings/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, Object.assign(Object.assign({}, smartTriggerParams), params));
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.post(endpoint, smartTriggerParams, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.getSmartTriggerActionMailings,
                });
            return response;
        });
    }
    listSmartTriggerAction(agent_id, page = 1, limit = 20, name, cachebuster, mailing_list_id, sm_trigger_campaign_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { agent_id, page, limit, name, cachebuster, mailing_list_id, sm_trigger_campaign_id };
            const endpoint = `/postalytics/smart-trigger-action/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.listSmartTriggerAction,
                });
            return response;
        });
    }
    getSmartTriggerAction(agent_id, sm_trigger_action_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { agent_id };
            const endpoint = `/postalytics/smart-trigger-action/${sm_trigger_action_id}/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.getSmartTriggerAction,
                });
            return response;
        });
    }
    updateSmartTriggerAction(agent_id, sm_trigger_action_id, UpdateData) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { agent_id };
            return this.client.patch(`/postalytics/smart-trigger-action/${sm_trigger_action_id}/`, UpdateData, params);
        });
    }
    deleteSmartTriggerAction(agent_id, sm_trigger_action_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { agent_id };
            return this.client.delete(`/postalytics/smart-trigger-action/${sm_trigger_action_id}/`, params);
        });
    }
    existTriggerName(agent_id, name, sm_trigger_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { agent_id, name, sm_trigger_id };
            return this.client.get(`/postalytics/sm-trigger-exist-name/`, params);
        });
    }
    listPropertyType(mailing_list_id, neighborhood_id, past_client_type) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { mailing_list_id, neighborhood_id, past_client_type };
            const endpoint = `/postalytics/mailing-property-type/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params, true, true);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.listPropertyType,
                });
            return response;
        });
    }
    listAddress(page = 1, limit = 12, args, agent_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = Object.assign(Object.assign({ page, limit }, args), { is_smart_action: true, agent_id });
            const endpoint = `/postalytics/addresses-list/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.listAddress,
                });
            return response;
        });
    }
    listCampaignType() {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `/postalytics/campaign-type/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, {});
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.listCampaignType,
                });
            return response;
        });
    }
    listTemplateDrips(template_email_id, agent_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { template_email_id, agent_id };
            const endpoint = `/postalytics/drips-template-list/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.listTemplateDrips,
                });
            return response;
        });
    }
    listLandingPageQRCode(page = 1, limit = 20, search) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { page, limit, search };
            const endpoint = `/postalytics/landing-page/qr-code/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.listLandingPageQRCode,
                });
            return response;
        });
    }
    createLandingPageQRCode(params) {
        return __awaiter(this, void 0, void 0, function* () {
            this.caching.deleteCacheByEndpoint(`/postalytics/landing-page/qr-code/`);
            return this.client.post(`/postalytics/landing-page/qr-code/`, params);
        });
    }
    updateLandingPageQRCode(landing_page_qr_code_id, data) {
        return __awaiter(this, void 0, void 0, function* () {
            this.caching.deleteCacheByEndpoint(`/postalytics/landing-page/qr-code/`);
            return this.client.patch(`/postalytics/landing-page/qr-code/${landing_page_qr_code_id}/`, data);
        });
    }
    deleteLandingPageQRCode(landing_page_qr_code_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { landing_page_qr_code_id };
            this.caching.deleteCacheByEndpoint(`/postalytics/landing-page/qr-code/`);
            return this.client.delete(`/postalytics/landing-page/qr-code/${landing_page_qr_code_id}/`, params);
        });
    }
    interactQRCode(landing_page_qr_code_id, interact_type) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { interact_type };
            return this.client.post(`/postalytics/landing-page/qr-code/${landing_page_qr_code_id}/interact/`, params);
        });
    }
    createDigitalCampaignRequestAdmin(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/postalytics/digital-campaign/request-admin/`, params);
        });
    }
    listDigitalCampaignAudience(page = 1, limit = 20) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { page, limit };
            const endpoint = `/postalytics/digital-campaign/audience/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.listDigitalCampaignAudience,
                });
            return response;
        });
    }
    listDigitalCampaignDuration(page = 1, limit = 20) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { page, limit };
            const endpoint = `/postalytics/digital-campaign/duration/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.listDigitalCampaignDuration,
                });
            return response;
        });
    }
    listDigitalCampaignType() {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `/postalytics/digital-campaign/type/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, {});
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.listDigitalCampaignType,
                });
            return response;
        });
    }
    listDigitalCampaignTemplate(page = 1, limit = 20, digital_ad_type, ordering) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { page, limit, digital_ad_type, ordering };
            const endpoint = `/postalytics/digital-campaign/template/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.listDigitalCampaignTemplate,
                });
            return response;
        });
    }
    createDigitalCampaignOrder(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/postalytics/digital-campaign/orders/`, params);
        });
    }
    cloneSmartTriggerToDripCampaign(smartTriggerId) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/postalytics/clone-to-drip-campaign/`, { id: smartTriggerId });
        });
    }
    cloneDripCampaignToSmartTrigger(dripCampaignId) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/postalytics/clone-to-smart-trigger/`, { id: dripCampaignId });
        });
    }
    getDigitalCampaignOrderMedia(digital_campaign_order_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.get(`/postalytics/digital-campaign/orders/${digital_campaign_order_id}/media/`);
        });
    }
    listDigitalCampaignCumulative(page = 1, limit = 20, ordering, search) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { page, limit, ordering, search };
            const endpoint = `/postalytics/digital-campaign/cumulative/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.listDigitalCampaignCumulative,
                });
            return response;
        });
    }
    getBrokerListingFoundDetail(listing_found_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `/postalytics/broker-listing-found/${listing_found_id}/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, {});
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.getBrokerListingFoundDetail,
                });
            return response;
        });
    }
    getMyListingFoundDetail(my_listing_found_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `/postalytics/my-listing-found/${my_listing_found_id}/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, {});
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.getMyListingFoundDetail,
                });
            return response;
        });
    }
    updateBrokerListingFound(listing_found_id, params) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.patch(`/postalytics/broker-listing-found/${listing_found_id}/`, params);
        });
    }
    listBrokerLandingPage(page = 1, limit = 20) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { page, limit };
            const endpoint = `/postalytics/automation-broker-landing-page/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.listBrokerLandingPage,
                });
            return response;
        });
    }
    updateBrokerLandingPage(id, data) {
        return __awaiter(this, void 0, void 0, function* () {
            this.caching.deleteCacheByEndpoint(`/postalytics/automation-broker-landing-page`);
            return this.client.patch(`/postalytics/automation-broker-landing-page/${id}/`, data);
        });
    }
    createBrokerLandingPage(data) {
        return __awaiter(this, void 0, void 0, function* () {
            this.caching.deleteCacheByEndpoint(`/postalytics/automation-broker-landing-page`);
            return this.client.post(`/postalytics/automation-broker-landing-page/`, data);
        });
    }
    sendCampaignForBroker(id, data) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/postalytics/broker-listing-found/${id}/send-campaign/`, data);
        });
    }
    sendCampaignForMyListing(id, data) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/postalytics/my-listing-found/${id}/send-campaign/`, data);
        });
    }
    cancelOrder(id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.delete(`/postalytics/orders/${id}/cancel/`);
        });
    }
    deleteOrder(id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.delete(`/postalytics/orders/${id}/delete_order/`);
        });
    }
}
exports.PostalyticsApi = PostalyticsApi;
