"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommonStatusEnums = exports.PaymentMethod = exports.DripResponseType = exports.RecipientType = exports.LandingPageType = exports.PhoneType = exports.OrderStatusEnum = exports.ListingFoundStatus = exports.DigitalRequestTypes = exports.DigitalAdTypes = exports.SortDigitalCampaignTemplateEnum = exports.SortDigitalCampaignOrderEnum = exports.SortOrderEnum = exports.HomeOwnerDataEnums = exports.OperatorEnums = exports.PastClientMailingTypeEnums = exports.PastClientTypeEnums = exports.MLSStatusEnums = exports.SELECT_RULE = exports.VIEW_OWNER_OCCUPIED = exports.VIEW_OWNER_OUT_OF_STATE = exports.VIEW_MODE = exports.INTERACT_QR_CODE = exports.SEND_ADDRESS = void 0;
var SEND_ADDRESS;
(function (SEND_ADDRESS) {
    SEND_ADDRESS["SEND_ADDRESSES_ALL"] = "send_addresses_all";
    SEND_ADDRESS["SEND_ADDRESSES_REALTOR"] = "send_addresses_realtor";
    SEND_ADDRESS["SEND_ADDRESSES_NOT_REALTOR"] = "send_addresses_not_realtor";
    SEND_ADDRESS["SEND_ADDRESSES_RENTER"] = "send_addresses_renter";
    SEND_ADDRESS["SEND_ADDRESSES_OWNER"] = "send_addresses_owner";
})(SEND_ADDRESS = exports.SEND_ADDRESS || (exports.SEND_ADDRESS = {}));
var INTERACT_QR_CODE;
(function (INTERACT_QR_CODE) {
    INTERACT_QR_CODE["WEB_FORM_FILLED"] = "web_form_filled";
    INTERACT_QR_CODE["QR_CODE_SCANNED"] = "qr_code_scanned";
})(INTERACT_QR_CODE = exports.INTERACT_QR_CODE || (exports.INTERACT_QR_CODE = {}));
var VIEW_MODE;
(function (VIEW_MODE) {
    VIEW_MODE["VIEW_MODE_ALL"] = "all";
    VIEW_MODE["VIEW_MODE_ON"] = "on";
    VIEW_MODE["VIEW_MODE_OFF"] = "off";
})(VIEW_MODE = exports.VIEW_MODE || (exports.VIEW_MODE = {}));
var VIEW_OWNER_OUT_OF_STATE;
(function (VIEW_OWNER_OUT_OF_STATE) {
    VIEW_OWNER_OUT_OF_STATE["OWNER_OUT_OF_STATE_OUT"] = "out_of_state";
    VIEW_OWNER_OUT_OF_STATE["OWNER_OUT_OF_STATE_IN"] = "in_state";
    VIEW_OWNER_OUT_OF_STATE["OWNER_OUT_OF_STATE_ALL"] = "all";
})(VIEW_OWNER_OUT_OF_STATE = exports.VIEW_OWNER_OUT_OF_STATE || (exports.VIEW_OWNER_OUT_OF_STATE = {}));
var VIEW_OWNER_OCCUPIED;
(function (VIEW_OWNER_OCCUPIED) {
    VIEW_OWNER_OCCUPIED["VIEW_OWNER_OCCUPIED"] = "occupied";
    VIEW_OWNER_OCCUPIED["VIEW_OWNER_OCCUPIED_NOT"] = "not_occupied";
    VIEW_OWNER_OCCUPIED["VIEW_OWNER_OCCUPIED_ALL"] = "all";
})(VIEW_OWNER_OCCUPIED = exports.VIEW_OWNER_OCCUPIED || (exports.VIEW_OWNER_OCCUPIED = {}));
var SELECT_RULE;
(function (SELECT_RULE) {
    SELECT_RULE["NEAREST_HOMES"] = "nearest_homes";
    SELECT_RULE["SIMILAR_HOMES"] = "similar_homes";
})(SELECT_RULE = exports.SELECT_RULE || (exports.SELECT_RULE = {}));
var MLSStatusEnums;
(function (MLSStatusEnums) {
    MLSStatusEnums["ACTIVE"] = "A";
    MLSStatusEnums["INACTIVE"] = "D";
    MLSStatusEnums["ACTIVE_UNDER_CONTRACT"] = "U";
    MLSStatusEnums["CANCELED"] = "K";
    MLSStatusEnums["COMING_SOON"] = "C";
    MLSStatusEnums["DELETE"] = "Z";
    MLSStatusEnums["HOLD"] = "H";
    MLSStatusEnums["INCOMPLETE"] = "I";
    MLSStatusEnums["EXPIRED"] = "X";
    MLSStatusEnums["PENDING"] = "P";
    MLSStatusEnums["CLOSED"] = "S";
    MLSStatusEnums["WITHDRAWN"] = "W";
})(MLSStatusEnums = exports.MLSStatusEnums || (exports.MLSStatusEnums = {}));
var PastClientTypeEnums;
(function (PastClientTypeEnums) {
    PastClientTypeEnums["MY_LISTING"] = "my_listing";
    PastClientTypeEnums["MY_BUYER"] = "my_buyer";
    PastClientTypeEnums["DOUBLE_ENDED"] = "double_ended";
})(PastClientTypeEnums = exports.PastClientTypeEnums || (exports.PastClientTypeEnums = {}));
// PastClientTypeEnums is better name, but it was taken
var PastClientMailingTypeEnums;
(function (PastClientMailingTypeEnums) {
    PastClientMailingTypeEnums["LISTING"] = "listing";
    PastClientMailingTypeEnums["BUYER"] = "buyer";
    PastClientMailingTypeEnums["DOUBLE_ENDED"] = "double_ended";
})(PastClientMailingTypeEnums = exports.PastClientMailingTypeEnums || (exports.PastClientMailingTypeEnums = {}));
var OperatorEnums;
(function (OperatorEnums) {
    OperatorEnums["EQUAL"] = "=";
    OperatorEnums["GREATER"] = ">";
    OperatorEnums["LOWER"] = "<";
    OperatorEnums["GREATER_OR_EQUAL"] = ">=";
    OperatorEnums["LOWER_OR_EQUAL"] = "<=";
    OperatorEnums["RANGE"] = "range";
})(OperatorEnums = exports.OperatorEnums || (exports.OperatorEnums = {}));
var HomeOwnerDataEnums;
(function (HomeOwnerDataEnums) {
    HomeOwnerDataEnums["JOB_CHANGED"] = "job_changed";
    HomeOwnerDataEnums["INDUSTRY_CHANGED"] = "industry_changed";
    HomeOwnerDataEnums["EMPLOYER_CHANGED"] = "employer_changed";
})(HomeOwnerDataEnums = exports.HomeOwnerDataEnums || (exports.HomeOwnerDataEnums = {}));
var SortOrderEnum;
(function (SortOrderEnum) {
    SortOrderEnum["IR"] = "interaction_range_calc";
    SortOrderEnum["AVG_COST"] = "avg_cost_per_opportunity";
    SortOrderEnum["CREATED"] = "created";
    SortOrderEnum["TYPE"] = "temp_type";
    SortOrderEnum["ORDER_TYPE"] = "order_type";
    SortOrderEnum["CALENDAR_APPOINTMENT"] = "calendar_appointment_interactions_count";
})(SortOrderEnum = exports.SortOrderEnum || (exports.SortOrderEnum = {}));
var SortDigitalCampaignOrderEnum;
(function (SortDigitalCampaignOrderEnum) {
    SortDigitalCampaignOrderEnum["CAMPAIGN_TYPE"] = "campaign_type";
    SortDigitalCampaignOrderEnum["DATE"] = "date";
    SortDigitalCampaignOrderEnum["ADVERTISER_COST"] = "advertiser_cost";
    SortDigitalCampaignOrderEnum["IMPRESSIONS_WON"] = "impressions_won";
    SortDigitalCampaignOrderEnum["CTR"] = "ctr";
    SortDigitalCampaignOrderEnum["CLICKS"] = "clicks";
    SortDigitalCampaignOrderEnum["CONVERSIONS"] = "conversions";
})(SortDigitalCampaignOrderEnum = exports.SortDigitalCampaignOrderEnum || (exports.SortDigitalCampaignOrderEnum = {}));
var SortDigitalCampaignTemplateEnum;
(function (SortDigitalCampaignTemplateEnum) {
    SortDigitalCampaignTemplateEnum["CLICK_THROUGH_RATE_MIN"] = "click_through_rate_min";
    SortDigitalCampaignTemplateEnum["CLICK_THROUGH_RATE_MAX"] = "click_through_rate_max";
    SortDigitalCampaignTemplateEnum["QR_CODE_SCANNED"] = "qr_code_scanned";
    SortDigitalCampaignTemplateEnum["WEEKLY_DURATION_TOTAL"] = "weekly_duration_total";
})(SortDigitalCampaignTemplateEnum = exports.SortDigitalCampaignTemplateEnum || (exports.SortDigitalCampaignTemplateEnum = {}));
var DigitalAdTypes;
(function (DigitalAdTypes) {
    DigitalAdTypes["DIGITAL_AD_IMAGE"] = "DIGITAL_AD_IMAGE";
    DigitalAdTypes["DIGITAL_AD_VIDEO"] = "DIGITAL_AD_VIDEO";
})(DigitalAdTypes = exports.DigitalAdTypes || (exports.DigitalAdTypes = {}));
var DigitalRequestTypes;
(function (DigitalRequestTypes) {
    DigitalRequestTypes["UPDATE_QR_CODE"] = "UPDATE_QR_CODE";
    DigitalRequestTypes["USE_TEMPLATE_FOR_NEW_MEDIA"] = "USE_TEMPLATE_FOR_NEW_MEDIA";
    DigitalRequestTypes["CREATE_NEW_TEMPLATE"] = "CREATE_NEW_TEMPLATE";
})(DigitalRequestTypes = exports.DigitalRequestTypes || (exports.DigitalRequestTypes = {}));
var ListingFoundStatus;
(function (ListingFoundStatus) {
    ListingFoundStatus["APPROVED"] = "approved";
    ListingFoundStatus["REJECTED"] = "rejected";
})(ListingFoundStatus = exports.ListingFoundStatus || (exports.ListingFoundStatus = {}));
var OrderStatusEnum;
(function (OrderStatusEnum) {
    OrderStatusEnum["ORDER_COMPLETED"] = "completed";
    OrderStatusEnum["ORDER_ON_HOLD"] = "on_hold";
    OrderStatusEnum["ORDER_PROCESSING"] = "processing";
    OrderStatusEnum["ORDER_FAILED"] = "failed";
    OrderStatusEnum["ORDER_CANCELED"] = "canceled";
    OrderStatusEnum["ORDER_PRINTING"] = "printing";
    OrderStatusEnum["ORDER_PROCESSED_FOR_DELIVERY"] = "processed_for_delivery";
    OrderStatusEnum["ORDER_PROCESSING_CANCELLATION"] = "processing_cancellation";
})(OrderStatusEnum = exports.OrderStatusEnum || (exports.OrderStatusEnum = {}));
var PhoneType;
(function (PhoneType) {
    PhoneType["NEW"] = "NEW";
    PhoneType["MANAGER"] = "MANAGER";
    PhoneType["AGENT"] = "AGENT";
    PhoneType["CUSTOM"] = "CUSTOM";
})(PhoneType = exports.PhoneType || (exports.PhoneType = {}));
var LandingPageType;
(function (LandingPageType) {
    LandingPageType["NORMAL"] = "normal";
    LandingPageType["QR_CODE"] = "qrcode";
    LandingPageType["DIGITAL_CAMPAIGN"] = "digital";
    LandingPageType["BROKER_MLS"] = "broker_mls";
})(LandingPageType = exports.LandingPageType || (exports.LandingPageType = {}));
var RecipientType;
(function (RecipientType) {
    RecipientType["ENGAGED"] = "engaged";
    RecipientType["ALL"] = "all";
})(RecipientType = exports.RecipientType || (exports.RecipientType = {}));
var DripResponseType;
(function (DripResponseType) {
    DripResponseType["TEXT"] = "text";
    DripResponseType["EMAIL"] = "email";
    DripResponseType["CALL_SCHEDULING"] = "call_scheduling";
    DripResponseType["MAIL_CAMPAIGN"] = "mail_campaign";
    DripResponseType["DIGITAL_AD"] = "digital_ad";
})(DripResponseType = exports.DripResponseType || (exports.DripResponseType = {}));
var PaymentMethod;
(function (PaymentMethod) {
    PaymentMethod["card"] = "card";
    PaymentMethod["credit"] = "credit";
})(PaymentMethod = exports.PaymentMethod || (exports.PaymentMethod = {}));
var CommonStatusEnums;
(function (CommonStatusEnums) {
    CommonStatusEnums["PENDING"] = "PENDING";
    CommonStatusEnums["IN_PROGRESS"] = "IN_PROGRESS";
    CommonStatusEnums["COMPLETED"] = "COMPLETED";
    CommonStatusEnums["FAILED"] = "FAILED";
})(CommonStatusEnums = exports.CommonStatusEnums || (exports.CommonStatusEnums = {}));
