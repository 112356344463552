import { MLS_STATUS } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postgrid/models';
import React, { useState } from 'react';
import { imageKeywords } from '@/utils/constant';
import { PC_PREFIX } from '../../helpers';
import RenderVariablesView from './RenderVariablesView';

const RenderVariables = (props: {
  listVariables;
  variableKey?: string;
  variableType?: string;
  isNotAvailable?: any;
  availablePostgridVar?: any;
  isEmailVariable?: boolean;
  preprocessCopyValue?: (variableKey: string, value: string) => string;
  onSelectVariable?: any;
  onCopyVariable?: any;
  getCurrentMailing?: any;
  isHandwritten?: boolean;
  sessionName?: string;
}) => {
  const {
    listVariables,
    variableKey,
    variableType,
    isNotAvailable,
    availablePostgridVar,
    isEmailVariable,
    preprocessCopyValue,
    onSelectVariable,
    onCopyVariable,
    getCurrentMailing,
    isHandwritten,
    sessionName,
  } = props;

  const [variableTooltip, setVariableTooltip] = useState<string>('');
  const uniqueValuesSet = new Set(listVariables?.map((item) => item.value.toLowerCase()));
  const uniqueArray = Array.from(uniqueValuesSet)?.map((uniqueValue) => {
    const matchingObject = listVariables?.find((item) => item.value.toLowerCase() === uniqueValue) || ({} as any);
    return matchingObject;
  });
  const listMLSImageVariable = uniqueArray?.filter((v) =>
    imageKeywords.some((imgKey) => v.label?.includes(imgKey) && !v.label?.endsWith('street_view_image')),
  );
  const currentMailing = getCurrentMailing?.(variableKey);
  const listImageFoundLength = currentMailing?.mls_media?.length;
  const isDisableStreetView =
    sessionName !== 'mailing_list_and_owner' ? (!currentMailing?.street_view_image ? true : false) : false;

  let disabledImageList = (
    variableKey &&
    [MLS_STATUS.MY_LISTINGS, MLS_STATUS.NOT_MY_LISTINGS, MLS_STATUS.LISTING_OF_MY_MAILING_LIST, PC_PREFIX]?.includes(
      variableKey,
    )
      ? listMLSImageVariable?.slice(listImageFoundLength) || []
      : []
  )?.map((v) => v?.label);

  if (isDisableStreetView) {
    const streetViewImgVar = uniqueArray?.filter((v) => v.label?.endsWith('street_view_image')).map((v) => v.label);
    disabledImageList = disabledImageList.concat(streetViewImgVar);
  }

  return (
    <RenderVariablesView
      disabledImageList={disabledImageList}
      setVariableTooltip={setVariableTooltip}
      uniqueArray={uniqueArray}
      variableTooltip={variableTooltip}
      availablePostgridVar={availablePostgridVar}
      isEmailVariable={isEmailVariable}
      isHandwritten={isHandwritten}
      isNotAvailable={isNotAvailable}
      onCopyVariable={onCopyVariable}
      onSelectVariable={onSelectVariable}
      preprocessCopyValue={preprocessCopyValue}
      variableKey={variableKey}
      variableType={variableType}
    />
  );
};

export default RenderVariables;
