"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Credit = void 0;
const caching_1 = require("../caching");
class Credit {
    constructor(client) {
        this.client = client;
        this.staleTimes = {
            getMyCredit: 2,
            creditHistory: 3,
            creditSetting: 60,
        };
        this.caching = new caching_1.Caching();
    }
    getMyCredit() {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `/credits/me/`;
            const params = {};
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.getMyCredit,
                });
            return response;
        });
    }
    editMyCredit(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            this.caching.deleteCache(`/credits/me/`);
            return this.client.patch(`/credits/me/`, payload);
        });
    }
    buyCredit(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            this.caching.deleteCache(`/credits/me/`);
            return this.client.post(`/credits/buy/`, payload);
        });
    }
    creditSetting() {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `/credits/setting/`;
            const params = {};
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.creditSetting,
                });
            return response;
        });
    }
    creditHistory(page, limit, transaction_type) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `/credits/history/`;
            const params = { page, limit, transaction_type };
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.creditHistory,
                });
            return response;
        });
    }
}
exports.Credit = Credit;
