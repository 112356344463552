import { useEffect } from 'react';

function useToggleClass(shouldToggle, elementId = 'app-root', className = 'overflow-hidden') {
  useEffect(() => {
    const element = document.getElementById(elementId);

    if (element) {
      if (shouldToggle) {
        element.classList.add(className);
      } else {
        element.classList.remove(className);
      }
    }
  }, [elementId, className, shouldToggle]);
}

export default useToggleClass;
