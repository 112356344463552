import styled from '@emotion/styled';
interface HeaderTextWrapperStyledProps {
  disabled: boolean;
}
export const HeaderTextWrapperStyled = styled.div<HeaderTextWrapperStyledProps>`
  .s-label {
    font-size: 16px;
    opacity: 1;
    font-weight: 700;
    transition-property: top, font-size, opacity;
    transition-duration: 0.1s;
    transition-timing-function: linear;
    margin-bottom: 5px;
    display: inline-block;
  }
  .header-text-alignment {
    margin: 20px 0;
    display: flex;
    gap: 10px;

    svg {
      width: 35px;
      height: 35px;
      cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    }
    .active {
      border: 1px solid #fff;
      border-radius: 5px;
    }
  }
  .header-text-font-size-color {
    display: flex;
    gap: 10px;
    width: 100%;
    max-width: 100%;
    .field {
      width: 100%;
      max-width: unset !important;
    }
  }
  .header-text-color-picker {
    height: fit-content;
    .s-label {
      width: 100px;
    }
    .header-text-color-preview {
      margin-bottom: 10px;
      padding: 0 10px;
      border: 1px solid;
      display: flex;
      align-items: center;
      gap: 20px;
      height: fit-content;
      cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

      width: fit-content;
      .anticon-caret-down {
        font-size: 20px;
      }
    }
    .header-text-chrome-picker {
      width: 40px;
      height: 40px;
      border-radius: 2px;
      cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
      margin: 10px 0;
    }
    .errorMsg {
      color: red;
    }
  }
`;
