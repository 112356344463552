import { message, ModalProps } from 'antd';
import AntUpload from 'antd/es/upload';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import { MouseEvent, MutableRefObject, ReactNode, RefObject, useCallback, useRef, useState } from 'react';

import { BeforeUpload, BeforeUploadReturnType, EasyCropRef, ImgCropProps } from '../types';

const useUpload = (
  easyCropRef: RefObject<EasyCropRef>,
  getCropCanvas: (target: ShadowRoot) => HTMLCanvasElement,
  onCancel: MutableRefObject<ModalProps['onCancel']>,
  onOk: MutableRefObject<ModalProps['onOk']>,
  quality?: number,
  aspect = 1,
  onModalOk?: (value: BeforeUploadReturnType) => void,
  onModalCancel?: (resolve: (value: BeforeUploadReturnType) => void) => void,
  beforeCrop?: BeforeUpload,
) => {
  const [modalImage, setModalImage] = useState('');

  const cb = useRef<Pick<ImgCropProps, 'onModalOk' | 'onModalCancel' | 'beforeCrop'>>({});
  cb.current.onModalOk = onModalOk;
  cb.current.onModalCancel = onModalCancel;
  cb.current.beforeCrop = beforeCrop;

  const runRawBeforeUpload = useCallback(
    async (
      beforeUpload: BeforeUpload,
      file: RcFile,
      pass: (parsedFile: BeforeUploadReturnType) => void,
      fail: (rejectErr: BeforeUploadReturnType) => void,
    ) => {
      const rawFile = file as unknown as File;
      if (typeof beforeUpload !== 'function') {
        pass(rawFile);
        return;
      }

      try {
        // https://ant.design/components/upload-cn#api
        // https://github.com/ant-design/ant-design/blob/master/components/upload/Upload.tsx#L152-L178
        const result = await beforeUpload(file, [file]);
        pass((result !== true && result) || rawFile);
      } catch (err) {
        fail(err as BeforeUploadReturnType);
      }
    },
    [],
  );

  const loadImage = (file: RcFile): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = URL.createObjectURL(file);
    });

  const getCanvasBlob = (canvas: HTMLCanvasElement): Promise<Blob | null> => {
    return new Promise(function (resolve) {
      canvas.toBlob((blob) => {
        resolve(blob);
      });
    });
  };

  const processFile = async (file: RcFile): Promise<RcFile> => {
    // ### DANGER ### Mikey's Custom Code
    const tempImage = await loadImage(file);

    if (tempImage.width / tempImage.height !== aspect) {
      const canvas = document.createElement('canvas');
      let canvasWidth;
      let canvasHeight;

      if (aspect >= 1) {
        canvasWidth = Math.max(tempImage.width, tempImage.height * aspect);
        canvasHeight = canvasWidth / aspect;
      } else {
        canvasHeight = Math.max(tempImage.height, tempImage.width / aspect);
        canvasWidth = canvasHeight * aspect;
      }

      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

      const dx = (canvas.width - tempImage.width) / 2;
      const dy = (canvas.height - tempImage.height) / 2;

      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(tempImage, dx, dy);
      const canvasBlob = await getCanvasBlob(canvas);
      return canvasBlob ? (new File([canvasBlob], file.name, { type: file.type }) as RcFile) : file;
    }

    return file;
  };

  const getNewBeforeUpload = useCallback(
    (beforeUpload: BeforeUpload) => {
      return ((file, fileList) => {
        // eslint-disable-next-line
        return new Promise(async (resolve, reject) => {
          let processedFile: RcFile;

          if (typeof cb.current.beforeCrop === 'function') {
            try {
              const result = await cb.current.beforeCrop(file, fileList);

              if (result === false) {
                return;
                // runRawBeforeUpload(beforeUpload, file, resolve, reject);
              }
              if (result !== true) {
                processedFile = (result as unknown as RcFile) || file;
              }
            } catch (err) {
              return runRawBeforeUpload(beforeUpload, file, resolve, reject);
            }
          }

          message.loading('Processing your image. Please wait a moment...', 0);
          processedFile = await processFile(file);
          // read file
          const reader = new FileReader();
          reader.addEventListener('load', () => {
            if (typeof reader.result === 'string') {
              setModalImage(reader.result); // open modal
            }
          });
          reader.readAsDataURL(processedFile as unknown as Blob);

          // on modal cancel
          onCancel.current = () => {
            setModalImage('');
            easyCropRef.current?.onReset();

            resolve(AntUpload.LIST_IGNORE);
            cb.current.onModalCancel?.(resolve);
          };

          // on modal confirm
          onOk.current = async (event: MouseEvent<HTMLElement>) => {
            setModalImage('');
            easyCropRef.current?.onReset();

            const canvas = getCropCanvas(event.target as ShadowRoot);
            const { type, name, uid } = processedFile as UploadFile;

            canvas.toBlob(
              async (blob) => {
                const newFile = new File([blob as BlobPart], name, { type });
                Object.assign(newFile, { uid });

                runRawBeforeUpload(
                  beforeUpload,
                  newFile as unknown as RcFile,
                  (parsedFile) => {
                    resolve(parsedFile);
                    cb.current.onModalOk?.(parsedFile);
                  },
                  (rejectErr) => {
                    reject(rejectErr);
                    cb.current.onModalOk?.(rejectErr);
                  },
                );
              },
              type,
              quality,
            );
          };

          message.destroy();
        });
      }) as BeforeUpload;
    },
    [getCropCanvas, quality, runRawBeforeUpload],
  );

  const getNewUpload = useCallback(
    (children: ReactNode) => {
      const upload = Array.isArray(children) ? children[0] : children;
      const { beforeUpload, accept, ...restUploadProps } = upload.props;

      return {
        ...upload,
        props: {
          ...restUploadProps,
          accept: accept || 'image/*',
          beforeUpload: getNewBeforeUpload(beforeUpload),
        },
      };
    },
    [getNewBeforeUpload],
  );

  return { modalImage, getNewUpload };
};

export default useUpload;
