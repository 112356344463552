import { useSelector } from 'react-redux';
import { RootState } from '@/reducers/model';
import { UserType } from '@/utils/enum';

export const useUserRole = () => {
  const userType = useSelector((state: RootState) => state.auth.myProfile?.user_type);

  const getUserRole = (type: UserType) => userType === type;

  return {
    isManager: getUserRole(UserType.MANAGER),
    isAgent: getUserRole(UserType.AGENT),
    isBroker: getUserRole(UserType.BROKER),
  };
};
