import { ActionBase } from '..';
import CreditAction from './action';
import { CreditState } from './model';

const initialState: CreditState = {
  creditSetting: null,
  myCredit: null,
};
const creditReducer = (state = initialState, action: ActionBase): CreditState => {
  if (!action.type.includes('@credit')) {
    return state;
  }
  switch (action.type) {
    case CreditAction.SET_CREDIT_SETTING: {
      return {
        ...state,
        creditSetting: action.payload,
      };
    }

    case CreditAction.SET_MY_CREDIT: {
      return {
        ...state,
        myCredit: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default creditReducer;
