import { RootState } from '@/reducers/model';
import { CampaignTypeEnum, FilterHashKey, KEY_SMART_FILTERS } from '@/utils/enum';
import { hashFilterHelper } from '@/utils/query';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParam } from 'react-use';
import { initialPostgridFilter } from '../campaign/ScrollTemplate/hooks/usePostgridFilter';
import { initialEmailTemplateFilter } from '../campaign/ScrollTemplate/hooks/useEmailFilter';
import CampaignService from '@/services/campaign';
import { _ALL_HOMES_LABEL } from '@/utils/constant';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { Pagination } from '@goldfishcode/first-team-real-estate-sdk/libs/type';
import { MailingList } from '@goldfishcode/first-team-real-estate-sdk/libs/api/mailing/models';

const createAddressFilters = (filterParams: any) => {
  const { owner_occupied, owner_out_of_state, search } = filterParams;
  return { owner_occupied, owner_out_of_state, search };
};

const createExtraFilters = (filterParams: any) => {
  const {
    has_linkedin,
    has_email,
    has_phone,
    mls_status,
    rea_properties,
    milestones,
    rea_neighborhood_ids,
    home_equity_percentage,
    bedrooms,
    bathrooms,
    sqft,
    avm_values,
    avg_years_owned,
    years_owned,
    action_type,
    stories,
    tags,
    estimated_price_operator,
    estimated_price_value,
    rea_loan_type_ids,
    age_range,
    price_per_square_foot,
    days_on_market,
    interest_rates,
    zip_codes,
  } = filterParams;

  const createHasLinkedinFilter = () => {
    return has_linkedin || false;
  };

  const createHasEmailFilter = () => {
    return has_email || false;
  };

  const createHasPhoneFilter = () => {
    return has_phone || false;
  };

  const createMlsStatusFilter = () => {
    return mls_status ?? undefined;
  };

  const createReaPropertiesFilter = () => {
    return rea_properties ?? undefined;
  };

  const createMilestonesFilter = () => {
    return milestones ?? undefined;
  };

  const createReaNeighborhoodIdsFilter = () => {
    return rea_neighborhood_ids ?? undefined;
  };

  const createHomeEquityPercentageFilter = () => {
    return home_equity_percentage ?? '0,100';
  };

  const createBedroomsFilter = () => {
    return bedrooms ?? '0,10';
  };

  const createBathroomsFilter = () => {
    return bathrooms ?? '0,10';
  };

  const createSqftFilter = () => {
    return sqft ?? '100,7500';
  };

  const createAvmValuesFilter = () => {
    return avm_values ?? undefined;
  };

  const createAvgYearsOwnedFilter = () => {
    return avg_years_owned ?? '0,60';
  };

  const createYearsOwnedFilter = () => {
    return years_owned ?? '0,60';
  };

  const createActionTypeFilter = () => {
    return action_type ?? undefined;
  };

  const createStoriesFilter = () => {
    return stories ?? undefined;
  };

  const createTagsFilter = () => {
    return tags ?? undefined;
  };

  const createEstimatedPriceOperatorFilter = () => {
    return estimated_price_operator ?? '';
  };

  const createEstimatedPriceValueFilter = () => {
    return estimated_price_value ?? '';
  };

  const createReaLoanTypeIdsFilter = () => {
    return rea_loan_type_ids ?? '';
  };

  const createAgeRangeFilter = () => {
    return age_range ?? '';
  };

  const createPricePerSquareFootFilter = () => {
    return price_per_square_foot ?? undefined;
  };

  const createDaysOnMarketFilter = () => {
    return days_on_market ?? '0,365';
  };

  const createInterestRatesFilter = () => {
    return interest_rates ?? '0,20';
  };

  return {
    [KEY_SMART_FILTERS.has_linkedin]: createHasLinkedinFilter(),
    [KEY_SMART_FILTERS.has_email]: createHasEmailFilter(),
    [KEY_SMART_FILTERS.has_phone]: createHasPhoneFilter(),
    [KEY_SMART_FILTERS.mls_status]: createMlsStatusFilter(),
    [KEY_SMART_FILTERS.rea_properties]: createReaPropertiesFilter(),
    [KEY_SMART_FILTERS.life_event]: createMilestonesFilter(),
    [KEY_SMART_FILTERS.rea_neighborhood_ids]: createReaNeighborhoodIdsFilter(),
    [KEY_SMART_FILTERS.equity]: createHomeEquityPercentageFilter(),
    [KEY_SMART_FILTERS.beds]: createBedroomsFilter(),
    [KEY_SMART_FILTERS.baths]: createBathroomsFilter(),
    [KEY_SMART_FILTERS.sq_feet]: createSqftFilter(),
    [KEY_SMART_FILTERS.avm]: createAvmValuesFilter(),
    [KEY_SMART_FILTERS.avg_years_owned]: createAvgYearsOwnedFilter(),
    [KEY_SMART_FILTERS.years_owned]: createYearsOwnedFilter(),
    [KEY_SMART_FILTERS.interaction_type]: createActionTypeFilter(),
    [KEY_SMART_FILTERS.stories]: createStoriesFilter(),
    [KEY_SMART_FILTERS.tags]: createTagsFilter(),
    [KEY_SMART_FILTERS.estimated_price_operator]: createEstimatedPriceOperatorFilter(),
    [KEY_SMART_FILTERS.estimated_price_value]: createEstimatedPriceValueFilter(),
    [KEY_SMART_FILTERS.rea_loan_type_ids]: createReaLoanTypeIdsFilter(),
    [KEY_SMART_FILTERS.age]: createAgeRangeFilter(),
    [KEY_SMART_FILTERS.price_per_square_foot]: createPricePerSquareFootFilter(),
    [KEY_SMART_FILTERS.days_on_market]: createDaysOnMarketFilter(),
    [KEY_SMART_FILTERS.interest_rate]: createInterestRatesFilter(),
    [KEY_SMART_FILTERS.zip_codes]: zip_codes,
  };
};

const useTakeFilterHashDataParams = () => {
  const filterHash = useSearchParam(FilterHashKey.CREATE_CAMPAIGN);
  const campaignTypeList = useSelector((state: RootState) => state.app.campaignTypeList);
  const loggedInAgentId = useSelector((state: RootState) => state.home.userAgent?.id);
  const queryClient = useQueryClient();
  const filterHashData = useMemo(() => (filterHash ? hashFilterHelper.decrypt(filterHash) : {}), [filterHash]);

  const generateHashFromRecipient = useCallback(
    async (type, selectedCampaign) => {
      const campaignType = campaignTypeList?.find(
        (v) => v.label === selectedCampaign?.postgrid_template?.campaign_type,
      );

      const mailType = selectedCampaign?.postgrid_template?.id
        ? CampaignTypeEnum.PHYSICAL
        : selectedCampaign.is_email
        ? CampaignTypeEnum.EMAIL
        : selectedCampaign.is_handwriting
        ? CampaignTypeEnum.HANDWRITTEN
        : CampaignTypeEnum.DIGITAL;

      const postgridFilter =
        mailType === CampaignTypeEnum.PHYSICAL
          ? {
              ...initialPostgridFilter,
              search: selectedCampaign?.postgrid_template?.name || initialPostgridFilter.search,
              agentId: selectedCampaign?.agent || initialPostgridFilter.agentId,
              campaignType: campaignType?.id || initialPostgridFilter.campaignType,
            }
          : initialPostgridFilter;

      const emailTemplateFilter =
        mailType === CampaignTypeEnum.EMAIL
          ? {
              ...initialEmailTemplateFilter,
              agent_id: selectedCampaign?.agent || initialEmailTemplateFilter.agent_id,
              search: selectedCampaign?.template_name || initialEmailTemplateFilter.search,
            }
          : initialEmailTemplateFilter;

      const assets = await CampaignService.campaignOverview(selectedCampaign?.agent);
      const allHomeAudience = assets.target_audience.find((v) => v.name === _ALL_HOMES_LABEL);

      const hashed = hashFilterHelper.encrypt({
        campaign_order_id: selectedCampaign?.id,
        postgrid_template_id: selectedCampaign?.postgrid_template?.id,
        agent_id: selectedCampaign?.agent,
        send_recipient: type,
        mail_type: mailType,
        neighborhoods_mailing_list_ids: selectedCampaign?.mailing_list_ids || [],
        audience_ids: !selectedCampaign?.postalytics_audience_ids
          ? allHomeAudience
            ? [allHomeAudience.id]
            : []
          : selectedCampaign?.postalytics_audience_ids,
        extraFilters: selectedCampaign.extra_filters,
        postgridFilter,
        emailTemplateFilter,
      });
      return hashed;
    },
    [campaignTypeList],
  );

  const generateHashFromSmartAction = useCallback((smartActionFilterString: string, campaignAssets) => {
    const filterParams = hashFilterHelper.decrypt(smartActionFilterString) ?? {};

    const allHomeAudience = campaignAssets?.target_audience?.find((v) => v?.name === _ALL_HOMES_LABEL);
    const { mailing_list_ids, audience_ids, zip_codes: _, ...rest } = filterParams;

    // Default MLs select all when the UI is not selected.
    let mlsIds: string[] = mailing_list_ids || [];
    if (mlsIds.length === 0) {
      const ids: InfiniteData<Pagination<MailingList>> | undefined = queryClient.getQueryData(['listMailingList'], {
        exact: false,
      });
      mlsIds =
        ids?.pages?.reduce<string[]>((acc, page) => {
          const { results } = page;
          acc = acc.concat(results.map((v) => v.id));
          return acc;
        }, []) || [];
    }
    const addressFilters = createAddressFilters(filterParams);
    const extraFilters = createExtraFilters(filterParams);

    const hashed = hashFilterHelper.encrypt({
      ...rest,
      agent_id: undefined,
      mail_type: CampaignTypeEnum.PHYSICAL,
      neighborhoods_mailing_list_ids: mlsIds,
      audience_ids: typeof audience_ids === 'string' ? audience_ids?.split(',') : [allHomeAudience?.id],
      addressFilters,
      extraFilters,
      postgridFilter: initialPostgridFilter,
      emailTemplateFilter: initialEmailTemplateFilter,
    });
    return hashed;
  }, []);

  const generateHashFromAgentReportHyperlink = useCallback(
    async ({ isManager, ssid, foundSegmentation, mailingListsCombine }) => {
      const agentId = !isManager ? loggedInAgentId : ssid;

      const assets = await CampaignService.campaignOverview(agentId);
      const audienceItem = assets.target_audience.find((v) => v.name === foundSegmentation?.label);
      const hashed = hashFilterHelper.encrypt({
        mail_type: CampaignTypeEnum.PHYSICAL,
        agent_id: isManager ? agentId : undefined,
        neighborhoods_mailing_list_ids: mailingListsCombine?.id ? [mailingListsCombine?.id] : [],
        audience_ids: audienceItem?.id ? [audienceItem?.id] : [],
        postgridFilter: initialPostgridFilter,
        emailTemplateFilter: initialEmailTemplateFilter,
      });
      return hashed;
    },
    [],
  );

  return {
    filterHash,
    filterHashData,
    isSendRecipient: !!filterHashData.send_recipient,
    generateHashFromRecipient,
    generateHashFromSmartAction,
    generateHashFromAgentReportHyperlink,
  };
};

export default useTakeFilterHashDataParams;
