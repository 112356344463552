import {
  AgentMailingListCompetior,
  IPropertyValuation,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/mailing/models';
import {
  BrokerLandingPage,
  BrokerLandingPageParams,
  DigitalCampaignOrderMedia,
  ILandingPageVar,
  INTERACT_QR_CODE,
  LandingPageType,
  LandingPageVarFields,
  LandingPageVarParams,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';
import { GetMailingWithFilledFallbackVariableParams } from '@goldfishcode/first-team-real-estate-sdk/libs/api/tools/variable/models';
import { Pagination } from '@goldfishcode/first-team-real-estate-sdk/libs/type';
import { AxiosResponse } from 'axios';
import { apiClient, apiIns } from '@/config/apiClient';
import globalVariable from '@/config/env';
import { CompareAgentInput, GetInTouchInput, GetTopAgentInput, LandingParams } from '@/models/LandingPageCampaignProps';

class LandingService {
  static get baseUrl(): string {
    const arr = globalVariable.API_URL.split(/\/v1/);

    return arr.length ? arr[0] : '';
  }

  static createForm(data: LandingParams): Promise<AxiosResponse> {
    return apiClient.axiosInstance.post(`${this.baseUrl}/campaigns/fill-form/`, data);
  }

  static getCampaignsData(order_detail_id: string, unique_purl_code?: string): Promise<AxiosResponse> {
    const params = order_detail_id ? { order_detail_id } : { unique_purl_code };

    return apiClient.axiosInstance.get(`${this.baseUrl}/campaigns/get-campaign-data`, {
      params,
    });
  }

  static compareYourAgent(data: CompareAgentInput): Promise<AxiosResponse> {
    return apiClient.axiosInstance.post(`${this.baseUrl}/campaigns/compare-agent-form/`, data);
  }

  static getInTouch(data: GetInTouchInput): Promise<AxiosResponse> {
    return apiClient.axiosInstance.post(`${this.baseUrl}/campaigns/get-in-touch-form/`, data);
  }

  static getTopAgent(data: GetTopAgentInput): Promise<AxiosResponse> {
    return apiClient.axiosInstance.get(`${this.baseUrl}/campaigns/mailing-list/`, { params: data });
  }
  static getMailingListCompare(mailing_id: string): Promise<AgentMailingListCompetior> {
    return apiIns.mailingList.mailingListCompare(mailing_id);
  }
  static interactQRCode(
    landing_page_qr_code_id: string,
    interact_type: INTERACT_QR_CODE,
  ): Promise<LandingPageVarFields> {
    return apiIns.postalytics.interactQRCode(landing_page_qr_code_id, interact_type);
  }
  static getDigitalCampaignOrderMedia(digital_campaign_order_id: string): Promise<DigitalCampaignOrderMedia> {
    return apiIns.postalytics.getDigitalCampaignOrderMedia(digital_campaign_order_id);
  }
  static getMailingPlunk(mailing_id: string): Promise<IPropertyValuation> {
    return apiIns.mailingList.getMailingPlunk(mailing_id);
  }
  static fetchMailingPlunk(mailing_id: string, from_lp?: boolean): Promise<IPropertyValuation> {
    return apiIns.mailingList.fetchMailingPlunk(mailing_id, from_lp);
  }
  static listLandingPageVariable(
    page: number,
    limit: number,
    type: LandingPageType,
    agentId: string,
  ): Promise<Pagination<ILandingPageVar>> {
    return apiIns.postalytics.listLandingPageVariable(page, limit, type, agentId);
  }
  static createLandingPageVariable(params: LandingPageVarParams): Promise<ILandingPageVar> {
    return apiIns.postalytics.createLandingPageVariable(params);
  }
  static retrieveLandingPageVariable(slug: string): Promise<ILandingPageVar> {
    return apiIns.postalytics.retrieveLandingPageVariable(slug);
  }

  static getListBrokerLandingPageVariable(page = 1, limit = 20): Promise<Pagination<BrokerLandingPage>> {
    return apiIns.postalytics.listBrokerLandingPage(page, limit);
  }
  static createBrokerLandingPageVariable(data: BrokerLandingPageParams): Promise<BrokerLandingPage> {
    return apiIns.postalytics.createBrokerLandingPage(data);
  }
  static updateBrokerLandingPageVariable(id: string, data: BrokerLandingPageParams): Promise<BrokerLandingPage> {
    return apiIns.postalytics.updateBrokerLandingPage(id, data);
  }
  static getOrderMailingsWithFilledFallback(
    order_detail_id: string,
    landing_page_vars_slug?: string,
  ): Promise<GetMailingWithFilledFallbackVariableParams> {
    return apiIns.postalytics.getOrderMailingsWithFilledFallback(order_detail_id, landing_page_vars_slug);
  }

  static cancelOrder(id: string) {
    return apiIns.postalytics.cancelOrder(id);
  }
  static deleteOrder(id: string) {
    return apiIns.postalytics.deleteOrder(id);
  }
}

export default LandingService;
