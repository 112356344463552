import { useState } from 'react';
import { EMPTY_MAILING, MLS_STATUS_MAPPING, MailingData, MlsFilters, PC_PREFIX } from '../helpers';
import { MailingArgs, SortByEnum } from '@goldfishcode/first-team-real-estate-sdk/libs/api/mailing/models';
import MailingService from '@/services/mailing';
import { uniqBy } from 'lodash';
import { MLS_STATUS } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postgrid/models';
import { GetSelectedMailingDataParams } from '../components/message-variable/MessageVariableView';
interface UseHandleSelectAddressVariablesOptions {
  agentIdCampaign?: string;
}
const useHandleSelectAddressVariables = ({ agentIdCampaign }: UseHandleSelectAddressVariablesOptions) => {
  const [mlsFilters, setMlsFilters] = useState<MlsFilters>({});
  const [isSearching, setSearching] = useState(false);
  const [mailingData, setMailingData] = useState<MailingData>({});

  const handleGetSelectedMailingData = async (variable: string, params: GetSelectedMailingDataParams) => {
    setSearching(true);
    const isMyListing = variable === MLS_STATUS.LISTING_OF_MY_MAILING_LIST || variable === MLS_STATUS.MY_LISTINGS;
    const isPastClient = variable?.startsWith(PC_PREFIX);
    let status = mlsFilters[variable]?.status;
    let mailingListId = mlsFilters[variable]?.mailingListId;
    let search = '';

    if (Object.getOwnPropertyDescriptor(params, 'status')) {
      status = params.status || '';
      setMlsFilters((prev) => ({ ...prev, [variable]: { ...prev[variable], status, mailing: EMPTY_MAILING } }));
      setMailingData((prev) => {
        return { ...prev, [variable]: { ...prev[variable], data: [], count: 0 } };
      });
    }
    if (Object.getOwnPropertyDescriptor(params, 'mailingListId')) {
      mailingListId = Array.isArray(params.mailingListId) ? params.mailingListId[0] : params.mailingListId || '';
      if (isPastClient) {
        const pastClientType = params?.pastClientType || null;
        setMlsFilters((prev) => ({
          ...prev,
          [variable]: {
            ...prev[variable],
            pastClientType,
            mailingListId,
            mailing: EMPTY_MAILING,
          },
        }));
      } else {
        setMlsFilters((prev) => ({
          ...prev,
          [variable]: { ...prev[variable], mailingListId, mailing: EMPTY_MAILING },
        }));
      }
      setMailingData((prev) => {
        return { ...prev, [variable]: { ...prev[variable], data: [], count: 0 } };
      });
    }
    if (Object.getOwnPropertyDescriptor(params, 'search')) {
      search = params.search || '';
      setMailingData((prev) => {
        return { ...prev, [variable]: { ...prev[variable], data: [], count: 0 } };
      });
    }

    if ((isPastClient || status) && mailingListId) {
      const params: MailingArgs = {
        search: search || '',
        mailing_list_ids: [mailingListId],
        mls_status: MLS_STATUS_MAPPING[status],
        is_my_listing: isMyListing,
        is_past_client: isPastClient,
        is_smart_action: true,
        sort_by: SortByEnum.LAST_NOTIFICATION_AT,
        agent_id: agentIdCampaign || undefined,
      };
      const data = await MailingService.listSmartAction(1, 20, params);
      if (data.results) {
        setMailingData((prev) => {
          return {
            ...prev,
            [variable]: { ...prev[variable], data: uniqBy(data?.results, (v) => v.id), count: data?.count },
          };
        });
        setSearching(false);
        return data.results.map((mailing) => ({
          label: mailing.address || '',
          value: mailing.id,
        }));
      }
    }
    setSearching(false);
    return [];
  };

  return {
    mlsFilters,
    mailingData,
    isSearching,
    setMlsFilters,
    setSearching,
    setMailingData,
    handleGetSelectedMailingData,
  };
};

export default useHandleSelectAddressVariables;
