import { Mailing } from '@goldfishcode/first-team-real-estate-sdk/libs/api/mailing/models';
import { differenceBy, uniqBy } from 'lodash';

export const mailingIteratee = (v: Mailing) => `${v.address}_${v.city}_${v.state}_${v.zip_code}`;
const getName = (v: Mailing) => `${v.first_name}_${v.last_name}`.toLocaleLowerCase();
export const uniqMailings = (allAddresses: Mailing[]) => {
  let address = [...allAddresses].sort((a, b) => (getName(a) > getName(b) ? 1 : -1));
  address = uniqBy(address, (v) => v.id);
  address = uniqBy(address, mailingIteratee);
  return address;
};

export const getDuplicatedHouses = (data: Mailing[]) => {
  const uniqueMailings = uniqBy(data, mailingIteratee);
  const duplicates = differenceBy(data, uniqueMailings, (v) => v.id).map((e) => e.id);
  return duplicates;
};

export const filterSearch = (data: Mailing[], search: string) => {
  return data.filter((v) => {
    const renterName = `${v.first_name} ${v.last_name}`.toLowerCase();
    const ownerName = `${v.owner_first_name} ${v.owner_last_name}`.toLowerCase();
    const renterAddress = `${v.address} ${v.city} ${v.state}`.toLowerCase();
    const ownerAddress = `${v.owner_address} ${v.owner_city} ${v.owner_state}`.toLowerCase();
    const isValid =
      renterName.includes(search.trim().toLowerCase()) ||
      ownerName.includes(search.trim().toLowerCase()) ||
      renterAddress.includes(search.trim().toLowerCase()) ||
      ownerAddress.includes(search.trim().toLowerCase());
    return isValid;
  });
};
