import {
  ICreateHandWritingOrderBody,
  ICreateHandWritingTemplate,
  IGiftCard,
  IHandWritingOrder,
  IHandWritingTemplateResponse,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/handwriting/models';
import { Pagination } from '@goldfishcode/first-team-real-estate-sdk/libs/type';
import { apiIns } from '@/config/apiClient';

class HandwrittenService {
  static listGiftCard(page = 1, limit = 20): Promise<Pagination<IGiftCard>> {
    return apiIns.handwriting.listGiftCard(page, limit);
  }
  static listHandWritingTemplate(
    page = 1,
    limit = 20,
    search = '',
    cardType = undefined,
  ): Promise<Pagination<IHandWritingTemplateResponse>> {
    return apiIns.handwriting.listHandWritingTemplate(page, limit, search, cardType == '0' ? undefined : cardType);
  }
  static createHandwritingOrder(data: ICreateHandWritingOrderBody): Promise<IHandWritingOrder> {
    return apiIns.handwriting.createHandwritingOrder(data);
  }
  static createHandWritingTemplate(template: ICreateHandWritingTemplate): Promise<IHandWritingTemplateResponse> {
    return apiIns.handwriting.createHandWritingTemplate(template);
  }
  static updateHandWritingTemplate(
    id: string,
    template: Partial<ICreateHandWritingTemplate>,
  ): Promise<IHandWritingTemplateResponse> {
    return apiIns.handwriting.updateHandWritingTemplate(id, template);
  }
  static deleteHandwritingTemplate(templateId: string) {
    return apiIns.handwriting.deleteWritingTemplate(templateId);
  }
}

export default HandwrittenService;
