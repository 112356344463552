import UserAction from '@/reducers/user/action';
import { UserState } from '@/reducers/user/model';
import { ActionBase } from '..';
import { initialStateDataPreviewModal } from '@/components/dashboard/campaign/ScrollTemplate';

const initialState: UserState = {
  publicProfile: null,
  agentPastOrders: null,
  campaignAssets: {},
  listMembersSearch: null,
  listMembersSearchfile: null,
  is_skipsetup: false,
  saveCardList: null,
  visibleModalConfirmPayment: false,
  visibleModalPayment: false,
  dataCampaign: null,
  emailTemplateList: null,
  campaignTemplate: null,
  listTrigger: {
    results: null,
    count: 0,
  },
  userPaymentCard: null,
  suggestMileStone: null,
  currentStateSmartTrigger: { page: 1, search: '' },
  digitalCampaignAssets: {},
  digitalTemplates: [],
  dataPreviewModal: initialStateDataPreviewModal,
  globalTemplate: null,
  visibleModalPaymentOptions: false,
};

const userReducer = (state = initialState, action: ActionBase): UserState => {
  if (!action.type.includes('@user')) {
    return state;
  }
  switch (action.type) {
    case UserAction.UPDATE_PUBLIC_PROFILE:
      return {
        ...state,
        publicProfile: action.payload,
      };

    case UserAction.SAVE_CARD_LIST:
      return {
        ...state,
        saveCardList: action.payload,
      };

    case UserAction.DATA_CAMPAIGNS:
      return {
        ...state,
        dataCampaign: action.payload,
      };

    case UserAction.VISIBLE_MODAL_CONFIRM_PAYMENT:
      return {
        ...state,
        visibleModalConfirmPayment: action.payload,
      };

    case UserAction.VISIBLE_MODAL_PAYMENT:
      return {
        ...state,
        visibleModalPayment: action.payload,
      };

    case UserAction.AGENT_PAST_ORDERS:
      return {
        ...state,
        agentPastOrders: action.payload,
      };

    case UserAction.CAMPAIGN_ASSETS:
      return {
        ...state,
        campaignAssets: action.payload,
      };

    case UserAction.GET_LIST_MEMBERS_SEARCH:
      return {
        ...state,
        listMembersSearch: action.payload,
      };

    case UserAction.GET_LIST_MEMBERS_SEARCH_FILE:
      return {
        ...state,
        listMembersSearchfile: action.payload,
      };

    case UserAction.IS_SKIPSETUP:
      return {
        ...state,
        is_skipsetup: action.payload,
      };

    case UserAction.EMAIL_TEMPLATE_LIST:
      return {
        ...state,
        emailTemplateList: action.payload,
      };

    case UserAction.CAMPAIGN_TEMPLATE:
      return {
        ...state,
        campaignTemplate: action.payload,
      };

    case UserAction.LIST_TRIGGER:
      return {
        ...state,
        listTrigger: action.payload,
      };

    case UserAction.CURRENT_STATE_TRIGGER:
      return {
        ...state,
        currentStateSmartTrigger: action.payload,
      };

    case UserAction.USER_PAYMENT_CARD:
      return {
        ...state,
        userPaymentCard: action.payload,
      };

    case UserAction.SUGGEST_MILESTONE:
      return {
        ...state,
        suggestMileStone: action.payload,
      };

    case UserAction.DIGITAL_CAMPAIGN_ASSETS:
      return {
        ...state,
        digitalCampaignAssets: action.payload,
      };

    case UserAction.DIGITAL_TEMPLATES:
      return {
        ...state,
        digitalTemplates: action.payload,
      };

    case UserAction.UPDATE_PREVIEW_TEMPLATE:
      return {
        ...state,
        dataPreviewModal: action.payload,
      };
    case UserAction.GLOBAL_TEMPLATE:
      return {
        ...state,
        globalTemplate: action.payload,
      };
    case UserAction.VISIBLE_MODAL_PAYMENT_OPTIONS:
      return {
        ...state,
        visibleModalPaymentOptions: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default userReducer;
