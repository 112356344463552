import { css } from '@emotion/react';
import { memo } from 'react';

export default memo(({ children }) => {
  return (
    <label
      css={css`
        color: #fff;
        font-size: 17px;
        font-weight: 700;
      `}
    >
      {children}
    </label>
  );
});
