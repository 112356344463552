import { color } from '@/styles/theme';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import HeaderTextView from './HeaderTextView';

interface HeaderTextContainer {
  fontColor: string;
  fontSize: string | number;
  data: string;
  textAlign: string;
  fontType: string;
}

interface HeaderTextProps {
  headerTextValue: HeaderTextContainer;
  onHeaderTextChanged: (value) => void;
  headerTextError?: any;
  disabled?: boolean;
}

export enum TextAlignmentEnum {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}
const DEFAULT_FONT_SIZE = 30;
const DEFAULT_FONT_COLOR = color.BLACK;
const headerFontStyles = [
  'Arial',
  'Arial Black',
  'Arial Narrow',
  'Comic Sans MS',
  'Courier New',
  'Impact',
  'Rockwell',
  'Tahoma',
  'Times New Roman',
  'Trebuchet MS',
  'Verdana',
].map((v) => ({ value: v, label: v }));

const HeaderTextContainer: React.FC<HeaderTextProps> = (props) => {
  const { headerTextValue, onHeaderTextChanged, headerTextError, disabled = false } = props;
  const [showColorPicker, setShowColorPicker] = useState(false);
  const colorPickerRef = useRef(null);

  const formik = useFormik<any>({
    initialValues: {
      fontColor: headerTextValue?.fontColor || DEFAULT_FONT_COLOR,
      fontSize: headerTextValue?.fontSize || DEFAULT_FONT_SIZE,
      data: headerTextValue?.data,
      textAlign: headerTextValue?.textAlign || TextAlignmentEnum.LEFT,
      fontType: headerTextValue?.fontType || headerFontStyles[0].value,
    },
    onSubmit() {
      // TODO: submit
    },
  });

  useEffect(() => {
    function handleClickOutside(event) {
      if (colorPickerRef.current && !(colorPickerRef.current as any)?.contains(event.target)) {
        setShowColorPicker(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleColorChange = (color) => {
    formik.setFieldValue('fontColor', color.hex);
  };

  const handleToggleColorPicker = () => {
    if (disabled) return;
    setShowColorPicker(!showColorPicker);
  };

  useEffect(() => {
    onHeaderTextChanged({
      data: formik.values.data,
      textAlign: formik.values.textAlign,
      fontType: formik.values.fontType,
      fontSize: formik.values.fontSize,
      fontColor: formik.values.fontColor,
      zoom: '1',
      flexDirection: 'column',
      justifyContent: 'center',
      isColored: true,
      height: 50,
    });
  }, [formik.values]);

  return (
    <HeaderTextView
      formik={formik}
      handleColorChange={handleColorChange}
      handleToggleColorPicker={handleToggleColorPicker}
      showColorPicker={showColorPicker}
      disabled={disabled}
      headerTextError={headerTextError}
    />
  );
};
export default HeaderTextContainer;
