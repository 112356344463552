import { MLSShowAddressEventData } from './../../models/index';
import { EventChannel, eventChannel, Subscribe } from 'redux-saga';
import SocketAction from './action';
import { ActionBase } from '..';
import { SocketEventName, SocketIO } from '@goldfishcode/first-team-real-estate-sdk';
import { apiClient } from '@/config/apiClient';
import { SmartActionInteractEventData } from '@/models';
import MailingAction from '../mailing/action';
import { DuplicatedMailingListEventData } from '@/models/DuplicatedMailingListEventData';

const ws = SocketIO.instance();

const connectSocket = (): EventChannel<any> => {
  const subscriber: Subscribe<any> = (emitEvent: (input: ActionBase) => void) => {
    const config = apiClient.getApiConfig();
    apiClient.getAuthToken().then((session) => {
      const socketUrl = config.socketUrl || '';
      if (!session || !socketUrl) {
        ws.disconnect();
        return () => {
          ws.close();
        };
      }

      if (!ws.isConnected()) {
        ws.connect(socketUrl, session?.access_token);
      }

      ws.on(SocketEventName.Unauthorized, (e) => {
        console.info('Socket is unauthorized: ', e);
      });

      ws.on(SocketEventName.Connect, () => {
        console.info('Socket is connected!');
      });

      ws.on(SocketEventName.Disconnect, (e) => {
        console.info('Socket is disconnected: ', e);
        emitEvent({
          type: SocketAction.SOCKET_DISCONNECT,
          payload: true,
        });
      });

      ws.on(SocketEventName.Close, (e) => {
        console.info('Socket is closed ', e);
      });

      ws.on(SocketEventName.Error, (e) => {
        console.info('Socket returns an error: ', e);
      });

      ws.on(SocketEventName.Join, (e: string) => {
        // const [userId, hashSecretKey, socketId] = e.split('_DASH_');
        // console.info('Socket is joined: ', `${userId}:${socketId}`);
        console.info('Socket is joined: ', e);
        emitEvent({
          type: SocketAction.USER_JOINED_SOCKET,
          payload: ws,
        });
        // window.sha = hashSecretKey;
        window.ws = ws;
      });

      ws.on(SocketEventName.Userstate, (e) => {
        console.info('User state is changed: ', e);
      });

      ws.on<SmartActionInteractEventData>(SocketEventName.SmartActionInteract, (data) => {
        emitEvent({
          type: MailingAction.LISTEN_MAILING_EVENT_DATA,
          payload: data,
        });
      });

      ws.on<MLSShowAddressEventData>(SocketEventName.MLSTriggerSchedule, (data) => {
        emitEvent({
          type: MailingAction.LISTEN_SHOW_ADDRESS_MLS_EVENT_DATA,
          payload: data,
        });
      });

      ws.on<MLSShowAddressEventData>(SocketEventName.MLSTriggerOnDemand, (data) => {
        emitEvent({
          type: MailingAction.LISTEN_TOGGLE_ON_DEMAND_MLS_DATA,
          payload: data,
        });
      });

      ws.on<DuplicatedMailingListEventData>(SocketEventName.DuplicatedMailings, (data) => {
        emitEvent({
          type: MailingAction.LISTEN_DUPLICATED_MAILING_LIST_DATA,
          payload: data,
        });
      });
    });

    return () => {
      ws.close();
    };
  };
  return eventChannel<any>(subscriber);
};

export default connectSocket;
