import Skeleton from 'react-loading-skeleton';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { random, times } from 'lodash';
import React from 'react';
const TableWrapper = styled.div<{ fill: number }>`
  display: grid;
  width: 100%;
  gap: 15px;
  grid-template-columns: ${(props) => `repeat(${props.fill}, auto)`};
  .skeleton-row {
    grid-column-start: 1;
    grid-column-end: 6;
  }
  .table-cell {
    background-color: #f9fafb;
  }
`;
const SkeletonTableStyled = styled.div<{ pad: number }>`
  background-color: #fff;
  padding: ${(props) => `${props.pad}px`};
`;

export const LoadingSkeletonTable = ({ repeatCol = 4, repeatRow = 4, pad = 0 }) => (
  <SkeletonTableStyled pad={pad}>
    <TableWrapper fill={repeatCol}>
      {times(repeatCol).map((v) => (
        <React.Fragment key={v}>
          {times(repeatRow - 1).map((i) => (
            <div className="table-cell" key={i}>
              <Skeleton height={20} />
            </div>
          ))}
        </React.Fragment>
      ))}
    </TableWrapper>
  </SkeletonTableStyled>
);

export const SmartTriggerSkeleton = () => {
  return (
    <div
      css={css`
        background-color: #fff;
        width: 100%;
        display: flex;
        flex-direction: column;
        .row {
          width: 100%;
          display: flex;
          margin-bottom: 16px;
        }
      `}
    >
      {times(12).map((v) => (
        <div className="row" key={v}>
          <Skeleton count={1} width={60} height={24} style={{ marginRight: 33, marginLeft: -14 }} />
          <Skeleton count={1} width={80} height={24} style={{ marginRight: 70 }} />
          <Skeleton count={1} width={80} height={24} style={{ marginRight: 68 }} />
          <Skeleton count={3} width={100} height={24} style={{ marginRight: 30 }} />
          <Skeleton count={2} width={80} height={24} style={{ marginRight: 44 }} />
          <Skeleton count={4} width={100} height={24} style={{ marginRight: 24 }} />
          <Skeleton count={3} width={100} height={24} style={{ marginRight: 40 }} />
          <Skeleton count={3} width={120} height={24} style={{ marginRight: 40 }} />
          <Skeleton count={4} width={120} height={24} style={{ marginRight: 64 }} />
          <Skeleton count={1} width={120} height={24} style={{ marginRight: 38 }} />
          <Skeleton count={1} width={120} height={40} style={{ marginRight: 32 }} />
          <Skeleton count={1} width={120} height={40} style={{ marginRight: 12 }} />
          <Skeleton count={1} width={40} height={40} style={{ marginRight: 12 }} />
          <Skeleton count={1} width={40} height={40} />
        </div>
      ))}
    </div>
  );
};
export const AgentReportSkeleton = () => {
  return (
    <div
      css={css`
        background-color: #fff;
        width: 100%;
        display: flex;
        padding: 20px;
        flex-direction: column;
        .row {
          width: 100%;
          display: flex;
          .box {
            margin-left: 20px;
            width: 100%;
          }
        }
      `}
    >
      <div className="row">
        {times(1).map((i) => {
          return (
            <div className="box" key={i}>
              <Skeleton count={1} width="50%" height={24} style={{ marginRight: 33, marginLeft: -14 }} />
              <Skeleton count={1} width="25%" height={24} style={{ marginRight: 33, marginLeft: -14 }} />
              <Skeleton count={1} width="100%" height={24} style={{ marginRight: 33, marginLeft: -14 }} />
              {times(3).map((v) => {
                const randomWidth = `${random(15, 75)}%`;
                return (
                  <Skeleton
                    count={1}
                    key={v}
                    width={randomWidth}
                    height={24}
                    style={{ marginRight: 33, marginLeft: -14 }}
                  />
                );
              })}
              <Skeleton count={1} width="25%" height={24} style={{ marginRight: 33, marginLeft: -14 }} />
              {times(15).map((v) => {
                const randomWidth = `${random(15, 90)}%`;
                return (
                  <Skeleton
                    count={1}
                    key={v}
                    width={randomWidth}
                    height={24}
                    style={{ marginRight: 33, marginLeft: -14 }}
                  />
                );
              })}
            </div>
          );
        })}
        {times(3).map((i) => {
          return (
            <div className="box" key={i}>
              <Skeleton count={1} width="50%" height={24} style={{ marginRight: 70 }} />
              <Skeleton count={1} width="0%" height={24} />
              <Skeleton count={1} width="100%" height={24} style={{ marginRight: 33 }} />
              {times(3).map((v) => {
                const randomWidth = `${random(15, 80)}%`;
                return <Skeleton count={1} key={v} width={randomWidth} height={24} style={{ marginRight: 33 }} />;
              })}
              <Skeleton count={1} width="0%" height={24} />
              {times(15).map((v) => {
                const randomWidth = `${random(15, 75)}%`;
                return <Skeleton count={1} key={v} width={randomWidth} height={24} style={{ marginRight: 33 }} />;
              })}
            </div>
          );
        })}
      </div>
      <div className="row" style={{ marginTop: '20px' }}>
        <div className="box">
          <Skeleton count={1} width="25%" height={24} style={{ marginRight: 33, marginLeft: -14 }} />
          {times(9).map((v) => {
            const randomWidth = `${random(15, 75)}%`;
            return (
              <Skeleton
                count={1}
                key={v}
                width={randomWidth}
                height={24}
                style={{ marginRight: 33, marginLeft: -14 }}
              />
            );
          })}
        </div>
        <div className="box">
          <Skeleton count={1} width="0%" height={24} />
          {times(9).map((v) => {
            const randomWidth = `${random(15, 75)}%`;
            return <Skeleton count={1} key={v} width={randomWidth} height={24} style={{ marginRight: 33 }} />;
          })}
        </div>
      </div>
    </div>
  );
};
export const LoadingSkeleton = () => {
  return (
    <div
      css={css`
        background-color: #fff;
        width: 100%;
        display: flex;
        padding: 15px;
        flex-direction: column;
        .row {
          width: 100%;
          display: flex;
          .box {
            width: 100%;
          }
        }
      `}
    >
      {times(random(1, 3)).map((i) => {
        return (
          <div className="g-mb-25" key={i}>
            <div className="row">
              <div className="box ">
                <Skeleton count={1} width="25%" height={24} style={{ marginLeft: 10 }} />
              </div>
            </div>
            <div className="row" key={i}>
              <div className="box">
                {times(9).map((v) => {
                  const randomWidth = `${random(15, 90)}%`;
                  return (
                    <Skeleton
                      count={1}
                      key={v}
                      width={randomWidth}
                      height={24}
                      style={{ marginRight: 33, marginLeft: 10 }}
                    />
                  );
                })}
              </div>
              <div className="box">
                {times(9).map((v) => {
                  const randomWidth = `${random(15, 75)}%`;
                  return <Skeleton count={1} key={v} width={randomWidth} height={24} style={{ marginRight: -14 }} />;
                })}
              </div>
              <div className="box">
                {times(9).map((v) => {
                  const randomWidth = `${random(15, 90)}%`;
                  return (
                    <Skeleton
                      count={1}
                      key={v}
                      width={randomWidth}
                      height={24}
                      style={{ marginRight: 33, marginLeft: 10 }}
                    />
                  );
                })}
              </div>
              <div className="box">
                {times(9).map((v) => {
                  const randomWidth = `${random(15, 75)}%`;
                  return <Skeleton count={1} key={v} width={randomWidth} height={24} style={{ marginRight: -14 }} />;
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export const MailingListSkeleton = () => {
  return (
    <div
      css={css`
        background-color: #fff;
        width: 100%;
        display: flex;
        flex-direction: column;
        .row {
          width: 100%;
          display: flex;
          .box {
            width: 100%;
            text-align: left;
            display: flex;
            &:nth-of-type(1) {
              width: 27%;
            }

            &:nth-of-type(2) {
              width: 15%;
            }

            &:nth-of-type(3) {
              width: 42%;
            }

            &:nth-of-type(4) {
              width: 10%;
            }
          }
        }
      `}
    >
      {times(25).map((v) => (
        <div className="row" key={v}>
          <div className="box">
            <Skeleton count={1} width={100} height={15} style={{ marginRight: 10 }} />
            <Skeleton count={1} width={100} height={15} style={{ marginRight: 10 }} />
          </div>
          <div className="box">
            <Skeleton count={1} width={100} height={15} style={{ marginRight: 10 }} />
          </div>
          <div className="box">
            <Skeleton count={1} width={60} height={24} style={{ marginRight: 10 }} />
            <Skeleton count={1} width={60} height={24} style={{ marginRight: 10 }} />
            <Skeleton count={1} width={60} height={24} style={{ marginRight: 10 }} />
            <Skeleton count={1} width={60} height={24} style={{ marginRight: 10 }} />
            <Skeleton count={1} width={60} height={24} style={{ marginRight: 10 }} />
          </div>
          <div className="box">
            <Skeleton count={1} width={120} height={24} style={{ marginRight: 10 }} />
            <Skeleton count={1} width={90} height={24} style={{ marginRight: 10 }} />
          </div>
        </div>
      ))}
    </div>
  );
};
export const QRCodeSkeleton = () => {
  return (
    <div
      css={css`
        background-color: #fff;
        width: 100%;
        display: flex;
        flex-direction: column;
        .row {
          width: 100%;
          display: flex;
          .box {
            width: 100%;
            text-align: left;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            &:nth-of-type(1) {
              width: 14%;
            }

            &:nth-of-type(2) {
              width: 18%;
            }

            &:nth-of-type(3) {
              width: 18%;
            }

            &:nth-of-type(4) {
              width: 17%;
            }
            &:nth-of-type(5) {
              width: 15%;
            }
            &:nth-of-type(6) {
              width: 5%;
            }
          }
        }
      `}
    >
      {times(25).map((v) => (
        <div className="row" key={v}>
          <div className="box">
            <Skeleton count={1} width={55} height={55} style={{ marginRight: 10 }} />
          </div>
          <div className="box">
            <Skeleton count={1} width={100} height={15} style={{ marginRight: 10 }} />
          </div>
          <div className="box">
            <Skeleton count={1} width={100} height={15} style={{ marginRight: 10 }} />
          </div>
          <div className="box">
            <Skeleton count={1} width={120} height={15} style={{ marginRight: 10 }} />
          </div>
          <div className="box">
            <Skeleton count={1} width={120} height={15} style={{ marginRight: 10 }} />
          </div>
          <div className="box">
            <Skeleton count={1} width={60} height={24} style={{ marginRight: 10 }} />
            <Skeleton count={1} width={60} height={24} style={{ marginRight: 10 }} />
            <Skeleton count={1} width={60} height={24} style={{ marginRight: 10 }} />
          </div>
        </div>
      ))}
    </div>
  );
};
