import { SettingTabsKey, SmartActionTabsKey } from '@/models';
import RoutePaths from './routes';
import WrapLink from '@/components/WrapLink';
import { reduxStore } from '@/config/storeRedux';
import AppAction from '@/reducers/app/action';
import { navigate } from 'gatsby';

const validation = {
  common: {
    required: 'Please fill in this field.',
  },
  email: {
    subject: 'Email subject is required.',
    required: 'Email is missing.',
    invalid: 'Enter a valid email address.',
    format: 'Email is already associated with an existing account.',
    exist: 'Email does not exist. Please contact admin.',
  },
  password: {
    required: 'Password is missing.',
    invalidPwdRegex: 'Minimum eight characters, at least one letter and one number.',
  },
  mailing: {
    required: 'Please enter a mailing list name.',
  },
  pastClientType: {
    required: 'Please select past client type.',
  },
  target: {
    required: 'Please fill in this field.',
  },
  homes: {
    required: 'Please fill in this field.',
  },
  farm: {
    required: 'Please fill in this field.',
  },
  interaction: {
    required: 'Please fill in this field.',
  },
  ratio: {
    required: 'Please fill in this field.',
  },
  events: {
    required: 'Please select',
  },
  adoption: {
    required: 'Please select',
  },
  avatar: {
    required: 'Please upload a profile photo',
  },
  frequency: {
    required: 'Please fill in this field.',
  },
  company: {
    required: 'Please fill in this field.',
    format: 'Company name cannot include numeric and special characters.',
  },
  companyDefault: {
    required: 'Please fill in this field.',
  },
  payment: {
    required: 'Please fill in this field.',
    formatName: 'Name cannot include numeric and special characters.',
  },
  role: {
    required: 'Please select a role',
  },
  firstName: {
    required: 'Please fill in this field.',
    trim: 'First Name cannot include leading and trailing spaces.',
    format: 'First name cannot include numeric and special characters.',
  },
  lastName: {
    required: 'Please fill in this field.',
    trim: 'Last Name cannot include leading and trailing spaces.',
    format: 'Last name cannot include numeric and special characters.',
  },
  confirm: {
    required: 'Password is missing.',
    invalidPwdRegex: 'Your account info was entered incorrectly.',
  },
  name: {
    required: 'Name is missing.',
    NHrequired: 'Please enter a neighborhood name',
  },
  agentName: {
    required: 'Please fill in this field.',
    format: 'Agent name cannot include numeric and special characters.',
  },
  agentFullName: {
    required: 'Please fill in this field.',
  },
  fullName: {
    required: 'Full name is missing.',
    NHrequired: 'Please enter your full name',
  },
  agentDre: {
    required: 'Please fill in this field.',
  },
  homeownerCompany: {
    required: 'Please fill in this field.',
  },
  dripCampaign: {
    required: 'Name is required.',
    message: 'Message is required.',
    mailCampaign: 'Please select mail campaign name.',
    digitalTemplate: 'Please select digital template name.',
    defaultVariables: 'Please Setup Default Variables.',
    NHrequired: 'Please enter a drip campaign name.',
    ExistingName: 'Drip Campaign name already exists.',
    format: 'Name of drip campaign contains only alphabet and numeric characters.',
    subjectMaxLength: 'Please input below 255 characters',
  },
  timeDelay: {
    integer: 'Time Delay must be integer number.',
    positive: 'Time Delay must be positive number.',
    required: 'Please select time delay.',
  },
  mailingName: {
    required: 'Name is missing.',
    NHrequired: 'Please enter a mailing list name.',
    ExistingName: 'Mailing list name already exists.',
    format: 'Name of mailing list can only contain alphabet and numeric characters.',
  },
  dre: {
    required: 'Please fill in this field.',
    trim: 'DRE cannot include leading and trailing spaces',
    format: 'DRE number is already associated with an existing account',
    invalid: 'Agent not found',
  },
  market: {
    required: 'Please select a market.',
    trim: 'Market cannot include leading and trailing spaces',
  },
  availableMLS: {
    required: 'Please select an Available MLS.',
  },
  website: {
    regWeb:
      /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w?[a-zA-Z-_%/@?]+)*([^/\w?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
    url: 'Your URL is invalid',
    required: 'Please fill in this field.',
    regFeedUrl: /^https?:\/\/[^\s/$.?#].[^\s]*\.csv$/,
  },
  title: {
    required: 'Please fill in this field.',
  },
  changePassword: {
    required: 'Please fill in this field.',
  },
  confirmPassword: {
    required: 'Please fill in this field.',
    doesNotMatch: 'Passwords do not match',
  },
  address: {
    required: 'Please fill in this field.',
  },
  city: {
    required: 'Please fill in this field.',
  },
  state: {
    required: 'Please fill in this field.',
  },
  zipcode: {
    required: 'Please fill in this field.',
    format: 'Please enter a valid Zip Code.',
  },
  country: {
    required: 'Please fill in this field.',
  },
  return_address: {
    required: 'Please fill in this field.',
  },
  return_city: {
    required: 'Please fill in this field.',
  },
  return_state: {
    required: 'Please fill in this field.',
  },
  return_zipcode: {
    required: 'Please fill in this field.',
    format: 'Please enter a valid Zip Code.',
  },
  return_country: {
    required: 'Please fill in this field.',
  },
  phone: {
    required: 'Please fill in this field.',
    invalid: 'Invalid phone number format.',
  },
  email_template: {
    required: 'Please choose a template or custom your rich text.',
    exist: 'Template name is already associated with an existing template name.',
    requireName: 'Template name is required.',
  },
  campaign_target: {
    required: 'Please select a target audience.',
  },
  campaign_neighborhood: {
    required: 'Please select a mailing list.',
  },
  campaign_type: {
    required: 'Please select a type.',
    requiredCategory: 'Please select a category.',
  },
  campaign_campaigns: {
    required: 'Please select a campaign.',
  },
  campaign_agent: {
    required: 'Please select an agent.',
  },
  modal_remove: {
    required: 'Please choose a reason to remove the action.',
  },
  nft_name: {
    required: 'NFT name is required.',
  },
  upload_file: {
    required: 'Please select a file.',
  },
  referred_by: {
    required: 'Please select "Referred by".',
  },
  wants_to: {
    required: 'Please select a wants to.',
  },
  getInTouchEmail: {
    required: 'Please fill in this field.',
  },
  homeLimit: {
    min: (number: number) => {
      return `Home limit must be bigger than ${number}.`;
    },
    typeError: 'You must specify a number',
    required: 'Please fill in this field.',
  },
  smartTriggerName: {
    required: 'Please fill in this field.',
    ExistingName: 'Smart Trigger  name already exists.',
    format: "Smart Trigger Name can't contain special characters.",
  },
  ownerFirstName: {
    required: 'Please fill in this field.',
    format: "Owner First Name can't contain special characters.",
  },
  ownerFullName: {
    required: 'Please fill in this field.',
    format: "Owner Full Name can't contain special characters.",
  },
  zipCode: {
    required: 'Please fill in this field.',
    format: "ZipCode can't contain special character.",
  },
  required: 'Please fill in this field.',
  requiredNumber: 'Please enter numbers only.',
  requiredTypeError: 'Please input a valid number.',
  requiredInteger: 'Please input an integer.',
  requiredPositive: 'Please input a positive number.',
  requiredLandingPage: 'Landing page is required.',
  validDate: 'Invalid Date.',
  date: {
    required: 'Please fill in this field.',
  },
  credit: {
    creditQuantity: 'Please input an integer greater than 0.',
    minCredit: 'Please input an integer greater than 0.',
    recurringCredit: 'Please input an integer greater than 0.',
  },
  tag: {
    required: 'Please enter segmentation tag name.',
    ExistingName: 'Tag name is already exists.',
  },
  children: {
    requiredInteger: 'The children field must be an integer and positive number.',
  },
  qrCode: {
    landingPage: 'Please select landing page name.',
    qrCodeName: 'Please enter QR code name.',
  },
  landingPage: {
    name: {
      required: 'Please fill in this field.',
      existingName: 'Landing Page name already exists.',
    },
    slug: {
      required: 'Please fill in this field.',
      format: 'Tag cannot include space and special characters.',
    },
    redirectUrl: {
      required: 'Please fill in this field.',
      format: 'Please enter a valid URL.',
    },
  },
  handwrittenGiftCard: {
    required: 'Gift Amount is required.',
  },
  filter: {
    minMax: 'Max value must be greater than min value.',
  },
  package: {
    numeric: 'Please enter an integer value.',
  },
};

export default validation;

export const MessageError = {
  address_invalid: (address: string) => {
    return `Invalid address: ${address}.`;
  },
  belongsToSmartTrigger: (error: Error, { smartTriggerId = '', mailingId = '' }) => {
    const idParams = `${mailingId ? `&mailingId=${mailingId}` : ''}${
      smartTriggerId ? `&smartTriggerId=${smartTriggerId}` : ''
    }`;
    const url = `${window.location.origin}${RoutePaths.SMART_ACTIONS}/?tab=${SmartActionTabsKey.SMART_TRIGGERS}${idParams}`;
    return <WrapLink text={error.message} url={url} targetText={'Smart Trigger'} />;
  },
  error_oops: 'Oops! Something went wrong, please try again.',
  all_home_invalid: 'Your total homes across all neighborhoods exceeds 3,000.',
  homes_not_enough: `You can't Draw a polygon because the number of homes is not enough.`,
  missing_market:
    'We do not detect any past transactions in the polygon you drew, this will result in missing market share data in your report. We recommend you redraw your polygon so that it includes one or more pins.',
  agent_not_found: 'Agent not found.',
  pins_not_found: `We don't have any pins for this Agent.`,
  limit_threshold: `We have reached the maximum threshold.`,
  nh_not_found: 'Neighborhood is not found',
  ml_not_found: 'Mailing List is not found',
  no_nfts: 'There are no campaign NFTs in your wallet',
  no_nfts_matched: 'There are no matched campaign NFTs. Please try again',
  metamask_duplicate_request_error: 'Please head to Metamask to complete your previous action.',
  not_campaign_owner: 'Only owner of this campaign can mint an NFT from it.',
  ttb_balance: 'Your data purchase failed, please contact admin.',
  tokenExpired: 'Token is invalid or expired.',
  unAuthenticate: 'You need to login into the system to use this function.',
  addCard: (
    <div>
      Please choose your card or enter new card on{' '}
      <span
        style={{
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        onClick={() => {
          reduxStore.dispatch({
            type: AppAction.VISIBLE_GLOBAL_CREDIT,
            payload: null,
          });
          navigate(`${RoutePaths.SETTINGS}/?tab=${SettingTabsKey.SUBSCRIPTION}`);
        }}
      >
        Settings
      </span>{' '}
      page
    </div>
  ),
};
