import HomeAction from '@/reducers/home/action';
import { HomeState } from '@/reducers/home/model';
import { ActionBase } from '..';

const initialState: HomeState = {
  soldHouse: [],
  compareArea: [],
  userAgent: {},
  userAgentSSID: {},
  website_domain: null,
  is_refresh: false,
};

const homeReducer = (state = initialState, action: ActionBase): HomeState => {
  if (!action.type.includes('@home')) {
    return state;
  }
  switch (action.type) {
    case HomeAction.UPDATE_SOLD_HOUSE:
      return {
        ...state,
        soldHouse: action?.payload,
      };
    case HomeAction.UPDATE_COMPARE_AREA:
      return {
        ...state,
        compareArea: action.payload,
      };
    case HomeAction.UPDATE_USER_AGENT:
      return {
        ...state,
        userAgent: action.payload,
      };
    case HomeAction.UPDATE_USER_AGENT_SSID:
      return {
        ...state,
        userAgentSSID: action.payload,
      };
    case HomeAction.UPDATE_DOMAIN_WEBSITE:
      return {
        ...state,
        website_domain: action.payload,
      };

    case HomeAction.IS_REFRESHED:
      return {
        ...state,
        is_refresh: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default homeReducer;
