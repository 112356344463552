import { isEqual, pick } from 'lodash';
import { mergeVariables } from '@/utils/format';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

export interface UseFallbackPostgridFormStore {
  isFetching: boolean;
  setIsFetching: (value: boolean) => void;
  oldTemplate: string;
  fallbacks: { [key: string]: any };
  setFallbackValues: (key: string, values: any) => void;
  initialValues: (
    listVariablesOnUI: string[],
    initialData: any,
    templateId: string,
    uniqueFallbackFormId: string,
  ) => void;
}

export const fallbackPrefixes = {
  ACTION_MAIL: 'mail_action_',
  ACTION_EMAIL: 'email_action_',
  ACTION_SMS: 'sms_action_',
  PREVIEW_CAMPAIGN_WITH_MAILING_LIST: 'preview_campaign_with_mailing_list_',
  PREVIEW_TEMPLATE: 'preview_template_',
  DRIP_ITEM: 'drip_item_',
  SMART_DRIP_ITEM: 'smart_drip_item_',
  EMAIL_EDITOR: 'email_editor_',
};

const useFallbackPostgridFormStore = create<UseFallbackPostgridFormStore>()(
  immer((set) => ({
    isFetching: false,
    setIsFetching: (value) => set(() => ({ isFetching: value })),
    oldTemplate: '',
    fallbacks: {},
    setFallbackValues: (key: string, values) =>
      set((state) => ({
        fallbacks: {
          ...state.fallbacks,
          [key]: values,
        },
      })),
    initialValues: (listVariablesOnUI: string[], initialData: any, templateId: string, uniqueFallbackFormId: string) =>
      set((state) => {
        const resetAll = state.oldTemplate !== templateId;

        const currentFallbacks = resetAll ? {} : state.fallbacks[uniqueFallbackFormId];

        const mergedValues = mergeVariables(
          pick(initialData, listVariablesOnUI),
          pick(currentFallbacks, listVariablesOnUI),
        );

        if (isEqual(currentFallbacks, mergedValues)) return state;
        state.fallbacks[uniqueFallbackFormId] = mergedValues;
        return state;
      }),
  })),
);

export default useFallbackPostgridFormStore;
