import { MySub, SubStatus } from '@goldfishcode/first-team-real-estate-sdk/libs/api/subscription/models';
import { User } from '@goldfishcode/first-team-real-estate-sdk/libs/api/user/models';
import { isEmpty } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/reducers/model';
import moment from 'moment';
import useGetConstantValueQuery from '@/queries/settings/useGetConstantValue';

const useDisableBySubscription = (isCheckReachedRequestLimit = false) => {
  const myProfile = useSelector((state: RootState) => state.auth.myProfile);

  const isUnlimitedHome = useSelector((state: RootState) => state.auth.myProfile?.unlimited_home);
  const userType = useSelector((state: RootState) => state.auth.myProfile?.user_type);
  const mySub = useSelector((state: RootState) => state.homeCredit.mySub);
  const { data } = useGetConstantValueQuery();

  const checkDisable = (profile?: User, currentMySub?: MySub) => {
    // isEmpty(mySub) is subscription package not exist.
    // mySub?.status === null is the subscription package has ended and cannot be activated
    // mySub?.is_cancel === true is the subscription package has cancel
    const currentProfile = profile || myProfile;
    const isUnlimitedHome = currentProfile?.unlimited_home;
    const isReachedRequestLimit = isCheckReachedRequestLimit ? currentProfile?.reached_request_limit : false;
    const currentSub = currentMySub || mySub;
    if (isUnlimitedHome) return false;

    const isInvalidStatus =
      currentSub?.status === null ||
      (currentSub?.status &&
        [SubStatus.CANCELED, SubStatus.UNPAID, SubStatus.INCOMPLETE_EXPIRED].includes(currentSub?.status));

    const isNotHaveSubscriptionPackage = currentSub?.subscription_package === null;

    const isCanceledAndPassedEndCycle =
      currentSub?.is_cancel &&
      moment().valueOf() > moment(Number(currentSub?.current_period_end || 0) * 1000).valueOf();

    return (
      isEmpty(currentSub) ||
      isInvalidStatus ||
      isNotHaveSubscriptionPackage ||
      isReachedRequestLimit ||
      isCanceledAndPassedEndCycle
    );
  };

  const isHomeBalanceInsufficient = useCallback(
    (home_count = 0) => {
      if (isUnlimitedHome) return false;
      return (mySub?.current_balance ?? 0) < home_count;
    },
    [mySub, isUnlimitedHome, userType],
  );

  const isUserUnsubscribed = useCallback(() => {
    if (isUnlimitedHome) return false;
    return isEmpty(mySub);
  }, [mySub, isUnlimitedHome, userType]);

  const isDisableBySubscription = useMemo(() => {
    if (!mySub || !myProfile) return false;
    const isDisabled = checkDisable();
    return Boolean(isDisabled);
  }, [mySub, myProfile]);

  const isDisabledDrawArea = useMemo(
    () =>
      isUnlimitedHome
        ? false
        : Number(mySub?.current_balance) < Number(data?.allow_draw_polygon_threshold) &&
          typeof data?.allow_draw_polygon_threshold !== 'undefined',
    [mySub, data, isUnlimitedHome],
  );

  return {
    isDisableBySubscription,
    isUnlimitedHome,
    isDisabledDrawArea,
    allowDrawPolygonThreshold: Number(data?.allow_draw_polygon_threshold),
    checkDisable,
    isHomeBalanceInsufficient,
    isUserUnsubscribed,
  };
};

export default useDisableBySubscription;
