import {
  CampaignPreviewType,
  UserPostGridTemplateFallback,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/postgrid/models';
import { MailingExtra } from '@goldfishcode/first-team-real-estate-sdk/libs/api/tools/variable/models';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/reducers/model';
import ToolsService from '@/services/tools';
import { qrCodeVars } from '@/utils/constant';
import { useUserRole } from '@/hooks/useUserRole';
import { LISTING_IMAGE_KEY, listingImageVars } from '@/components/custom-modals/modal-fallbacks/types';
import { generateDefaultFallbacksByHtmlString } from '@/utils/utils';
import OverviewService from '@/services/overview';
import useTakeFilterHashDataParams from '@/components/dashboard/hooks/useTakeFilterHashDataParams';

const useConvertPostgridFallback = () => {
  const { filterHashData } = useTakeFilterHashDataParams();
  const userAgent = useSelector((state: RootState) => state.home.userAgent);
  const { isManager, isBroker } = useUserRole();

  const getAgentInfo = async () => {
    let final = { ...(userAgent?.user_agent || {}) }; // Not pass agent_id if is not manager and not select An Agent dropdown
    if (!filterHashData) return final;

    const { agent_id } = filterHashData;
    const hasSelectedAgent = isManager && agent_id;
    if (hasSelectedAgent) {
      const agentData = await OverviewService.getAgentInfo(agent_id);
      final = {
        ...agentData?.user_agent,
        agent_id: agentData?.id,
      };
    }
    return final;
  };

  const getMailingsWithFilledFallbackVariable = async (
    rawTemplateFallback: UserPostGridTemplateFallback,
    mailings: MailingExtra[],
    type?: CampaignPreviewType,
    htmlString?: string,
    forceAgentInfo?: any,
  ) => {
    const agentInfo = isBroker ? {} : forceAgentInfo || (await getAgentInfo());
    const newDefaultFallback = generateDefaultFallbacksByHtmlString(htmlString || '');
    const listVariableInHTML = Object.keys(newDefaultFallback);
    if (listVariableInHTML.some((key) => listingImageVars.some((imageVar) => key.includes(imageVar)))) {
      // If the template utilizes variables {{listing_image_1->5}}, it is necessary to manually set the {{listing_image}} variable since we do not have the {{listing_image}} variables available in the session variable field.
      newDefaultFallback[LISTING_IMAGE_KEY] = [];
    }
    rawTemplateFallback.default_fallbacks = newDefaultFallback;

    if (!rawTemplateFallback || !mailings?.length || !agentInfo?.id) return [];
    const result = await ToolsService.getMailingsWithFilledFallbackVariable(
      agentInfo,
      rawTemplateFallback,
      mailings,
      type,
    );
    return result;
  };

  const getFallbackVariableWithoutMailings = async (templateFallback: UserPostGridTemplateFallback) => {
    if (isBroker) return templateFallback;
    const agentInfo = await getAgentInfo();
    const result = await ToolsService.getFallbackVariableWithoutMailingData(agentInfo, templateFallback);
    return result;
  };

  const getQRCodeVariableOnUI = useCallback((htmlString) => {
    return ToolsService.extractVariablesFromHtmlString(htmlString).filter((htmlVar) =>
      qrCodeVars?.some((v) => v === htmlVar),
    );
  }, []);

  return {
    getAgentInfo,
    getMailingsWithFilledFallbackVariable,
    getFallbackVariableWithoutMailings,
    getQRCodeVariableOnUI,
  };
};

export default useConvertPostgridFallback;
