import { SizeType } from 'antd/lib/config-provider/SizeContext';

// Based on your final design
const layout = {
  minHeight: 940,
  maxWidth: 1440,
  maxWidthSM: 1200,
  footerHeight: 70,
  headerHeight: 100,
  marginContainer: 24,
  layoutId: 'app-root',
};
const media = {
  XXLdesktops: 1440,
  XLdesktops: 1360,
  desktops: 991,
  tablets: 767,
  phones: 479,
};

export enum color {
  NONE = 'transparent',
  BLACK = '#000000',
  BLACK_1 = '#191919',
  BLACK_2 = '#282828',
  BLACK_3 = '#676767',
  BLACK_4 = '#999999',
  BLACK_5 = '#031f2d',
  BLACK_6 = '#24242459',
  BLACK_O10 = '#00000010',
  BLACK_O30 = '#00000030',
  GRAY = '#7B7B7B',
  GRAY_1 = '#C4C4C4',
  GRAY_2 = '#CECECE',
  GRAY_3 = '#333333',
  GRAY_4 = '#979797',
  GRAY_5 = '#CCCCCC',
  GRAY_6 = '#e5e5e5',
  GRAY_7 = '#bcbcbc',
  GRAY_8 = '#cbcbcb',
  GRAY_9 = '#bfbfbf',
  GRAY_10 = '#9FABB1',
  GRAY_11 = '#a8b0c4',
  GRAY_12 = '#707070',
  WHITE = '#FFFFFF',
  WHITE_1 = '#F5F5F5',
  WHITE_2 = '#E7EDF3',
  WHITE_3 = '#eaeaea',
  WHITE_4 = '#ebebeb',
  WHITE_TRANS_0 = '#FFFFFF00',
  WHITE_TRANS_1 = '#FFFFFF30',
  WHITE_TRANS_2 = '#FFFFFF60',
  GREEN = '#3BE880',
  GREEN_2 = '#0ed290',
  DARKBLUE = '#2e0249',
  DARKBLUE_LIGHT = '#39005cfa',
  PURPLE = '#2e0448',
  PINK = '#ff00dd',
  BLUE = '#40a9ff',
  BLUE_2 = '#0000ee',
  BLUE_3 = '#1c87c6',
  RED = '#ff0000',
}

export enum InputSizeEnum {
  SMALL = 32,
  MIDDLE = 42,
  LARGE = 52,
  HUGE = 75,
}

const text = {
  blackColor: color.DARKBLUE,
  whiteColor: color.WHITE,
  grayColor: color.GRAY_5,
  errorColor: color.RED,
  blueColor: color.BLUE_3,
};

const backgroundColor = {
  button: color.BLUE_3,
};

const theme = {
  layout,
  text,
  backgroundColor,
  media,
  color,
};

export type AppSizeType = SizeType;

export const heightSize = new Map<AppSizeType, { height: string; minHeight?: string }>([
  ['small', { height: `${InputSizeEnum.SMALL}px !important`, minHeight: `${InputSizeEnum.SMALL}px !important` }],
  ['middle', { height: `${InputSizeEnum.MIDDLE}px !important`, minHeight: `${InputSizeEnum.MIDDLE}px !important` }],
  ['large', { height: `${InputSizeEnum.LARGE}px !important`, minHeight: `${InputSizeEnum.LARGE}px !important` }],
  ['huge', { height: `${InputSizeEnum.HUGE}px !important`, minHeight: `${InputSizeEnum.HUGE}px !important` }],
]);

export type AppTheme = typeof theme;

export default theme;
