import styled from '@emotion/styled';
import type { ModalProps } from 'antd';
import AntModal from 'antd/es/modal';
import React, { forwardRef, useMemo, useRef } from 'react';
import type CropperRef from 'react-easy-crop';

import { PREFIX } from './constants';
import type { EasyCropRef, ImgCropProps } from './types';
import EasyCrop from './EasyCrop';
import useCrop from './hooks/useCrop';
import useUpload from './hooks/useUpload';

export type { ImgCropProps } from './types';

const AntModalStyled = styled(AntModal)`
  .ant-modal-header {
    .ant-modal-title {
      font-weight: 600;
    }
  }

  .ant-modal-footer {
    text-align: center;

    button {
      color: rgb(255, 255, 255);
      background: #2e0249;
      width: 160px;
      height: 46px;
      font-weight: 600;
      font-size: 16px;
      border: 0;

      &.ant-btn-primary {
        background: #ff00dd;
      }
    }
  }
` as any;

const ImageCrop = forwardRef<CropperRef, ImgCropProps>((props, cropperRef) => {
  const {
    quality = 0.9,
    fillColor = 'white',

    zoomSlider = true,
    rotationSlider = false,
    aspectSlider = false,
    showReset = false,
    resetText,

    aspectSelector = [],
    customAspect = false,
    aspect = 1,
    minZoom = 1,
    maxZoom = 10,
    cropShape = 'rect',
    showGrid = false,
    cropperProps,

    modalClassName,
    modalTitle,
    modalWidth,
    modalOk,
    modalCancel,
    onModalOk,
    onModalCancel,
    modalProps,

    beforeCrop,
    children,
  } = props;

  const onCancel = useRef<ModalProps['onCancel']>();
  const onOk = useRef<ModalProps['onOk']>();

  /**
   * crop
   */
  const easyCropRef = useRef<EasyCropRef>(null);
  const { getCropCanvas } = useCrop(easyCropRef, rotationSlider, fillColor);

  /**
   * upload
   */
  const { modalImage, getNewUpload } = useUpload(
    easyCropRef,
    getCropCanvas,
    onCancel,
    onOk,
    quality,
    aspect,
    onModalOk,
    onModalCancel,
    beforeCrop,
  );

  /**
   * modal
   */
  const modalBaseProps = useMemo(() => {
    const obj: Pick<ModalProps, 'width' | 'okText' | 'cancelText'> = {};
    obj.width = modalWidth || '640px';
    if (modalOk !== undefined) obj.okText = modalOk;
    if (modalCancel !== undefined) obj.cancelText = modalCancel;
    return obj;
  }, [modalCancel, modalOk, modalWidth]);

  const wrapClassName = `${PREFIX}-modal${modalClassName ? ` ${modalClassName}` : ''}`;

  const title = modalTitle || 'Edit image';
  const resetBtnText = resetText || 'Reset';

  return (
    <>
      {getNewUpload(children)}
      {modalImage && (
        <AntModalStyled
          {...modalProps}
          {...modalBaseProps}
          open={true}
          title={title}
          onCancel={onCancel.current}
          onOk={onOk.current}
          wrapClassName={wrapClassName}
          maskClosable={false}
          destroyOnClose
        >
          <EasyCrop
            ref={easyCropRef}
            cropperRef={cropperRef}
            zoomSlider={zoomSlider}
            rotationSlider={rotationSlider}
            aspectSlider={aspectSlider}
            showReset={showReset}
            resetBtnText={resetBtnText}
            modalImage={modalImage}
            aspect={aspect}
            minZoom={minZoom}
            maxZoom={maxZoom}
            cropShape={cropShape}
            showGrid={showGrid}
            aspectSelector={aspectSelector}
            customAspect={customAspect}
            cropperProps={cropperProps}
          />
        </AntModalStyled>
      )}
    </>
  );
});

export default ImageCrop;
