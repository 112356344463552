"use strict";
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-var-requires */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Caching = void 0;
class Caching {
    constructor() {
        this.cache = new Map();
    }
    checkType(param) {
        if (Array.isArray(param)) {
            return 'array';
        }
        else if (typeof param === 'object' && param !== null) {
            return 'object';
        }
        else {
            return typeof param;
        }
    }
    sortObjectByKeyName(myObject) {
        if (this.checkType(myObject) !== 'object')
            return myObject;
        return Object.fromEntries(Object.entries(myObject).sort(([a], [b]) => a.localeCompare(b)));
    }
    isBrowser() {
        if (typeof window === 'undefined')
            return false;
        return true;
    }
    generateCacheKey(endpoint, params) {
        if (!this.isBrowser())
            return null;
        const keyParams = this.normalizeParams(params);
        return JSON.stringify({ endpoint, keyParams });
    }
    normalizeParams(params) {
        switch (this.checkType(params)) {
            case 'array':
                return [...params].sort();
            case 'object':
                return this.sortObjectByKeyName(params);
            default:
                return params;
        }
    }
    setCache(key, data) {
        this.cache.set(key, data);
    }
    getCache(key) {
        if (!this.cache.has(key))
            return null;
        const cachedData = this.cache.get(key);
        const isDataFresh = Date.now() - cachedData.timestamp < cachedData.staleTime * 1000;
        return isDataFresh ? cachedData : null;
    }
    deleteCache(key) {
        this.cache.delete(key);
    }
    deleteCacheByEndpoint(endpoint) {
        this.cache.forEach((value, key) => {
            const isValidEndpoint = key.includes(endpoint);
            console.log('XXXXXXXX', isValidEndpoint);
            if (isValidEndpoint) {
                this.cache.delete(key);
            }
        });
    }
    clearCache() {
        this.cache.clear();
    }
}
exports.Caching = Caching;
