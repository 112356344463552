enum AppAction {
  APP_LOADING = '@app/APP_LOADING',
  RESET_ROOT_STATE = '@app/RESET_ROOT_STATE',
  SET_THEME = '@app/SET_THEME',
  SET_ID_MODAL_WEBSITE = '@app/SET_ID_MODAL_WEBSITE',
  SHOW_MODAL_ONBOARDING = '@app/SHOW_MODAL_ONBOARDING',
  UPDATE_WEBSITE_MANAGER = '@app/UPDATE_WEBSITE_MANAGER',
  MODAL_ACTIONS_TYPE = '@app/MODAL_ACTIONS_TYPE',
  VISIBLE_MODAL_BUTTON_ADD_MAILING = '@app/VISIBLE_MODAL_BUTTON_ADD_MAILING',
  VISIBLE_MODAL_ADD_MAILING = '@app/VISIBLE_MODAL_ADD_MAILLING',
  VISIBLE_RECIPIENTS_MODAL = '@app/VISIBLE_RECIPIENTS_MODAL',
  VISIBLE_ADD_INFORMATION_ACTIONS_MODAL = '@app/VISIBLE_ADD_INFORMATION_ACTIONS_MODAL',
  VISIBLE_ADD_HOME_MODAL = '@app/VISIBLE_ADD_HOME_MODAL',
  VISIBLE_MAIL_ACTIONS_MODAL = '@app/VISIBLE_MAIL_ACTIONS_MODAL',
  VISIBLE_WARNING_CREDIT_BALANCE_MODAL = '@app/VISIBLE_WARNING_CREDIT_BALANCE_MODAL',
  VISIBLE_THRESHOLD_LIMIT_MODAL = '@app/VISIBLE_THRESHOLD_LIMIT_MODAL',
  VISIBLE_TRIGGER_ONDEMAND_MODAL = '@app/VISIBLE_TRIGGER_ONDEMAND_MODAL',
  VISIBLE_MESSAGE_NODATA_MODAL = '@app/VISIBLE_MESSAGE_NODATA_MODAL',
  VISIBLE_MODAL_PURCHASE_PREMIUM = '@app/VISIBLE_MODAL_PURCHASE_PREMIUM',
  VISIBLE_CONFIRM_MODAL_PURCHASE_PREMIUM_MODAL = '@app/VISIBLE_CONFIRM_MODAL_PURCHASE_PREMIUM_MODAL',
  VISIBLE_PREVIEW_LANDING_PAGE_MODAL = '@app/VISIBLE_PREVIEW_LANDING_PAGE_MODAL',
  SET_URL_PREVIEW_LANDING_PAGE_MODAL = '@app/SET_URL_PREVIEW_LANDING_PAGE_MODAL',
  VISIBLE_INFO_REDIRECT_PAST_ORDER_MODAL = '@app/VISIBLE_INFO_REDIRECT_PAST_ORDER_MODAL',
  VISIBLE_CREATE_LANDING_PAGE_MODAL = '@app/VISIBLE_CREATE_LANDING_PAGE_MODAL',
  NEW_LANDING_PAGE = '@app/NEW_LANDING_PAGE',
  CLOSE_ALL_MODAL_APP_ROOT = '@app/CLOSE_ALL_MODAL_APP_ROOT',
  VISIBLE_MODAL_SMART_TRIGGER = '@app/VISIBLE_MODAL_SMART_TRIGGER',
  VISIBLE_MODAL_CHANGE_SUBSCRIPTION_PACKAGE = '@app/VISIBLE_MODAL_CHANGE_SUBSCRIPTION_PACKAGE',
  VISIBLE_INSUFFICIENT_HOME_CREDIT = '@app/VISIBLE_INSUFFICIENT_HOME_CREDIT',
  VISIBLE_GLOBAL_CREDIT = '@app/VISIBLE_GLOBAL_CREDIT',
  VISIBLE_GLOBAL_UNSUBSCRIBE = '@app/VISIBLE_GLOBAL_UNSUBSCRIBE',
  CAMPAIGN_TYPE_LIST = '@app/CAMPAIGN_TYPE_LIST',
  VISIBLE_CONFIGURE_BROKER_SUBSCRIPTION = '@app/VISIBLE_CONFIGURE_BROKER_SUBSCRIPTION',
}
export default AppAction;
