import { MINIMUM_HOME_LIMIT_BOUNDARY } from '@/utils/constant';
import { ActionBase } from '..';
import CreditAction from './action';
import { HomeCreditState } from './model';

const initialState: HomeCreditState = {
  minHome: MINIMUM_HOME_LIMIT_BOUNDARY,
  mySub: null,
  mySetting: null,
  mySettingList: null,
};
const creditReducer = (state = initialState, action: ActionBase): HomeCreditState => {
  if (!action.type.includes('@home-credit')) {
    return state;
  }
  switch (action.type) {
    case CreditAction.SET_MY_SETTING_HOME_CREDIT: {
      return {
        ...state,
        mySetting: action.payload,
      };
    }
    case CreditAction.SET_MY_SUB_HOME_CREDIT: {
      return {
        ...state,
        mySub: action.payload,
      };
    }
    case CreditAction.SET_MY_SETTING_HOME_CREDIT_LIST: {
      return {
        ...state,
        mySettingList: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default creditReducer;
