/* eslint-disable react/react-in-jsx-scope */
import { navigate } from 'gatsby';
import RoutePaths from '@/utils/routes';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { SweetAlertResult } from 'sweetalert2';

export const MySwal = withReactContent(Swal);

interface ShowPopupConfirmProps {
  message: string | HTMLElement | Element;
  title: string | HTMLElement;
  confirmButtonText: string;
  cancelButtonText?: string;
  allowOutsideClick?: boolean;
  allowEscapeKey?: boolean;
  allowEnterKey?: boolean;
  showCloseButton?: boolean;
  error?: boolean;
  width?: number;
}

export const AlertTextError = (
  _title: string | HTMLElement,
  _body: string | HTMLElement,
  callBack?: (value: SweetAlertResult<unknown>) => void,
  showCloseButton?: boolean,
  width?: string | number,
) => {
  return MySwal.fire({
    title: _title,
    customClass: {
      title: 'text-center mr-0',
    },
    icon: 'error',
    text: _body as string,
    html: _body,
    showCloseButton: showCloseButton ?? true,
    confirmButtonText: 'Okay',
    confirmButtonColor: '#2e0249',
    focusConfirm: true,
    width,
  }).then(callBack);
};
export const AlertTextSuccess = (
  _title: string | HTMLElement,
  _body: string | HTMLElement,
  callBack?: (value: SweetAlertResult<unknown>) => void,
  timer = 10000,
) => {
  return MySwal.fire({
    title: _title,
    customClass: {
      title: 'text-center mr-0',
    },
    icon: 'success',
    text: _body as string,
    html: _body,
    showCloseButton: true,
    confirmButtonText: 'Okay',
    confirmButtonColor: '#2e0249',
    focusConfirm: true,
    timer,
    width: 520,
  }).then(callBack);
};
export const AlertProspectError = (_title: string, _row: string, number: number) => {
  return MySwal.fire({
    title: _title,
    customClass: {
      title: 'text-center mr-0',
    },
    icon: 'error',
    html: (
      // eslint-disable-next-line react/react-in-jsx-scope
      <div>
        <p style={{ textAlign: 'initial' }}>
          {number} {number > 1 ? 'agents' : 'agent'} could not be imported due to invalid data. We have imported all
          other agents. You can update your import data & reupload it now, or you can add further agents at a later
          time.
        </p>
        <br />
        <p style={{ textAlign: 'initial' }}>
          To fix the invalid data, please check the following rows in your file: {_row}
          {number > 5 ? ',..' : null}. If you have further issues, please contact the system administrator.
        </p>
      </div>
    ),
    showCloseButton: true,
    confirmButtonText: 'Okay',
    confirmButtonColor: '#2e0249',
    focusConfirm: true,
  });
};
export const AlertMailingError = (_title: string, _row: string, number: number) => {
  return MySwal.fire({
    title: _title,
    customClass: {
      title: 'text-center mr-0',
    },
    icon: 'error',
    html: (
      // eslint-disable-next-line react/react-in-jsx-scope
      <div>
        <p style={{ textAlign: 'initial' }}>
          {number} {number > 1 ? 'addresses' : 'address'} could not be imported due to invalid data. We have imported
          all other addresses. You can update your import data & reupload it now, or you can add further addresses at a
          later time.
        </p>
        <br />
        <p style={{ textAlign: 'initial' }}>
          To fix the Invalid data, please check the following rows in your file: {_row}
          {number > 5 ? ',..' : null}. If you have further issues, please contact the system administrator.
        </p>
      </div>
    ),
    confirmButtonText: 'Okay',
    showCloseButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
    focusConfirm: true,
    width: 520,
  }).then(() => navigate(RoutePaths.MAILING_LISTS));
};
export const AlertProspectManagedError = (_title: string, _row: string, number: number) => {
  return MySwal.fire({
    title: _title,
    customClass: {
      title: 'text-center mr-0',
    },
    icon: 'error',
    html: (
      // eslint-disable-next-line react/react-in-jsx-scope
      <div>
        <p style={{ textAlign: 'initial' }}>
          {number} {number > 1 ? 'prospects ' : 'prospect'} were not added because you already manage these agents.
          Please check the following rows in your file: {_row}
          {number > 5 ? ',..' : null}.
        </p>
      </div>
    ),
    showCloseButton: true,
    confirmButtonText: 'Okay',
    confirmButtonColor: '#2e0249',
    focusConfirm: true,
  });
};
export const AlertErrorProspectError = (_title: string, _row: string, number: number) => {
  return MySwal.fire({
    title: _title,
    customClass: {
      title: 'text-center mr-0',
    },
    icon: 'error',
    html: (
      // eslint-disable-next-line react/react-in-jsx-scope
      <div>
        <p style={{ textAlign: 'initial' }}>
          {number} {number > 1 ? 'agents ' : 'agent'} cannot be managed because it is a Prospect Agent. Please check the
          following rows in your file: {_row}
          {number > 3 ? ',..' : null}.
        </p>
      </div>
    ),
    showCloseButton: true,
    confirmButtonText: 'Okay',
    confirmButtonColor: '#2e0249',
    focusConfirm: true,
  });
};
export const showPopup = (
  {
    message,
    title,
    confirmButtonText,
    cancelButtonText,
    allowOutsideClick = false,
    allowEscapeKey = false,
    allowEnterKey = false,
    showCloseButton = false,
    error = false,
    width = 520,
  }: ShowPopupConfirmProps,
  callBack?: (value: SweetAlertResult<unknown>) => void,
) => {
  return MySwal.fire({
    title: title,
    html: message,
    showCloseButton,
    showCancelButton: Boolean(cancelButtonText),
    cancelButtonText: cancelButtonText,
    confirmButtonText: confirmButtonText,
    cancelButtonColor: '#2e0249',
    confirmButtonColor: '#ff00dd',
    reverseButtons: true,
    customClass: {
      popup: 'popup-add-cart',
      title: 'text-center mr-0',
    },
    allowOutsideClick,
    allowEscapeKey,
    allowEnterKey,
    icon: error ? 'error' : undefined,
    width,
  }).then(callBack);
};
