import { color as themeColor } from '@/styles/theme';
import styled from '@emotion/styled';
import { CSSProperties } from 'react';

export interface AppLabelStyledProps {
  paddingBottom?: CSSProperties['paddingBottom'];
  labelColor?: keyof typeof themeColor;
  labelAlign?: CSSProperties['textAlign'];
}

export const AppLabelStyled = styled.label<AppLabelStyledProps>(
  {
    fontSize: '16px !important',
    fontWeight: 700,
    display: 'block',
  },
  ({ paddingBottom, labelColor, labelAlign }) => ({
    paddingBottom: paddingBottom ?? '6px',
    color: labelColor ? themeColor[labelColor] : themeColor.DARKBLUE,
    textAlign: labelAlign ?? 'left',
  }),
);
