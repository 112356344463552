import CampaignAction from '@/reducers/campaign/actions';
import { RootState } from '@/reducers/model';
import ToolsService from '@/services/tools';
import { getFileUrl } from '@/utils/upload';
import { getListVarsOnFallbackForm, getListVarsOnHtmlUI } from '@/utils/utils';
import { PlusOutlined } from '@ant-design/icons';
import { useFormik } from 'formik';
import { cloneDeep, isEqual } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useFallbackPostgridFormStore from '../hooks/useFallbackPostgridFormStore';
import { FallbackTypeEnum, listingImageVars, LISTING_IMAGE_KEY, VariableOnUITemplate } from '../types';
import SetFallbackPostgridFormView from './SetFallbackPostgridFormView';

interface FallbackFormProps extends VariableOnUITemplate {
  extraData?: any;
  templateFallback?: any;
  initialData: any;
  isDripCampaign?: boolean;
  className?: string;
  isSms?: boolean;
  type?: FallbackTypeEnum;
  htmlString?: string;
  isUseDisclaimerFallback?: boolean;
  onConfirm?: () => void;
  onChange?: (values: any) => void;
  onCancel?: () => void;
  uniqueFallbackFormId: string;
  templateId?: string;
  globalDataKeys?: string[];
  mlsData?: {
    [key: string]: string;
  };
}

const SetFallbackPostgridForm: React.FunctionComponent<FallbackFormProps> = React.memo(
  ({
    onChange,
    className,
    initialData = {},
    templateFallback = {},
    extraData = {},
    isDripCampaign = false,
    type = FallbackTypeEnum.POSTGRID,
    htmlString = '',
    isUseDisclaimerFallback = false,
    onVariableOnUIUpdate,
    isSms,
    templateId,
    uniqueFallbackFormId,
    globalDataKeys,
    mlsData,
  }) => {
    const { fallbacks, initialValues } = useFallbackPostgridFormStore();

    const listAllVariable = useSelector((state: RootState) => state.campaign.listPostgridSessionVar);

    const listMLIdNotExist = useSelector((state: RootState) => state.campaign.notExistMLId);

    const errFields = Object.keys(mlsData || {})?.filter((key) => listMLIdNotExist.includes(mlsData?.[key] || ''));

    const dispatch = useDispatch();

    const [generalError] = React.useState<string | undefined>('');

    const formik = useFormik({
      initialValues: {},
      onSubmit: async () => {
        // Do nothing
      },
    });

    const formikValues = formik?.values || {};

    const listVariablesOnFallbackForm = useMemo(() => {
      return (getListVarsOnFallbackForm(type, htmlString, listAllVariable, false) as string[]).filter(
        (variable) => !listingImageVars.includes(variable),
      );
    }, [type, htmlString]);
    const listVariablesOnHtmlUI = useMemo(() => {
      return getListVarsOnHtmlUI(htmlString, listAllVariable, false) as string[];
    }, [type, htmlString]);

    const specialCaseVariableOnUI = useMemo(() => {
      const listSpecialCase = ToolsService.extractVariablesFromHtmlString(htmlString).filter((htmlVar) =>
        listingImageVars?.some((v) => v === htmlVar),
      );
      if (listSpecialCase.some((v) => listingImageVars.some((u) => v === u))) listSpecialCase.push(LISTING_IMAGE_KEY);
      return listSpecialCase;
    }, [type, htmlString]);

    const getImageFile = async (file: File) => {
      try {
        const avatarUpload = await getFileUrl(file, undefined, undefined, true);
        const { url } = avatarUpload;
        formik.setFieldValue(LISTING_IMAGE_KEY, [
          url,
          ...(Array.isArray(formikValues?.[LISTING_IMAGE_KEY]) ? formikValues?.[LISTING_IMAGE_KEY] : []),
        ]);
        return avatarUpload;
      } catch (error) {
        // Do nothing
      }
    };

    const customRequest = (info) => {
      getImageFile && getImageFile(info.file);
    };

    const onPreview = async (file: any) => {
      let src = file.url;
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow?.document.write(image.outerHTML);
    };

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );

    const handleRemoveImage = (url: any) => {
      const listImage = cloneDeep(formikValues?.[LISTING_IMAGE_KEY]);
      if (listImage.some((v) => v === url)) {
        formik.setFieldValue(
          LISTING_IMAGE_KEY,
          listImage.filter((v) => v !== url),
        );
        listingImageVars.forEach((listingImageKey) => {
          if (formikValues[listingImageKey] === url) formik.setFieldValue(listingImageKey, '');
        });
      } else formik.setFieldValue(LISTING_IMAGE_KEY, listImage);
    };

    const debouncedOnChange = !onChange ? null : onChange;

    useEffect(() => {
      if (!listVariablesOnFallbackForm) return;
      onVariableOnUIUpdate && onVariableOnUIUpdate(listVariablesOnFallbackForm);
    }, [listVariablesOnFallbackForm]);

    useEffect(() => {
      if (
        !uniqueFallbackFormId ||
        !listVariablesOnFallbackForm.concat(specialCaseVariableOnUI)?.length ||
        !Object.keys(initialData).length ||
        !templateId ||
        Object.keys(formikValues).length
      ) {
        return;
      }

      initialValues(
        listVariablesOnHtmlUI.concat(specialCaseVariableOnUI),
        initialData,
        templateId,
        uniqueFallbackFormId,
      );
    }, [listVariablesOnFallbackForm, initialData, templateId, uniqueFallbackFormId]);

    useEffect(() => {
      dispatch({
        type: CampaignAction?.NOT_EXIST_ML_ID,
        payload: [],
      });
      if (
        !uniqueFallbackFormId ||
        !listVariablesOnFallbackForm.concat(specialCaseVariableOnUI)?.length ||
        !Object.keys(initialData).length ||
        !templateId ||
        !Object.keys(formikValues).length
      ) {
        return;
      }
      initialValues(
        listVariablesOnFallbackForm.concat(specialCaseVariableOnUI),
        initialData,
        templateId,
        uniqueFallbackFormId,
      );
    }, [templateId]);

    useEffect(() => {
      if (!uniqueFallbackFormId || isEqual(fallbacks[uniqueFallbackFormId] || {}, formikValues)) {
        return;
      }
      formik.setValues(fallbacks[uniqueFallbackFormId]);
    }, [fallbacks, uniqueFallbackFormId]);

    useEffect(() => {
      if (!debouncedOnChange || !onChange) return;

      if (!uniqueFallbackFormId || isEqual(fallbacks[uniqueFallbackFormId] || {}, formikValues)) {
        return;
      }

      debouncedOnChange(formikValues);
    }, [formikValues, uniqueFallbackFormId]);

    const isHideDripFallback = listVariablesOnFallbackForm?.length === 0 && specialCaseVariableOnUI?.length === 0;

    return (
      <SetFallbackPostgridFormView
        className={className}
        templateFallback={templateFallback}
        extraData={extraData}
        isDripCampaign={isDripCampaign}
        type={type}
        isUseDisclaimerFallback={isUseDisclaimerFallback}
        isSms={isSms}
        globalDataKeys={globalDataKeys}
        formik={formik}
        isHideDripFallback={isHideDripFallback}
        listVariablesOnFallbackForm={listVariablesOnFallbackForm}
        errFields={errFields}
        specialCaseVariableOnUI={specialCaseVariableOnUI}
        onPreview={onPreview}
        customRequest={customRequest}
        uploadButton={uploadButton}
        handleRemoveImage={handleRemoveImage}
        generalError={generalError}
      />
    );
  },
);
export default SetFallbackPostgridForm;
