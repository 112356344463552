enum CampaignNftAction {
  GET_LIST_CAMPAIGN_NFT = '@campaign-nft/GET_LIST_CAMPAIGN_NFT',
  GET_LIST_CAMPAIGN_NFT_SUCCESS = '@campaign-nft/GET_LIST_CAMPAIGN_NFT_SUCCESS',
  GET_CAMPAIGN_NFT_DETAIL = '@campaign-nft/GET_CAMPAIGN_NFT_DETAIL',
  GET_CAMPAIGN_NFT_DETAIL_SUCCESS = '@campaign-nft/GET_CAMPAIGN_NFT_DETAIL_SUCCESS',
  GET_LIST_CAMPAIGN_AUDIENCE = '@campaign-nft/GET_LIST_CAMPAIGN_AUDIENCE',
  GET_CAMPAIGN_PUBLIC_DATA = '@campaign-nft/GET_CAMPAIGN_PUBLIC_DATA',
  GET_CAMPAIGN_PUBLIC_DATA_SUCCESS = '@campaign-nft/GET_CAMPAIGN_PUBLIC_DATA_SUCCESS',
  GET_CAMPAIGN_HOMEOWNERSMAILED = '@campaign-nft/GET_CAMPAIGN_HOMEOWNERSMAILED',
  GET_CAMPAIGN_HOMEOWNERSMAILED_SUCCESS = '@campaign-nft/GET_CAMPAIGN_HOMEOWNERSMAILED_SUCCESS',
  GET_CAMPAIGN_INTERACTIONS = '@campaign-nft/GET_CAMPAIGN_INTERACTIONS',
  GET_CAMPAIGN_INTERACTIONS_SUCCESS = '@campaign-nft/GET_CAMPAIGN_INTERACTIONS_SUCCESS',
  GET_CAMPAIGN_PUBLIC_AND_PRIVATE_DATA = '@campaign-nft/GET_CAMPAIGN_PUBLIC_AND_PRIVATE_DATA',
  DATA_CAMPAIGN = '@campaign-nft/DATA_CAMPAIGN',
  GET_BLUR_IMAGE = '@campaign-nft/GET_BLUR_IMAGE',
  GET_BLUR_IMAGE_SUCCESS = '@campaign-nft/GET_BLUR_IMAGE_SUCCESS',
  GET_CONFIG_VARIABLES = '@campaign-nft/GET_CONFIG_VARIABLE',
  GET_CONFIG_VARIABLES_SUCCESS = '@campaign-nft/GET_CONFIG_VARIABLE_SUCCESS',
}
export default CampaignNftAction;
