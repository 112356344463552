import styled from '@emotion/styled';
import Button, { ButtonProps } from 'antd/es/button';
import { CSSProperties } from 'react';
import { css } from '@emotion/react';
import { ButtonColor, fontWeight as fontWeightMap, letters, style, Size, SizeHaveNone } from './types';
import { heightSize, color } from '@/styles/theme';
import { TooltipProps } from 'antd';

/**
 * CustomButtonProps interface represents the props for the custom button component.
 */
interface CustomButtonProps {
  /**
   * The color of the button.
   */
  color?: ButtonColor;
  /**
   * The border color and text color of the Outline button. It mean color="Outline"
   */
  outlineColor?: color;
  /**
   * The width of the button.
   */
  width?: CSSProperties['width'];

  /**
   * The height of the button.
   */
  height?: CSSProperties['height'];

  /**
   * Whether to infer the loading state of the button.
   */
  inferLoading?: boolean;

  /**
   * The letter size of the button.
   * By default value used as the value of size
   * @param { 'Size' } small letter-spacing: 0.5px
   * @param { 'Size' } middle letter-spacing: 1px
   * @param { 'Size' } large letter-spacing: 1.5px
   * @param { 'Size' } none letter-spacing: 0px
   */
  letter?: SizeHaveNone | number;

  /**
   * The size of the button.
   * @param { 'small' } small is a Size and height is 32px
   * @param { 'middle' } middle is a Size and height is 40px
   * @param { 'large' } large is a Size and height is 54px
   */
  size?: Size;

  /**
   * The font size of the button.
   */
  fontSize?: CSSProperties['fontSize'];

  /**
   * Whether to display the button text in uppercase.
   */
  uppercase?: false;
  p?: CSSProperties['padding'];
  /**
   * The font weight of the button.
   */
  fontWeight?: CSSProperties['fontWeight'];
  /**
   * The gap of the button.
   */
  gap?: CSSProperties['gap'];
  fixedLetter?: boolean;
  hoverLetter?: SizeHaveNone | number;
  noborder?: boolean;
  roundedFull?: boolean;
}

const getLetter = (size: Size, letter?: SizeHaveNone | number) => {
  if (letter) {
    return typeof letter !== 'number' ? letters.get(letter) : `${letter}px`;
  }
  return letters.get(size);
};

const getValue = (value?: string | number): string | undefined => {
  if (typeof value === 'number') {
    return `${value}px`;
  }
  return value;
};

export interface ButtonLargeProps
  extends Omit<ButtonProps, 'color' | 'type' | 'danger' | 'ghost' | 'size'>,
    CustomButtonProps {
  tooltipProps?: TooltipProps;
}

const customStyled = ({
  color,
  height,
  width,
  size,
  fontSize,
  uppercase,
  p,
  fontWeight,
  gap,
  fixedLetter,
  letter,
  noborder,
  roundedFull,
  outlineColor,
}: CustomButtonProps) => css`
  ${style.get(color || 'Pin')};
  width: ${typeof width === 'undefined' ? '100%' : getValue(width)};
  /* min-width: ${getValue(width)}; */
  height: ${height ?? heightSize.get(size || 'large')?.height};
  font-size: ${fontSize ? getValue(fontSize) : size === 'small' ? '13px' : '17px'};
  text-transform: ${uppercase === false ? undefined : 'uppercase'};
  padding: ${p ? p : '0px 4px'};
  font-weight: ${fontWeight ? fontWeight : fontWeightMap.get(size)};
  gap: ${getValue(gap)};
  letter-spacing: ${fixedLetter ? getLetter(size || 'large', letter) : '0px'};
  ${noborder
    ? css`
        border-width: 0 !important;
      `
    : ''}
  ${roundedFull
    ? css`
        border-radius: 100px !important;
      `
    : ''}
  ${color === 'Outline' && outlineColor
    ? css`
        border-color: ${outlineColor} !important;
        color: ${outlineColor} !important;
      `
    : ''}
`;

export const AppButtonStyled = styled(Button)<ButtonLargeProps>`
  &.ant-btn {
    line-height: 1.4;
    font-family: Montserrat, sans-serif;
    border-width: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    ${customStyled};

    &:hover,
    &:focus {
      ${({ letter, size, hoverLetter }) => ({
        letterSpacing: getLetter(size, hoverLetter || letter),
      })};
    }

    &:disabled {
      opacity: 0.4;
    }
  }
`;
