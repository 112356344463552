"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserApi = void 0;
class UserApi {
    constructor(client) {
        this.client = client;
    }
    register(args) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post('/auth/registration/', args, {}, false);
        });
    }
    me() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.get('/users/me/');
        });
    }
    profile() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.get('/profile/');
        });
    }
    updateProfile(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = '/users/profile/';
            return this.client.patch(uri, data, {});
        });
    }
    existsUser(username) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = `/users/exists/`;
            const r = yield this.client.get(uri, { username });
            return r.exists;
        });
    }
    existsEmail(email, broker_user_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = `/users/exists/`;
            const r = yield this.client.get(uri, { email, broker_user_id });
            return r.exists;
        });
    }
    existsDRE(dre) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = `/users/exists/`;
            const r = yield this.client.get(uri, { dre });
            return r.exists;
        });
    }
    validAgent(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = `/users/agent-valid/`;
            const r = yield this.client.post(uri, params);
            return r.valid;
        });
    }
    listBrokerUser(page = 1, limit = 20) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = `/users/broker/`;
            const params = { page, limit };
            return yield this.client.get(uri, params);
        });
    }
    updateEmail(args) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = `/users/email/change/`;
            return this.client.patch(uri, args);
        });
    }
}
exports.UserApi = UserApi;
