"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HandWritingApi = void 0;
const caching_1 = require("../caching");
class HandWritingApi {
    constructor(client) {
        this.client = client;
        this.staleTimes = {
            listHandWritingTemplate: 5,
            listGiftCard: 5,
        };
        this.caching = new caching_1.Caching();
    }
    listHandWritingTemplate(page = 1, limit = 20, search, card_type) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = '/handwriting/templates/';
            const params = { page, limit, search: search || undefined, card_type: card_type || undefined };
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.listHandWritingTemplate,
                });
            return response;
        });
    }
    listGiftCard(page = 1, limit = 20) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = '/handwriting/giftcards/';
            const params = { page, limit };
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.listGiftCard,
                });
            return response;
        });
    }
    createHandWritingTemplate(template) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post('/handwriting/templates/', template);
        });
    }
    updateHandWritingTemplate(id, template) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.patch(`/handwriting/templates/${id}/`, template);
        });
    }
    deleteWritingTemplate(id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.delete(`/handwriting/templates/${id}`);
        });
    }
    createHandwritingOrder(data) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post('/handwriting/create-order/', data);
        });
    }
}
exports.HandWritingApi = HandWritingApi;
