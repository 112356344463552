import { UnsupportedChainIdError } from '@web3-react/core';
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from '@web3-react/frame-connector';
import {
  WalletConnectConnector,
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
} from '@web3-react/walletconnect-connector';
import {
  InjectedConnector,
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector';
import globalVariable from './env';
import { AlertTextWarning } from '@/components/alert/NotificationAlert';
import { MessageError } from '@/utils/validation';

export const injected = new InjectedConnector({});

export const walletConnect = new WalletConnectConnector({
  rpc: {
    [globalVariable.POLYGON_CHAINID]: globalVariable.POLYGON_RPC as string,
  },
  bridge: globalVariable.BRIDGE_URL,
  qrcode: true,
  // pollingInterval: POLLING_INTERVAL,
});

export function getErrorConnectMessage(error: Error, metamaskNotFound?: any) {
  localStorage.removeItem('walletconnect');
  if (error instanceof NoEthereumProviderError) {
    return metamaskNotFound && metamaskNotFound();
  } else if (error instanceof UnsupportedChainIdError) {
    return '';
  } else if (
    error instanceof UserRejectedRequestErrorInjected ||
    error instanceof UserRejectedRequestErrorWalletConnect ||
    error instanceof UserRejectedRequestErrorFrame
  ) {
    return '';
  } else {
    if ((error as any).code === -32002) AlertTextWarning(MessageError.metamask_duplicate_request_error, true);
    return '';
  }
}
