import {
  AgentMailingListCompetior,
  BulkVerifyData,
  CheckNameArgs,
  CheckNameResult,
  DrawDetail,
  DrawDetailArgs,
  FilterTagTypeEnum,
  ISkipTrace,
  LoanType,
  Mailing,
  MailingArgs,
  MailingList,
  MailingListArgs,
  MailingParams,
  MailingsImportResult,
  OneHome,
  OrderByMailingListEnum,
  PremiumDataCountArg,
  PremiumDataParams,
  REANeighborhood,
  SegmentationTag,
  SendEmailArgs,
  SendMailArgs,
  SendSMSArgs,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/mailing/models';
import { CampaignOrder } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';
import { Pagination } from '@goldfishcode/first-team-real-estate-sdk/libs/type';
import { cloneDeep, omit } from 'lodash';
import { apiIns } from '@/config/apiClient';
import ToolsService from '../tools';
import { ExtraFilters } from '@/components/dashboard/campaign/types';
import { AddressesSearchMLSParams } from '@/models/AddressesSearchMLSParams';
import { AddressesSearchCampaignParams } from '@/models';
import { CampaignTypeEnum } from '@/utils/enum';

class MailingService {
  static listMailingList(
    ...params: Parameters<typeof apiIns.mailingList.listMailingList>
  ): Promise<Pagination<MailingList>> {
    return apiIns.mailingList.listMailingList(...params);
  }
  static mailingListCompare(mailingList_id: string, agent_id: string): Promise<AgentMailingListCompetior> {
    return apiIns.mailingList.mailingListCompare(mailingList_id, agent_id);
  }

  static getMailingList(
    id: string,
    is_report?: boolean,
    agent_id?: string,
    order_by?: OrderByMailingListEnum,
  ): Promise<MailingList> {
    return apiIns.mailingList.getMailingList(id, is_report, agent_id, order_by ?? OrderByMailingListEnum.CREATED_DESC);
  }

  static updateMailingList(id: string, data: MailingListArgs): Promise<MailingList> {
    return apiIns.mailingList.updateMailingList(id, data);
  }

  static deleteMailingList(id: string): Promise<MailingList> {
    return apiIns.mailingList.deleteMailingList(id);
  }

  static importMailingList(params: FormData): Promise<MailingsImportResult> {
    return apiIns.mailingList.importMailingList(params);
  }

  static createDrawDetail(arg: DrawDetailArgs): Promise<DrawDetail> {
    return apiIns.mailingList.createDrawDetail(arg);
  }
  static premiumDataMailingList(params: PremiumDataParams): Promise<{
    success: boolean;
  }> {
    return apiIns.mailingList.premiumDataMailingList(params);
  }
  static listMailings(page?: number, limit?: number, mailing_list_id?: string): Promise<Pagination<Mailing>> {
    return apiIns.mailingList.listMailings(page, limit, { mailing_list_id });
  }
  static retrieveDrawDetail(mailing_list_id: string): Promise<DrawDetail> {
    return apiIns.mailingList.retrieveDrawDetail(mailing_list_id);
  }
  static checkNameAlreadyExist(arg: CheckNameArgs): Promise<CheckNameResult> {
    return apiIns.mailingList.checkNameAlreadyExist(arg);
  }
  static downloadMailingList(id: string): Promise<unknown> {
    return apiIns.mailingList.exportMailingList(id);
  }
  static getNewestMlsMailings(
    mailingList_id: string,
    is_my_listing?: boolean,
  ): Promise<{ [mlsStatusAbbr: string]: Mailing }> {
    return apiIns.variableTool.getNewestMlsMailings(mailingList_id, is_my_listing);
  }
  static listSmartAction(page: number, limit: number, args: MailingArgs): Promise<Pagination<Mailing>> {
    return apiIns.mailingList.listMailings(page, limit, args);
  }
  static downloadSmartAction(params: MailingArgs): Promise<any> {
    return apiIns.mailingList.DownloadSmartAction(params);
  }
  static retrieveMailing(mailing_id: string): Promise<Mailing> {
    return apiIns.mailingList.retrieveMailing(mailing_id);
  }
  static updateMailing(mailing_id: string, MailingParams: MailingParams): Promise<Mailing> {
    return apiIns.mailingList.updateMailing(mailing_id, MailingParams);
  }

  static sendEmailSmartAction(data: SendEmailArgs): Promise<any> {
    return apiIns.mailingList.SendEmailSmartAction(data);
  }
  static sendSMSSmartAction(data: SendSMSArgs): Promise<any> {
    return apiIns.mailingList.SendSMSSmartAction(data);
  }
  static sendMailSmartAction(data: SendMailArgs): Promise<any> {
    return apiIns.mailingList.SendMailSmartAction(data);
  }
  static exportCSVTemplateView(): Promise<any> {
    return apiIns.mailingList.ExportCSVTemplateView();
  }
  static updatePastClient(past_client_id: string, args: MailingListArgs): Promise<MailingList> {
    return apiIns.mailingList.updatePastClient(past_client_id, args);
  }
  static deletePastClient(past_client_id: string): Promise<any> {
    return apiIns.mailingList.deletePastClient(past_client_id);
  }
  static checkMailingAddress(mailing_id: string): Promise<{
    is_renter_address_valid: boolean;
    is_owner_address_valid: boolean;
  }> {
    return apiIns.mailingList.checkMailingAddress(mailing_id);
  }
  static enhancedMailingList(args: FormData): Promise<{
    success: boolean;
    error_rows?: any;
    error_rows_total?: any;
  }> {
    return apiIns.mailingList.enhancedMailingList(args);
  }
  static addHome(data: OneHome): Promise<MailingList> {
    return apiIns.mailingList.addHome(data);
  }
  static listTags(type?: FilterTagTypeEnum, agent_id?: string): Promise<SegmentationTag[]> {
    return apiIns.mailingList.listTags(type, agent_id);
  }

  static createTag(name: string): Promise<SegmentationTag> {
    return apiIns.mailingList.createTag(name);
  }
  static deleteTag(tag_id: string): Promise<any> {
    return apiIns.mailingList.deleteTag(tag_id);
  }

  static getCountPastClient() {
    return apiIns.mailingList.getCountPastClient();
  }
  static fetchPastClient() {
    return apiIns.mailingList.fetchPastClient();
  }
  static bulkVerify(data: BulkVerifyData): Promise<any> {
    return apiIns.mailingList.bulkVerify(data);
  }
  static listAddressesCampaign(params: AddressesSearchCampaignParams & ExtraFilters) {
    const {
      mailing_list_ids = [],
      employer = '',
      audience_ids = '',
      interests = '',
      // range_age = '',
      service = '',
      is_past_client,
      type = '',
      mls_status,
      milestones,
      has_linkedin,
      has_email,
      has_phone,
      home_equity_percentage,
      bedrooms,
      bathrooms,
      sqft,
      avm_values,
      avg_years_owned,
      rea_neighborhood_ids,
      page = 1,
      limit = 12,
      action_type,
      tags,
      stories,
      years_owned,
      estimated_price_value,
      estimated_price_operator,
      rea_loan_type_ids,
      agent_id,
      age_range,
      rea_properties,
      interest_rates,
      days_on_market,
      price_per_square_foot,
      zip_codes,
    } = params;
    const isPastClient = !!is_past_client;
    const isEmail = type === CampaignTypeEnum.EMAIL;
    return apiIns.postalytics.listAddress(
      page,
      limit,
      {
        age_range,
        audience_ids,
        employer,
        interest: interests,
        industry: service,
        is_email: isEmail,
        is_past_client: isPastClient,
        is_neighborhood: false,
        is_realtor: '',
        neighborhood_id: '',
        owner_occupied: undefined,
        search: undefined,
        owner_out_of_state: undefined,
        mailing_list_ids,
        mls_status,
        milestones,
        has_linkedin,
        has_email,
        has_phone,
        home_equity_percentage,
        bedrooms,
        bathrooms,
        sqft,
        avm_values,
        avg_years_owned,
        rea_neighborhood_ids,
        action_type,
        tags,
        stories,
        years_owned,
        estimated_price_value,
        estimated_price_operator,
        rea_loan_type_ids,
        rea_properties,
        interest_rates,
        days_on_market,
        price_per_square_foot,
        zip_codes,
      } as any,
      agent_id as string,
    );
  }
  static listAddressFromMailingIds(page = 1, limit = 100, mailing_ids: string, agent_id?: string) {
    return apiIns.variableTool.listAddressFromMailingIds(page, limit, mailing_ids, agent_id);
  }
  static listAddressesMLS(params: AddressesSearchMLSParams) {
    const { page = 1, limit = 12, ...rest } = params;

    const newRest = omit(rest, [
      // Show Addresses popup fields on FE side
      'isRealtor',
      'filterState',
      'textSearch',
      'ownerOutOfState',
      'ownerOccupied',
      // Show Addresses popup fields on FE side

      // Exclude on API payload
      'send_to_owner',
      'send_to',
      'exclude_mailings',
      'name',
      'smart_trigger_action_id',
      'drip_exist',
      'id',
      'is_active',
      'is_default',
      'is_property_empty',
      'neighborhoods',
      'sm_trigger_campaign',
      'user',
      'agent',
      'view_mode',
      'view_owner_occupied',
      'view_owner_out_of_state',
      'owner_occupied',
      'owner_out_of_state',
      'is_realtor',
      'search',
      'send_to_mailings',
      'isMailingListNotChanged',
    ]);

    return apiIns.postalytics.getSmartTriggerActionMailings(
      newRest?.agent_id || '',
      page,
      limit,
      {
        is_realtor: undefined,
        owner_occupied: undefined,
        search: undefined,
        owner_out_of_state: undefined,
      } as any,
      newRest,
    );
  }

  static fetchPremiumDataCount(data: PremiumDataCountArg) {
    return apiIns.mailingList.fetchPremiumDataCount(data);
  }
  static fetchMLSStatus(polygon: string) {
    return apiIns.mailingList.fetchMLSStatus(polygon);
  }
  static countREAPropertiesDrawDetail(polygon: string) {
    return apiIns.mailingList.countREAPropertiesDrawDetail(polygon);
  }
  static getCampaignHistory(mailing_id: string): Promise<Pagination<CampaignOrder>> {
    return apiIns.mailingList.getCampaignHistory(mailing_id);
  }
  static getREANeighborhoods(page: number, limit: number, agent_id?: string): Promise<Pagination<REANeighborhood>> {
    return apiIns.mailingList.getREANeighborhoods(page, limit, agent_id);
  }
  static getLoanTypes(page: number, limit: number, agent_id?: string): Promise<Pagination<LoanType>> {
    return apiIns.mailingList.getLoanTypes(page, limit, agent_id);
  }

  static saveMailingFallback(
    id: string,
    data: { [key: string]: any },
    isSendToOwnerAddress: boolean,
    mlsData: { [key: string]: any } = {},
    mailingListData: { [key: string]: any } = {},
    agent_id?: string,
  ) {
    const cloned = cloneDeep(data);

    ToolsService.removeFormatFallbackVariables(cloned);
    return apiIns.mailingList.saveMailingFallback(
      id,
      { ...cloned, agent_id },
      isSendToOwnerAddress,
      mlsData,
      mailingListData,
    );
  }
  static fetchMailingSkipTrace(mailing_id: string): Promise<ISkipTrace> {
    return apiIns.mailingList.fetchMailingSkipTrace(mailing_id);
  }
  static getMailingSkipTrace(mailing_id: string): Promise<ISkipTrace> {
    return apiIns.mailingList.getMailingSkipTrace(mailing_id);
  }
  static refreshReportForMailingList(agent_id: string) {
    return apiIns.mailingList.refreshReportForMailingList(agent_id);
  }
  static countCompetitors(polygon: string) {
    return apiIns.mailingList.countCompetitors(polygon);
  }
}
export default MailingService;
