import {
  BuyPayload,
  CreditTransaction,
  CreditTransactionType,
  EditCreditUser,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/credit/models';
import { ChargeDetail, MySub, SubSetting } from '@goldfishcode/first-team-real-estate-sdk/libs/api/subscription/models';
import { Pagination } from '@goldfishcode/first-team-real-estate-sdk/libs/type';
import { apiIns } from '@/config/apiClient';

class CreditService {
  static getMyCredit() {
    return apiIns.credit.getMyCredit();
  }
  static editMyCredit(params: EditCreditUser) {
    return apiIns.credit.editMyCredit(params);
  }
  static buyCredit(buyParams: BuyPayload) {
    return apiIns.credit.buyCredit(buyParams);
  }
  static getCreditSetting() {
    return apiIns.credit.creditSetting();
  }
  static setDefaultStripeCard(card_id: string): Promise<any> {
    return apiIns.payment.setDefaultStripeCard(card_id);
  }
  static getMySub(): Promise<MySub | any> {
    return apiIns.subscription.getMySub();
  }
  static sub(sub_id: number): Promise<MySub> {
    return apiIns.subscription.sub(sub_id);
  }
  static subSetting(is_manager?: boolean): Promise<SubSetting[]> {
    return apiIns.subscription.subSetting(is_manager);
  }
  static cancelSub(): Promise<MySub> {
    return apiIns.subscription.cancel();
  }
  static subAgain(sub_id: number): Promise<MySub> {
    return apiIns.subscription.subAgain(sub_id);
  }
  static buyExtra(extraAmount: number, sub_id: number): Promise<MySub> {
    return apiIns.subscription.buyExtra(extraAmount, sub_id);
  }
  static changeSub(sub_id: number): Promise<MySub> {
    return apiIns.subscription.changeSub(sub_id);
  }
  static previewChangeSub(sub_id: number): Promise<ChargeDetail> {
    return apiIns.subscription.previewChangeSub(sub_id);
  }
  static creditHistory(
    page?: number,
    limit?: number,
    transaction_type?: CreditTransactionType,
  ): Promise<Pagination<CreditTransaction>> {
    return apiIns.credit.creditHistory(page, limit, transaction_type);
  }
}

export default CreditService;
