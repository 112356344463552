import React from 'react';
import { MailingExtra } from '@goldfishcode/first-team-real-estate-sdk/libs/api/tools/variable/models';
import { PastClientTypeEnum } from '@goldfishcode/first-team-real-estate-sdk/libs/api/mailing/models';
/*  ================= CONSTANTS HELPERS ================= */

export const MLS_STATUS_MAPPING = {
  inactive: 'D',
  coming_soon: 'C',
  active: 'A',
  active_under_contract: 'U',
  pending: 'P',
  sold: 'S',
  withdrawn: 'W',
  expired: 'X',
};
export const EMPTY_MAILING = { label: '', value: '' };

/* ================= MODEL TYPES HELPERS ================= */

// 'variable' could be 1 of these 3 values:[ MLS_STATUS.NOT_MY_LISTINGS | MLS_STATUS.MY_LISTINGS | PC_PREFIX {currently using 'pc'} ]
// MlsFilters[variable].status includes status(inactive, sold,...) OR type(listing, buyer, double_ended)
export interface MlsFilters {
  [variable: string]: {
    status: string;
    useManualSelect?: boolean;
    mailingListId: string;
    mailing: { label: string; value: string };
    pastClientType?: PastClientTypeEnum | null;
  };
}
// LatestFilters is the copycat of MlsFilters for auto-latest usage case
export interface LatestFilters {
  [variable: string]: {
    status: string;
    mailingListId: string;
    mailingListLabel: string;
    mailing?: MailingExtra;
    pastClientType?: PastClientTypeEnum | null;
  };
}

export interface SearchKeywords {
  [variable: string]: { mailingList: string; mailing: string };
}

export interface MailingData {
  [variable: string]: { data: MailingExtra[]; count: number };
}

export interface MlsData {
  [addressUniqueKeyMlsVariable: string]: string;
}

// { [mailingListName_mlsVariable]: mailingListId }
// CAREFUL: mailingListId, not mailingId
export interface MailingListData {
  [mailingListNameMlsVariable: string]: string;
}

/* ================= FUNCTIONAL HELPERS ================= */
export const variableInstruction = () => {
  return (
    <>
      <p>Incorporate an image variable into your template by following these steps:</p>
      <p>1. Navigate to the &apos;Merge Variables&apos; category and select &apos;Image&apos;.</p>
      <p>
        2. In the &apos;Merge Variables&apos; textbox, type in your specific variable name (e.g., &apos;qr_code&apos;).
      </p>
      <p>3. Construct your template with the desired design elements.</p>
      <p>4. Save your template design to retain the modifications.</p>
    </>
  );
};

export const PC_PREFIX = 'pc';
