import styled from '@emotion/styled';
import { DigitalCampaignTemplate } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';
import {
  PostGridTemplate,
  TemplateViewSide,
  UserPostGridTemplateFallback,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/postgrid/models';
import { MailingExtra } from '@goldfishcode/first-team-real-estate-sdk/libs/api/tools/variable/models';
import { Pagination } from 'antd';
import classNames from 'classnames';
import { cloneDeep, debounce } from 'lodash';
import React, { FC, ReactNode, useRef, useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { MdOutlineArrowDropDown, MdOutlineSaveAlt } from 'react-icons/md';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
// internal
import {
  CardTypeEnum,
  IHandWritingTemplateResponse,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/handwriting/models';
import FileImageOutLinedSvg from '@/assets/images/file-image-outline.svg';
import { convertPostgridHtmlToRenderDOM } from '@/components/dashboard/campaign/ScrollTemplate/hooks/useScrollTemplate';
import RenderHTML from '@/components/dashboard/campaign/ScrollTemplate/RenderHTML';
import { useCampaignManagerStore } from '@/components/dashboard/hooks/useCampaignManager';
import { InnerLoading } from '@/components/loading/loadingBase';
import { reduxStore } from '@/config/storeRedux';
import useToggleClass from '@/hooks/useToggleClass';
import MailingAction from '@/reducers/mailing/action';
import { RootState } from '@/reducers/model';
import MailingService from '@/services/mailing';
import { TemplateITemEnum } from '@/utils/enum';
import { replaceDataVariablesInHtml } from '@/utils/format';
import { calcResolutionSize, getListingImageUrl, getListVarsOnFallbackForm, isMLSVariable } from '@/utils/utils';
import { useShallow } from 'zustand/react/shallow';
import { PreviewModalBaseStyle, PreviewModalTemplateContentStyled } from '..';
import { fallbackPrefixes } from '../../modal-fallbacks/hooks/useFallbackPostgridFormStore';
import { FallbackTypeEnum, landing_page_var_key } from '../../modal-fallbacks/types';
import { AddressesSearchCampaignParams } from '@/models';
import useConvertPostgridFallback from './hooks/useConvertPostgridFallback';
import usePagination from './hooks/usePagination';
import useSaveFallbackToMailing from './hooks/useSaveFallbackToMailing';
import SetFallbackPostgridForm from '../../modal-fallbacks/SetFallbackPostgridForm';
import AppButtonV2 from '@/components/AppButtonV2';
import { color } from '@/styles/theme';

interface ModalPreviewTemplateWithMailingListProps {
  video?: string;
  visible: boolean;
  className?: string;
  templatePreviewContentData: any;
  rawTemplateFallback: UserPostGridTemplateFallback;
  addressesFoundCountForCampaign: {
    count: number;
    updatedCount: number;
    params: AddressesSearchCampaignParams;
    mailings: MailingExtra[];
  };
  onCancel: () => void;
}

const PAGE_SIZE = 20;
const ModalPreviewTemplateWithMailingList: FC<ModalPreviewTemplateWithMailingListProps> = ({
  visible,
  templatePreviewContentData,
  onCancel,
  className,
  addressesFoundCountForCampaign,
  rawTemplateFallback,
  ...rest
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const type = Object.keys(TemplateITemEnum).includes(
    templatePreviewContentData?.type || templatePreviewContentData?.digital_ad_type,
  )
    ? TemplateITemEnum[templatePreviewContentData?.type || templatePreviewContentData?.digital_ad_type]
    : TemplateITemEnum.POSTCARD;

  const dispatch = useDispatch();
  useToggleClass(visible);

  const [previewThumbnailSide, setPreviewThumbnailSide] = useState<TemplateViewSide>(
    templatePreviewContentData?.side?.toString() || TemplateViewSide.FRONT,
  );

  const {
    isLoading,
    homeAddresses,
    paginatedData: paginatedAddresses,
    currentPage,
    totalPages,
    setCurrentPage,
  } = usePagination({
    data: addressesFoundCountForCampaign.mailings,
    pageSize: PAGE_SIZE,
    rawTemplateFallback,
    templatePreviewContentData,
  });

  const renderPreviewData = (
    data: PostGridTemplate | DigitalCampaignTemplate,
    mailing: MailingExtra,
    side?: TemplateViewSide,
  ) => {
    if (!data || !mailing.fallback) return '';

    if (
      (side === TemplateViewSide.FRONT && !(data as PostGridTemplate).front_template) ||
      (side === TemplateViewSide.BACK && !(data as PostGridTemplate).back_template)
    )
      return `<div class="no-template-img"><img src="${FileImageOutLinedSvg}" alt="preview-template" class="no-template-img" /></div>`;

    // Handle for Postgrid case - PHYSICAL MAIL CAMPAIGN
    const fallbackVariables = mailing.fallback || {};
    const htmlTemplateStringBySide =
      side === TemplateViewSide.FRONT
        ? (data as PostGridTemplate).front_template
        : side === TemplateViewSide.BACK
        ? (data as PostGridTemplate).back_template
        : '';
    const htmlString = convertPostgridHtmlToRenderDOM(htmlTemplateStringBySide, {
      id: mailing.id,
      mailing,
      isPreviewRawTemplate: false,
      agentImage: fallbackVariables.agent_image,
      agentLogo: fallbackVariables.agent_logo,
      brokerLogo: fallbackVariables.broker_logo,
      listingImage1: getListingImageUrl(fallbackVariables?.['listing_image'], fallbackVariables?.['listing_image_1']),
      listingImage2: getListingImageUrl(fallbackVariables?.['listing_image'], fallbackVariables?.['listing_image_2']),
      listingImage3: getListingImageUrl(fallbackVariables?.['listing_image'], fallbackVariables?.['listing_image_3']),
      listingImage4: getListingImageUrl(fallbackVariables?.['listing_image'], fallbackVariables?.['listing_image_4']),
      listingImage5: getListingImageUrl(fallbackVariables?.['listing_image'], fallbackVariables?.['listing_image_5']),
    });
    return replaceDataVariablesInHtml(htmlString, {
      ...fallbackVariables,
      [landing_page_var_key]: (data as any)?.landing_page_name,
    });
  };

  const getAddressHome = (mailing: MailingExtra) => {
    if (mailing.is_send_owner_address) {
      return (
        <>
          {mailing?.owner_address ? `${mailing?.owner_address}, ` : null}
          {mailing?.owner_city ? `${mailing?.owner_city}, ` : null}
          {mailing?.owner_state ? `${mailing?.owner_state} ` : null}
          {mailing?.owner_zip_code ? `${mailing?.owner_zip_code}` : null}
          {!mailing?.owner_address && !mailing?.owner_city && !mailing?.owner_state && !mailing?.owner_zip_code
            ? 'Owner Address: N/A'
            : null}
        </>
      );
    }
    return (
      <>
        {mailing?.address ? `${mailing?.address}, ` : null}
        {mailing?.city ? `${mailing?.city}, ` : null}
        {mailing?.state ? `${mailing?.state} ` : null}
        {mailing?.zip_code ? `${mailing?.zip_code}` : null}
        {!mailing?.address && !mailing?.city && !mailing?.state && !mailing?.zip_code ? 'Address: N/A' : null}
      </>
    );
  };

  const handleOnChangeFallbackValue = debounce((fallbackFormId: string, fallbackValue = {}) => {
    const addressesFoundCountForCampaignFallback = reduxStore.getState().mailing.addressesFoundCountForCampaignFallback;
    if (!addressesFoundCountForCampaignFallback) return;
    const temp = cloneDeep(addressesFoundCountForCampaignFallback);
    const idx = temp?.findIndex((v) => v.id === fallbackFormId);

    if (idx >= 0) {
      const newDataFallback = { ...(temp?.[idx]?.fallback || {}), ...fallbackValue };
      temp?.splice(idx, 1, {
        ...temp[idx],
        fallback: newDataFallback,
      });
      dispatch({
        type: MailingAction.SET_ADDRESSES_COUNT_CAMPAIGN_NOT_RENDER,
        payload: temp,
      });
      dispatch({
        type: MailingAction.SET_ADDRESSES_COUNT_CAMPAIGN,
        payload: {
          ...addressesFoundCountForCampaign,
          mailings: temp,
        },
      });
    }
  }, 500);

  const onPrev = () => {
    if (currentPage > 0) setCurrentPage((state) => state - 1);
  };

  const onNext = () => {
    if (currentPage < totalPages - 1) setCurrentPage((state) => state + 1);
  };

  if (!templatePreviewContentData) return null;

  return (
    <PreviewModalBaseStyle
      visible={visible && templatePreviewContentData}
      width={[TemplateITemEnum.DIGITAL_AD_VIDEO, TemplateITemEnum.POSTCARD].includes(type) ? 1000 : ''}
      {...rest}
      footer={false}
      destroyOnClose={true}
      centered
      closable={false}
      onCancel={() => {
        setCurrentPage(0);
        onCancel && onCancel();
      }}
      getContainer={false}
      className={className}
      wrapClassName={'preview-pagination-modal'}
    >
      {/* <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> */}
      <div ref={modalRef} className="preview-modal-wrapper">
        <NavigationStyle
          style={{
            display: modalRef.current && homeAddresses && homeAddresses.length > PAGE_SIZE ? 'block' : 'none',
            width: modalRef.current ? modalRef.current.offsetWidth : 0,
          }}
        >
          <AppButtonV2 className="prev nav-btn" disabled={isLoading || currentPage === 0} onClick={onPrev}>
            {isLoading ? <InnerLoading iconStyle={{ color: '#fff' }} /> : <FaArrowLeft />}
          </AppButtonV2>
          <AppButtonV2 className="next nav-btn" disabled={isLoading || currentPage === totalPages - 1} onClick={onNext}>
            {isLoading ? <InnerLoading iconStyle={{ color: '#fff' }} /> : <FaArrowRight />}
          </AppButtonV2>
        </NavigationStyle>

        {paginatedAddresses?.map((item, index) => (
          <PreviewOnHomeItem
            key={item.id}
            isFirst={[0, 1].includes(index)}
            item={item}
            type={type}
            address={getAddressHome(item)}
            htmlStringConverted={renderPreviewData(templatePreviewContentData, item, previewThumbnailSide)}
            previewThumbnailSide={previewThumbnailSide}
            handleOnChangeFallbackValue={handleOnChangeFallbackValue}
            setPreviewThumbnailSide={setPreviewThumbnailSide}
            showForce
            templateHtmlString={
              ((templatePreviewContentData as PostGridTemplate)?.front_template || '') +
              ((templatePreviewContentData as PostGridTemplate)?.back_template || '')
            }
            templateFallback={rawTemplateFallback}
            isUseSaveFallbackToMailing
            postCardSize={templatePreviewContentData?.postcard_size}
            globalDataKeys={item.globalDataKeys}
          />
        ))}

        {homeAddresses && homeAddresses.length > PAGE_SIZE && (
          <PaginationStyle
            current={currentPage + 1}
            onChange={(page) => setCurrentPage(page - 1)}
            total={homeAddresses.length}
            pageSize={PAGE_SIZE}
            showTitle={false}
            disabled={isLoading}
          />
        )}
      </div>
    </PreviewModalBaseStyle>
  );
};

interface PreviewOnHomeItemProps {
  item?: MailingExtra;
  isFirst?: boolean;
  type?: TemplateITemEnum;
  address?: ReactNode;
  htmlStringConverted?: string;
  previewThumbnailSide: TemplateViewSide;
  handleOnChangeFallbackValue?: (fallbackFormId: string, fallbackValue: any) => void;
  setPreviewThumbnailSide?: (data: TemplateViewSide) => void;
  showForce?: boolean;
  isUseFallbackButton?: boolean;
  alwaysShow?: boolean;
  className?: string;
  templateHtmlString?: string;
  templateFallback: UserPostGridTemplateFallback;
  isUseSaveFallbackToMailing?: boolean;
  postCardSize?: string;
  templateData?: IHandWritingTemplateResponse | any;
  globalDataKeys?: string[];
}
export const PREFIX_FALLBACK = 'preview_address_with_mailing_list_';

export const PreviewOnHomeItem = React.memo<PreviewOnHomeItemProps>(
  ({
    item,
    isFirst,
    type,
    address,
    htmlStringConverted,
    previewThumbnailSide,
    handleOnChangeFallbackValue,
    setPreviewThumbnailSide,
    showForce = false,
    isUseFallbackButton = true,
    alwaysShow = false,
    className,
    templateHtmlString = '',
    templateFallback,
    isUseSaveFallbackToMailing = false,
    postCardSize = '',
    templateData,
    globalDataKeys,
  }) => {
    const dispatch = useDispatch();
    const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.05, rootMargin: '0px 0px 640px 0px' });
    const [showFallback, setShowFallback] = useState<boolean>(showForce);
    const listAllVariable = useSelector((state: RootState) => state.campaign.listPostgridSessionVar);
    const [variablesOnUI, setVariablesOnUI] = useState<string[]>();

    const mailingFallbackChange = useRef({});

    const { getMailingsWithFilledFallbackVariable } = useConvertPostgridFallback();
    const { agentIdCampaign } = useCampaignManagerStore(
      useShallow((state) => ({
        agentIdCampaign: state.agentIdCampaign,
      })),
    );

    const { handleToSaveFallback, isLoading } = useSaveFallbackToMailing({
      variablesOnUI,
      isUseConfirm: false,
    });

    const onVariableOnUIUpdate = (data: string[]) => {
      setVariablesOnUI(data);
    };

    const isDisableFallbackBtn = !getListVarsOnFallbackForm(
      FallbackTypeEnum.POSTGRID,
      templateHtmlString,
      listAllVariable,
    )?.length;

    const onSaveFallbacks = () => {
      if (!item) return;
      // Update newest values before saving fallback
      item.fallback = mailingFallbackChange.current;

      handleToSaveFallback(
        item,
        {
          async onSuccess() {
            const mailingsResult = await MailingService.listAddressFromMailingIds(1, 1, item.id, agentIdCampaign);
            const mailingWithFallback = await getMailingsWithFilledFallbackVariable(
              templateFallback,
              mailingsResult.results,
              templateFallback.type,
              templateHtmlString,
            );

            const addressesFoundCountForCampaign = reduxStore.getState().mailing.addressesFoundCountForCampaign;
            let updatedMailings = cloneDeep(addressesFoundCountForCampaign?.mailings) || [];

            const mlsVarsFallbacks = mailingWithFallback?.[0].fallback
              ? Object.fromEntries(
                  Object.entries(mailingWithFallback[0].fallback).filter(([key]) => isMLSVariable(key)),
                )
              : {};

            updatedMailings = updatedMailings.map((updatedMailing) => ({
              ...(updatedMailing.id === item.id ? mailingWithFallback?.[0] : updatedMailing),
              fallback: Object.assign(
                {},
                updatedMailing.id === item.id ? mailingWithFallback?.[0].fallback : updatedMailing?.fallback,
                mlsVarsFallbacks,
              ),
            }));
            dispatch({
              type: MailingAction.SET_ADDRESSES_COUNT_CAMPAIGN,
              payload: {
                ...addressesFoundCountForCampaign,
                mailings: updatedMailings,
              },
            });
          },
        },
        templateFallback.mls_data,
        templateFallback.mailing_list_data,
      );
    };

    return (
      <PreviewModalTemplateContentStyled
        className={className}
        ref={ref}
        type={type}
        size={calcResolutionSize(postCardSize, type)}
      >
        <p className="address">{address}</p>
        <RenderHTML
          className="preview-content"
          id={'preview'}
          htmlString={
            (isFirst || inView) && htmlStringConverted ? htmlStringConverted : '<div class="skeleton skeleton-loader"/>'
          }
        />
        {(isFirst || inView) && (
          <div className="footer-btn">
            {type === TemplateITemEnum.POSTCARD ? (
              <div className="preview-btn">
                <AppButtonV2
                  width={72}
                  size="small"
                  color={previewThumbnailSide === TemplateViewSide.FRONT ? 'Pin' : 'Outline'}
                  outlineColor={color.PINK}
                  uppercase={false}
                  onClick={() => setPreviewThumbnailSide && setPreviewThumbnailSide(TemplateViewSide.FRONT)}
                >
                  Front
                </AppButtonV2>
                <AppButtonV2
                  width={72}
                  size="small"
                  color={previewThumbnailSide === TemplateViewSide.BACK ? 'Pin' : 'Outline'}
                  outlineColor={color.PINK}
                  uppercase={false}
                  onClick={() => setPreviewThumbnailSide && setPreviewThumbnailSide(TemplateViewSide.BACK)}
                >
                  Back
                </AppButtonV2>
              </div>
            ) : type === TemplateITemEnum.HANDWRITTEN ? (
              <div className="preview-btn">
                <AppButtonV2
                  width={105}
                  size="small"
                  color={previewThumbnailSide === TemplateViewSide.FRONT ? 'Pin' : 'Outline'}
                  outlineColor={color.PINK}
                  uppercase={false}
                  onClick={() => setPreviewThumbnailSide?.(TemplateViewSide.FRONT)}
                >
                  Card Front
                </AppButtonV2>
                {templateData?.card_type === CardTypeEnum.Fold && (
                  <AppButtonV2
                    width={105}
                    size="small"
                    color={previewThumbnailSide === TemplateViewSide.INSIDE ? 'Pin' : 'Outline'}
                    outlineColor={color.PINK}
                    uppercase={false}
                    onClick={() => setPreviewThumbnailSide?.(TemplateViewSide.INSIDE)}
                  >
                    Card Inside
                  </AppButtonV2>
                )}

                <AppButtonV2
                  width={105}
                  size="small"
                  color={previewThumbnailSide === TemplateViewSide.BACK ? 'Pin' : 'Outline'}
                  outlineColor={color.PINK}
                  uppercase={false}
                  onClick={() => setPreviewThumbnailSide?.(TemplateViewSide.BACK)}
                  className={classNames(`${previewThumbnailSide === TemplateViewSide.BACK ? 'active' : ''}`, {})}
                >
                  Card Back
                </AppButtonV2>
              </div>
            ) : (
              <div />
            )}
            <div className="fallback-btn-group">
              <div className="save-fallback-to-mailing">
                {isUseSaveFallbackToMailing && (
                  <AppButtonV2
                    width={100}
                    size="small"
                    color="Pin"
                    uppercase={false}
                    className={classNames('fallback-btn', {
                      'hide-arrow': alwaysShow,
                    })}
                    onClick={onSaveFallbacks}
                    disabled={isDisableFallbackBtn}
                  >
                    {isLoading ? (
                      <InnerLoading iconStyle={{ fontSize: 18, color: '#fff' }} />
                    ) : (
                      <MdOutlineSaveAlt size={18} />
                    )}
                    <span>Save</span>
                  </AppButtonV2>
                )}
              </div>
              {isUseFallbackButton && (
                <div className="fallback">
                  <AppButtonV2
                    width={100}
                    size="small"
                    color="Pin"
                    uppercase={false}
                    className={classNames('fallback-btn', {
                      'hide-arrow': alwaysShow,
                    })}
                    onClick={() => {
                      if (!isUseFallbackButton || alwaysShow) return;
                      setShowFallback((prev) => !prev);
                    }}
                    disabled={isDisableFallbackBtn}
                  >
                    Fallback
                    <MdOutlineArrowDropDown fontSize={24} />
                  </AppButtonV2>
                </div>
              )}
            </div>
          </div>
        )}
        {isUseFallbackButton && (isFirst || inView) && showFallback && (
          <SetFallbackPostgridForm
            onChange={(values) => {
              if (!item || !handleOnChangeFallbackValue) return;
              mailingFallbackChange.current = values;
            }}
            className="postgrid-fallback"
            initialData={item?.fallback}
            extraData={item?.extra_data}
            isDripCampaign={false}
            isSms={false}
            htmlString={templateHtmlString}
            isUseDisclaimerFallback
            onVariableOnUIUpdate={onVariableOnUIUpdate}
            uniqueFallbackFormId={`${fallbackPrefixes.PREVIEW_CAMPAIGN_WITH_MAILING_LIST}${item?.id}`}
            templateId={templateFallback.template}
            templateFallback={item?.fallback}
            globalDataKeys={globalDataKeys}
            mlsData={templateFallback.mls_data}
          />
        )}
      </PreviewModalTemplateContentStyled>
    );
  },
);

export default ModalPreviewTemplateWithMailingList;

const NavigationStyle = styled.div`
  position: fixed;
  top: 50%;
  transform: translateY(-50%);

  .nav-btn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);
    background-color: rgb(255, 0, 221);
    border: none;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    font-size: 16px;
    cursor: pointer;

    &.ant-btn[disabled] {
      color: rgb(255, 255, 255);
      background-color: rgb(255, 0, 221);
      opacity: 0.5;
      cursor: not-allowed;
    }

    &:hover {
      color: rgb(255, 255, 255);
      background-color: rgb(255, 0, 221);
    }

    &.prev {
      right: calc(100% + 33px);
    }

    &.next {
      left: calc(100% + 33px);
    }
  }
`;

const PaginationStyle = styled(Pagination)`
  &.ant-pagination {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.45);
    padding: 16px 22px 16px 30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    z-index: 1;

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-options {
      display: none;
    }

    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      .ant-pagination-item-container {
        .ant-pagination-item-ellipsis {
          color: #fff;
        }

        .ant-pagination-item-link-icon {
          font-size: 16px;
          color: rgb(255, 0, 221);
        }
      }
    }

    .ant-pagination-item {
      width: 38px;
      height: 38px;
      line-height: 38px;
      font-size: 16px;
    }

    .ant-pagination-item-active {
      background: rgb(255, 0, 221);

      a {
        color: #fff;
      }

      &:hover {
        a {
          color: #fff;
        }
      }
    }
  }
`;
