"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeEquityFormat = exports.formatCommas = void 0;
exports.formatCommas = (number) => {
    return number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0;
};
// Define the function to remove equity formatting
exports.removeEquityFormat = (equityValue) => {
    // Remove percentage symbols, normalize decimal and thousand separators, and parse as float
    return parseFloat(equityValue.replace(/[%,]/g, '').replace(/(\.\d*)\./g, '$1'));
};
