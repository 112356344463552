import { apiIns } from '@/config/apiClient';
import {
  CampaignPreviewType,
  UserPostGridTemplateFallback,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/postgrid/models';
import { UserAgentBasic } from '@goldfishcode/first-team-real-estate-sdk/libs/api/user/models';
import { MailingExtra } from '@goldfishcode/first-team-real-estate-sdk/libs/api/tools/variable/models';
import { TEMPLATE_PREVIEW_TYPE } from '@goldfishcode/first-team-real-estate-sdk/libs/api/template_preview/model';

class ToolsService {
  static getFallbackVariableWithoutMailingData(
    agentInfo: UserAgentBasic,
    templateFallback: UserPostGridTemplateFallback,
  ) {
    return apiIns.variableTool.getFallbackVariableWithoutMailingData(agentInfo, templateFallback);
  }
  static getMailingsWithFilledFallbackVariable(
    agentInfo: UserAgentBasic,
    rawTemplateFallback: UserPostGridTemplateFallback,
    mailings: MailingExtra[],
    type?: CampaignPreviewType,
  ) {
    return apiIns.variableTool.getMailingsWithFilledFallbackVariable(
      agentInfo,
      {
        ...rawTemplateFallback,
        isReplaceEmptyValue: true,
      },
      mailings,
      type,
    );
  }
  static extractVariablesFromHtmlString = (html: string): string[] => {
    return apiIns.variableTool.extractVariablesFromHtmlString(html || '');
  };
  static getAllVariablesFromSectionVars = (sectionVars: any): Array<string> => {
    return apiIns.variableTool.getAllVariablesFromSectionVars(sectionVars);
  };
  static removeFormatFallbackVariables = (variables: any) => {
    return apiIns.variableTool.removeFormatFallbackVariables(variables);
  };
  static createTeplatePreview = (
    preview_type: TEMPLATE_PREVIEW_TYPE,
    html: string,
    template_id: string,
    width: number,
    height: number,
  ) => {
    return apiIns.templatePreview.createTeplatePreview(preview_type, html, template_id, width, height);
  };
}
export default ToolsService;
