import AppInputV2 from '@/components/AppInputV2';
import DisclaimerTooltip from '@/components/disclaimer-tooltip';
import ErrorMessage from '@/components/error-message';
import ImageCrop from '@/components/image-crop';
import { acceptImageFileExtension, acceptImageType } from '@/utils/constant';
import { formatObjectKeyToTitle, handleOnChangePhoneNumber } from '@/utils/utils';
import { css } from '@emotion/react';
import { message, Space, Typography, Upload } from 'antd';
import classNames from 'classnames';
import { FormikProps } from 'formik';
import { isEmpty, isEqual } from 'lodash';
import React, { FC, ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PictureWall from '../PictureWall';
import SelectImage from '../SelectImage';
import {
  FallbackTypeEnum,
  FallbackVariableOnUI,
  LISTING_IMAGE_KEY,
  phoneFormatVarKeys,
  VariableOnUITemplate,
} from '../types';
import { ListingImagesVarStyled, SetFallbackPostgridFormStyled } from './SetFallbackPostgridFormStyled';
const { Title } = Typography;

interface FallbackFormProps extends VariableOnUITemplate {
  className?: string;
  templateFallback?: any;
  extraData?: any;
  isDripCampaign?: boolean;
  type?: FallbackTypeEnum;
  isUseDisclaimerFallback?: boolean;
  isSms?: boolean;
  globalDataKeys?: string[];
  formik: FormikProps<any>;
  isHideDripFallback: boolean;
  listVariablesOnFallbackForm: string[];
  errFields: string[];
  specialCaseVariableOnUI: string[];
  onPreview: (file: any) => Promise<void>;
  customRequest: (info: any) => void;
  uploadButton: ReactNode;
  handleRemoveImage: (url: any) => void;
  generalError: string | undefined;
}

const SetFallbackPostgridFormView: FC<FallbackFormProps> = React.memo((props) => {
  const {
    className,
    templateFallback = {},
    extraData = {},
    isDripCampaign = false,
    type = FallbackTypeEnum.POSTGRID,
    isUseDisclaimerFallback = false,
    isSms,
    globalDataKeys,
    formik,
    isHideDripFallback,
    listVariablesOnFallbackForm,
    errFields,
    onPreview,
    customRequest,
    uploadButton,
    handleRemoveImage,
    specialCaseVariableOnUI,
    generalError,
  } = props;
  const formikValues = formik?.values || {};

  return (
    <SetFallbackPostgridFormStyled
      onSubmit={formik.handleSubmit}
      className={classNames(className, {
        'hide-form': isHideDripFallback,
      })}
    >
      <div
        className={classNames('form-wrapper fallback-form', {
          'drip-postgrid-fallback': isDripCampaign && type !== FallbackTypeEnum.EMAIL,
          'drip-email-fallback': type === FallbackTypeEnum.EMAIL,
        })}
      >
        {!isHideDripFallback && isDripCampaign && type !== FallbackTypeEnum.EMAIL && (
          <Title className="title">Setup Default Variables</Title>
        )}

        {listVariablesOnFallbackForm ? (
          <Space className="w-full" direction="vertical" size={16}>
            {listVariablesOnFallbackForm
              .map((v) => ({ value: v, label: formatObjectKeyToTitle(v) } as FallbackVariableOnUI))
              ?.map((v, i) => {
                // Check disclaimer vars

                const hasDisclaimer = isUseDisclaimerFallback
                  ? templateFallback?.[v.value] === formikValues[v.value] &&
                    (globalDataKeys?.includes(v.value) || !!extraData?.[v?.value])
                  : false;

                const isEqualGlobalData = globalDataKeys?.includes(v.value);

                return (
                  <div
                    key={i}
                    className={classNames('form-item', {
                      'error-msg': errFields?.includes(v.value),
                    })}
                    css={css`
                      &.error-msg {
                        .ant-input-affix-wrapper {
                          border-width: 2px;
                        }
                      }
                    `}
                  >
                    <AppInputV2
                      className={'field name-field'}
                      label={v.label}
                      labelChildren={
                        !!hasDisclaimer && (
                          <DisclaimerTooltip
                            title={`Note: This is a substituted value from the ${
                              isEqualGlobalData ? 'global' : 'mailing'
                            } fallback.`}
                          />
                        )
                      }
                      name={v.value}
                      placeholder={`Enter Fallback For ${v.label}`}
                      onChange={(e) => {
                        if (phoneFormatVarKeys.includes(v.value)) {
                          handleOnChangePhoneNumber(e, formik.setFieldValue, 'formik', v.value);
                        } else formik.setValues(Object.assign({}, { ...formikValues, [v.value]: e.target.value }));
                      }}
                      onBlur={() => formik.setFieldTouched(v.value, true)}
                      onFocus={() => formik.setFieldTouched(v.value, false)}
                      value={formikValues?.[v.value]}
                      disabled={formik.isSubmitting}
                      allowClear
                      hasError={errFields?.includes(v.value)}
                    />
                  </div>
                );
              })}
          </Space>
        ) : null}

        {!isSms && specialCaseVariableOnUI?.length > 0 && (
          <div className="listing-image">
            {specialCaseVariableOnUI.some((v) => v.includes(LISTING_IMAGE_KEY)) && (
              <section id="contain-listing_image-vars">
                <p className="s-label">Listing Image</p>
                <div className="upload-wrapper">
                  <ImageCrop
                    aspect={1 / 1}
                    showGrid
                    modalTitle="Edit Listing Image"
                    beforeCrop={(file) => {
                      const getFileExtension = (file: any) =>
                        file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
                      const isPNG =
                        acceptImageType.includes(file.type) &&
                        acceptImageFileExtension.includes(getFileExtension(file));
                      if (!isPNG) {
                        message.error({
                          content: `${file.name} is not valid. Supported extension file:  jpg | png | jpeg`,
                          className: 'error-msg',
                          style: {
                            display: 'flex',
                            justifyContent: 'flex-end',
                          },
                        });
                      }
                      return isPNG; // || Upload.LIST_IGNORE;
                    }}
                  >
                    <Upload
                      name="logo"
                      listType="picture-card"
                      accept="image/png, image/jpeg, image/jpg"
                      onPreview={onPreview}
                      customRequest={(info) => customRequest(info)}
                      showUploadList={false}
                    >
                      {uploadButton}
                    </Upload>
                  </ImageCrop>
                  <PictureWall
                    listPicture={formikValues?.[LISTING_IMAGE_KEY] || []}
                    handleRemoveImage={handleRemoveImage}
                  />
                </div>
              </section>
            )}

            <section id="special-vars">
              {specialCaseVariableOnUI
                ?.filter((v) => v !== LISTING_IMAGE_KEY)
                ?.sort()
                ?.map((v) => {
                  // Listing Images Vars
                  const templateFallbackValue =
                    typeof templateFallback?.[v] === 'object' ? templateFallback?.[v]?.url : templateFallback?.[v];

                  const existedImage = formikValues?.[LISTING_IMAGE_KEY]?.includes(formikValues?.[v]);

                  const hasDisclaimer = isUseDisclaimerFallback
                    ? templateFallbackValue === formikValues?.[v] &&
                      !!formikValues[v]?.length &&
                      existedImage &&
                      (globalDataKeys?.includes(v) || !!extraData?.[v])
                    : false;

                  const isEqualGlobalData = globalDataKeys?.includes(v);

                  const uuid = uuidv4();

                  return (
                    <ListingImagesVarStyled
                      isPostGrid={isDripCampaign && type === FallbackTypeEnum.POSTGRID}
                      key={v}
                      id={uuid}
                    >
                      <SelectImage
                        className={`image-field  ${isDripCampaign && type === FallbackTypeEnum.POSTGRID ? 'drip' : ''}`}
                        label={formatObjectKeyToTitle(v)}
                        placeholder="Select Image"
                        idParent={uuid}
                        itemSelect={isEmpty(formikValues?.[LISTING_IMAGE_KEY]) ? [] : formikValues?.[LISTING_IMAGE_KEY]}
                        handleChange={(value: string) => {
                          if (value === formikValues?.[v]) {
                            formik.setFieldValue(v, '');
                          } else {
                            formik.setFieldValue(v, value);
                          }
                        }}
                        disclaimer={
                          !!hasDisclaimer && (
                            <DisclaimerTooltip
                              title={`Note: This is a substituted value from the ${
                                isEqualGlobalData ? 'global' : 'mailing'
                              } fallback.`}
                            />
                          )
                        }
                        handleBlur={formik.handleBlur}
                        value={formikValues[v]}
                        isGetContainer
                        disabled={formik.isSubmitting}
                        selectedValue={specialCaseVariableOnUI.map((v) => {
                          if (formikValues[v]) return formikValues[v];
                        })}
                      />
                    </ListingImagesVarStyled>
                  );
                })}
            </section>
          </div>
        )}
        {generalError && <ErrorMessage>{generalError}</ErrorMessage>}
      </div>
    </SetFallbackPostgridFormStyled>
  );
}, isEqual);

export default SetFallbackPostgridFormView;
