import { MailingList } from '@goldfishcode/first-team-real-estate-sdk/libs/api/mailing/models';
import { Pagination } from '@goldfishcode/first-team-real-estate-sdk/libs/type';

export const updateMailingListState = (
  mailingList: MailingList,
  mailingListState: Pagination<MailingList>,
): Pagination<MailingList> => {
  if (!mailingListState.results.length) return mailingListState;

  const itemJustUpdateIdx = mailingListState.results.findIndex((item) => item.id === mailingList.id);

  if (itemJustUpdateIdx < 0) return mailingListState;

  const items = [...mailingListState.results];

  const { id, ...data } = mailingList;

  items.splice(itemJustUpdateIdx, 1, {
    id,
    ...data,
  });

  return {
    ...mailingListState,
    results: items,
  };
};

export const isObjectEqual = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
      return false;
    }
  }

  return true;
};
