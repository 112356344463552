import AppButtonV2 from '@/components/AppButtonV2';
import { TemplateViewSide } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postgrid/models';
import React, { useState } from 'react';
import PostgridCreatorStyled from './PostgridCreatorStyled';
import { color } from '@/styles/theme';
import { CreateMailPreviewData } from '@/models';

interface PostgridCreatorViewProps {
  createLetterData: CreateMailPreviewData;
}

const PostgridCreatorView: React.FC<PostgridCreatorViewProps> = ({ createLetterData }) => {
  const [viewSide, setViewSide] = useState(TemplateViewSide.FRONT);
  const isPostcard = !!createLetterData.back_url && !!createLetterData.front_url;
  return !createLetterData.front_url && !createLetterData.back_url ? (
    <PostgridCreatorStyled.Empty>No URL Data</PostgridCreatorStyled.Empty>
  ) : (
    <PostgridCreatorStyled.Container className="postgrid-creator">
      <div className={'template-editor'}>
        <div className="title-side">
          {isPostcard && (
            <p style={{ marginLeft: 16 }}>{viewSide === TemplateViewSide.FRONT ? 'Front Template' : 'Back Template'}</p>
          )}
          <div className="side-btn">
            {isPostcard && (
              <AppButtonV2
                color={viewSide === TemplateViewSide.FRONT ? 'Pin' : 'Outline'}
                outlineColor={color.PINK}
                size="small"
                width={70}
                uppercase={false}
                onClick={() => setViewSide(TemplateViewSide.FRONT)}
              >
                Front
              </AppButtonV2>
            )}
            {isPostcard && (
              <AppButtonV2
                color={viewSide === TemplateViewSide.BACK ? 'Pin' : 'Outline'}
                outlineColor={color.PINK}
                size="small"
                width={70}
                uppercase={false}
                onClick={() => setViewSide(TemplateViewSide.BACK)}
              >
                Back
              </AppButtonV2>
            )}
          </div>
        </div>
        {createLetterData.front_url ? (
          <PostgridCreatorStyled.Iframe
            isPostcard={isPostcard}
            style={{
              zIndex: viewSide === TemplateViewSide.FRONT ? 0 : -10,
            }}
            src={createLetterData.front_url + '?hideChat=true'}
            title="Create A Template Front"
            width="100%"
            frameBorder="0"
          />
        ) : null}
        {createLetterData.back_url ? (
          <PostgridCreatorStyled.Iframe
            isPostcard={isPostcard}
            style={{
              zIndex: viewSide === TemplateViewSide.BACK ? 0 : -10,
            }}
            src={createLetterData.back_url + '?hideChat=true'}
            title="Create A Template Back"
            width="100%"
            frameBorder="0"
          />
        ) : null}
      </div>

      <p className="mr-10"></p>
    </PostgridCreatorStyled.Container>
  );
};

export default PostgridCreatorView;
