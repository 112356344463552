import { color } from '@/styles/theme';
import { css } from '@emotion/react';
import classNames from 'classnames';
import { CSSProperties, memo } from 'react';

type ActionsProps<T extends React.ElementType> = {
  component?: T;
  size?: number;
  padding?: CSSProperties['padding'];
  className?: HTMLElement['className'];
  children: React.ReactNode;
  gap?: number | boolean;
} & React.ComponentProps<T>;

const ActionView = <T extends React.ElementType = 'div'>({
  children,
  size,
  className,
  component: Component = 'div',
  padding,
  gap,
  ...rest
}: ActionsProps<T>) => {
  return (
    <Component
      className={classNames(className, 'actions')}
      css={css`
        background: ${color.DARKBLUE};
        padding: ${padding ?? '35px'};
        border-radius: 10px;
        width: ${size ? `${size}px` : undefined};
        min-width: ${size ? `${size}px` : undefined};
        margin: 0 auto;
        ${gap
          ? `
          gap: ${gap === true ? 16 : gap}px;
          display: flex;
          flex-direction: column
        `
          : undefined}
      `}
      {...rest}
    >
      {children}
    </Component>
  );
};

export default memo(ActionView) as typeof ActionView;
