import styled from '@emotion/styled';

const PostgridCreatorStyled = {
  Container: styled.div``,
  Empty: styled.div`
    height: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Iframe: styled.iframe<{ isPostcard?: boolean }>`
    position: absolute;
    top: ${(props) => (props.isPostcard ? '46px' : '0px')};
    left: 16px;
  `,
};

export default PostgridCreatorStyled;
