"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.JwtAuth = void 0;
const adapter_1 = require("./adapter");
const string_1 = require("../../../utils/string");
class JwtAuth extends adapter_1.AbstractAuthAdapter {
    login(username, password) {
        return __awaiter(this, void 0, void 0, function* () {
            const data = { email: username, password };
            const r = yield this.client.post('/token/', data, {}, false);
            yield this.setAuthToken(r);
            return r;
        });
    }
    getAuthToken() {
        return __awaiter(this, void 0, void 0, function* () {
            const config = this.client.getApiConfig();
            if (!config.session || !config.authSessionKey)
                return {
                    token_type: 'Bearer',
                    access_token: config.accessTokenAdmin || '',
                    refresh_token: '',
                    expires_in: new Date().valueOf(),
                };
            const jwtJson = yield config.session.get(config.authSessionKey);
            const jtwToken = string_1.tryParseJson(jwtJson);
            if (!jtwToken || !jtwToken.access) {
                return null;
            }
            return {
                token_type: 'Bearer',
                access_token: jtwToken.access,
                refresh_token: jtwToken.refresh,
                expires_in: jtwToken.expires_in,
            };
        });
    }
    refreshToken() {
        return __awaiter(this, void 0, void 0, function* () {
            const token = yield this.getAuthToken();
            if (!token) {
                return;
            }
            const refresh = token.refresh_token;
            const data = { refresh };
            const r = yield this.client.post('/token/refresh/', data, {}, false);
            yield this.setAuthToken(r);
            return r;
        });
    }
    loginAsAdmin(uid) {
        return __awaiter(this, void 0, void 0, function* () {
            const r = yield this.client.post(`/token/login-as/`, { uid }, {}, false);
            yield this.setAuthToken(r);
            return r;
        });
    }
    facebookLogin(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = `/social-auth/facebook/`;
            const r = yield this.client.post(uri, data, {}, false);
            yield this.setAuthToken(r);
            return r;
        });
    }
    connectFacebook(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = `/social-auth/facebook/connect/`;
            return this.client.post(uri, data);
        });
    }
    googleLogin(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = `/social-auth/google/`;
            const r = yield this.client.post(uri, params, {}, false);
            yield this.setAuthToken(r);
            return r;
        });
    }
    twitterLogin(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = `/social-auth/twitter/`;
            const r = yield this.client.post(uri, params, {}, false);
            yield this.setAuthToken(r);
            return r;
        });
    }
    twitterAuthorization() {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = `/social-auth/twitter/authorization/`;
            const r = yield this.client.get(uri);
            return r.url;
        });
    }
    linkedInLogin(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = `/social-auth/linkedin/`;
            const r = yield this.client.post(uri, params, {}, false);
            yield this.setAuthToken(r);
            return r;
        });
    }
    appleLogin(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = `/social-auth/apple/`;
            const r = yield this.client.post(uri, params, {}, false);
            yield this.setAuthToken(r);
            return r;
        });
    }
}
exports.JwtAuth = JwtAuth;
