import { HandwrittenTemplateCustom } from '@/components/dashboard/campaign/ScrollTemplate/hooks/useScrollTemplate';
import { DigitalUploadType, PostgridEditorActionType } from '@/utils/enum';
import { generateDefaultImageForVariables } from '@/utils/utils';
import { CardTypeEnum } from '@goldfishcode/first-team-real-estate-sdk/libs/api/handwriting/models';
import { MailingExtra } from '@goldfishcode/first-team-real-estate-sdk/libs/api/tools/variable/models';
import { FormikErrors } from 'formik';
import _ from 'lodash';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import useCreateHandwritten, { HandwrittenCreatingType } from '../hooks/useCreateHandwritten';
import HandwrittenCreatorView from './HandwrittenCreatorView';

interface HandwrittenCreatorProps {
  templateData?: HandwrittenTemplateCustom;
  onHandwrittenDataChange: (data: HandwrittenCreatingType) => void;
  actionType?: PostgridEditorActionType;
  ref: any;
  isLoading?: boolean;
  mailing?: MailingExtra;
  className?: string;
}

enum CardSideEnum {
  CARD_FRONT = 'CARD_FRONT',
  CARD_INSIDE = 'CARD_INSIDE',
  CARD_BACK = 'CARD_BACK',
}

export interface HandwrittenCreatorViewContainerRef {
  handleClickMessage: (value: string, isImageVariable: any) => Promise<void>;
  validateForm: () => Promise<FormikErrors<HandwrittenCreatingType>>;
}

const HandwrittenCreatorViewContainer = forwardRef<HandwrittenCreatorViewContainerRef, HandwrittenCreatorProps>(
  (props, ref) => {
    const { mailing, templateData, onHandwrittenDataChange, actionType, isLoading, className } = props;

    const hasInitializeData = useRef(false);

    const formik = useCreateHandwritten();
    const inputRef = useRef<any>();
    const isDisabledHeader = formik.values.cardType === CardTypeEnum.Fold;
    const isDisabledFrontImage = formik.values.isUseSVImgAsFront;
    const isDisabledBackImage = formik.values.cardType === CardTypeEnum.Flat || formik.values.isUseSVImgAsBack;
    const [cardSide, setCardSide] = useState<CardSideEnum>(CardSideEnum.CARD_FRONT);

    const isUseSVImgFront =
      Boolean(mailing?.street_view_image) && mailing?.street_view_image === templateData?.front_image;
    const isUseSVImgBack =
      Boolean(mailing?.street_view_image) && mailing?.street_view_image === templateData?.back_image;
    useEffect(() => {
      if (!onHandwrittenDataChange || !formik.values) return;
      onHandwrittenDataChange({
        ...formik.values,
      });
    }, [formik.values]);
    useEffect(() => {
      if (hasInitializeData.current || !templateData) return;
      const frontImageNameSequences = templateData?.front_image?.split('/');
      const backImageNameSequences = templateData?.back_image?.split('/');
      const headImageNameSequences = templateData?.header_image?.split('/');
      const headerTextObject = JSON.parse(templateData?.header_text || '{}');

      formik.setValues({
        templateName: actionType === PostgridEditorActionType.HANDWRITTEN_UPDATE ? templateData.name : '',
        signature: templateData.signature,
        landingPage: templateData.landing_page,
        fontStyle: templateData.font,
        cardType: templateData.card_type,
        frontImage: templateData.front_image
          ? isUseSVImgFront
            ? {
                selectedFileData: {
                  url: templateData.front_image,
                },
              }
            : {
                selectedFileData: {
                  url: templateData.front_image,
                },
                selectedFileName: frontImageNameSequences?.[frontImageNameSequences?.length - 1],
                file_type: DigitalUploadType.IMAGE,
              }
          : null,
        backImage: templateData.back_image
          ? isUseSVImgBack
            ? {
                selectedFileData: {
                  url: templateData.back_image,
                },
              }
            : {
                selectedFileData: {
                  url: templateData.back_image,
                },
                selectedFileName: backImageNameSequences?.[backImageNameSequences?.length - 1],
                file_type: DigitalUploadType.IMAGE,
              }
          : null,
        headerImage: templateData.header_image
          ? {
              selectedFileData: {
                url: templateData.header_image,
              },
              selectedFileName: headImageNameSequences?.[headImageNameSequences?.length - 1],
              file_type: DigitalUploadType.IMAGE,
            }
          : null,
        headerType: templateData?.header_type,
        headerText: headerTextObject || {},
        mailingListData: templateData?.mailing_list_data || {},
        mlsData: templateData?.mls_data || {},
        isUseSVImgAsFront: isUseSVImgFront,
        isUseSVImgAsBack: isUseSVImgBack,
      });

      if (inputRef.current) inputRef.current?.setData(templateData.message || '');
      hasInitializeData.current = true;
    }, [templateData]);

    const onUploadHandwrittenImage = async (file, fieldName) => {
      formik.setFieldError(fieldName, undefined);
      if (!file) {
        formik.setFieldValue(fieldName, file);
        return;
      }
      if (!file?.selectedFileData?.url) {
        const imageUrl = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file?.selectedFile as Blob);
          reader.onload = () => resolve(reader?.result);
        });
        formik.setFieldValue(fieldName, {
          ...file,
          selectedFileData: {
            url: imageUrl as string,
          },
        });
      }
    };

    useImperativeHandle(ref, () => ({
      handleClickMessage: async (value: string, isImageVariable) => {
        if (!inputRef?.current) return;
        let messageContent = `{{${value}}}`;
        if (isImageVariable) {
          const defaultImage = Object.values((await generateDefaultImageForVariables([value])) || {})?.[0];
          messageContent = `<img src="{{${value}}}" srcset="${defaultImage}" style="height: 200px; width: 200px"/>
        `;
        }
        inputRef.current?.insertHtml(`${messageContent}`);
      },
      validateForm: async () => {
        const errorObj = await formik.validateForm();
        return errorObj;
      },
    }));

    const debouncedValidate = _.debounce((editor) => {
      // Assuming 'editor' is your CKEditor instance
      const htmlContent: string = editor.getData();

      // Use a regular expression to strip HTML tags
      const textContent = htmlContent
        .replace(/<[^>]*>/g, '') // Remove HTML tags
        .replace(/&nbsp;/g, ' ')
        .replace('\n', ''); // Break line in SMS

      formik.setFieldValue('htmlMessage', htmlContent);
      formik.setFieldValue('message', textContent);
      formik.setFieldError('message', undefined);
    }, 200);

    const backImageSource = formik.values.isUseSVImgAsBack
      ? mailing?.street_view_image
      : formik.values.backImage?.selectedFileData?.url;
    const frontImageSource = formik.values.isUseSVImgAsFront
      ? mailing?.street_view_image
      : formik.values.frontImage?.selectedFileData?.url;
    const updateInputRef = (editor) => {
      inputRef.current = editor;
    };
    return (
      <HandwrittenCreatorView
        cardSide={cardSide}
        debouncedValidate={debouncedValidate}
        backImageSource={backImageSource}
        frontImageSource={frontImageSource}
        isDisabledBackImage={isDisabledBackImage}
        isDisabledFrontImage={isDisabledFrontImage}
        isDisabledHeader={isDisabledHeader}
        formik={formik}
        inputRef={inputRef}
        updateInputRef={updateInputRef}
        onUploadHandwrittenImage={onUploadHandwrittenImage}
        setCardSide={setCardSide}
        isLoading={isLoading}
        className={className}
        isUseSVImgBack={isUseSVImgBack}
        isUseSVImgFront={isUseSVImgFront}
        templateData={templateData}
        mailing={mailing}
      />
    );
  },
);

export default HandwrittenCreatorViewContainer;
