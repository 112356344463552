import { css } from '@emotion/react';
import React, { memo, useLayoutEffect, useRef, useState } from 'react';
import { AppLabelStyled, AppLabelStyledProps } from './AppLabelStyled';
import { color as themeColor } from '@/styles/theme';
export interface AppLabelProps extends AppLabelStyledProps, React.ComponentProps<'label'> {
  name?: string;
  label?: React.ReactNode;
  requiredMark?: boolean;
}

const LabelView = memo(({ name, label, requiredMark, children, labelColor, ...rest }: AppLabelProps) => {
  const labelRef = useRef<HTMLLabelElement>(null);
  const [color, setColor] = useState<AppLabelStyledProps['labelColor']>(labelColor);

  // With label in modal color is white and darkblue otherwise
  useLayoutEffect(() => {
    if (labelColor) return;
    const isInModal = labelRef.current?.closest('.ant-modal-content');
    setColor(isInModal ? 'WHITE' : 'DARKBLUE');
  }, [labelColor]);

  return label || children ? (
    <AppLabelStyled htmlFor={name} ref={labelRef} labelColor={color} {...rest}>
      {label}
      {requiredMark && (
        <span
          css={css`
            color: ${themeColor.PINK};
          `}
        >
          {requiredMark ? '*' : ''}
        </span>
      )}

      {children}
    </AppLabelStyled>
  ) : null;
});

export default LabelView;
