"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbstractAuthAdapter = void 0;
class AbstractAuthAdapter {
    constructor(client) {
        this.client = client;
    }
    loginAsAdmin(uid) {
        return __awaiter(this, void 0, void 0, function* () {
            const r = yield this.client.post(`/token/login-as/`, { uid }, {}, false);
            yield this.setAuthToken(r);
            return r;
        });
    }
    getAuthHeader() {
        return __awaiter(this, void 0, void 0, function* () {
            const authToken = yield this.getAuthToken();
            if (!authToken || !authToken.access_token) {
                return '';
            }
            if (authToken.token_type) {
                return `${authToken.token_type} ${authToken.access_token}`;
            }
            return authToken.access_token;
        });
    }
    logout() {
        return __awaiter(this, void 0, void 0, function* () {
            const config = this.client.getApiConfig();
            const sessionStorage = config.session;
            if (sessionStorage && config.authSessionKey)
                yield sessionStorage.remove(config.authSessionKey);
        });
    }
    setAuthToken(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const config = this.client.getApiConfig();
            const sessionStorage = config.session;
            if (sessionStorage && config.authSessionKey)
                yield sessionStorage.set(config.authSessionKey, JSON.stringify(data));
        });
    }
    isAuthenticated() {
        return __awaiter(this, void 0, void 0, function* () {
            const token = yield this.getAuthToken();
            if (token) {
                return true;
            }
            return false;
        });
    }
    getAuthorizationHeaders() {
        return __awaiter(this, void 0, void 0, function* () {
            const authHeader = yield this.getAuthHeader();
            const headers = {
                Authorization: authHeader,
            };
            return headers;
        });
    }
    changePassword(oldPassword, newPassword) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = `/auth/password/change/`;
            const params = {
                old_password: oldPassword,
                new_password1: newPassword,
                new_password2: newPassword,
            };
            return this.client.post(uri, params);
        });
    }
    resetPassword(email) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = `/auth/password/reset/`;
            const params = {
                email: email,
            };
            return this.client.post(uri, params);
        });
    }
    resetPasswordConfirm(newPassword, uid, token) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = `/auth/password/reset/confirm/`;
            const params = {
                new_password1: newPassword,
                new_password2: newPassword,
                uid: uid,
                token: token,
            };
            return this.client.post(uri, params);
        });
    }
    verifyEmail(key) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = `/auth/registration/verify-email/`;
            const params = {
                key: key,
            };
            return this.client.post(uri, params);
        });
    }
    resendEmail(email) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = `/auth/registration/resend-email/`;
            const params = {
                email: email,
            };
            return this.client.post(uri, params);
        });
    }
    register(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const r = yield this.client.post('/auth/registration/', params, {}, false);
            yield this.setAuthToken(r);
            return r;
        });
    }
}
exports.AbstractAuthAdapter = AbstractAuthAdapter;
