import { ForkEffect, put, takeLatest } from 'redux-saga/effects';
import CreditService from '@/services/credit';
import CreditAction from './action';

function* watchGetMyCredit() {
  try {
    const myCredit = yield CreditService.getMyCredit();
    yield put({
      type: CreditAction.SET_MY_CREDIT,
      payload: myCredit,
    });
  } catch (error: any) {
    // Do nothing
  }
}

function* creditSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(CreditAction.GET_MY_CREDIT, watchGetMyCredit);
}

export default creditSaga;
