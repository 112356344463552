import { AlertTextError } from '@/components/alert/SweetAlert';
import { MailingListData, MlsData } from '@/components/custom-html-editor/helpers';
import useConvertPostgridFallback from '@/components/custom-modals/modal-preview-template/modal-preview-template-with-mailing-list/hooks/useConvertPostgridFallback';
import useFetchDefaultTemplateFallback from '@/components/custom-modals/modal-preview-template/modal-preview-template-with-mailing-list/hooks/useFetchDefaultTemplateFallback';
import useScrollTemplate from '@/components/dashboard/campaign/ScrollTemplate/hooks/useScrollTemplate';
import { useCampaignManagerStore } from '@/components/dashboard/hooks/useCampaignManager';
import { CreateMailPreviewData } from '@/models';
import CampaignService from '@/services/campaign';
import { POSTGRID_DEFAULT_VALUES, SCAN_NOW_QR_CODE } from '@/utils/constant';
import { PostgridEditorActionType } from '@/utils/enum';
import { getFileUrlWithAuthentication } from '@/utils/upload';
import { generateDefaultFallbacksByHtmlString } from '@/utils/utils';
import { CardTypeEnum, HeaderTypeEnum } from '@goldfishcode/first-team-real-estate-sdk/libs/api/handwriting/models';
import {
  CampaignPreviewType,
  PostGridTemplate,
  PostgridTemplateType,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/postgrid/models';
import { isEmpty, omit, pick } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { agentInfoVars } from '../modal-fallbacks/types';
import ModalCreateTemplateView, { ModalCreateTemplateViewRef } from './ModalCreateTemplateView';
import { HandwrittenCreatingType } from './hooks/useCreateHandwritten';

interface CreateLetterModalProps {
  onCancel: () => void;
  createLetterData: CreateMailPreviewData;
  isBrokerTemplate?: boolean;
  onSave?: (template?: PostGridTemplate) => void;
}

const ModalCreateLetter: React.FunctionComponent<CreateLetterModalProps> = ({
  onCancel,
  onSave,
  createLetterData,
  isBrokerTemplate,
}) => {
  const isHandwritten = createLetterData.type === PostgridTemplateType.HANDWRITTEN;
  // Handwritten Creating
  const [creatingHandwrittenData, setCreatingHandwrittenData] = useState<HandwrittenCreatingType>();
  // Handwritten Creating
  const createLetterViewRef = useRef<ModalCreateTemplateViewRef>(null);
  const handwrittenRef = createLetterViewRef?.current?.handwrittenRef || null;

  const [isCreating, setIsCreating] = useState(false);
  const [isPreView, setIsPreView] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const [isPreviewTemplateVisible, setPreviewTemplateVisible] = useState(false);
  const [previewTemplate, setPreviewTemplate] = useState<any>({});
  const [mlsData, setMlsData] = useState<MlsData>({});
  const [mailingListData, setMailingListData] = useState<MailingListData>({});

  const { getFallbackVariableWithoutMailings } = useConvertPostgridFallback();
  const { templateFallback, setTemplateFallback } = useFetchDefaultTemplateFallback(null, true);
  const {
    handleUpdatePostgridTemplateList,
    handleDeletePostgridTemplate,
    handleSyncTemplateFromPostgridDashboard,
    handleGetPostgridTemplateDetail,
    handleCreateHandwrittenTemplate,
    handleUpdateHandwrittenTemplate,
  } = useScrollTemplate();

  const { agentIdCampaign } = useCampaignManagerStore(
    useShallow((state) => ({
      agentIdCampaign: state.agentIdCampaign,
    })),
  );

  const [visibleConfirmSaveTemplate, setVisibleConfirmSaveTemplate] = useState<boolean>(false);

  const handleUploadBackground = async (isUseSVImgAsFront, isUseSVImgAsBack) => {
    const imageKeyArr = ['frontImage', 'backImage', 'headerImage'];
    const imageArray = await Promise.all(
      imageKeyArr.map(async (key) => {
        if ((key === 'frontImage' && isUseSVImgAsFront) || (key === 'backImage' && isUseSVImgAsBack)) {
          return createLetterData.mailing?.street_view_image;
        }
        const data = creatingHandwrittenData?.[key];
        if (data && data.selectedFile) {
          const mediaUrl = await getFileUrlWithAuthentication(
            data.selectedFile as File,
            'handwritten',
            (e) => AlertTextError('Upload Failed', e?.message),
            true,
          );
          return mediaUrl?.url || '';
        } else {
          return data?.selectedFileData?.url;
        }
      }),
    );
    return {
      frontImage: imageArray?.[0],
      backImage: imageArray?.[1],
      headerImage: imageArray?.[2],
    };
  };

  const handleSavePostgridTemplate = async () => {
    if (isHandwritten) {
      if (
        [PostgridEditorActionType.HANDWRITTEN_CLONE, PostgridEditorActionType.HANDWRITTEN_CREATE].includes(
          createLetterData.actionType,
        )
      ) {
        const errorObj = await handwrittenRef?.current?.validateForm();
        if (!isEmpty(errorObj)) {
          let errorMsg: any = '';

          const errMsgValue = Object.values(omit(errorObj, 'headerText') || {}).concat(
            Object.values(errorObj?.headerText || {}).map((value) => String(value)),
          ) as string[];
          const requiredFieldMsg = errMsgValue?.filter((v) => v.includes('required'));
          const requiredFieldLength = errMsgValue?.filter((v) => v.includes('required'))?.length;
          const overLimitFieldLength = errMsgValue?.filter((v) => v === '')?.length;

          if (requiredFieldLength && overLimitFieldLength)
            errorMsg = (
              <div>
                <p>Please fill in all required fields</p>
                <p>Some fields are over limit characters</p>
              </div>
            );
          else if (requiredFieldLength > 1) errorMsg = 'Please fill all required fields';
          else if (overLimitFieldLength > 0) errorMsg = 'Some fields are over limit characters';
          else errorMsg = requiredFieldMsg?.[0];
          AlertTextError('Submission Failed', errorMsg);
          return;
        }
        setIsCreating(true);
        const { backImage, frontImage, headerImage } = await handleUploadBackground(
          creatingHandwrittenData?.isUseSVImgAsFront,
          creatingHandwrittenData?.isUseSVImgAsBack,
        );
        await handleCreateHandwrittenTemplate(
          {
            name: creatingHandwrittenData?.templateName || '',
            card_type: creatingHandwrittenData?.cardType || '',
            message: creatingHandwrittenData?.message || '',
            landing_page: creatingHandwrittenData?.landingPage || '',
            signature: creatingHandwrittenData?.signature || '',
            font: creatingHandwrittenData?.fontStyle || '',
            mls_data: mlsData,
            mailing_list_data: mailingListData,
            front_image: frontImage,
            back_image: backImage,
            header_image: headerImage,
            header_type: creatingHandwrittenData?.headerType,
            header_text: isEmpty(creatingHandwrittenData?.headerText)
              ? ''
              : JSON.stringify(creatingHandwrittenData?.headerText),
          } as any,
          createLetterData.card_type,
        );
        setIsCreating(false);
        onCancel();
      } else if (createLetterData.actionType === PostgridEditorActionType.HANDWRITTEN_UPDATE) {
        const errorObj = await handwrittenRef?.current?.validateForm();
        if (!isEmpty(errorObj)) return;
        setIsCreating(true);
        const { backImage, frontImage, headerImage } = await handleUploadBackground(
          creatingHandwrittenData?.isUseSVImgAsFront,
          creatingHandwrittenData?.isUseSVImgAsBack,
        );
        handleUpdateHandwrittenTemplate(
          createLetterData.templateId,
          {
            name: creatingHandwrittenData?.templateName || '',
            card_type: creatingHandwrittenData?.cardType || '',
            message: creatingHandwrittenData?.message || '',
            landing_page: creatingHandwrittenData?.landingPage || '',
            signature: creatingHandwrittenData?.signature || '',
            font: creatingHandwrittenData?.fontStyle || '',
            mls_data: mlsData,
            mailing_list_data: mailingListData,
            front_image: frontImage,
            back_image: backImage,
            header_image: headerImage,
            header_type: creatingHandwrittenData?.headerType,
            header_text: isEmpty(creatingHandwrittenData?.headerText)
              ? ''
              : JSON.stringify(creatingHandwrittenData?.headerText),
          } as any,
          createLetterData.card_type,
        );
        setIsCreating(false);
        onCancel();
      }
    } else if (isBrokerTemplate) {
      setIsCreating(true);
      // TODO: remove unused mailing address from mlsData, detect by usedVars in canvas
      await handleSyncTemplateFromPostgridDashboard(createLetterData.templateId, mlsData, mailingListData);
      const template = await handleGetPostgridTemplateDetail(
        createLetterData.templateId,
        false,
        mlsData,
        mailingListData,
        agentIdCampaign,
      );
      if (template && onSave) {
        setIsCreating(false);
        onSave(template);
      }
    } else {
      if (createLetterData.actionType === PostgridEditorActionType.CLONE) {
        setIsCreating(true);
        await handleUpdatePostgridTemplateList(
          createLetterData.templateId,
          mlsData,
          mailingListData,
          PostgridEditorActionType.CLONE,
          agentIdCampaign,
        );
        setIsCreating(false);
        onCancel();
      } else if (createLetterData.actionType === PostgridEditorActionType.UPDATE) {
        setIsCreating(true);
        await CampaignService.saveUpdateToTemplate(
          createLetterData.templateId,
          mlsData,
          mailingListData,
          createLetterData?.rootTemplateId || '',
          agentIdCampaign,
        );
        handleUpdatePostgridTemplateList(
          createLetterData.templateId,
          mlsData,
          mailingListData,
          PostgridEditorActionType.UPDATE,
          agentIdCampaign,
        );
        setIsCreating(false);
        onCancel();
      }
    }

    setVisibleConfirmSaveTemplate(false);
  };

  const handleCancelCreatingPostgridTemplate = async () => {
    if (isHandwritten) {
      onCancel();
      setVisibleConfirmSaveTemplate(false);
    } else {
      // Only refetch postgrid template if isBrokerTemplate
      setIsCanceling(true);
      if (createLetterData?.actionType !== PostgridEditorActionType.UPDATE)
        await handleDeletePostgridTemplate(createLetterData.templateId, !isBrokerTemplate, agentIdCampaign);
      setIsCanceling(false);
      onCancel();
      setVisibleConfirmSaveTemplate(false);
    }
  };

  const handleOnPreviewTemplate = async (e: any) => {
    e.currentTarget.blur();
    if (isHandwritten) {
      const frontImage = creatingHandwrittenData?.frontImage?.selectedFileData?.url;
      const backImage = creatingHandwrittenData?.backImage?.selectedFileData?.url;
      const headerImage = creatingHandwrittenData?.headerImage?.selectedFileData?.url;

      let headerTemplateHtmlString = `<div class="simply-noted-API image-container" style="max-height: 80px; width: 100%"><img style="height:80px; width: fit-content;" src="${
        headerImage || POSTGRID_DEFAULT_VALUES.NO_DATA_IMAGE
      }" alt="" class="image-adjust"/></div>`;
      if (creatingHandwrittenData?.headerType === HeaderTypeEnum.Text) {
        const headerTextFont = (creatingHandwrittenData?.headerText as any)?.fontType?.split(' ')?.join('_');
        headerTemplateHtmlString = `<div class="simply-noted-API ${headerTextFont}" style="width:100%; padding:16px; font-size:${
          Number((creatingHandwrittenData?.headerText as any)?.fontSize || 30) * 1.5
        }px; color:${(creatingHandwrittenData?.headerText as any)?.fontColor}; text-align:${
          (creatingHandwrittenData?.headerText as any)?.textAlign
        }">${(creatingHandwrittenData?.headerText as any)?.data}</div>`;
      }
      const messageContent = ` <div class="simply-noted-API ${creatingHandwrittenData?.fontStyle} message-content-adjust">${creatingHandwrittenData?.message}</div>`;
      const signature = `<div class="simply-noted-API ${creatingHandwrittenData?.fontStyle} message-content-adjust" style="padding:30px 10px 10px 50%; word-wrap:break-word">${creatingHandwrittenData?.signature}</div>`;
      const footerContent = creatingHandwrittenData?.landingPage
        ? `<div class="simply-noted-API message-content-adjust" style="width: 100%; height: 70px; text-align: right;">
              <img src="${SCAN_NOW_QR_CODE}" alt="scannow_qrcode" style="height:90px; width: fit-content;" />
            </div>`
        : '';

      const bodyContent = `<div style="min-height:480px">${
        headerTemplateHtmlString + messageContent + signature
      }</div>`;

      const template_message =
        creatingHandwrittenData?.cardType === CardTypeEnum.Flat
          ? `${bodyContent + footerContent}`
          : `${messageContent + signature}`;

      const previewTemplate = {
        name: creatingHandwrittenData?.templateName || '',
        card_type: creatingHandwrittenData?.cardType || '',
        message: creatingHandwrittenData?.message || '',
        landing_page: creatingHandwrittenData?.landingPage || '',
        signature: creatingHandwrittenData?.signature || '',
        font: creatingHandwrittenData?.fontStyle || '',
        mls_data: mlsData,
        mailing_list_data: mailingListData,
        front_image: frontImage,
        back_image: backImage,
        header_image: headerImage,
        type: PostgridTemplateType.HANDWRITTEN,
        front_template: `<div class="simply-noted-API image-container"><img class="image-adjust" src="${
          frontImage || POSTGRID_DEFAULT_VALUES.NO_DATA_IMAGE
        }" alt=""/></div>`,
        back_template: `<div class="simply-noted-API image-container">
        <div style="position: relative; width:100%;">
        <img class="image-adjust" src="${
          backImage || POSTGRID_DEFAULT_VALUES.NO_DATA_IMAGE
        }" alt=""/><img class="handwritten-qr-code simply-noted-API image-container" src="${SCAN_NOW_QR_CODE}" alt=""/>
        </div>
        </div>`,
        header_template: headerTemplateHtmlString,
        message_template: template_message,
        user_id: 'userId',
        user_template_fallback: {
          default_fallbacks: {},
          template: 'templateId',
          mls_data: mlsData,
          mailing_list_data: mailingListData,
        },
      };

      const templateFallbacks = await getFallbackVariableWithoutMailings({
        template: 'templateId',
        default_fallbacks: {},
        mls_data: mlsData,
        mailing_list_data: mailingListData,
        type: CampaignPreviewType.POSTGRID,
      });
      const mlsFallbacksOnly = pick(
        templateFallbacks.default_fallbacks,
        Object.keys(mlsData || {})
          .concat(Object.keys(mailingListData || {}))
          .concat(agentInfoVars),
      );
      templateFallbacks.default_fallbacks = mlsFallbacksOnly;
      setTemplateFallback(templateFallbacks);

      // if (Object.keys(mlsFallbacksBundle?.mls_data || {}).length) {
      setPreviewTemplate(previewTemplate);
      setPreviewTemplateVisible(true);
    } else {
      setIsPreView(true);
      const previewTemplate = await handleGetPostgridTemplateDetail(
        createLetterData.templateId,
        true,
        mlsData,
        mailingListData,
        agentIdCampaign,
      );

      const htmlString = (previewTemplate?.front_template || '') + '&&&' + (previewTemplate?.back_template || '');

      const newDefaultFallback = generateDefaultFallbacksByHtmlString(htmlString);

      const templateFallbacks = await getFallbackVariableWithoutMailings({
        template: createLetterData.templateId,
        default_fallbacks: newDefaultFallback,
        mls_data: mlsData,
        mailing_list_data: mailingListData,
        type: CampaignPreviewType.POSTGRID,
      });

      const mlsFallbacksOnly = pick(
        templateFallbacks.default_fallbacks,
        Object.keys(mlsData || {})
          .concat(Object.keys(mailingListData || {}))
          .concat(agentInfoVars),
      );
      templateFallbacks.default_fallbacks = mlsFallbacksOnly;
      setTemplateFallback(templateFallbacks);

      // if (Object.keys(mlsFallbacksBundle?.mls_data || {}).length) {
      setPreviewTemplate(previewTemplate);
      setPreviewTemplateVisible(true);
      setIsPreView(false);
      // }
    }
  };

  useEffect(() => {
    if (createLetterData.mlsData && Object.keys(createLetterData.mlsData).length) {
      setMlsData(createLetterData.mlsData);
    }
  }, [createLetterData.mlsData]);

  useEffect(() => {
    if (createLetterData.mailingListData && Object.keys(createLetterData.mailingListData).length) {
      // setInitialMailingListData(createLetterData.mailingListData);
      setMailingListData(createLetterData.mailingListData);
    }
  }, [createLetterData.mailingListData]);

  return (
    <ModalCreateTemplateView
      ref={createLetterViewRef}
      createLetterData={createLetterData}
      handleCancelCreatingPostgridTemplate={handleCancelCreatingPostgridTemplate}
      handleOnPreviewTemplate={handleOnPreviewTemplate}
      handleSavePostgridTemplate={handleSavePostgridTemplate}
      isCanceling={isCanceling}
      isCreating={isCreating}
      isHandwritten={isHandwritten}
      isPreView={isPreView}
      isPreviewTemplateVisible={isPreviewTemplateVisible}
      previewTemplate={previewTemplate}
      setCreatingHandwrittenData={setCreatingHandwrittenData}
      setMailingListData={setMailingListData}
      setMlsData={setMlsData}
      setVisibleConfirmSaveTemplate={setVisibleConfirmSaveTemplate}
      visibleConfirmSaveTemplate={visibleConfirmSaveTemplate}
      setPreviewTemplateVisible={setPreviewTemplateVisible}
      templateFallback={templateFallback}
    />
  );
};

export default ModalCreateLetter;
