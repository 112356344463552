import theme, { AppSizeType, color, heightSize, InputSizeEnum } from '@/styles/theme';
import styled from '@emotion/styled';
import { Form } from 'antd';
import { CSSProperties } from 'react';

type StyleWithSize = {
  height?: CSSProperties['height'];
  fontSize?: CSSProperties['fontSize'];
  borderRadius: CSSProperties['borderRadius'];
};

const styleWithSize = new Map<AppSizeType, StyleWithSize>([
  [
    'small',
    {
      ...heightSize.get('small'),
      borderRadius: '15px',
    },
  ],
  [
    'middle',
    {
      ...heightSize.get('middle'),
      borderRadius: '6px',
    },
  ],
  [
    'large',
    {
      ...heightSize.get('large'),
      fontSize: '14px !important',
      borderRadius: '5px',
    },
  ],
]);

const styleWithTextareaRows = ({ rows }: { rows: number }) =>
  new Map<AppSizeType, { height?: CSSProperties['height'] }>([
    [
      'small',
      {
        height: `${InputSizeEnum.SMALL * rows}px !important`,
      },
    ],
    [
      'middle',
      {
        height: `${InputSizeEnum.MIDDLE * rows}px !important`,
      },
    ],
    [
      'large',
      {
        height: `${InputSizeEnum.LARGE * rows}px !important`,
      },
    ],
  ]);

interface AppInputV2StyledProps {
  size: AppSizeType;
  noValidate?: boolean;
  rows?: number;
}
export const AppInputV2Styled = {
  Container: styled.div`
    width: 100%;
  `,
  InputStyled: styled(Form.Item)<AppInputV2StyledProps>`
    /* .ant-input-affix-wrapper, */
    &.ant-form-item {
      margin-bottom: ${({ noValidate }) => (noValidate ? '0' : '24px')};
    }
    .ant-input {
      ${({ size }) => styleWithSize.get(size || 'middle')};
      ${({ size, rows = 1 }) => styleWithTextareaRows({ rows }).get(size || 'middle')};
    }
    .stripe-input {
      ${({ size }) => styleWithSize.get(size || 'middle')};
      background-color: ${theme.color.WHITE};
      border-radius: 5px !important;
      padding: 16.5px 11px;

      font-family: 'Montserrat';
      line-height: 45px;
      color: ${theme.color.DARKBLUE};
    }
    .ant-input-password {
      ${({ size }) => styleWithSize.get(size || 'middle')};
      padding: 0 11px;
      overflow: hidden;
      .ant-input-password-icon.anticon {
        font-size: 18px;
      }
    }

    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
      border-color: ${color.RED};
    }
    .ant-input,
    .ant-input:focus,
    .ant-input:hover,
    .ant-input-focused,
    .ant-input-affix-wrapper {
      border-color: ${color.DARKBLUE};
      border-radius: ${({ size }) => styleWithSize.get(size || 'middle')?.borderRadius};
    }
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: unset;
    }

    .ant-input-affix-wrapper-disabled,
    .ant-input-affix-wrapper-disabled:hover {
      background-color: ${color.WHITE};
      opacity: 0.8;
      border-color: ${color.BLACK_O30};
    }
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused,
    .ant-input:focus,
    .ant-input-focused {
      box-shadow: unset;
    }
    .ant-form-item-explain-error {
      font-size: 14px;
      font-weight: 500;
      color: ${color.RED};
    }
    .ant-input-password-icon.anticon {
      color: ${color.DARKBLUE};
      font-size: 24px;
    }
    .ant-col.ant-form-item-label {
      padding: 0;
    }
  `,
};
