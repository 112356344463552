const RoutePaths = {
  LOGIN: '/login',
  ON_BOARDING: '/on-boarding',
  CREATE_MAILING_MANAGER: '/mailing-lists/draw-report',
  DASHBOARD: '/',
  VERIFY_ACCOUNT: '/verify',
  LOGOUT: '/logout',
  MANAGER: '/manager',
  SETTINGS: '/settings',
  NOT_FOUND: '/404',
  AGENT_REPORT: '/agent-report',
  AGENT_LOOKUP: '/agentlookup',
  FULL_ATTRIBUTION: '/full-attribution',
  HOME_GRAPH: '/home-graph',
  CAMPAIGNS: '/agent-campaigns',
  SMART_ACTIONS: '/smart-actions',
  MY_NFT: '/my-nft',
  CAMPAIGN_NFT_DETAIL: '/campaign-nft-detail',
  MINT_CAMPAIGN_NFT: '/mint-campaign-nft',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  PRIVACY_POLICY: '/privacy-policy',
  REGISTER: '/register',
  SETUP_MANAGER: '/setup-manager',
  DETAIL_DOWNSIZES: '/detail-downsizes',
  DETAIL_DOWNSIZES_LATTER: '/detail-downsizes-letter',
  TEMPLATE_1: '/templates-1',
  TEMPLATE_2: '/templates-2',
  TEMPLATE_3: '/templates-3',
  TEMPLATE_4: '/templates-4',
  HOME_BUYER: '/home-buyer',
  HOME_SELLERS: '/home-sellers',
  ABSENTEE_HOMEOWNER: '/absentee-homeowner',
  HOME_UPGRADE: '/home-upgrade',
  MY_REPORT: '/my-report',
  AGENT_REPORT_DYNAMIC: 'agent-report',
  MY_REPORT_DYNAMIC: 'my-report',
  PROSPECT_LOOKUP: '/prospect-lookup',
  LIKE_TO_MOVE_V1: '/likely-to-move-v1',
  LIKE_TO_MOVE_V2: '/likely-to-move-v2',
  EQUITY_IN_HOME_V1: '/equity-in-home-v1',
  EQUITY_IN_HOME_V2: '/equity-in-home-v2',
  PREPARE_FOR_CONSUMER: '/prepare-for-whats-coming-consumer',
  PREPARE_FOR_AGENT: '/prepare-for-whats-coming-agents',
  MAILING_LISTS: '/mailing-lists',
  MAILING_LISTS_CREATE: '/mailing-lists/create',
  DRIP_CAMPAIGNS: '/drip-campaigns',
  DRIP_CAMPAIGNS_CREATE: '/drip-campaigns/create',
  TRACKING_CALL: '/tracking/call',
  CREATE_QR_CODE: '/create-qrcode',
  FORGOT_PASS: '/forgot-password',
  REQUIRE_SUBSCRIPTION: '/require-subscription',
  BROKER_CAMPAIGN_SENDING: '/broker/campaign',
  BROKER_MAILING_FETCHING: '/broker/mailing',
  BROKER_MY_AGENTS: '/broker/my-agents',
  MYLISTING_CAMPAIGN_SENDING: '/my-listing/campaign',
};
export default RoutePaths;
