import { ForkEffect, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { ActionBase } from '..';
import { RootState } from '../model';
import UserAction from './action';
import { cloneDeep } from 'lodash';

function* watchAddNewOrder(action: ActionBase) {
  const data = yield action.payload;
  if (!data) return;
  yield put({
    type: UserAction.AGENT_PAST_ORDERS,
    payload: { ...data },
  });
}
function* watchDeleteSmartTrigger(action: ActionBase) {
  const id = yield action.payload;
  const currentListTrigger = yield select((state: RootState) => state.user.listTrigger);
  const newListTrigger = cloneDeep(currentListTrigger);
  if (!id && !newListTrigger?.results) return;
  const indexItem = newListTrigger?.results.findIndex((v) => v?.id === id);
  if (indexItem < 0) return;
  newListTrigger?.results?.splice(indexItem, 1);
  yield put({
    type: UserAction.LIST_TRIGGER,
    payload: { ...newListTrigger },
  });
}

function* userSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(UserAction.ADD_NEW_ORDER, watchAddNewOrder);
  yield takeEvery(UserAction.DELETE_SMART_TRIGGER, watchDeleteSmartTrigger);
}

export default userSaga;
