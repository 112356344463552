import { CaretDownOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import classNames from 'classnames';
import { FormikProps } from 'formik';
import React, { forwardRef } from 'react';
import { ChromePicker } from 'react-color';
import { FiAlignJustify, FiAlignLeft, FiAlignRight } from 'react-icons/fi';
import { HANDWRITTEN_HEADER_TEXT_LIMIT } from '../hooks/useCreateHandwritten';
import { TextAlignmentEnum } from './HeaderTextContainer';
import { HeaderTextWrapperStyled } from './HeaderTextStyled';
import AppInputV2 from '@/components/AppInputV2';
import AppLabel from '@/components/AppLabel';
import AppSelectV2 from '@/components/AppSelectV2';

interface HeaderTextProps {
  headerTextError?: any;
  disabled?: boolean;
  formik: FormikProps<any>;
  handleToggleColorPicker: () => void;
  showColorPicker: boolean;
  handleColorChange: (color: any) => void;
}

const headerFontStyles = [
  'Arial',
  'Arial Black',
  'Arial Narrow',
  'Comic Sans MS',
  'Courier New',
  'Impact',
  'Rockwell',
  'Tahoma',
  'Times New Roman',
  'Trebuchet MS',
  'Verdana',
].map((v) => ({ value: v, label: v }));
//font size from 8 to 40.
const headerFontSize = Array.from({ length: 33 }, (_, index) => index + 8).map((v) => ({ label: v, value: v }));

const HeaderTextView = forwardRef<HTMLDivElement, HeaderTextProps>((props, colorPickerRef) => {
  const {
    headerTextError,
    disabled = false,
    formik,
    handleToggleColorPicker,
    showColorPicker,
    handleColorChange,
  } = props;

  return (
    <HeaderTextWrapperStyled disabled={disabled}>
      <div>
        <div className="handwritten-editor-header">
          <AppLabel label="Header Text" requiredMark />
          <p
            className={classNames('handwritten-limit-char', {
              errorMsg: (formik.values?.data?.length || 0) > HANDWRITTEN_HEADER_TEXT_LIMIT,
            })}
          >
            {HANDWRITTEN_HEADER_TEXT_LIMIT - (formik.values.data || '')?.length} characters remaining
          </p>
        </div>
        <AppInputV2
          className="field template-name-field"
          name="data"
          type="text"
          size="large"
          placeholder="Enter Header Text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.data}
          disabled={disabled}
          hasError={Boolean(headerTextError?.data)}
          errorMessage={headerTextError?.data}
        />
      </div>
      <div
        css={css`
          display: flex;
          gap: 10px;
        `}
      >
        <div
          css={css`
            width: 49%;
            margin-bottom: 20px;
          `}
        >
          <div className="header-text-alignment">
            <AppLabel label="Alignment" requiredMark />
            <div
              className={classNames('header-text-alignment-item', {
                active: formik.values.textAlign === TextAlignmentEnum.LEFT,
              })}
              onClick={() => {
                if (disabled) return;
                formik.setFieldValue('textAlign', TextAlignmentEnum.LEFT);
              }}
            >
              <FiAlignLeft />
            </div>
            <div
              className={classNames('header-text-alignment-item', {
                active: formik.values.textAlign === TextAlignmentEnum.CENTER,
              })}
              onClick={() => {
                if (disabled) return;
                formik.setFieldValue('textAlign', TextAlignmentEnum.CENTER);
              }}
            >
              <FiAlignJustify />
            </div>
            <div
              className={classNames('header-text-alignment-item', {
                active: formik.values.textAlign === TextAlignmentEnum.RIGHT,
              })}
              onClick={() => {
                if (disabled) return;
                formik.setFieldValue('textAlign', TextAlignmentEnum.RIGHT);
              }}
            >
              <FiAlignRight />
            </div>
          </div>
          <div
            id="handwritten-header-font_style"
            className="handwritten-input-container haft-size"
            css={css`
              width: 100%;
              max-width: 100% !important;
              height: unset !important;
            `}
          >
            <AppSelectV2
              className="field type-field"
              label="Header Font"
              placeholder="Select Font"
              options={headerFontStyles}
              onChange={(v) => formik.setFieldValue('fontType', v)}
              value={formik.values.fontType}
              requiredMark
              disabled={disabled}
            />
          </div>
          <div className="header-text-font-size-color">
            <AppSelectV2
              className="field type-field"
              label="Font Size"
              placeholder="Select Font Size"
              options={headerFontSize}
              onChange={(v) => {
                formik.setFieldValue('fontSize', v);
              }}
              value={formik.values.fontSize}
              requiredMark
              disabled={disabled}
            />
          </div>
        </div>
        <div className="header-text-color-picker">
          <AppLabel label="Font Color" requiredMark />
          <div
            ref={colorPickerRef}
            css={css`
              display: flex;
              gap: 10px;
            `}
          >
            <div className="header-text-color-preview" onClick={handleToggleColorPicker}>
              <div
                className="header-text-chrome-picker"
                style={{
                  background: formik.values.fontColor,
                }}
              />
              <CaretDownOutlined />
            </div>
            {showColorPicker && <ChromePicker color={formik.values.fontColor} onChange={handleColorChange} />}
          </div>
        </div>
      </div>
    </HeaderTextWrapperStyled>
  );
});

export default HeaderTextView;
