import AppButtonV2 from '@/components/AppButtonV2';
import ErrorMessage from '@/components/error-message';
import Modal from '@/components/Modal';
import { Space } from 'antd';
import React, { FC, memo } from 'react';
import { ConfirmModalProps } from './ModalConfirmContainer';
import ModalConfirmViewStyled from './ModalConfirmViewStyled';

const ConfirmModalView: FC<ConfirmModalProps> = memo(
  ({
    title,
    visible,
    btnSubmitText = 'Confirm',
    btnCancelText = 'Cancel',
    onCancel,
    onSubmit,
    children,
    errMsg,
    isLoading,
    body,
    modalWidth,
    ...rest
  }) => {
    return (
      <Modal
        visible={visible}
        onCancel={onCancel}
        centered
        footer={false}
        destroyOnClose
        isTransparent
        width={modalWidth ?? 520}
        {...rest}
      >
        <Modal.Body>
          <Space direction="vertical" size={16} className="w-full">
            {title && <Modal.Title>{title}</Modal.Title>}
            {body && <ModalConfirmViewStyled.Message className="body-modal">{body}</ModalConfirmViewStyled.Message>}
            {errMsg && (
              <ErrorMessage textAlign="center" className="error">
                {errMsg}
              </ErrorMessage>
            )}
            {children && <ModalConfirmViewStyled.Children>{children}</ModalConfirmViewStyled.Children>}
            {btnSubmitText && (
              <AppButtonV2 inferLoading={isLoading} block onClick={onSubmit} disabled={isLoading}>
                {btnSubmitText}
              </AppButtonV2>
            )}
            {btnCancelText && (
              <AppButtonV2 onClick={onCancel} block color="WhiteTransparent" disabled={isLoading}>
                {btnCancelText}
              </AppButtonV2>
            )}
          </Space>
        </Modal.Body>
      </Modal>
    );
  },
);

export default ConfirmModalView;
