import SettingsService from '@/services/settings';
import { convertTextSold } from '@/utils/utils';
import { withProperties } from '@/utils/withProperties';
import { ConstantValue } from '@goldfishcode/first-team-real-estate-sdk/libs/api/settings/models';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryOptions } from '../types';

type FnParams = QueryOptions<typeof SettingsService.getConstantValue>;

const queryKey = (): FnParams['queryKey'] => {
  return ['getConstantValue'];
};

const useQueryHandle = (configs?: FnParams['config']): UseQueryResult<ConstantValue, any> => {
  return useQuery({
    queryKey: queryKey(),
    queryFn: async () => {
      const result = await SettingsService.getConstantValue();
      if (result.smart_trigger?.mls_status) {
        result.smart_trigger.mls_status = convertTextSold(result.smart_trigger.mls_status);
      }
      return result;
    },
    staleTime: Infinity,
    cacheTime: Infinity,
    // not auto fetch because we need to check the pathname
    enabled: false,
    ...configs,
  });
};

const useGetConstantValueQuery = withProperties(useQueryHandle, { queryKey });
export default useGetConstantValueQuery;
