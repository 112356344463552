import { useState } from 'react';
import { MailingExtra } from '@goldfishcode/first-team-real-estate-sdk/libs/api/tools/variable/models';
import { pick, pickBy } from 'lodash';
import { AlertTextError, showPopup } from '@/components/alert/SweetAlert';
import MailingService from '@/services/mailing';
import Swal from 'sweetalert2';
import { useCampaignManagerStore } from '@/components/dashboard/hooks/useCampaignManager';
import { useShallow } from 'zustand/react/shallow';
import CampaignAction from '@/reducers/campaign/actions';
import { NOT_EXIST_ML_CODE } from '@/utils/constant';
import { useDispatch } from 'react-redux';

interface SaveFallbackMailingOption {
  onSuccess?: (preventActionSendInSmartAction?: boolean) => void;
  onError?: () => void;
  onSkip?: () => void;
  message?: string;
  title?: string;
  acceptLabel?: string;
  cancelLabel?: string;
}
interface SaveFallbackToMailingOptions {
  variablesOnUI?: string[];
  isUseConfirm?: boolean;
}

const listingImageVars = [
  'listing_image',
  'listing_image_1',
  'listing_image_2',
  'listing_image_3',
  'listing_image_4',
  'listing_image_5',
];

const messageDefault = 'Are you sure you want to save the fallback data for this mailing?';
const titleDefault = 'Fallback Data Save Confirmation';
const acceptDefaultLabel = 'Yes';
const cancelDefaultLabel = 'No';

export interface HandleToSaveFallback {
  handleToSaveFallback: (
    mailing: MailingExtra,
    options: SaveFallbackMailingOption,
    mlsData?: { [key: string]: any },
    mailingListData?: { [key: string]: any },
    emailCreatedVariableOnTemplate?: string[],
  ) => void;
  isLoading: boolean;
}

const useSaveFallbackToMailing = ({
  variablesOnUI,
  isUseConfirm = true,
}: SaveFallbackToMailingOptions): HandleToSaveFallback => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const removeEmptyFields = (obj) => pickBy(obj, (value) => ![null, undefined].includes(value));
  const { agentIdCampaign } = useCampaignManagerStore(
    useShallow((state) => ({
      agentIdCampaign: state.agentIdCampaign,
    })),
  );
  const handleSavingMailingDataFromFallback = async (
    mailing: MailingExtra,
    variablesOnUI: string[],
    onSuccess?: (preventActionSendInSmartAction?: boolean) => void,
    mlsData?: { [key: string]: any },
    mailingListData?: { [key: string]: any },
  ) => {
    try {
      setIsLoading(true);
      const fallbacks = removeEmptyFields(pick(mailing.fallback, variablesOnUI.concat(listingImageVars)));
      await MailingService.saveMailingFallback(
        mailing.id,
        fallbacks,
        mailing.is_send_owner_address || false,
        mlsData,
        mailingListData,
        agentIdCampaign,
      );

      onSuccess && onSuccess();
      // AlertTextSuccess('Action Success', 'Save fallback data successfully!', onSuccess);
    } catch (error) {
      let errorMsg = (error as Error).message;
      if ((error as any)?.code === NOT_EXIST_ML_CODE) {
        dispatch({
          type: CampaignAction.NOT_EXIST_ML_ID,
          payload: (error as any).message,
        });
        errorMsg = 'Unable to Save Fallback for Nonexistent Address';
      }
      AlertTextError('Action Failed', errorMsg);
      onSuccess && onSuccess(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToSaveFallback = async (
    mailing: MailingExtra,
    { onSuccess, onSkip, message, title, acceptLabel, cancelLabel }: SaveFallbackMailingOption,
    mlsData?: { [key: string]: any },
    mailingListData?: { [key: string]: any },
    emailCreatedVariableOnTemplate?: string[], //remove late if change logic (allow the selection of another template after creating one, instead of immediately sending the created template.)
  ) => {
    if (!mailing || !(emailCreatedVariableOnTemplate ?? variablesOnUI)) return;

    if (!isUseConfirm) {
      handleSavingMailingDataFromFallback(
        mailing,
        (emailCreatedVariableOnTemplate ?? variablesOnUI) || [],
        onSuccess,
        mlsData,
        mailingListData,
      );

      return;
    }

    showPopup(
      {
        message: message || messageDefault,
        title: title || titleDefault,
        confirmButtonText: acceptLabel || acceptDefaultLabel,
        cancelButtonText: cancelLabel || cancelDefaultLabel,
        showCloseButton: true,
      },
      async (value) => {
        // Yes option
        if (value && value.isConfirmed && variablesOnUI) {
          handleSavingMailingDataFromFallback(mailing, variablesOnUI, onSuccess, mlsData, mailingListData);
        } else {
          if (value.dismiss === Swal.DismissReason.cancel) {
            onSkip && onSkip();
          } else if (value.dismiss === Swal.DismissReason.close) {
            // Handle close action
          }
        }
      },
    );
  };

  return {
    handleToSaveFallback,
    isLoading,
  };
};

export default useSaveFallbackToMailing;
