import { Api, ApiConfiguration, createApiClient } from '@goldfishcode/first-team-real-estate-sdk';
import { ApiClient } from '@goldfishcode/first-team-real-estate-sdk/libs/http/client';
import { StorageKeys } from '@/utils/enum';
import globalVariable from './env';
import { MessageError } from '@/utils/validation';
import { JwtToken } from '@goldfishcode/first-team-real-estate-sdk/libs/api/auth/models';
import { forceLogout } from '@/utils/auth';

let apiClient: ApiClient;
let apiIns: Api;
let isRefreshing = false;
let refreshSubscribers: Array<(data?: JwtToken) => void> = [];
let requestQueue: Array<{
  originalRequest: any;
  resolve: (value?: any) => void;
  reject: (reason?: any) => void;
}> = [];

class SessionStorage {
  public async set(key: string, value: string): Promise<void> {
    localStorage.setItem(key, value);
  }

  public async get(key: string): Promise<string> {
    const value = localStorage.getItem(key);
    return value || '';
  }

  public async remove(key: string): Promise<void> {
    localStorage.removeItem(key);
  }
}

const sessionStorage = new SessionStorage();
const config: ApiConfiguration = {
  baseUrl: globalVariable.API_URL,
  authSessionKey: StorageKeys.SESSION_KEY,
  session: sessionStorage,
  socketUrl: globalVariable.SOCKET_URL,
  useRememberMe: true,
  rememberKey: StorageKeys.REMEMBER_ME_KEY,
  enableCache: false,
  cacheExpiresIn: globalVariable.GATSBY_CACHE_TIMEOUT,
};

// Your function to refresh the token
const runTokenRefreshFunction = async () => {
  try {
    // Call your API to refresh the token
    const newToken = await apiIns.auth.refreshToken();

    if (!newToken?.access) throw new Error('Token refresh failed');

    // Notify all subscribers with the new token
    refreshSubscribers.forEach((callback) => callback(newToken));

    return newToken;
  } catch (refreshError) {
    // Handle token refresh error (e.g., logout user, redirect to login)
    isRefreshing = false;
    refreshSubscribers = [];
    forceLogout(true);
    throw refreshError;
  }
};

const initSDKService = (): void => {
  apiClient = createApiClient(config);

  apiIns = new Api(apiClient);
  const api = apiClient.axiosInstance;
  if (typeof window !== 'undefined') {
    apiClient.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && error.response.data.message === MessageError.tokenExpired) {
          if (isRefreshing) {
            // Queue the original request along with its resolve and reject functions
            const promise = new Promise((resolve, reject) => {
              requestQueue.push({ originalRequest, resolve, reject });
            });
            return promise;
          }

          // Set the flag to indicate that the token is being refreshed
          isRefreshing = true;

          try {
            // Perform token refresh and store the new token
            const newToken = await runTokenRefreshFunction();

            // Handle if newToken have no access_token inside
            if (!newToken?.access) throw new Error('Failed to refresh token');

            // Update the original request header with the new token
            originalRequest.headers.Authorization = 'Bearer ' + newToken.access;
            // Reissue the original request
            const response = await api(originalRequest);

            // Process the queued requests
            requestQueue.forEach((queuedRequest) => {
              queuedRequest.originalRequest.headers.Authorization = 'Bearer ' + newToken.access;
              queuedRequest.resolve(api(queuedRequest.originalRequest));
            });

            return response;
          } catch (refreshError) {
            // Reject the queued requests with the refresh error
            requestQueue.forEach((queuedRequest) => {
              queuedRequest.reject(refreshError);
            });

            throw refreshError;
          } finally {
            // Reset the flag, clear the subscribers array, and empty the request queue
            isRefreshing = false;
            refreshSubscribers = [];
            requestQueue = [];
          }
        }

        // If the error is not due to token expiration, reject with the error
        return Promise.reject(error);
      },
    );
  }
};

export { initSDKService, apiClient, apiIns };
