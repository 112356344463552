"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TwoFactorAuthApi = void 0;
class TwoFactorAuthApi {
    constructor(client) {
        this.client = client;
    }
    // setup login with OTP: request to update user.enabled_2fa = true
    setup() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post('/otp/2fa/');
        });
    }
    // enable login with OTP: update user.enabled_2fa = true
    enable(params) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const r = yield this.client.patch('/otp/2fa/', params);
            (_a = this.client.authenticator) === null || _a === void 0 ? void 0 : _a.setAuthToken(r);
            return r;
        });
    }
    // disable login with OTP: update user.enabled_2fa = false
    disable() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const r = yield this.client.delete('/otp/2fa/');
            (_a = this.client.authenticator) === null || _a === void 0 ? void 0 : _a.setAuthToken(r);
            return r;
        });
    }
    //login OTP: verify token
    login(params) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const r = yield this.client.post('/otp/2fa/login/', params);
            (_a = this.client.authenticator) === null || _a === void 0 ? void 0 : _a.setAuthToken(r);
            return r;
        });
    }
}
exports.TwoFactorAuthApi = TwoFactorAuthApi;
