"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Api = exports.createApiClient = void 0;
const client_1 = require("../http/client");
const user_1 = require("./user");
const slipstream_1 = require("./slipstream");
const auth_1 = require("./auth");
const otp_auth_1 = require("./otp_auth");
const upload_1 = require("./upload");
const semrush_1 = require("./semrush");
const relitix_1 = require("./relitix");
const settings_1 = require("./settings");
const postalytics_1 = require("./postalytics");
const titletoolbox_1 = require("./titletoolbox");
const payment_1 = require("./payment");
const grow_1 = require("./grow");
const mailing_1 = require("./mailing");
const nfts_1 = require("./nfts");
const campaign_metadata_1 = require("./campaign_metadata");
const credit_1 = require("./credit");
const subscription_1 = require("./subscription");
const postgrid_1 = require("./postgrid");
const variable_1 = require("./tools/variable");
const handwriting_1 = require("./handwriting");
const template_preview_1 = require("./template_preview");
exports.createApiClient = (config) => {
    const client = new client_1.ApiClient(config);
    return client;
};
class Api {
    constructor(client) {
        this.client = client;
        this.variableTool = new variable_1.VariableTool(this.client);
        // APIs
        this.auth = auth_1.authFactory(this.client);
        this.otpAuth = new otp_auth_1.TwoFactorAuthApi(this.client);
        this.overview = new slipstream_1.SlipStreamApi(this.client);
        this.relitix = new relitix_1.RelitixApi(this.client);
        this.semrush = new semrush_1.SemrushApi(this.client);
        this.settings = new settings_1.SettingsApi(this.client);
        this.postalytics = new postalytics_1.PostalyticsApi(this.client);
        this.titletoolbox = new titletoolbox_1.TitleToolboxApi(this.client);
        this.credit = new credit_1.Credit(this.client);
        this.subscription = new subscription_1.Subscription(this.client);
        // @todo: find a better option to set up authentication for ApiClient
        this.client.setAuthenticator(this.auth);
        this.user = new user_1.UserApi(this.client);
        this.uploadFile = upload_1.uploadFileFactory(this.client);
        this.payment = new payment_1.PaymentApi(this.client);
        this.grow = new grow_1.GrowApi(this.client);
        this.mailingList = new mailing_1.MailingListApi(this.client);
        this.campaignNFT = new nfts_1.CampaignNFTApi(this.client);
        this.campaignMetadata = new campaign_metadata_1.NFTMetadataApi(this.client);
        this.postgrid = new postgrid_1.PostGridApi(this.client);
        this.handwriting = new handwriting_1.HandWritingApi(this.client);
        this.templatePreview = new template_preview_1.TemplatePreviewApi(this.client);
    }
}
exports.Api = Api;
