const globalVariable = {
  API_URL: process.env.GATSBY_API_URL || 'https://firstteam-real-estate.dev2.goldfishcode.com/v1',
  SOCKET_URL: process.env.GATSBY_SOCKET_URL || 'https://firstteam-real-estate-ws.dev2.goldfishcode.com',
  GOOGLE_SITE_VERIFICATION: process.env.GATSBY_GOOGLE_SITE_VERIFICATION,
  GOOGLE_CLIENT_ID: process.env.GATSBY_GOOGLE_CLIENT_ID || '',
  GOOGLE_MAP_KEY: process.env.GATSBY_GOOGlE_MAP_KEY,
  GATSBY_STRIPE_PUBLISH_KEY:
    process.env.GATSBY_STRIPE_PUBLISH_KEY ||
    'pk_test_51L5tftBeAsrxREZ57zyPG5WC2ly5oTrcDN2qbXNArPsz7KxRfWA7d9hWbhoTDZ28grvn2flJvPcuMlIkuUH1gf5d00xllO6aZO',
  BRIDGE_URL: process.env.GATSBY_WALLET_BRIDGE || 'https://pancakeswap.bridge.walletconnect.org',
  POLYGON_CHAINID: Number(process.env.GATSBY_POLYGON_CHAINID) || 80001,
  POLYGON_CHAINID_HEX: process.env.GATSBY_POLYGON_CHAINID_HEX || '0x13881',
  POLYGON_RPC: process.env.GATSBY_POLYGON_RPC || 'https://matic-mumbai.chainstacklabs.com',
  POLYGON_CHAIN_NAME: process.env.GATSBY_POLYGON_CHAIN_NAME || 'Mumbai',
  POLYGONSCAN: process.env.GATSBY_POLYGONSCAN || 'https://mumbai.polygonscan.com/',
  POLYGON_OPENSEA: process.env.GATSBY_POLYGON_OPENSEA || 'https://testnets.opensea.io/assets/mumbai/',
  FIREBASE_API_KEY: process.env.GATSBY_FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID: process.env.GATSBY_FIREBASE_PROJECT_ID,
  FIREBASE_DATABASE_URL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  FIREBASE_STORAGE_BUCKET: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID: process.env.GATSBY_FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
  CAMPAIGN_FORM_ID: process.env.GATSBY_CAMPAIGN_FORM_ID,
  REMAINING_TIME_REFRESH: process.env.GATSBY_REMAINING_TIME_REFRESH,
  SHOW_IS_TESTING: process.env.GATSBY_SHOW_IS_TESTING === 'true',
  GATSBY_CACHE_TIMEOUT: process.env.GATSBY_CACHE_TIMEOUT ? Number(process.env.GATSBY_CACHE_TIMEOUT) : 10, //seconds
  GATSBY_SFPID: Number(process.env.GATSBY_SFPID) || '',
  GATSBY_CHAT_LOADER_YAPMO: process.env.GATSBY_CHAT_LOADER_YAPMO || '',
  WEB_APP_URL: process.env.GATSBY_SITE_MAP_URL,
};
export default globalVariable;
