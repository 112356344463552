import { ICustomTemplatePayload } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';
import { Pagination } from '@goldfishcode/first-team-real-estate-sdk/libs/type';

export const pagination = {
  limit: 20,
};

export const fetchDataFromAllPages = async (apiCall) => {
  return new Promise((res) => {
    try {
      const pageSize = 100; // Number of items per page
      let currentPage = 1;
      let totalCount = 0;
      let allData = [];
      const fetchPageData = async (page, apiCall: (page, limit) => Promise<Pagination<any>>) => {
        // Make your API call here with the appropriate page parameter
        try {
          const response = await apiCall(page, pageSize);
          const { results, count } = response;
          allData = allData.concat(results as any);
          // Update the total count (if available)
          if (count) {
            totalCount = count;
          }
        } catch (error) {
          // Not thing to do
        }
      };
      const fetchNextPage = async (apiCall) => {
        await fetchPageData(currentPage, apiCall);
        currentPage++;
        // Check if there are more pages to fetch
        if (allData.length < totalCount) {
          await fetchNextPage(apiCall);
        } else {
          res(allData);
        }
      };
      fetchNextPage(apiCall).then(() => {
        if (allData.length === totalCount) res(allData);
      });
      // Verify if the returned data length matches the totalCount
    } catch (error) {
      res([]);
    }
  });
};

export const formatEmailDataBeforeSendingApi = (custom_template_infos: ICustomTemplatePayload) => {
  const customFallbacks = custom_template_infos.custom_fallbacks;
  const numericKeys = ['square_feet'];
  Object.keys(customFallbacks).map((key) => {
    if (numericKeys.some((v) => key.endsWith(v))) {
      const numberWithoutComma = customFallbacks[key].replace(/\D/g, '');
      customFallbacks[key] = Number(numberWithoutComma);
    }
  });
};
