import React from 'react';
import IcAttachmentPin from '@/assets/icons/attachment-pin-icon.png';
import TextEditorView from './TextEditorView';

interface TextEditorProps {
  onChange: (value) => void;
  placeholder: string;
  isEditorEmail?: boolean;
  onFocus?: () => void;
  isModalEditor?: boolean;
  handleImage?: (file: any) => void;
  handleEditorReady?: (editor: any) => void;
  editorHeight?: string;
  isFileAttachment?: boolean;
  readOnly?: boolean;
  useSpecialCharEntity?: boolean;
}

const TextEditor: React.FC<TextEditorProps> = (props) => {
  const {
    onChange,
    placeholder,
    isEditorEmail,
    onFocus,
    isModalEditor,
    handleImage,
    handleEditorReady,
    editorHeight,
    isFileAttachment = true,
    readOnly = false,
    useSpecialCharEntity = true,
  } = props;

  const toolbarConfig = {
    toolbar: [
      {
        name: 'basicstyles',
        items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat'],
      },
      { name: 'clipboard', items: ['Undo', 'Redo'] },
      {
        name: 'forms',
        items: ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'],
      },
      {
        name: 'paragraph',
        groups: ['list', 'indent', 'blocks', 'align', 'bidi'],
        items: [
          'NumberedList',
          'BulletedList',
          'Outdent',
          'Indent',
          '-',
          'Blockquote',
          'CreateDiv',
          '-',
          'JustifyLeft',
          'JustifyCenter',
          'JustifyRight',
          'JustifyBlock',
          '-',
          'BidiLtr',
          'BidiRtl',
        ],
      },
      { name: 'links', items: ['Link', 'Unlink'] },
      { name: 'insert', items: ['HorizontalRule'] },
      { name: 'styles', items: ['Styles'] },
      // '/',
      { name: 'styles', items: ['Format'] },
      { name: 'colors', items: ['TextColor', 'BGColor'] },
      { name: 'document', items: ['Source', '-', 'Save', 'NewPage', 'Preview', '-', 'Templates'] },
      { name: 'tools', items: ['Maximize', 'ShowBlocks'] },
    ],
    extraPlugins: 'editorplaceholder',
    editorplaceholder: placeholder,
    allowedContent: true,

    height: editorHeight,
    enableContextMenu: false,
    pasteFilter: 'plain-text',
    bodyId: 'contents_id',
    readOnly: readOnly,
    entities: useSpecialCharEntity,
  };
  React.useEffect(() => {
    if (!isFileAttachment) {
      const newLineIndex = toolbarConfig?.toolbar?.findIndex((v) => v?.toString() === '/');
      if (newLineIndex >= 0) {
        toolbarConfig.toolbar.splice(newLineIndex, 1);
      }
    }
  }, [isFileAttachment]);

  const handleUploadFile = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/png, image/jpeg, image/jpg, .pdf, .doc, .docx, .csv,.xls, .xlsx';
    fileInput.addEventListener('change', (event: any) => {
      if (!event?.target?.files) return;
      const selectedFile = event.target.files[0];
      handleImage && handleImage(selectedFile);
    });
    fileInput.click();
  };

  function customizeEditorToolbar(editor) {
    if (editor && isModalEditor && isEditorEmail && isFileAttachment) {
      const toolbox = document.querySelector('.editor-section .cke_toolbox[role="group"]');
      const btn = document.querySelector('#cke_44_5');

      if (!btn && toolbox) {
        const newDiv = document.createElement('span');
        newDiv.id = 'cke_44_5';
        newDiv.className = 'cke_toolbar';
        const spanButton = document.createElement('span');
        spanButton.className = 'cke_toolgroup input-attachment';
        const img = document.createElement('img');
        img.src = `${IcAttachmentPin}`;

        spanButton.addEventListener('click', handleUploadFile);

        spanButton.appendChild(img);

        newDiv.appendChild(spanButton);

        const sixthElement = toolbox?.children[5];
        if (toolbox && sixthElement) {
          toolbox.insertBefore(newDiv, sixthElement);
        }
      }
    }
  }

  return (
    <TextEditorView
      customizeEditorToolbar={customizeEditorToolbar}
      onChange={onChange}
      isEditorEmail={isEditorEmail}
      onFocus={onFocus}
      isModalEditor={isModalEditor}
      handleImage={handleImage}
      toolbarConfig={toolbarConfig}
      editorHeight={editorHeight}
      handleEditorReady={handleEditorReady}
      isFileAttachment={isFileAttachment}
    />
  );
};

export default TextEditor;
