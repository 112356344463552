"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocalUploadFileApi = void 0;
const adapter_1 = require("./adapter");
class LocalUploadFileApi extends adapter_1.AbstractUploadAdapter {
    upload(data, onProgress, isAuthenticated = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.client.upload('/uploads/', data, {}, isAuthenticated, onProgress);
            if (Array.isArray(res) && res.length > 0) {
                return res[0];
            }
            return { file_path: '' };
        });
    }
    uploadMultiFiles(data, onProgress, isAuthenticated = false) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.client.upload('/uploads/', data, {}, isAuthenticated, onProgress);
        });
    }
    uploadImage(params, callback, isAuthenticated = false) {
        return __awaiter(this, void 0, void 0, function* () {
            params.append('folder_name', 'photos');
            return yield this.upload(params, callback, isAuthenticated);
        });
    }
    uploadMultiImages(params, callback, isAuthenticated = false) {
        return __awaiter(this, void 0, void 0, function* () {
            params.append('folder_name', 'photos');
            return yield this.uploadMultiFiles(params, callback, isAuthenticated);
        });
    }
}
exports.LocalUploadFileApi = LocalUploadFileApi;
