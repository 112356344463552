import { DigitalAdTypes } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';
import { UploadProps } from 'antd';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useRef } from 'react';

import { AlertTextError } from '@/components/alert/SweetAlert';
import { DigitalUploadType } from '@/utils/enum';
import { getFileUrlWithAuthentication, validateVideoDuration } from '@/utils/upload';
import { splitString } from '@/utils/utils';
import UploadDigitalMediaView from './UploadDigitalMediaView';
import { color as themeColor } from '@/styles/theme';

const videoFormats = ['.mp4', '.mov', '.wmv', '.avi'];
const imageFormats = ['.bmp', '.jpeg', '.jpg', '.png'];

const MAX_VIDEO_DIGITAL_SIZE = 1024 * 1024 * 50;
const MAX_IMAGE_DIGITAL_SIZE = 1024 * 1024 * 10;
const MAX_IMAGE_HANDWRITTEN_SIZE = 1024 * 1024 * 1;

export enum UploadMediaType {
  DIGITAL_MEDIA = 'digital_media',
  NORMAL_MEDIA = 'normal_file',
  HANDWRITTEN_IMAGE_MEDIA = 'handwritten_image_file',
}

export type SelectedFileNameDigitalMediaType = {
  selectedFile?: File | Blob;
  selectedFileName?: string;
  selectedFileData?: {
    url?: string;
    id?: string;
  };
  file_type?: string;
  digital_ad_type?: string;
  digital_type_id?: string;
};

export type FileType<T extends true | undefined> = {
  isUseMultiple?: T;
  value?: T extends true ? SelectedFileNameDigitalMediaType[] : SelectedFileNameDigitalMediaType;
  onSelectedFile: (
    file: (T extends true ? SelectedFileNameDigitalMediaType[] : SelectedFileNameDigitalMediaType) | null,
  ) => void;
};

type UploadDigitalMediaProps<T extends true | undefined> = FileType<T> & {
  mode?: DigitalAdTypes;
  isRequestAdmin?: boolean;
  buttonColor?: 'pink' | 'black';
  disabled?: boolean;
  title?: string;
  selectFileButtonLabel?: string;
  uploadType?: UploadMediaType;
  beforeUpload?: Exclude<UploadProps['beforeUpload'], undefined>;
  onLoadingUpload?: (flag: boolean) => void;
  alignPreviewImage?: 'center' | 'left';
  className?: string;
  requiredMark?: boolean;
  onDeleteFile?: (flag: boolean) => void;
  isUseDisclaimer?: boolean;
  limitImage?: number;
  labelColor?: keyof typeof themeColor;
};

const UploadDigitalMedia = <T extends true | undefined = undefined>({
  title = 'Upload Media',
  selectFileButtonLabel = 'Select File',
  uploadType = UploadMediaType.DIGITAL_MEDIA,
  value,
  mode,
  isRequestAdmin = false,
  disabled,
  buttonColor = 'black',
  beforeUpload,
  onLoadingUpload,
  onSelectedFile,
  alignPreviewImage = 'center',
  className,
  requiredMark,
  onDeleteFile,
  isUseDisclaimer = true,
  isUseMultiple = undefined,
  limitImage,
  labelColor,
}: UploadDigitalMediaProps<T>) => {
  const allowedExtensions: string[] = [];
  if (!mode) {
    allowedExtensions.push(...videoFormats, ...imageFormats);
  } else if (mode === DigitalAdTypes.DIGITAL_AD_IMAGE) {
    allowedExtensions.push(...imageFormats);
  } else {
    allowedExtensions.push(...videoFormats);
  }
  const inputFileRef = useRef<HTMLInputElement>(null);

  const [digitalUploadLoading, setDigitalUploadLoading] = React.useState<boolean>(false);
  const [selectedFileName, setSelectedFileName] = React.useState<SelectedFileNameDigitalMediaType[] | null>(null);

  useEffect(() => {
    if (!onLoadingUpload) return;
    onLoadingUpload(digitalUploadLoading);
  }, [digitalUploadLoading]);

  useEffect(() => {
    if (!onSelectedFile) return;
    if (!isUseMultiple) {
      onSelectedFile((selectedFileName?.[0] || null) as any);
    } else {
      onSelectedFile((selectedFileName || []) as any);
    }
  }, [selectedFileName?.[0], selectedFileName?.length]);

  useEffect(() => {
    if (isEmpty(value)) {
      setSelectedFileName(null);
    } else if (Array.isArray(value) && value?.length) {
      for (let index = 0; index < value.length; index++) {
        value[index].selectedFileName = splitString(value[index].selectedFileName, 13, 8);
      }
      setSelectedFileName(value);
    } else if (!Array.isArray(value)) {
      value.selectedFileName = splitString(value.selectedFileName, 13, 8);
      setSelectedFileName([value]);
    } else {
      setSelectedFileName(null);
    }
  }, [value]);

  const onBtnClick = () => {
    if (isUseMultiple && selectedFileName?.length === limitImage) {
      AlertTextError('Action Failed', `Maximum ${limitImage} images can be uploaded..`);
      return;
    }
    if (!inputFileRef?.current) return;
    inputFileRef?.current?.click();
  };

  const handleUploadDigitalMediaForDigitalAds = async (event: any, file: File) => {
    try {
      setDigitalUploadLoading(true);
      const typeMedia = file?.type.split('/')[0];
      const mediaType =
        typeMedia === DigitalUploadType.IMAGE ? DigitalAdTypes.DIGITAL_AD_IMAGE : DigitalAdTypes.DIGITAL_AD_VIDEO;
      const mediaUrl = await getFileUrlWithAuthentication(
        file,
        'media',
        (e) => {
          setDigitalUploadLoading(false);
          AlertTextError('Upload Failed', e?.message);
        },
        true,
        mediaType,
        isRequestAdmin,
      );
      if (!mediaUrl) return;
      const data = {
        selectedFile: file,
        selectedFileName: splitString(get(event, 'target.files[0].name', ''), 13, 8),
        selectedFileData: mediaUrl,
        file_type: typeMedia,
        digital_ad_type:
          typeMedia === DigitalUploadType.IMAGE ? DigitalAdTypes.DIGITAL_AD_IMAGE : DigitalAdTypes.DIGITAL_AD_VIDEO,
      };
      setSelectedFileName((prev) => {
        if (isUseMultiple) return [...(prev || []), data];
        else return [data];
      });
    } catch (error: any) {
      // Do nothing
    } finally {
      setDigitalUploadLoading(false);
      event.target.value = '';
    }
  };

  const reNameFile = (fileName: string) => {
    const fileNameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.')).toLowerCase();
    const fileExtension = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();
    return `${fileNameWithoutExtension}(1)${fileExtension}`;
  };

  const handleUploadMediaFile = async (event: any, file: File) => {
    try {
      setDigitalUploadLoading(true);
      const typeMedia = file?.type.split('/')[0];
      const data = {
        selectedFile: file,
        selectedFileName: splitString(get(event, 'target.files[0].name', ''), 13, 8),
        file_type: typeMedia,
        digital_ad_type:
          typeMedia === DigitalUploadType.IMAGE ? DigitalAdTypes.DIGITAL_AD_IMAGE : DigitalAdTypes.DIGITAL_AD_VIDEO,
      };
      setSelectedFileName((prev) => {
        if (prev?.[0]?.selectedFileName === data.selectedFileName) {
          data.selectedFileName = reNameFile(data.selectedFileName);
        }
        if (isUseMultiple) {
          return [...(prev || []), data];
        } else return [data];
      });
    } catch (error: any) {
      // Do nothing
    } finally {
      setDigitalUploadLoading(false);
      event.target.value = '';
    }
  };
  const getFileExtension = (file: any) => file.name.substring(file.name.lastIndexOf('.')).toLowerCase();

  const isValidFileType = (file: any) => {
    const fileExtension = getFileExtension(file);
    return allowedExtensions.includes(fileExtension);
  };

  const handleChangeMediaFile = async (event: any) => {
    const file = event?.target?.files[0];
    if (!file) return;

    if (!isValidFileType(file) && inputFileRef.current) {
      const string = `Your media is incorrect. Please upload again.(Supported file: ${allowedExtensions.join(' | ')})`;
      inputFileRef.current.value = '';
      return AlertTextError('Upload Failed', string);
    }

    if (
      (file?.size > MAX_VIDEO_DIGITAL_SIZE ||
        (mode === DigitalAdTypes.DIGITAL_AD_IMAGE && file?.size > MAX_IMAGE_DIGITAL_SIZE) ||
        (uploadType === UploadMediaType.HANDWRITTEN_IMAGE_MEDIA && file?.size > MAX_IMAGE_HANDWRITTEN_SIZE)) &&
      inputFileRef.current
    ) {
      inputFileRef.current.value = '';
      return AlertTextError('Upload Failed', 'Your media size is too large, please choose another media.');
    }

    if (videoFormats.includes(getFileExtension(file))) {
      const isValidDuration = await validateVideoDuration(file);
      if (typeof isValidDuration !== 'undefined' && !isValidDuration)
        return AlertTextError('Upload Failed', 'Your video duration exceeds 30 seconds.');
    }

    let croppedFile = file;
    if (beforeUpload && imageFormats.includes(getFileExtension(file))) {
      croppedFile = await beforeUpload(file, event?.target?.files);
    }

    switch (uploadType) {
      case UploadMediaType.DIGITAL_MEDIA:
        handleUploadDigitalMediaForDigitalAds(event, croppedFile);
        break;

      default:
        handleUploadMediaFile(event, croppedFile);
        break;
    }
  };

  return (
    <UploadDigitalMediaView
      className={className}
      alignPreviewImage={alignPreviewImage}
      requiredMark={requiredMark}
      isUseDisclaimer={isUseDisclaimer}
      title={title}
      selectFileButtonLabel={selectFileButtonLabel}
      disabled={disabled}
      buttonColor={buttonColor}
      inputFileRef={inputFileRef}
      allowedExtensions={allowedExtensions}
      digitalUploadLoading={digitalUploadLoading}
      selectedFileName={selectedFileName}
      isUseMultiple={isUseMultiple}
      labelColor={labelColor}
      setDigitalUploadLoading={setDigitalUploadLoading}
      onSelectedFile={onSelectedFile}
      onDeleteFile={onDeleteFile}
      handleChangeMediaFile={handleChangeMediaFile}
      setSelectedFileName={setSelectedFileName}
      onBtnClick={onBtnClick}
    />
  );
};

export default UploadDigitalMedia;
