"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GiftCardTypeEnum = exports.WritingStyleEnum = exports.CardTypeEnum = exports.HeaderTypeEnum = void 0;
/* eslint-disable @typescript-eslint/no-explicit-any */
var HeaderTypeEnum;
(function (HeaderTypeEnum) {
    HeaderTypeEnum["Image"] = "image";
    HeaderTypeEnum["Text"] = "text";
})(HeaderTypeEnum = exports.HeaderTypeEnum || (exports.HeaderTypeEnum = {}));
var CardTypeEnum;
(function (CardTypeEnum) {
    CardTypeEnum["Flat"] = "flat5x7";
    CardTypeEnum["Fold"] = "folded5x7";
})(CardTypeEnum = exports.CardTypeEnum || (exports.CardTypeEnum = {}));
var WritingStyleEnum;
(function (WritingStyleEnum) {
    WritingStyleEnum["WRITING_STYLE_TARZAN"] = "Tarzan";
    WritingStyleEnum["WRITING_STYLE_STITCH"] = "Stitch";
    WritingStyleEnum["WRITING_STYLE_PINOCCHIO"] = "Pinocchio";
    WritingStyleEnum["WRITING_STYLE_SIMBA"] = "Simba";
    WritingStyleEnum["WRITING_STYLE_ROO"] = "Roo";
    WritingStyleEnum["WRITING_STYLE_NEMO"] = "Nemo";
    WritingStyleEnum["WRITING_STYLE_LUMIERE"] = "Lumiere";
    WritingStyleEnum["WRITING_STYLE_KAA"] = "Kaa";
    WritingStyleEnum["WRITING_STYLE_DUMBO"] = "Dumbo";
    WritingStyleEnum["WRITING_STYLE_BOLT"] = "Bolt";
    //   WRITING_STYLE_BELLE = 'Belle',  // Deprecated
    WritingStyleEnum["WRITING_STYLE_CINDERELLA"] = "Cinderella";
    WritingStyleEnum["WRITING_STYLE_HERCULES"] = "Hercules";
    WritingStyleEnum["WRITING_STYLE_MERLIN"] = "Merlin";
    WritingStyleEnum["WRITING_STYLE_RAPUNZEL"] = "Rapunzel";
    WritingStyleEnum["WRITING_STYLE_SCAR"] = "Scar";
})(WritingStyleEnum = exports.WritingStyleEnum || (exports.WritingStyleEnum = {}));
var GiftCardTypeEnum;
(function (GiftCardTypeEnum) {
    GiftCardTypeEnum["GIFT_STARBUCK"] = "Starbucks Gift Card";
    GiftCardTypeEnum["GIFT_AMAZON"] = "Amazon Gift Card";
    GiftCardTypeEnum["GIFT_VISA"] = "Visa Gift Card";
    GiftCardTypeEnum["GIFT_HOME_DEPO"] = "Home Depot Gift Card";
    GiftCardTypeEnum["GIFT_LOWE"] = "Lowe\u2019s Gift Card";
})(GiftCardTypeEnum = exports.GiftCardTypeEnum || (exports.GiftCardTypeEnum = {}));
