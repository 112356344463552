"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiClient = void 0;
const axios_1 = require("axios");
const error_1 = require("./error");
const axios_cache_adapter_1 = require("axios-cache-adapter");
const qs_1 = require("qs");
const TIMEOUT_REQUEST_DEFAULT = 5 * 60 * 1000; //300s
const CACHE_EXPIRES_DEFAULT = 5 * 60 * 1000; //300s
class ApiClient {
    constructor(apiConfig) {
        var _a;
        this.apiConfig = apiConfig;
        const cache = axios_cache_adapter_1.setupCache({
            maxAge: apiConfig.cacheExpiresIn ? apiConfig.cacheExpiresIn * 1000 : CACHE_EXPIRES_DEFAULT,
            exclude: {
                query: false,
            },
        });
        const options = {
            baseURL: apiConfig.baseUrl,
            timeout: (_a = apiConfig.timeout) !== null && _a !== void 0 ? _a : TIMEOUT_REQUEST_DEFAULT,
            withCredentials: true,
            headers: this.headers(),
            adapter: apiConfig.cache
                ? apiConfig.cache.adapter
                : apiConfig.enableCache && cache.adapter
                    ? cache.adapter
                    : undefined,
        };
        this.axiosInstance = axios_1.default.create(options);
    }
    headers() {
        let headers = {};
        if (this.apiConfig.userAgent) {
            headers = Object.assign(Object.assign({}, headers), { httpAgent: this.apiConfig.userAgent, httpsAgent: this.apiConfig.userAgent });
        }
        if (this.apiConfig.appVersion) {
            headers = Object.assign(Object.assign({}, headers), { 'App-Version': this.apiConfig.appVersion });
        }
        if (this.apiConfig.deviceId) {
            headers = Object.assign(Object.assign({}, headers), { 'Device-Id': this.apiConfig.deviceId });
        }
        if (this.apiConfig.clientId) {
            headers = Object.assign(Object.assign({}, headers), { 'Client-Id': this.apiConfig.clientId });
        }
        return headers;
    }
    setApiConfig(apiConfig) {
        this.apiConfig = apiConfig;
    }
    getApiConfig() {
        return this.apiConfig;
    }
    setAuthenticator(authenticator) {
        this.authenticator = authenticator;
        return this;
    }
    getAuthToken() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.authenticator) {
                return null;
            }
            return this.authenticator.getAuthToken();
        });
    }
    success(response) {
        return response.data;
    }
    error(e) {
        if (e.response && e.response.data) {
            const errorCode = e.response.data.code || 'unknown';
            const statusCode = e.response.data.status_code || 'unknown';
            const summary = e.response.data.summary || 'unknown';
            const message = e.response.data.error_detail || e.response.data.message || e.message;
            return new error_1.ApiError(message, errorCode, statusCode, summary);
        }
        return this.handleUnknownError(e);
    }
    handleUnknownError(e) {
        return new error_1.ApiError(e.message, 'unknown', 'unknown', 'unknown');
    }
    uri(uri) {
        return `${this.apiConfig.baseUrl}${uri}`;
    }
    configs(config, isAuthenticated = true, isSerializer = false) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (isSerializer) {
                config = Object.assign({}, config, {
                    paramsSerializer: (params) => {
                        return qs_1.stringify(params, { indices: false });
                    },
                });
            }
            const authHeader = yield ((_a = this.authenticator) === null || _a === void 0 ? void 0 : _a.getAuthHeader());
            let headers = (config === null || config === void 0 ? void 0 : config.headers) || {};
            if (this.apiConfig.accessTokenAdmin) {
                headers = Object.assign(Object.assign({}, headers), { Authorization: `Bearer ${this.apiConfig.accessTokenAdmin}` });
            }
            else if (authHeader && isAuthenticated) {
                headers = Object.assign(Object.assign({}, headers), { Authorization: authHeader });
            }
            headers = Object.assign({}, headers);
            return Object.assign(Object.assign({}, config), { headers });
        });
    }
    addInterceptor(interceptor) {
        if (interceptor.request) {
            this.addRequestInterceptor(interceptor.request);
        }
        if (interceptor.response) {
            this.addResponseInterceptor(interceptor.response);
        }
    }
    addRequestInterceptor(interceptor) {
        this.axiosInstance.interceptors.request.use(interceptor);
    }
    addResponseInterceptor(interceptor) {
        this.axiosInstance.interceptors.response.use(interceptor);
    }
    get(uri, params = {}, isAuthenticated = true, isSerializer = false) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const config = yield this.configs({ params }, isAuthenticated, isSerializer);
                const r = yield this.axiosInstance.get(this.uri(uri), config);
                return this.success(r);
            }
            catch (e) {
                throw this.error(e);
            }
        });
    }
    post(uri, data, params = {}, isAuthenticated = true) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const config = yield this.configs({ params }, isAuthenticated);
                const r = yield this.axiosInstance.post(this.uri(uri), data, config);
                return this.success(r);
            }
            catch (e) {
                throw this.error(e);
            }
        });
    }
    patch(uri, data, params = {}, isAuthenticated = true) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const config = yield this.configs({ params }, isAuthenticated);
                const r = yield this.axiosInstance.patch(this.uri(uri), data, config);
                return this.success(r);
            }
            catch (e) {
                throw this.error(e);
            }
        });
    }
    put(uri, data, params = {}, isAuthenticated = true) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const config = yield this.configs({ params }, isAuthenticated);
                const r = yield this.axiosInstance.put(this.uri(uri), data, config);
                return this.success(r);
            }
            catch (e) {
                throw this.error(e);
            }
        });
    }
    delete(uri, params = {}, isAuthenticated = true) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const config = yield this.configs({ params }, isAuthenticated);
                const r = yield this.axiosInstance.delete(this.uri(uri), config);
                return this.success(r);
            }
            catch (e) {
                throw this.error(e);
            }
        });
    }
    upload(uri, data, params = {}, isAuthenticated = true, onProgress) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const onUploadProgress = (progressEvent) => {
                    const loaded = progressEvent.loaded;
                    const total = progressEvent.total;
                    const percent = Math.round((loaded / total) * 100);
                    if (typeof onProgress === 'function') {
                        onProgress(percent);
                    }
                };
                const headers = { 'Content-Type': 'multipart/form-data' };
                const config = yield this.configs({ params, headers, onUploadProgress }, isAuthenticated);
                const r = yield this.axiosInstance.post(this.uri(uri), data, Object.assign(Object.assign({}, config), { timeout: 0 }));
                return this.success(r);
            }
            catch (e) {
                throw this.error(e);
            }
        });
    }
}
exports.ApiClient = ApiClient;
