"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NFTMetadataApi = void 0;
const caching_1 = require("../caching");
class NFTMetadataApi {
    constructor(client) {
        this.client = client;
        this.staleTimes = {
            listNFTMetadata: 3,
            getHomeownersMailedDetails: 3,
            getCampaignInteractions: 3,
        };
        this.caching = new caching_1.Caching();
    }
    listNFTMetadata(page = 1, limit = 20) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `/campaigns/nft-metadata/`;
            const params = { page, limit };
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.listNFTMetadata,
                });
            return response;
        });
    }
    getNFTMetadata(campaign_order_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.get(`/campaigns/nft-metadata/${campaign_order_id}/`);
        });
    }
    updateNFTMetadata(campaign_order_id, args) {
        return __awaiter(this, void 0, void 0, function* () {
            this.caching.deleteCacheByEndpoint('/campaigns/nft-metadata/');
            return this.client.patch(`/campaigns/nft-metadata/${campaign_order_id}/`, args);
        });
    }
    deleteNFTMetadata(campaign_order_id) {
        return __awaiter(this, void 0, void 0, function* () {
            this.caching.deleteCacheByEndpoint('/campaigns/nft-metadata/');
            return this.client.delete(`/campaigns/nft-metadata/${campaign_order_id}/`);
        });
    }
    getHomeownersMailedDetails(page = 1, limit = 20, campaign_order_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `/campaigns/nft-metadata/${campaign_order_id}/homeowners-mailed/`;
            const params = { page, limit };
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.getHomeownersMailedDetails,
                });
            return response;
        });
    }
    getCampaignInteractions(page = 1, limit = 20, campaign_order_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `/campaigns/nft-metadata/${campaign_order_id}/campaign-interactions/`;
            const params = { page, limit };
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.getCampaignInteractions,
                });
            return response;
        });
    }
}
exports.NFTMetadataApi = NFTMetadataApi;
