enum UserAction {
  UPDATE_PUBLIC_PROFILE = '@user/UPDATE_PUBLIC_PROFILE',
  CAMPAIGN_ASSETS = '@user/CAMPAIGN_ASSETS',
  AGENT_PAST_ORDERS = '@user/AGENT_PAST_ORDERS',
  ADD_NEW_ORDER = '@user/ADD_NEW_ORDER',
  GET_LIST_MEMBERS_SEARCH = '@user/GET_LIST_MEMBERS_SEARCH',
  GET_LIST_MEMBERS_SEARCH_FILE = '@user/GET_LIST_MEMBERS_SEARCH_FILE',
  IS_SKIPSETUP = '@user/IS_SKIPSETUP',
  SAVE_CARD_LIST = '@user/SAVE_CARD_LIST',
  VISIBLE_MODAL_CONFIRM_PAYMENT = '@user/VISIBLE_MODAL_CONFIRM_PAYMENT',
  VISIBLE_MODAL_PAYMENT = '@user/VISIBLE_MODAL_PAYMENT',
  DATA_CAMPAIGNS = '@user/DATA_CAMPAIGNS',
  EMAIL_TEMPLATE_LIST = '@user/EMAIL_TEMPLATE_LIST',
  CAMPAIGN_TEMPLATE = '@user/CAMPAIGN_TEMPLATE',
  LIST_TRIGGER = '@user/LIST_TRIGGER',
  CURRENT_STATE_TRIGGER = '@user/CURRENT_STATE_TRIGGER',
  DELETE_SMART_TRIGGER = '@user/DELETE_SMART_TRIGGER',
  USER_PAYMENT_CARD = '@user/USER_PAYMENT_CARD',
  SUGGEST_MILESTONE = '@user/SUGGEST_MILESTONE',
  DIGITAL_CAMPAIGN_ASSETS = '@user/DIGITAL_CAMPAIGN_ASSETS',
  DIGITAL_TEMPLATES = '@user/DIGITAL_TEMPLATES',
  UPDATE_PREVIEW_TEMPLATE = '@user/UPDATE_PREVIEW_TEMPLATE',
  GLOBAL_TEMPLATE = '@user/GLOBAL_TEMPLATE',
  VISIBLE_MODAL_PAYMENT_OPTIONS = '@user/VISIBLE_MODAL_PAYMENT_OPTIONS',
}

export default UserAction;
