"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SlipStreamApi = void 0;
const caching_1 = require("../caching");
class SlipStreamApi {
    constructor(client) {
        this.client = client;
        this.staleTimes = {
            agentInfo: 2,
            agentSoldHouses: 3,
            listTeamMember: 3,
            listBrokerAgents: 3,
            listAgent: 10,
        };
        this.caching = new caching_1.Caching();
    }
    agentInfo(agent_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `/slipstream/submission/info/`;
            const params = { agent_id };
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.agentInfo,
                });
            return response;
        });
    }
    submit(args) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/slipstream/submission/`, args);
        });
    }
    refreshSubmission(args) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/slipstream/submission/refresh/`, args);
        });
    }
    deleteSubmission(submission_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.delete(`/slipstream/submission/${submission_id}/`);
        });
    }
    updateSubmission(submission_id, args) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.patch(`/slipstream/submission/${submission_id}/`, args);
        });
    }
    agentSoldHouses(agent_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `/slipstream/sold_houses/`;
            const params = { agent_id };
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.agentSoldHouses,
                });
            return response;
        });
    }
    refreshAgentSoldHouses(args) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/slipstream/sold_houses/refresh/`, args);
        });
    }
    listAgent(page = 1, limit = 20, search_agent) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `/slipstream/agents/`;
            const params = { page, limit, search_agent };
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.listAgent,
                });
            return response;
        });
    }
    listUserAgent(page = 1, limit = 20, search_agent) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `/slipstream/agents/`;
            const params = { page, limit, search_agent, is_user: true, is_follower: true };
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.listAgent,
                });
            return response;
        });
    }
    listFollowerAgent(page = 1, limit = 20, search_agent) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `/slipstream/agents/`;
            const params = { page, limit, search_agent, is_follower: true };
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.listAgent,
                });
            return response;
        });
    }
    listAgentProspect(page = 1, limit = 20, search_agent) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `/slipstream/agents/prospect/`;
            const params = { page, limit, search_agent };
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.listAgent,
                });
            return response;
        });
    }
    deleteAgentProspect(agent_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.delete(`/slipstream/agents/prospect/${agent_id}/`);
        });
    }
    readyAgentProspect(args) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/slipstream/agents/prospect/ready/`, args);
        });
    }
    importAgent(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/slipstream/agents/import/`, params);
        });
    }
    importAgentProspect(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/slipstream/agents/import/prospect/`, params);
        });
    }
    manageAgent(args) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/slipstream/agents/manage/`, args);
        });
    }
    inviteAgents(args) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/slipstream/agents/invite/`, args);
        });
    }
    addAgent(args) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/slipstream/agents/add/`, args);
        });
    }
    createTeamMember(data) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/slipstream/team-members/`, data);
        });
    }
    existTeamMember(data) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/slipstream/team-members/exist/`, data);
        });
    }
    listTeamMember(page = 1, limit = 20) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `/slipstream/team-members/`;
            const params = { page, limit };
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.listTeamMember,
                });
            return response;
        });
    }
    deleteTeamMember(agent_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.delete(`/slipstream/team-members/${agent_id}`);
        });
    }
    listBrokerAgents(page = 1, limit = 20) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `/slipstream/broker-agents/`;
            const params = { page, limit };
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.listBrokerAgents,
                });
            return response;
        });
    }
    loginAsAgent(agent_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.get(`/slipstream/broker-agents/${agent_id}/login-as/`);
        });
    }
    brokerAddAgent(args) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/slipstream/broker-agents/`, args);
        });
    }
    confirmBrokerAgent(broker_agent_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/slipstream/broker-agents/${broker_agent_id}/confirm-invitation/`, {});
        });
    }
    resendAgentInvitation(broker_agent_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.post(`/slipstream/broker-agents/${broker_agent_id}/resend-invitation/`, {});
        });
    }
    paySubBrokerAgent(broker_agent_id, params) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.patch(`/slipstream/broker-agents/${broker_agent_id}/`, params);
        });
    }
    removeSubBrokerAgent(broker_agent_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.delete(`/slipstream/broker-agents/${broker_agent_id}/`);
        });
    }
}
exports.SlipStreamApi = SlipStreamApi;
