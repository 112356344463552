import DisclaimerTooltip from '@/components/disclaimer-tooltip';
import MailingService from '@/services/mailing';
import { AppSizeType } from '@/styles/theme';
import { limit_mailing_list } from '@/utils/constant';
import {
  MailingList,
  MailingListStatusEnum,
  MailingListTypeEnum,
  PastClientTypeEnum,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/mailing/models';
import { Empty, Select, Space } from 'antd';
import { BaseOptionType } from 'antd/lib/select';
import { debounce, isArray, omit } from 'lodash';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AppSelectV2 from '../AppSelectV2';
import { SelectColor } from '../AppSelectV2/AppSelectV2Styled';
import { AppSelectV2Props } from '../AppSelectV2/types';

interface MailingState {
  loadingMoreMailing: boolean;
  hasMoreItemsMailing: boolean;
  currentPageMailing: number;
  searchValueMailing: string;
}

export interface SelectObjType extends MailingList {
  label: string;
  value: string;
  disabled: boolean;
  pastClientType?: PastClientTypeEnum;
}

interface MailingListSelectProps
  extends BaseOptionType,
    Pick<AppSelectV2Props, 'hasError' | 'errorMessage' | 'labelColor'> {
  size?: AppSizeType;
  color?: SelectColor;
  requiredMark?: boolean;
  label?: string;
  className?: string;
  handleChange: (value: string | string[], options: SelectObjType[] | SelectObjType) => void;
  handleBlur?: (e: React.FocusEvent) => void;
  handleFocus?: (e: React.FocusEvent) => void;
  value: string | null | undefined | string[];
  placeholder?: string;
  disabled?: boolean;
  name?: string;
  onSearch?: (value: string) => void;
  isTrigger?: boolean;
  searchValue?: string;
  searchIds?: string;
  loading?: boolean;
  onOptionsLoaded?: (mailings: SelectObjType[]) => void;
  isDigital?: boolean;
  disclaimer?: ReactNode;
  agentId?: string;
  skipLoading?: boolean;
  isReport?: boolean;
  mailingListType?: MailingListTypeEnum;
  isMyListing?: boolean;
  isExcludeMyListing?: boolean;
  isDisableIfSelectMyListing?: boolean;
  isOnlyPastClient?: boolean;
}

const MailingListSelect = (props: MailingListSelectProps) => {
  const {
    className = '',
    handleChange,
    handleBlur,
    handleFocus,
    value,
    placeholder,
    isTrigger,
    searchValue,
    loading = false,
    onOptionsLoaded,
    isDigital,
    disclaimer,
    color,
    agentId,
    skipLoading = false,
    isReport,
    mailingListType = undefined,
    isMyListing = false,
    isExcludeMyListing = false,
    searchIds,
    isDisableIfSelectMyListing,
    size,
    isOnlyPastClient,
    ...rest
  } = props;

  const valueRef = useRef<any>(value);
  valueRef.current = value;
  const inputRef = useRef<any>(null);
  const [optionsMailing, setOptionsMailing] = useState<SelectObjType[]>([]);
  const [mailingState, setMailingState] = useState<MailingState>({
    searchValueMailing: searchValue || '',
    loadingMoreMailing: false,
    hasMoreItemsMailing: true,
    currentPageMailing: 1,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { loadingMoreMailing, hasMoreItemsMailing, currentPageMailing, searchValueMailing } = mailingState;
  const onFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      handleFocus && handleFocus(e);
    },
    [handleFocus],
  );

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      handleBlur && handleBlur(e);
    },
    [handleBlur],
  );

  const onChange = useCallback(
    (value: string | string[], option: SelectObjType | SelectObjType[]) => {
      handleChange && handleChange(value, option);

      if (rest.mode !== 'multiple') {
        onInputRefBlur();
      }
    },
    [handleChange],
  );

  const updateMailingState = useCallback(
    (type: string, value: any) => {
      setMailingState((prevState) => ({
        ...prevState,
        [type]: value,
      }));
    },
    [setMailingState],
  );

  const handleInfiniteLoadMailing = useCallback(
    async (event: React.UIEvent<HTMLDivElement>) => {
      if (event && shouldLoadMore(event)) {
        updateMailingState('loadingMoreMailing', true);
        try {
          setIsLoading(true);
          const res = await MailingService.listMailingList(
            currentPageMailing,
            limit_mailing_list,
            mailingState.searchValueMailing,
            isMyListing
              ? MailingListTypeEnum.My_Listing
              : isOnlyPastClient
              ? MailingListTypeEnum.Past_Client
              : mailingListType,

            isReport ? true : false,
            isDigital ? true : false,
            false,
            Date.now(),
            agentId || undefined,
            false,
            isExcludeMyListing,
          );

          setOptionsMailing((prev) => [...prev, ...convertFormatSelect(res?.results)]);
          if (res.links?.next) {
            setMailingState((prevState) => ({
              ...prevState,
              currentPageMailing: prevState.currentPageMailing + 1,
            }));
          } else {
            updateMailingState('hasMoreItemsMailing', false);
          }
        } catch (error) {
          // Do nothing
        } finally {
          setIsLoading(false);
          updateMailingState('loadingMoreMailing', false);
        }
      }
    },
    [loadingMoreMailing, hasMoreItemsMailing, mailingState],
  );

  const shouldLoadMore = (event: React.UIEvent<HTMLDivElement>) => {
    event.persist();
    const target = event.target as HTMLDivElement;
    const isEndOfList = target.scrollTop > (2 / 5) * target.scrollHeight;
    return isEndOfList && !loadingMoreMailing && hasMoreItemsMailing;
  };

  ///search input

  useEffect(() => {
    setMailingState((prevState) => ({
      ...prevState,
      searchValueMailing: searchValue ?? '',
    }));
  }, [searchValue]);

  useEffect(() => {
    return () => {
      updateMailingState('searchValueMailing', '');
    };
  }, [isTrigger]);

  useEffect(() => {
    debounceSearchMailingLists(searchValueMailing, isDigital, agentId);
  }, [searchValueMailing, isDigital, agentId]);

  useEffect(() => {
    if (!optionsMailing || !onOptionsLoaded) return;
    onOptionsLoaded(optionsMailing);
  }, [optionsMailing]);

  const debounceSearchMailingLists = useCallback(
    debounce(async (value, isDigital, agentId) => {
      updateMailingState('loadingMoreMailing', true);
      if (value === '') {
        updateMailingState('hasMoreItemsMailing', true);
      }
      setOptionsMailing([]);
      try {
        setIsLoading(true);
        const res = await MailingService.listMailingList(
          1,
          limit_mailing_list,
          searchIds ? undefined : value,
          isMyListing
            ? MailingListTypeEnum.My_Listing
            : isOnlyPastClient
            ? MailingListTypeEnum.Past_Client
            : mailingListType,

          isReport ? true : false,
          isDigital ? true : false,
          false,
          Date.now(),
          agentId || undefined,
          false,
          isExcludeMyListing,
          searchIds,
        );
        const filteredML = convertFormatSelect(res?.results);
        setOptionsMailing(filteredML);
        if (res.links?.next) {
          updateMailingState('currentPageMailing', 2);
        } else {
          updateMailingState('hasMoreItemsMailing', false);
        }
      } catch (error: any) {
        // Do nothing
      } finally {
        updateMailingState('loadingMoreMailing', false);
        setIsLoading(false);
      }
    }, 500),
    [value],
  );

  const onInputRefBlur = useCallback(() => {
    inputRef.current?.blur();
  }, [inputRef]);

  const convertFormatSelect = useCallback(
    (data) => {
      if (!data?.length) return [];
      if (!isDisableIfSelectMyListing || valueRef.current?.length === 0)
        return data.map((v) => {
          return {
            ...v,
            value: v.id,
            label: v.name,
            disabled: false,
          };
        });
      else {
        const isSelectMyListing =
          valueRef.current &&
          isArray(valueRef.current) &&
          valueRef.current.some(
            (id) => data.find((mailing) => mailing.id === id)?.type === MailingListTypeEnum.My_Listing,
          );

        return data.map((v) => ({
          ...v,
          value: v.id,
          label: v.name,
          disabled:
            (v.type !== MailingListTypeEnum.My_Listing && isSelectMyListing) ||
            (v.type === MailingListTypeEnum.My_Listing && !isSelectMyListing),
        }));
      }
    },
    [isDisableIfSelectMyListing, value],
  );

  useEffect(() => {
    setOptionsMailing((v) => convertFormatSelect(v));
  }, [valueRef.current]);

  const handleSearchMailingLists = useCallback(
    (value: string) => {
      updateMailingState('searchValueMailing', value);
    },
    [updateMailingState],
  );

  if (Array.isArray(value)) {
    rest.mode === 'multiple';
  }
  // Removes props that are not part of the component's type or the DOM
  const restOmitted = useMemo(() => ({ ...omit(rest, ['onSearch', 'isActive']) }), [rest]);

  return (
    <div className={className ?? ''}>
      <AppSelectV2
        {...restOmitted}
        labelChildren={disclaimer}
        loading={!skipLoading && (loading || isLoading)}
        allowClear
        showArrow
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        notFoundContent={
          loadingMoreMailing ? 'Loading...' : <Empty className="empty-data" image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
        placeholder={placeholder}
        value={value}
        searchValue={searchValueMailing}
        onPopupScroll={handleInfiniteLoadMailing}
        onSearch={handleSearchMailingLists}
        color={color}
        size={size}
      >
        {optionsMailing.map((mailing) => {
          return (
            <Select.Option
              key={mailing.name}
              value={mailing.value}
              label={mailing.label}
              type={mailing.type}
              pastClientType={mailing.past_client_type}
              disabled={mailing.status === MailingListStatusEnum.InProgress || mailing.disabled}
            >
              <Space>
                <div>
                  {mailing.name}
                  {mailing.type === MailingListTypeEnum.My_Listing && isDisableIfSelectMyListing && (
                    <DisclaimerTooltip
                      title={
                        mailing.type === MailingListTypeEnum.My_Listing
                          ? 'When creating a trigger using the ‘My Listings’ mailing list, the system will generate a new Mailing List centered around the newly found address. You should select the number of Homes to Target and the Rule for the Mailing List creation'
                          : ''
                      }
                    />
                  )}
                  {mailing.status === MailingListStatusEnum.InProgress && (
                    <span style={{ fontStyle: 'italic' }}> - Processing...</span>
                  )}
                </div>
              </Space>
            </Select.Option>
          );
        })}
      </AppSelectV2>
    </div>
  );
};

export default MailingListSelect;
