import { OrderSortby } from '@/utils/enum';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { BaseOptionType, DefaultOptionType } from 'antd/lib/select';
import React, { forwardRef, useCallback, useMemo, useRef } from 'react';
import SelectV2View from './AppSelectV2View';
import { AppSelectV2Props } from './types';

const AppSelectV2 = forwardRef(
  <ValueType, OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType>(
    props: Omit<AppSelectV2Props<ValueType, OptionType>, 'loadMore'>,
    ref: React.Ref<React.ElementRef<typeof SelectV2View>>,
  ) => {
    const {
      value,
      options: optionsProps,
      onLoadMore: onLoadMoreProp,
      onChange,
      sortByType,
      showArrow = true,
      ...rest
    } = props;
    // Convert options to undefined if it is empty array -> fix issue with select show empty options value when mode === multiple
    const options = useMemo(() => {
      if (!optionsProps || optionsProps.length === 0) return undefined;
      return optionsProps;
    }, [optionsProps]);
    const [loadMore, setLoadMore] = React.useState(false);
    const optionsSelectedRef = useRef<OptionType | OptionType[] | null>(null);

    const valueHandle = useMemo((): typeof value => {
      if (
        (typeof value === 'string' && value === '') ||
        (options && options.length === 0)
        // With MultiSelect, value is never null
        // || (Array.isArray(value) && value.length === 0)
      )
        return null;
      if (props.mode === 'multiple' && (!value || (Array.isArray(value) && value.length === 0))) return undefined;
      return value;
    }, [options, value]);

    const getPrefixFilter = <T,>(value: T, type?: OrderSortby): ValueType => {
      switch (type ?? sortByType) {
        case OrderSortby.ASC:
          return (OrderSortby.ASC + value) as unknown as ValueType;
        default:
          return (OrderSortby.DESC + value) as unknown as ValueType;
      }
    };

    const handleOnChange = useCallback(
      (value: ValueType, option: OptionType | OptionType[]) => {
        if (sortByType) {
          optionsSelectedRef.current = option;
          onChange?.(getPrefixFilter(value), option);
        }
        return onChange?.(value, option);
      },
      [onChange],
    );

    const handleLoadMore = useCallback(
      async (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
        if (loadMore || !onLoadMoreProp) return;
        event.persist();
        const target = event.target as HTMLDivElement;
        const isEndOfList = target.scrollTop + target.offsetHeight === target.scrollHeight;
        if (!isEndOfList) return;
        try {
          setLoadMore(true);
          await onLoadMoreProp(event);
        } finally {
          setLoadMore(false);
        }
      },
      [loadMore, onLoadMoreProp, setLoadMore],
    );

    const renderSuffixIcon = useMemo(() => {
      if (!showArrow) return null;
      if ('sortByType' in props) {
        return (
          <div
            className="up-down-arrow"
            css={css`
              display: flex;
              flex-direction: column;
              span {
                display: block;
                height: 12px;
                svg {
                  font-size: 16px !important;
                }
              }
              .opacity-5 {
                opacity: 0.5;
              }
            `}
          >
            <CaretUpOutlined
              className={`suffix-icon ${sortByType === OrderSortby.ASC ? 'opacity-5' : ''}`}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (sortByType === OrderSortby.DESC) return;
                onChange?.(getPrefixFilter(value, OrderSortby.DESC) as ValueType, optionsSelectedRef.current || []);
              }}
            />
            <CaretDownOutlined
              className={`suffix-icon  ${sortByType === OrderSortby.DESC ? 'opacity-5' : ''}`}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();

                if (sortByType === OrderSortby.ASC) return;
                onChange?.(getPrefixFilter(value, OrderSortby.ASC), optionsSelectedRef.current || []);
              }}
            />
          </div>
        );
      }
      return <CaretDownOutlined className="icon ant-select-suffix" />;
    }, [showArrow, sortByType, value]);

    return (
      <SelectV2View
        {...rest}
        ref={ref}
        value={valueHandle}
        options={options}
        onLoadMore={handleLoadMore}
        loadMore={loadMore}
        suffixIcon={showArrow ? renderSuffixIcon : null}
        showArrow={showArrow}
        onChange={handleOnChange}
        filterOption={
          rest.filterOption || (typeof rest.filterOption === 'boolean' && rest.filterOption === false)
            ? rest.filterOption
            : (input, option) =>
                (rest.fieldNames?.label || ((option?.label || '') as string))
                  .trim()
                  .toLowerCase()
                  .includes(input.trim().toLowerCase())
        }
      />
    );
  },
);
export default AppSelectV2 as <ValueType, OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType>(
  props: AppSelectV2Props<ValueType, OptionType> & { ref?: React.Ref<React.ElementRef<typeof SelectV2View>> },
) => ReturnType<typeof SelectV2View>;
