"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TEMPLATE_PREVIEW_TYPE = void 0;
var TEMPLATE_PREVIEW_TYPE;
(function (TEMPLATE_PREVIEW_TYPE) {
    TEMPLATE_PREVIEW_TYPE["EMAIL"] = "email";
    TEMPLATE_PREVIEW_TYPE["POSTGRID_FRONT"] = "postgrid_front";
    TEMPLATE_PREVIEW_TYPE["POSTGRID_BACK"] = "postgrid_back";
    TEMPLATE_PREVIEW_TYPE["SIMPLYNOTED_FRONT"] = "simplynoted_front";
    TEMPLATE_PREVIEW_TYPE["SIMPLYNOTED_BACK"] = "simplynoted_back";
})(TEMPLATE_PREVIEW_TYPE = exports.TEMPLATE_PREVIEW_TYPE || (exports.TEMPLATE_PREVIEW_TYPE = {}));
