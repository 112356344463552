import { color } from '@/styles/theme';
import { css } from '@emotion/react';
import { memo } from 'react';

interface BodyProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  padding?: HTMLDivElement['style']['padding'];
}
export default memo(({ children, padding, ...divProps }: BodyProps) => {
  return (
    <div
      css={css`
        background: ${color.DARKBLUE};
        padding: ${padding ?? '45px 35px'};
        margin-bottom: 30px;
        border-radius: 10px;
        color: ${color.WHITE};
      `}
      {...divProps}
    >
      {children}
    </div>
  );
});
