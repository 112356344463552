"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergeVariables = exports.cloneDeep = void 0;
exports.cloneDeep = (data) => {
    return JSON.parse(JSON.stringify(data || {}));
};
exports.mergeVariables = (a = {}, b = {}) => {
    const c = Object.assign({}, a);
    for (const key in b) {
        if (b[key] !== '' && (!(key in c) || (c === null || c === void 0 ? void 0 : c[key]) === '')) {
            c[key] = b[key];
        }
    }
    return c;
};
