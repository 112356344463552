import { SessionsVarsBody, SessionVar } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postgrid/models';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { listingImageVars } from '@/components/custom-modals/modal-fallbacks/types';
import { SelectObjType } from '@/components/select/mailing-list-select';
import { RootState } from '@/reducers/model';
import { qrCodeVars } from '@/utils/constant';
import { formatObjectKeyToTitle } from '@/utils/utils';
import { VariableType } from '../../hooks/useFetchVariablesByType';
import HomeSearchView from './HomeSearchView';

export interface HomeSearchContainerProps {
  handleMailingListChange: (value: string | string[], option?: SelectObjType | SelectObjType[]) => void;
  mailingListValue: string;
  handleMailingListSearch: (value: string) => void;
  mailingListSearchValue: string;
  parentId: string;
  agentIdCampaign?: string;
  mlsStatusValue: string;
  handleMLSStatusChange: (value: string) => void;
  isHandwritten?: boolean;
  variableType: VariableType;
  containerClassName: string;
  mailingListFieldName: string;
  isMyListing?: boolean;
  isExcludeMyListing?: boolean;
  isPastClient?: boolean;
}

const HomeSearchContainer: FC<HomeSearchContainerProps> = (props) => {
  const { isHandwritten, variableType, ...restProps } = props;
  const postgridSessionVar = useSelector((state: RootState) => state.campaign.postgridSessionVar);

  const imageVariables = [
    'agent_image',
    'agent_logo',
    'broker_logo',
    'broker_logo_white',
    'crmls_logo',
    'san_diego_logo',
    'claw_logo',
    'dessert_area_logo',
    'sboar_logo',
  ].concat(listingImageVars);
  // Only apply qr codes for Postgrid
  if (variableType === VariableType.POSTGRID && !isHandwritten) {
    imageVariables.unshift(...qrCodeVars);
  }

  const postgridSessionVarWithImageVar: SessionsVarsBody & { image_variables: SessionVar } = {
    ...postgridSessionVar,
    image_variables: {
      original_vars: imageVariables,
    },
  };

  const mlsStatusOptions = postgridSessionVarWithImageVar['mls_status']
    ? postgridSessionVarWithImageVar['mls_status'].map((status: string) => ({
        label: formatObjectKeyToTitle(status),
        value: status,
      }))
    : [];

  return <HomeSearchView {...restProps} mlsStatusOptions={mlsStatusOptions} />;
};

export default HomeSearchContainer;
