"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadType = exports.AuthType = void 0;
var AuthType;
(function (AuthType) {
    AuthType["JWT"] = "jwt";
    AuthType["OAUTH2"] = "oauth2";
    AuthType["TOKEN"] = "token";
})(AuthType = exports.AuthType || (exports.AuthType = {}));
var UploadType;
(function (UploadType) {
    UploadType["AWS"] = "aws";
    UploadType["LOCAL"] = "local";
})(UploadType = exports.UploadType || (exports.UploadType = {}));
