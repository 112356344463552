import { CaretDownOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Empty, Spin } from 'antd';
import type { SelectProps } from 'antd/es/select';
import { isEqual } from 'lodash';
import debounce from 'lodash/debounce';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import AppSelectV2 from '../AppSelectV2';

export interface HomeSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
  defaultOptions?: ValueType[];
  loading?: boolean;
}

function HomeSelect<ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any>({
  fetchOptions,
  debounceTimeout = 800,
  defaultOptions = [],
  loading = false,
  ...props
}: HomeSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>(defaultOptions);
  const fetchRef = useRef(0);

  const fetchAll = () => {
    setOptions([]);
    setFetching(true);

    fetchOptions('').then((newOptions) => {
      setOptions(newOptions);
      setFetching(false);
    });
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  const onClear = () => {
    fetchAll();
  };

  useEffect(() => {
    if (!isEqual(options, defaultOptions)) setOptions(defaultOptions);
  }, [defaultOptions]);

  return (
    <AppSelectV2
      size="small"
      color="DARKBLUE"
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      onClear={onClear}
      suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
      notFoundContent={
        fetching || loading ? (
          <Spin size="small" />
        ) : (
          <StyledEmpty className="empty-data" image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      options={options}
      {...props}
    />
  );
}

const StyledEmpty = styled(Empty)`
  &.ant-empty-normal {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;

    // Disclaimer: I hate !important but the outside css overrided my style :((
    .ant-empty-image {
      height: 24px !important;
      width: auto !important;

      svg {
        height: 100% !important;
        width: 100% !important;
      }
    }

    .ant-empty-description {
      font-size: 14px !important;
    }
  }
`;
export default HomeSelect;
