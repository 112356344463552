export enum StorageKeys {
  SESSION_KEY = 'session_key',
  REMEMBER_ME_KEY = 'remember_me',
  WALLET_CONNECTED = 'w_cn',
  IS_NEW_BROKER_USER_CHECK = 'is_new_launch_user',
  EMAIL_REGISTER = 'e_r',
  BROKERAGE_TOKEN = 'br_tk',
}

export enum numLevel {
  LEVEL_4 = '4',
  LEVEL_5 = '5',
  LEVEL_6 = '6',
  LEVEL_7 = '7',
}
export enum typeSelect {
  SELECT_ANALYTICS = 'select_analytics',
  SELECT = 'select',
  SELECT_SETTING = 'select_setting',
}
export enum UserType {
  MANAGER = 'MANAGER',
  AGENT = 'AGENT',
  BROKER = 'BROKER',
}
export enum StatusManager {
  NOTFOUND = 'Agent data not found',
  GENERATING = 'Generating report',
  COMPLETED = 'COMPLETED',
  INITIATED = 'initiated',
  INITIATING = 'initiating',
}

export enum TypeMetaDataOther {
  TURNOVER_RATE = 'turnover_rate',
  SOLD = 'S',
  ACTIVE = 'A',
  PENDING = 'P',
  COMING_SOON = 'C',
}

export enum TitleToolBoxType {
  MOVE_UP = 'move_up_seller_buyer',
  DOWNSIZERS = 'downsizer',
  ABSENTEE = 'absentee',
  LIKELY_TO_MOVE = 'likely_to_move',
  HOME_COUNT = 'home_count',
  MO_TOTAL_TRANSACTIONS_12 = 'mo_total_transactions_12',
  TENANTS = 'tenants',
}
export enum RealEstateType {
  ASSUMABLE = 'assumable',
  FORECLOSURE = 'foreclosure',
  INVESTOR_BUYER = 'investor_buyer',
  ADJUSTABLE_RATE = 'adjustable_rate',
  OUT_OF_STATE_ABSENTEE_OWNER = 'out_of_state_owner',
  IN_STATE_ABSENTEE_OWNER = 'in_state_owner',
  CASH_BUYER = 'cash_buyer',
  CORPORATE_OWNED = 'corporate_owned',
  HIGH_EQUITY = 'high_equity',
  INHERITED = 'inherited',
}

export const TypeMetaData = { ...TypeMetaDataOther, ...TitleToolBoxType, ...RealEstateType };

export enum TitleToolBoxPremiumDataType {
  NOTICE = 'notice',
  DIVORCEES = 'divorcees',
  AFFIDAVITS_OF_DEATH = 'affidavits_of_death',
}

export enum ActionsType {
  TEXT = 'text',
  EMAIL = 'email',
  PHONE = 'phone',
  MAIL = 'mail',
  ADD_INFORMATION = 'add_information',
  ADD_MILESTONE = 'add_milestone',
  INTERACTIONS = 'interactions',
  SEGMENTATION = 'segmentation',
  REMODEL_VALUE = 'remodel_values',
  CAMPAIGN_HISTORY = 'campaign_history',
}
export enum CardListType {
  ENTER_NEW_CARD = 'ENTER_NEW_CARD',
}
export enum EmailType {
  ENTER_NEW_EMAIL = 'ENTER_NEW_EMAIL',
  EMAIL_NAME = 'Create New Template',
}
export enum LandingPage {
  CREATE_LANDING_PAGE = 'CREATE_LANDING_PAGE',
}
export enum StatusAVGYearType {
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum HeaderTab {
  OVERVIEW = 'My Top',
  OTHER = 'Description',
}

export enum ManagerActionViewKeys {
  AGENT_LOOKUP = 'AGENT_LOOKUP',
  PROSPECTS_LOOKUP = 'PROSPECTS_LOOKUP',
  PROCESS_ONBOARDING = 'PROCESS_ONBOARDING',
}
export enum ZoomControlPosition {
  TOP_LEFT = '1',
  TOP_CENTER = '2',
  TOP_RIGHT = '3',
  LEFT_CENTER = '4',
  LEFT_TOP = '5',
  LEFT_BOTTOM = '6',
  RIGHT_TOP = '7',
  RIGHT_CENTER = '8',
  RIGHT_BOTTOM = '9',
  BOTTOM_LEFT = '10',
  BOTTOM_CENTER = '11',
  BOTTOM_RIGHT = '12',
}

export enum MailingListTypeEnum {
  IMPORT = 'IMPORT',
  DRAW = 'DRAW',
  MY_LISTING = 'MY_LISTING',
}

export enum MaxLengthText {
  MAILING_LIST_NAME = '50',
  DRIP_CAMPAIGN_NAME = '50',
}
export enum PaymentModalType {
  CREATE_CAMPAIGN = 'CREATE_CAMPAIGN',
  MINT_NFT = 'MINT_NFT',
  MANUAL_BUY_CREDIT = 'MANUAL_BUY_CREDIT',
  AUTO_BUY_CREDIT = 'AUTO_BUY_CREDIT',
  CREDIT_CARD_INFO = 'CREDIT_CARD_INFO',
  BUY_MISSING_DATA = 'BUY_MISSING_DATA',
  ADD_MY_TEAM_MEMBER = 'ADD_MY_TEAM_MEMBER',
  SEND_BROKER_CAMPAIGN = 'SEND_BROKER_CAMPAIGN',
  SEND_MYLISTING_CAMPAIGN = 'SEND_MYLISTING_CAMPAIGN',
  ONLY_ADD_CARD = 'ONLY_ADD_CARD',
  ADD_CARD_BROKER_REGISTER = 'ADD_CARD_BROKER_REGISTER',
  ADD_CARD_BROKER = 'ADD_CARD_BROKER',
}

export enum DripItemType {
  SHOW = 'SHOW',
  EDIT = 'EDIT',
}
export enum DripCampaignType {
  DRIP_CAMPAIGN = 'DRIP_CAMPAIGN',
  SMART_DRIP_CAMPAIGN = 'SMART_DRIP_CAMPAIGN',
}

export enum KEY_VALUE_TYPE {
  checked = 'checked',
  operator = 'operator',
  value = 'value',
}

export enum KEY_MLS_TRIGGER {
  home_equity_percentage = 'home_equity_percentage',
  city = 'city',
  zip_code = 'zip_code',
  property_type = 'property_type',
  num_of_bathrooms = 'num_of_bathrooms',
  num_of_bedrooms = 'num_of_bedrooms',
  estimated_price = 'estimated_price',
  street_address = 'street_address',
  abbreviated_street_name = 'abbreviated_street_name',
  owner_first_name = 'owner_first_name',
  owner_full_name = 'owner_full_name',
  stories = 'stories',
  year_built = 'year_built',
  last_sold_date = 'last_sold_date',
  features = 'features',
  last_sold_price = 'last_sold_price',
  year_since_purchase = 'year_since_purchase',
  turn_over_rate = 'turn_over_rate',
  avg_year_owned = 'avg_year_owned',
  name = 'name',
  sm_trigger_campaign_id = 'sm_trigger_campaign_id',
  mls_status = 'mls_status',
  mls_status_filters = 'mls_status_filters',
  milestone = 'milestone',
  postalytics_audience_ids = 'postalytics_audience_ids',
  lead = 'lead',
  mailing_lists = 'mailing_lists',
  interests = 'interests',
  employers = 'employers',
  industries = 'industries',
  job_titles = 'job_titles',
  square_feet = 'square_feet',
  trigger_on_demand = 'trigger_on_demand',
  is_new_past_client = 'is_new_past_client',
  landing_page = 'landing_page',
  years_owned = 'years_owned',
  avm_value = 'avm_value',
  interest_rate = 'interest_rates',
  days_on_market = 'days_on_market',
  price_per_square_foot = 'price_per_square_foot',

  notice_of_default = 'premium_notice',
  divorcees = 'premium_divorcees',
  affidavits_of_death = 'premium_affidavits_of_death',

  select_rule = 'select_rule',
  max_search_homes = 'max_search_homes',
}
export enum KEY_SMART_FILTERS {
  // these field use to store value of the form and pass to api with single value field.
  mailing_list = 'mailing_list_ids',
  audience = 'audience_ids',
  sort_by = 'sort_by',
  interaction_type = 'action_type',
  mls_status = 'mls_status',
  life_event = 'milestones',
  beds = 'bedrooms',
  baths = 'bathrooms',
  sq_feet = 'sqft',
  equity = 'home_equity_percentage',
  tags = 'tags',
  notice_of_default = 'notice_of_default',
  divorcees = 'divorcees',
  affidavits_of_death = 'affidavits_of_death',
  home_price = 'home_price',
  is_past_client = 'is_past_client',
  avm = 'avm_values',
  rea_neighborhood_ids = 'rea_neighborhood_ids',
  avg_years_owned = 'avg_years_owned',
  years_owned = 'years_owned',
  has_linkedin = 'has_linkedin',
  has_email = 'has_email',
  has_phone = 'has_phone',
  rea_loan_type_ids = 'rea_loan_type_ids',
  stories = 'stories',
  age = 'age_range',
  rea_properties = 'rea_properties',
  interest_rate = 'interest_rates',
  days_on_market = 'days_on_market',
  price_per_square_foot = 'price_per_square_foot',

  // these key use to pass to api with 2+ value with the same field. (operator + value)

  estimated_price_operator = 'estimated_price_operator',
  estimated_price_value = 'estimated_price_value',
  notice_of_default_operator = 'notice_of_default_operator',
  notice_of_default_value = 'notice_of_default_value',
  divorcees_operator = 'divorcees_operator',
  divorcees_value = 'divorcees_value',
  affidavits_of_death_operator = 'affidavits_of_death_operator',
  affidavits_of_death_value = 'affidavits_of_death_value',
  first_loan_estimated_interest_rate_value = 'first_loan_estimated_interest_rate_value',
  first_loan_estimated_interest_rate_operator = 'first_loan_estimated_interest_rate_operator',
  first_loan_amount_operator = 'first_loan_amount_operator',
  first_loan_amount_value = 'first_loan_amount_value',

  //flag to check is getALl value of some fields
  isGetAllMlsStatus = 'isGetAllMlsStatus',
  isGetAllML = 'isGetAllML',
  zip_codes = 'zip_codes',
}
export enum ActionTypePremium {
  SELECT_MAILING = 'SELECT_MAILING',
  SINGLE = 'SINGLE',
  DRAW = 'DRAW',
  SELECT_MAILING_GEOMETRY_AVAILABLE = 'SELECT_MAILING_GEOMETRY_AVAILABLE',
}

export enum KEY_LANDING_TYPE {
  landing_page = 'landing_page',
}

export enum TabSmFilter {
  TAB_SM_FILTER = 'TAB_SM_FILTER',
  TAB_PREMIUM = 'TAB_PREMIUM',
}

export enum TemplateITemEnum {
  POSTCARD = 'POSTCARD',
  LETTER = 'LETTER',
  HANDWRITTEN = 'HANDWRITTEN',
  DIGITAL_AD_VIDEO = 'DIGITAL_AD_VIDEO',
  DIGITAL_AD_IMAGE = 'DIGITAL_AD_IMAGE',
  EMAIL = 'EMAIL',
}

export enum PreviewTemplateAction {
  FALLBACK = 'FALLBACK',
  UPDATE_QR_CODE = 'UPDATE_QR_CODE',
  USE_TEMPLATE_FOR_NEW_VIDEO = 'USE_TEMPLATE_FOR_NEW_VIDEO',
  CREATE_FROM_TEMPLATE = 'CREATE_FROM_TEMPLATE',
  DELETE_TEMPLATE = 'DELETE_TEMPLATE',
  EDIT_POSTGRID_TEMPLATE = 'EDIT_POSTGRID_TEMPLATE',
  SAVE_CUSTOM_FALLBACK_POSTGRID_TEMPLATE = 'SAVE_CUSTOM_FALLBACK_POSTGRID_TEMPLATE',
  EDIT_EMAIL_TEMPLATE = 'EDIT_EMAIL_TEMPLATE',
  DELETE_EMAIL_TEMPLATE = 'DELETE_EMAIL_TEMPLATE',
  CREATE_OWN_VERSION_EMAIL_TEMPLATE = 'CREATE_OWN_VERSION_EMAIL_TEMPLATE',
}

export enum GlobalModalCredits {
  CREDITS = 'CREDITS',
  HOME_CREDITS = 'HOME_CREDITS',
}

export enum ModalInteractionType {
  PAST_ORDER_CAMPAIGN = 'PAST_ORDER_CAMPAIGN',
  INTERACTION_SMART_ACTION = 'INTERACTION_SMART_ACTION',
  CAMPAIGN_HISTORY = 'CAMPAIGN_HISTORY',
}

export enum ResolutionTemplate {
  Email = 'Email',
  Letter = '8.5x11',
  Photo6x4 = '6x4',
  Photo9x6 = '9x6',
  Photo11x6 = '11x6',
}

export enum OrderSortby {
  ASC = '',
  DESC = '-',
}

export enum EditableSelectType {
  Phone = 'PHONE',
  Email = 'EMAIL',
}

export enum PostgridEditorActionType {
  CLONE = 'CLONE',
  UPDATE = 'UPDATE',
  HANDWRITTEN_CREATE = 'HANDWRITTEN_CREATE',
  HANDWRITTEN_UPDATE = 'HANDWRITTEN_UPDATE',
  HANDWRITTEN_CLONE = 'HANDWRITTEN_CLONE',
}

export enum CampaignTypeEnum {
  PHYSICAL = 'Physical Mail',
  EMAIL = 'Email',
  DIGITAL = 'Digital',
  HANDWRITTEN = 'HANDWRITTEN',
}

export enum DigitalUploadType {
  IMAGE = 'image',
  VIDEO = 'video',
}

export enum SelectedField {
  TARGET = 'TARGET',
  NEIGHBORHOOD = 'NEIGHBORHOOD',
  TYPE = 'TYPE',
  CAMPAIGNS = 'CAMPAIGNS',
  CAMPAIGN_TYPE = 'CAMPAIGN_TYPE',
  MAILING = 'MAILING',
  AUDIENCE = 'AUDIENCE',
  DURATION = 'DURATION',
}

export enum PaymentOptions {
  PHYSICAL_CAMPAIGN = 'PHYSICAL_CAMPAIGN',
}
export enum PaymentOptionsType {
  CREDIT = 'CREDIT',
  CREDIT_CARD = 'CREDIT_CARD',
}

export enum HeaderCsvField {
  FIRST_NAME = 'First name',
  LAST_NAME = 'Last name',
  PHONE_NUMBER = 'Phone number',
  EMAIL = 'Email',
  STREET = 'Street Number + Street',
  APARTMENT = 'Apartment #/ Unit # (Optional)',
  CITY = 'City',
  STATE = 'State',
  ZIP_CODE = 'Zip Code',
  LINKEDIN = 'Linkedin',
  SOCIAL = 'Social Profiles',
  COMMUNITY = 'Community',
  AGE = 'Age',
  STORIES = 'property_stories',
  ASSUMABLE = 'Assumable',
  FORECLOSURE = 'Foreclosure',
  INVESTOR_BUYER = 'Investor Buyer',
  ADJUSTABLE_RATE = 'Adjustable Rate',
  OUT_OF_STATE_ABSENTEE_OWNER = 'Out Of State Owner',
  IN_STATE_ABSENTEE_OWNER = 'In State Owner',
  CASH_BUYER = 'Cash Buyer',
  CORPORATE_OWNED = 'Corporate Owned',
  HIGH_EQUITY = 'High Equity',
  INHERITED = 'Inherited',
}

export enum FilterHashKey {
  CREATE_CAMPAIGN = 'campaignHash',
  PAST_ORDER = 'pastOrderHash',
  SMART_ACTION = 'filter',
}

export enum UploadImageEnum {
  avatar = 'AVATAR',
  logo = 'LOGO',
  affiliate_logo_1 = 'AFFILIATE_LOGO_1',
  affiliate_logo_2 = 'AFFILIATE_LOGO_2',
}
