import { LandingPageType } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';
import { ActionBase } from '..';
import AppAction from './action';
import { AppState } from './model';

const initialState: AppState = {
  loading: 0,
  theme: 'light',
  idModal: null,
  idModalWebsite: null,
  submission_id: null,
  isModalOnBoarding: false,
  modalActionsData: null,
  modalRemoveActions: null,
  visibleModalButtonMailingList: false,
  visibleModalMailingList: false,
  visibleRecipients: false,
  visibleAddInformationActions: false,
  visibleAddHome: false,
  visibleMailAction: false,
  visibleWarningCreditBalanceModal: false,
  visibleThresholdLimitModal: false,
  visibleTriggerOnDemandModal: false,
  visibleMessageNoDataModal: false,
  visiblePurchasePremium: false,
  visibleConfirmPurchasePremiumModal: false,
  visiblePreviewLandingPageModal: null,
  urlPreviewLandingPage: {},
  visibleInfoRedirectPastOrderModal: false,
  visibleCreateLandingPageModal: {
    visible: false,
    type: LandingPageType.NORMAL,
    fieldName: 'landing_page',
  },
  newLandingPage: {},
  visibleModalSmartTrigger: false,
  visibleModalChangeSubscriptionPackage: false,
  visibleInsufficientHomeCreditModal: false,
  visibleGlobalCredit: null,
  visibleGlobalUnsubscribe: false,
  campaignTypeList: [],
  visibleConfigureBrokerSubscription: false,
};
const appReducer = (state = initialState, action: ActionBase): AppState => {
  if (!action.type.includes('@app')) {
    return state;
  }
  switch (action.type) {
    case AppAction.APP_LOADING:
      return {
        ...state,
        loading: action.payload === 'INCREMENT' ? state.loading + 1 : state.loading > 0 ? state.loading - 1 : 0,
      };
    case AppAction.CLOSE_ALL_MODAL_APP_ROOT:
      return {
        ...state,
        ...action.payload,
        ...{
          idModal: null,
          idModalWebsite: null,
          submission_id: null,
          visibleModalMailingList: false,
          visibleModalButtonMailingList: false,
          visibleWarningCreditBalanceModal: false,
          visiblePurchasePremium: false,
          visiblePreviewLandingPageModal: null,
          visibleThresholdLimitModal: false,
          modalActionsData: false,
          visibleAddInformationActions: false,
          visibleModalSmartTrigger: false,
          visibleModalChangeSubscriptionPackage: false,
          visibleGlobalCredit: false,
          visibleCreateLandingPageModal: {
            visible: false,
            type: LandingPageType.NORMAL,
          },
          visibleConfigureBrokerSubscription: false,
        },
      };
    case AppAction.SET_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    case AppAction.MODAL_ACTIONS_TYPE:
      return {
        ...state,
        modalActionsData: action.payload,
      };
    case AppAction.SET_ID_MODAL_WEBSITE:
      return {
        ...state,
        idModalWebsite: action.payload,
      };
    case AppAction.UPDATE_WEBSITE_MANAGER:
      return {
        ...state,
        submission_id: action.payload,
      };
    case AppAction.SHOW_MODAL_ONBOARDING:
      return {
        ...state,
        isModalOnBoarding: action.payload,
      };
    case AppAction.VISIBLE_MODAL_BUTTON_ADD_MAILING:
      return {
        ...state,
        visibleModalButtonMailingList: action.payload,
      };
    case AppAction.VISIBLE_MODAL_ADD_MAILING:
      return {
        ...state,
        visibleModalMailingList: action.payload,
      };
    case AppAction.VISIBLE_RECIPIENTS_MODAL:
      return {
        ...state,
        visibleRecipients: action.payload,
      };
    case AppAction.VISIBLE_ADD_INFORMATION_ACTIONS_MODAL:
      return {
        ...state,
        visibleAddInformationActions: action.payload,
      };
    case AppAction.VISIBLE_ADD_HOME_MODAL:
      return {
        ...state,
        visibleAddHome: action.payload,
      };
    case AppAction.VISIBLE_MAIL_ACTIONS_MODAL:
      return {
        ...state,
        visibleMailAction: action.payload,
      };
    case AppAction.VISIBLE_WARNING_CREDIT_BALANCE_MODAL:
      return {
        ...state,
        visibleWarningCreditBalanceModal: action.payload,
      };

    case AppAction.VISIBLE_THRESHOLD_LIMIT_MODAL:
      return {
        ...state,
        visibleThresholdLimitModal: action.payload,
      };
    case AppAction.VISIBLE_TRIGGER_ONDEMAND_MODAL:
      return {
        ...state,
        visibleTriggerOnDemandModal: action.payload,
      };
    case AppAction.VISIBLE_MESSAGE_NODATA_MODAL:
      return {
        ...state,
        visibleMessageNoDataModal: action.payload,
      };
    case AppAction.VISIBLE_MODAL_PURCHASE_PREMIUM:
      return {
        ...state,
        visiblePurchasePremium: action.payload,
      };
    case AppAction.VISIBLE_CONFIRM_MODAL_PURCHASE_PREMIUM_MODAL:
      return {
        ...state,
        visibleConfirmPurchasePremiumModal: action.payload,
      };
    case AppAction.SET_URL_PREVIEW_LANDING_PAGE_MODAL:
      return {
        ...state,
        urlPreviewLandingPage: action.payload,
      };
    case AppAction.VISIBLE_PREVIEW_LANDING_PAGE_MODAL:
      return {
        ...state,
        visiblePreviewLandingPageModal: action.payload,
      };

    case AppAction.VISIBLE_INFO_REDIRECT_PAST_ORDER_MODAL:
      return {
        ...state,
        visibleInfoRedirectPastOrderModal: action.payload,
      };
    case AppAction.VISIBLE_CREATE_LANDING_PAGE_MODAL:
      return {
        ...state,
        visibleCreateLandingPageModal: action.payload,
      };
    case AppAction.NEW_LANDING_PAGE:
      return {
        ...state,
        newLandingPage: action.payload,
      };

    case AppAction.VISIBLE_MODAL_SMART_TRIGGER:
      return {
        ...state,
        visibleModalSmartTrigger: action.payload,
      };
    case AppAction.VISIBLE_MODAL_CHANGE_SUBSCRIPTION_PACKAGE:
      return {
        ...state,
        visibleModalChangeSubscriptionPackage: action.payload,
      };
    case AppAction.VISIBLE_INSUFFICIENT_HOME_CREDIT:
      return {
        ...state,
        visibleInsufficientHomeCreditModal: action.payload,
      };
    case AppAction.VISIBLE_GLOBAL_CREDIT:
      return {
        ...state,
        visibleGlobalCredit: action.payload,
      };
    case AppAction.VISIBLE_GLOBAL_UNSUBSCRIBE:
      return {
        ...state,
        visibleGlobalUnsubscribe: action.payload,
      };
    case AppAction.CAMPAIGN_TYPE_LIST:
      return {
        ...state,
        campaignTypeList: action.payload,
      };
    case AppAction.VISIBLE_CONFIGURE_BROKER_SUBSCRIPTION:
      return {
        ...state,
        visibleConfigureBrokerSubscription: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default appReducer;
