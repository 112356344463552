import { Modal, ModalProps } from 'antd';
import React, { memo } from 'react';
import { InnerLoading } from '../loading/loadingBase';
import IconYellowClose from '@/assets/images/yellow-close.svg';
import IconClose from '@/assets/images/close.svg';
import { css } from '@emotion/react';
import { color } from '@/styles/theme';

export interface AppModalProps extends ModalProps {
  visible: boolean;
  onOK?: () => void;
  onCancel?: () => void;
  isYellowClose?: boolean;
  isLoading?: boolean;
  isTransparent?: boolean;
}

const AppModalView: React.FC<AppModalProps> = ({
  visible,
  onOK,
  onCancel,
  children,
  isYellowClose,
  isLoading = false,
  isTransparent,
  ...props
}) => {
  return (
    <Modal
      closeIcon={
        isLoading ? (
          <InnerLoading iconStyle={{ color: '#fff' }} />
        ) : (
          <img src={isYellowClose ? IconYellowClose : IconClose} alt="" />
        )
      }
      open={visible}
      onOk={onOK}
      onCancel={() => {
        if (isLoading) return;
        onCancel && onCancel();
      }}
      {...props}
      css={
        isTransparent
          ? css`
              .ant-modal-content {
                background: transparent;
                box-shadow: none;
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
              }
              .ant-modal-body {
                padding: 0;
              }
            `
          : css`
              .ant-modal-content {
                background: ${color.DARKBLUE};
                border-radius: 10px;
              }
              .ant-modal-body {
                padding: 35px;
              }
            `
      }
    >
      {children}
    </Modal>
  );
};

export default memo(AppModalView);
