"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uploadFileFactory = void 0;
const config_1 = require("../../http/config");
const local_1 = require("./adapter/local");
exports.uploadFileFactory = (client) => {
    const type = client.getApiConfig();
    switch (type.uploadType) {
        case config_1.UploadType.AWS:
            return new local_1.LocalUploadFileApi(client);
        default:
            return new local_1.LocalUploadFileApi(client);
    }
};
