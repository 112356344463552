enum MailingAction {
  LIST_MAILING_LIST = '@mailing/LIST_MAILING_LIST',
  LIST_MAILING_LIST_SUCCESS = '@mailing/LIST_MAILING_LIST_SUCCESS',

  UPDATE_MAILING_LIST_COMBINE = '@mailing/UPDATE_MAILING_LIST_COMBINE',
  UPDATE_FETCHING = '@mailing/UPDATE_FETCHING',

  UPDATE_MAILING_LIST_SUCCESS = '@mailing/UPDATE_MAILING_LIST_SUCCESS',

  LIST_MAILING_LIST_COMBINE = '@mailing/LIST_MAILING_LIST_COMBINE',
  RESET_MAILING_LIST_COMBINE = '@mailing/RESET_MAILING_LIST_COMBINE',

  LIST_PAST_CLIENTS = '@mailing/LIST_PAST_CLIENTS',
  LIST_PAST_CLIENTS_SUCCESS = '@mailing/LIST_PAST_CLIENTS_SUCCESS',

  LIST_LEADS = '@mailing/LIST_LEADS',
  LIST_LEADS_SUCCESS = '@mailing/LIST_LEADS_SUCCESS',

  UPDATE_PAST_CLIENTS_SUCCESS = '@mailing/UPDATE_PAST_CLIENTS_SUCCESS',

  LISTEN_MAILING_EVENT_DATA = '@mailing/LISTEN_MAILING_EVENT_DATA',

  UPDATE_LEADS_SUCCESS = '@mailing/UPDATE_LEADS_SUCCESS',

  UPDATE_MAILING_LOADING = '@mailing/UPDATE_MAILING_LOADING',

  UPDATE_MAILING_DETAIL = '@mailing/UPDATE_MAILING_DETAIL',

  TOGGLE_PREMIUM_DATA_SELECTED = '@mailing/TOGGLE_PREMIUM_DATA_SELECTED',
  UPDATE_LOADING_STATE_PREMIUM_DATA = '@mailing/UPDATE_LOADING_STATE_PREMIUM_DATA',
  UPDATE_PREMIUM_DATA = '@mailing/UPDATE_PREMIUM_DATA',

  SELECT_MAILING = '@mailing/SELECT_MAILING',
  UPDATE_DATA_SELECTED = '@mailing/UPDATE_DATA_SELECTED',
  RESET_DATA_SELECTED = '@mailing/RESET_DATA_SELECTED',

  UPDATE_PARAMS_MAILING_ADDRESS_SENT = '@mailing/UPDATE_PARAMS_MAILING_ADDRESS_SENT',
  OPEN_MAILING_ADDRESS_POPUP = '@mailing/OPEN_MAILING_ADDRESS_POPUP',
  SET_ADDRESSES_COUNT_CAMPAIGN = '@mailing/SET_ADDRESSES_COUNT_CAMPAIGN',
  SET_ADDRESSES_COUNT_CAMPAIGN_NOT_RENDER = '@mailing/SET_ADDRESSES_COUNT_CAMPAIGN_NOT_RENDER',
  SET_ADDRESSES_COUNT_CAMPAIGN_NOT_RENDER_LOADING = '@mailing/SET_ADDRESSES_COUNT_CAMPAIGN_NOT_RENDER_LOADING',
  RESET_ADDRESSES_COUNT_CAMPAIGN = '@mailing/RESET_ADDRESSES_COUNT_CAMPAIGN',
  SET_ADDRESSES_COUNT_MLS = '@mailing/SET_ADDRESSES_COUNT_MLS',
  RESET_ADDRESSES_COUNT_MLS = '@mailing/RESET_ADDRESSES_COUNT_MLS',
  SET_ADDRESSES_COUNT_LOADING = '@mailing/SET_ADDRESSES_COUNT_LOADING',
  LISTEN_SHOW_ADDRESS_MLS_EVENT_DATA = '@mailing/LISTEN_SHOW_ADDRESS_MLS_EVENT_DATA',
  LISTEN_TOGGLE_ON_DEMAND_MLS_DATA = '@mailing/LISTEN_TOGGLE_ON_DEMAND_MLS_DATA',
  LISTEN_DUPLICATED_MAILING_LIST_DATA = '@mailing/LISTEN_DUPLICATED_MAILING_LIST_DATA',

  UPDATE_SMART_TRIGGER_STATE = '@mailing/UPDATE_SMART_TRIGGER_STATE',
  SET_MLS_UPDATED_FROM_EVENT = '@mailing/SET_MLS_UPDATED_FROM_EVENT',
}

export default MailingAction;
