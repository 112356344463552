import MessageVariable from '@/components/custom-html-editor/components/message-variable';
import { MailingListData, MlsData, variableInstruction } from '@/components/custom-html-editor/helpers';
import { VariableType } from '@/components/custom-html-editor/hooks/useFetchVariablesByType';
import ConfirmModal from '@/components/custom-modals/modal-confirm';
import ModalPreviewTemplateWithMailing from '@/components/custom-modals/modal-preview-template/modal-preview-template-with-mailing';
import { HandwrittenTemplateCustom } from '@/components/dashboard/campaign/ScrollTemplate/hooks/useScrollTemplate';
import { LETTER_TEMPLATE_SIZE } from '@/utils/constant';
import { PostgridEditorActionType } from '@/utils/enum';
import { Mailing } from '@goldfishcode/first-team-real-estate-sdk/libs/api/mailing/models';
import { PostgridTemplateType } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postgrid/models';
import { Col, Row } from 'antd';
import React, { Dispatch, forwardRef, SetStateAction, useImperativeHandle, useRef } from 'react';
import { CreateTemplateStyled } from './CreateTemplateStyled';
import HandwrittenCreator from './handwritten-creator';
import { HandwrittenCreatingType } from './hooks/useCreateHandwritten';
import { HandwrittenCreatorViewContainerRef } from './handwritten-creator/HandwrittenCreatorContainer';
import { CreateMailPreviewData } from '@/models';
import AppButtonV2 from '@/components/AppButtonV2';
import isEqual from 'react-fast-compare';
import PostgridCreator from './postgrid-creator';

interface CreateLetterModalProps {
  createLetterData: CreateMailPreviewData;
  isHandwritten: boolean;
  isCanceling: boolean;
  isCreating: boolean;
  handleCancelCreatingPostgridTemplate: () => void;
  isPreView: boolean;
  handleOnPreviewTemplate: (e: any) => void;
  handleSavePostgridTemplate: () => void;
  setVisibleConfirmSaveTemplate: (value: boolean) => void;
  setMlsData: Dispatch<SetStateAction<MlsData>>;
  setMailingListData: Dispatch<SetStateAction<MailingListData>>;
  setCreatingHandwrittenData: (data: HandwrittenCreatingType) => void;
  templateFallback: any;
  isPreviewTemplateVisible: boolean;
  previewTemplate: any;
  setPreviewTemplateVisible: (value: boolean) => void;
  visibleConfirmSaveTemplate: boolean;
}

export interface ModalCreateTemplateViewRef {
  handwrittenRef: React.RefObject<HandwrittenCreatorViewContainerRef>;
}

const ModalCreateTemplateView = forwardRef<ModalCreateTemplateViewRef, CreateLetterModalProps>((props, ref) => {
  const {
    createLetterData,
    isHandwritten,
    isCanceling,
    isCreating,
    handleCancelCreatingPostgridTemplate,
    isPreView,
    handleOnPreviewTemplate,
    handleSavePostgridTemplate,
    setVisibleConfirmSaveTemplate,
    setMlsData,
    setMailingListData,
    setCreatingHandwrittenData,
    templateFallback,
    isPreviewTemplateVisible,
    previewTemplate,
    setPreviewTemplateVisible,
    visibleConfirmSaveTemplate,
  } = props;
  const handwrittenRef = useRef<HandwrittenCreatorViewContainerRef>(null);

  useImperativeHandle(ref, () => ({
    handwrittenRef,
  }));

  return (
    <>
      <CreateTemplateStyled
        getContainer={false}
        visible={createLetterData.visible}
        footer={false}
        centered
        closable={false}
        destroyOnClose={true}
        width={'90%'}
      >
        <div className="content">
          <div className="section-modal">
            <div className="wrapper-modal">
              <div className="modal-header">
                <div className="invisible"></div>
                <div className="template-title">
                  <p className="title">
                    {createLetterData?.actionType === PostgridEditorActionType.UPDATE
                      ? 'Update Template'
                      : createLetterData?.actionType === PostgridEditorActionType.CLONE
                      ? 'Create A Template'
                      : [
                          PostgridEditorActionType.HANDWRITTEN_CREATE,
                          PostgridEditorActionType.HANDWRITTEN_CLONE,
                        ].includes(createLetterData?.actionType)
                      ? 'Create A Handwritten Letter'
                      : createLetterData?.actionType === PostgridEditorActionType.HANDWRITTEN_UPDATE
                      ? 'Update Handwritten Letter'
                      : ''}
                  </p>
                  <p className="sub-title">
                    {createLetterData.type === PostgridTemplateType.LETTER
                      ? `LETTER ${LETTER_TEMPLATE_SIZE}`
                      : createLetterData.size
                      ? `POSTCARD (${createLetterData.size})`
                      : isHandwritten
                      ? ''
                      : 'POSTCARD'}
                  </p>
                </div>
                <div className="button-group">
                  <p>Please click on the {isHandwritten ? 'Save' : 'Confirm'} button to complete your design</p>
                  <Row className="button-field" gutter={10}>
                    <Col flex="1">
                      <AppButtonV2
                        block
                        disabled={isCanceling || isCreating}
                        loading={isCanceling}
                        onClick={handleCancelCreatingPostgridTemplate}
                        color="WhiteTransparent"
                        size="middle"
                        fontSize={14}
                      >
                        Cancel
                      </AppButtonV2>
                    </Col>
                    <Col flex="1">
                      <AppButtonV2
                        block
                        disabled={
                          isCanceling || isCreating
                          // || !Object.keys(mlsData).length
                        }
                        loading={isCreating || isPreView}
                        onClick={handleOnPreviewTemplate}
                        size="middle"
                        fontSize={14}
                      >
                        Preview
                      </AppButtonV2>
                    </Col>
                    <Col flex="1">
                      <AppButtonV2
                        block
                        disabled={isCanceling || isCreating}
                        loading={isCreating}
                        onClick={() => {
                          if (isHandwritten) {
                            handleSavePostgridTemplate();
                          } else {
                            setVisibleConfirmSaveTemplate(true);
                          }
                        }}
                        size="middle"
                        fontSize={14}
                      >
                        {isHandwritten ? 'Save' : 'Confirm'}
                      </AppButtonV2>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="editor-section">
                <div className="variable">
                  <p className="var-title">Copy Variables</p>

                  <MessageVariable
                    initialMailingListData={createLetterData.mailingListData}
                    variableType={isHandwritten ? VariableType.EMAIL : VariableType.POSTGRID}
                    onSelectMailingMLSData={(mlsData) => setMlsData((prev) => ({ ...prev, ...mlsData }))}
                    onSelectMailingListData={(mailingListData) =>
                      setMailingListData((prev) => ({ ...prev, ...mailingListData }))
                    }
                    isHandwritten={isHandwritten}
                    onSelectVariable={(e, isImageVariable) => {
                      handwrittenRef?.current?.handleClickMessage(e, isImageVariable);
                    }}
                  />
                  {!isHandwritten && (
                    <div className="notice">
                      <p className="var-title">Instructions</p>
                      {variableInstruction()}
                    </div>
                  )}
                </div>
                {isHandwritten ? (
                  <HandwrittenCreator
                    templateData={createLetterData.previewTemplateData as HandwrittenTemplateCustom}
                    onHandwrittenDataChange={(data) => setCreatingHandwrittenData(data)}
                    actionType={createLetterData.actionType}
                    ref={handwrittenRef}
                    isLoading={isCreating}
                    mailing={createLetterData.mailing}
                    className="handwritten-creator"
                  />
                ) : (
                  <PostgridCreator createLetterData={createLetterData} />
                )}
              </div>
            </div>
          </div>
        </div>
      </CreateTemplateStyled>

      {/* {!!Object.keys(mlsFallbacks)?.length && ( */}
      {templateFallback && (
        <ModalPreviewTemplateWithMailing
          visible={isPreviewTemplateVisible}
          data={previewTemplate}
          mailing={{ id: 'fake_mailing_id', is_available_for_campaign: true } as Mailing}
          fallbacks={{ ...templateFallback?.default_fallbacks }}
          onCancel={() => setPreviewTemplateVisible(false)}
          templateFallback={templateFallback}
        />
      )}

      {/* )} */}

      <ConfirmModal
        title={'Warning'}
        body={`Don't forget to click on "Save Design" to save your latest design before you click "Confirm"`}
        visible={visibleConfirmSaveTemplate}
        onCancel={() => {
          setVisibleConfirmSaveTemplate(false);
        }}
        onSubmit={handleSavePostgridTemplate}
        btnSubmitText="Continue"
        btnCancelText="Back"
        isLoading={isCreating}
      />
    </>
  );
});

export default React.memo(ModalCreateTemplateView, isEqual);
