import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiIns } from '@/config/apiClient';
import { withProperties } from '@/utils/withProperties';
import { QueryOptions } from '../types';
import { AlertTextError, AlertTextSuccess } from '@/components/alert/SweetAlert';
import { useDispatch } from 'react-redux';
import HomeLimitAction from '@/reducers/home-credit/action';

type FnParams = QueryOptions<typeof apiIns.subscription.getMySub>;

const queryKey = (): FnParams['queryKey'] => {
  return ['subscription-sub-setting']; // Add null as the second element
};

const useQueryHandle = (config?: FnParams['config']) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: queryKey(),
    queryFn: async () => {
      const response = await apiIns.subscription.getMySub();
      dispatch({
        type: HomeLimitAction.SET_MY_SUB_HOME_CREDIT,
        payload: response,
      });
      return response;
    },
    ...config,
  });
};

export const useSubscriptionSubSetting = withProperties(useQueryHandle, { queryKey });

export const useExtraAgent = (onClose: () => void) => {
  const clientQuery = useQueryClient();

  return useMutation({
    mutationFn: async ({ amount, usbId }: { amount: number; usbId: number }) => {
      await apiIns.subscription.buyExtraAgent(amount, usbId);
      await clientQuery.invalidateQueries(useSubscriptionSubSetting.queryKey());
    },
    onSuccess: () => {
      AlertTextSuccess('Update Agent Successfully', 'Subscription has been changed successfully.');
      onClose();
    },
    onError(error: any) {
      AlertTextError('Error', error.message);
    },
  });
};
