import { initialStatePremiumData } from '@/components/custom-modals/modal-purchase-premium/models';
import MailingAction from '@/reducers/mailing/action';
import { AddressProps, MailingSelected, MailingState } from '@/reducers/mailing/model';
import { ActionTypePremium } from '@/utils/enum';
import { updateMailingListState } from '@/utils/mailing';
import { extractEssentialData, hasRequiredFields } from '@/utils/utils';
import { ActionBase } from '..';
import { MailingList } from '@goldfishcode/first-team-real-estate-sdk/libs/api/mailing/models';
import { uniqBy } from 'lodash';
import { AddressesSearchCampaignParams, AddressesSearchMLSParams } from '@/models';

export const initialStateDataSelected = {
  type: ActionTypePremium.DRAW,
  premium_data_selected: initialStatePremiumData,
  draw_meta_data: {} as MailingSelected,
  singleMailingSelected: {} as MailingList,
  multipleMailingsSelected: [],
  addressInvalid: [],
};
const initialState: MailingState = {
  mailingListsCombine: { results: [], count: 0, isFetching: false },
  mailingList: {
    count: 0,
    results: [],
  },
  pastClientList: {
    count: 0,
    results: [],
  },
  leadList: {
    count: 0,
    results: [],
  },
  mailingEventData: null,
  loading: null,
  mailingListDetail: null,

  dataSelected: initialStateDataSelected as AddressProps,
  // Addresses show
  isOpenAddressPopup: false,
  addressesFoundCountForCampaign: {
    count: 0,
    updatedCount: 0,
    params: {} as AddressesSearchCampaignParams,
    mailings: [],
  },
  addressesFoundCountForMLS: {
    count: 0,
    updatedCount: 0,
    params: {} as AddressesSearchMLSParams,
    mailings: [],
  },
  stateSmartTrigger: {
    idEditingItemSmartTrigger: null,
    isDuplicateItem: false,
  },
  mlsUpdatedEventData: null,
  addressesFoundCountForCampaignFallback: [],
  isLoadingAddressesFoundCountForCampaignFallback: false,
  duplicatedMLEventData: null,
};

const mailingReducer = (state = initialState, action: ActionBase): MailingState => {
  if (!action.type.includes('@mailing')) {
    return state;
  }
  switch (action.type) {
    case MailingAction.RESET_MAILING_LIST_COMBINE:
      return {
        ...state,
        mailingListsCombine: {
          results: [],
          count: 0,
        },
      };
    case MailingAction.UPDATE_MAILING_LIST_COMBINE:
      if (action.payload?.override) {
        return {
          ...state,
          mailingListsCombine: {
            ...state.mailingListsCombine,
            results: action.payload.results, // Concatenate the new results to existing results
            count: action.payload.count,
          },
        };
      } else {
        return {
          ...state,
          mailingListsCombine: {
            ...state.mailingListsCombine,
            results: uniqBy([...state.mailingListsCombine.results, ...action.payload.results], 'id'), // Concatenate the new results to existing results
            count: action.payload.count,
          },
        };
      }
    case MailingAction.UPDATE_FETCHING:
      return {
        ...state,
        mailingListsCombine: { ...state.mailingListsCombine, isFetching: action?.payload },
      };
    case MailingAction.LIST_MAILING_LIST_SUCCESS:
      return {
        ...state,
        mailingList: action.payload,
      };

    case MailingAction.LIST_PAST_CLIENTS_SUCCESS:
      return {
        ...state,
        pastClientList: action.payload,
      };

    case MailingAction.LIST_LEADS_SUCCESS:
      return {
        ...state,
        leadList: action.payload,
      };

    case MailingAction.UPDATE_MAILING_LIST_SUCCESS:
      return {
        ...state,
        mailingList: updateMailingListState(action.payload, state.mailingList),
      };

    case MailingAction.UPDATE_PAST_CLIENTS_SUCCESS:
      return {
        ...state,
        pastClientList: updateMailingListState(action.payload, state.pastClientList),
      };
    case MailingAction.UPDATE_LEADS_SUCCESS:
      return {
        ...state,
        leadList: updateMailingListState(action.payload, state.leadList),
      };

    case MailingAction.LISTEN_MAILING_EVENT_DATA:
      return {
        ...state,
        mailingEventData: action.payload,
      };

    case MailingAction.UPDATE_MAILING_DETAIL:
      return {
        ...state,
        mailingListDetail: action.payload,
      };

    case MailingAction.UPDATE_MAILING_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case MailingAction.TOGGLE_PREMIUM_DATA_SELECTED: {
      const { key, mode } = action.payload;
      const premium_data_selected = [...state.dataSelected.premium_data_selected];

      const premiumDataList = updatePremiumDataSelected(
        premium_data_selected,
        key,
        (item) => ({
          ...item,
          checked: mode === 'SELECT_ALL' ? item.matches > 0 : !item.checked,
        }),
        mode,
      );
      return {
        ...state,
        dataSelected: {
          ...state.dataSelected,
          premium_data_selected: premiumDataList,
        },
      };
    }
    case MailingAction.UPDATE_LOADING_STATE_PREMIUM_DATA: {
      const { key, bool } = action.payload;
      const premium_data_selected = [...state.dataSelected.premium_data_selected];
      const premiumDataList = updatePremiumDataSelected(premium_data_selected, key, (item) => ({
        ...item,
        loading: bool,
      }));
      return {
        ...state,
        dataSelected: {
          ...state.dataSelected,
          premium_data_selected: premiumDataList,
        },
      };
    }
    case MailingAction.UPDATE_PREMIUM_DATA: {
      const { key, data, fee } = action.payload;
      const premium_data_selected = [...state.dataSelected.premium_data_selected];
      const premiumDataList = updatePremiumDataSelected(premium_data_selected, key, (item) => {
        const totalMatches = (item?.matches || 0) + (data?.matches || 0);
        const totalPrice = (data?.matches || 0) * fee + (item?.price || 0) + (data?.price || 0);
        const totalPriceWithoutFee = (item?.priceWithoutFee || 0) + (data?.price || 0);
        return {
          ...item,
          ...data,
          matches: totalMatches,
          price: totalPrice,
          priceWithoutFee: totalPriceWithoutFee,
        };
      });

      return {
        ...state,
        dataSelected: {
          ...state.dataSelected,
          premium_data_selected: premiumDataList,
        },
      };
    }

    case MailingAction.SELECT_MAILING: {
      const { mailings } = action.payload;
      const newMailingSelected = mailings
        .map((item) => {
          return extractEssentialData(item);
        })
        .filter((item) => hasRequiredFields(item));
      const updatedDataSelected = {
        ...state.dataSelected,
        multipleMailingsSelected: newMailingSelected,
      };
      return {
        ...state,
        dataSelected: updatedDataSelected,
      };
    }
    case MailingAction.UPDATE_DATA_SELECTED: {
      const { key, value } = action.payload;
      const updatedDataSelected = {
        ...state.dataSelected,
        [key]: value,
      };
      return {
        ...state,
        dataSelected: updatedDataSelected,
      };
    }

    case MailingAction.RESET_DATA_SELECTED: {
      return {
        ...state,
        dataSelected: initialStateDataSelected,
      };
    }

    case MailingAction.OPEN_MAILING_ADDRESS_POPUP:
      return {
        ...state,
        isOpenAddressPopup: action.payload,
      };
    case MailingAction.RESET_ADDRESSES_COUNT_CAMPAIGN:
      return {
        ...state,
        addressesFoundCountForCampaign: initialState.addressesFoundCountForCampaign
          ? {
              ...initialState.addressesFoundCountForCampaign,
            }
          : undefined,
      };
    case MailingAction.SET_ADDRESSES_COUNT_CAMPAIGN: {
      return {
        ...state,
        addressesFoundCountForCampaign: {
          ...state.addressesFoundCountForCampaign,
          ...action.payload,
        },
      };
    }
    case MailingAction.SET_ADDRESSES_COUNT_CAMPAIGN_NOT_RENDER: {
      if (
        action?.payload?.length === 0 &&
        action?.payload?.length === state.addressesFoundCountForCampaignFallback?.length
      )
        return state;
      return {
        ...state,
        addressesFoundCountForCampaignFallback: action.payload,
      };
    }

    case MailingAction.SET_ADDRESSES_COUNT_CAMPAIGN_NOT_RENDER_LOADING: {
      return {
        ...state,
        isLoadingAddressesFoundCountForCampaignFallback: action.payload,
      };
    }

    case MailingAction.RESET_ADDRESSES_COUNT_MLS:
      return {
        ...state,
        addressesFoundCountForMLS: initialState.addressesFoundCountForMLS
          ? {
              ...initialState.addressesFoundCountForMLS,
            }
          : undefined,
      };
    case MailingAction.SET_ADDRESSES_COUNT_MLS:
      return {
        ...state,
        addressesFoundCountForMLS: {
          ...state.addressesFoundCountForMLS,
          ...action.payload,
        },
      };

    case MailingAction.SET_ADDRESSES_COUNT_LOADING:
      return {
        ...state,
        addressesFoundCountLoading: action.payload,
      };

    case MailingAction.UPDATE_SMART_TRIGGER_STATE:
      return {
        ...state,
        stateSmartTrigger: action.payload,
      };

    case MailingAction.SET_MLS_UPDATED_FROM_EVENT:
      return {
        ...state,
        mlsUpdatedEventData: action.payload,
      };
    case MailingAction.LISTEN_DUPLICATED_MAILING_LIST_DATA:
      return {
        ...state,
        duplicatedMLEventData: action.payload,
      };

    default: {
      return state;
    }
  }
};

const updatePremiumDataSelected = (premium_data_selected, key, updaterFn, mode = '') => {
  if (mode === 'SELECT_ALL') {
    return premium_data_selected.map((data) => ({ ...data, checked: data.matches > 0 }));
  }
  return premium_data_selected.map((item) => {
    if (item.key === key) {
      return updaterFn(item);
    }
    return item;
  });
};
export default mailingReducer;
