import { memo } from 'react';
import { color as themeColor } from '@/styles/theme';
import { css } from '@emotion/react';
import { Typography } from 'antd';
interface TitleProps {
  className?: HTMLElement['className'];
  marginBottom?: number;
  color?: keyof typeof themeColor;
  size?: 'small' | 'middle' | 'large';
}

const AppTitleView = memo(
  ({ children, className, marginBottom, color = 'WHITE', size = 'large' }: React.PropsWithChildren<TitleProps>) => {
    const sizes = {
      small: 12,
      middle: 22,
      large: 32,
    };
    return (
      <Typography.Title
        className={className}
        level={2}
        css={css`
          font-size: ${sizes[size]}px !important;
          margin-bottom: ${marginBottom ?? 0}px !important;
          color: ${themeColor[color]} !important;
          text-align: center;
          font-weight: 700 !important;
          font-family: Firasans;
          display: flex;
          gap: 6px;
          align-items: baseline;
          justify-content: center;
        `}
      >
        {children}
      </Typography.Title>
    );
  },
);

export default AppTitleView;
