import { CardTypeEnum } from '@goldfishcode/first-team-real-estate-sdk/libs/api/handwriting/models';
import {
  CommonStatusEnums,
  DigitalCampaignTemplate,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';
import { PostGridTemplate, TemplateViewSide } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postgrid/models';

import FileImageOutLinedSvg from '@/assets/images/file-image-outline.svg';
import { HandwrittenTemplateCustom } from '@/components/dashboard/campaign/ScrollTemplate/hooks/useScrollTemplate';
import { TemplateITemEnum } from '@/utils/enum';
import { replaceDataVariablesInHtml } from '@/utils/format';

export interface HandlePreviewSingleTemplate {
  renderPreviewData: (
    type: TemplateITemEnum,
    data?: PostGridTemplate | DigitalCampaignTemplate | HandwrittenTemplateCustom,
    side?: TemplateViewSide,
    templateFallbacks?: Record<string, string>,
  ) => string;
  convertMedia: (data: DigitalCampaignTemplate) => string;
}
const useHandlePreviewSingleTemplate = (): HandlePreviewSingleTemplate => {
  const renderPreviewData = (
    type: TemplateITemEnum,
    data?: PostGridTemplate | DigitalCampaignTemplate | HandwrittenTemplateCustom,
    side?: TemplateViewSide,
    templateFallbacks?: Record<string, string>,
  ) => {
    if (
      type === TemplateITemEnum.DIGITAL_AD_VIDEO &&
      (data as DigitalCampaignTemplate)?.mp4_convert_status === CommonStatusEnums.COMPLETED
    )
      return `<video controls width="100%" height="100%">
            <source src="${convertMedia(data as DigitalCampaignTemplate)}" />
            Your browser does not support for video.
          </video>`;
    if (
      type === TemplateITemEnum.DIGITAL_AD_VIDEO &&
      (data as DigitalCampaignTemplate)?.mp4_convert_status !== CommonStatusEnums.COMPLETED
    ) {
      if (!(data as DigitalCampaignTemplate)?.thumbnail)
        return `<p style="color: #fff;text-align: center;margin-top: 10px;font-style: italic;"> Please wait for a moment. Template is generating...</p>`;
      return `<div>
               <picture><img src="${
                 (data as DigitalCampaignTemplate).thumbnail
               }" alt="preview-template" style="width: 100%;"/></picture>
               <p style="color: #fff;text-align: center;margin-top: 10px;font-style: italic;"> Please wait for a moment. Template is generating...</p>
              </div>`;
    }

    if (type === TemplateITemEnum.DIGITAL_AD_IMAGE)
      return `<picture><img src="${
        (data as DigitalCampaignTemplate).thumbnail || (data as DigitalCampaignTemplate)?.media_file
      }" alt="preview-template" /></picture>`;
    if (type === TemplateITemEnum.EMAIL) {
      return (
        (data as any).htmlString ||
        `<div class="no-template-img"><picture><img src="${FileImageOutLinedSvg}" alt="preview-template" class="no-template-img" /></picture></div>`
      );
    }

    if ([TemplateITemEnum.POSTCARD, TemplateITemEnum.LETTER].includes(type)) {
      if (side === TemplateViewSide.FRONT) {
        const htmlString =
          (data as PostGridTemplate).front_template ||
          `<div class="no-template-img"><picture><img src="${FileImageOutLinedSvg}" alt="preview-template" class="no-template-img" /></picture></div>`;
        return templateFallbacks ? replaceDataVariablesInHtml(htmlString, templateFallbacks) : htmlString;
      }
      if (side === TemplateViewSide.BACK) {
        const htmlString =
          (data as PostGridTemplate).back_template ||
          `<div class="no-template-img"><picture><img src="${FileImageOutLinedSvg}" alt="preview-template" class="no-template-img" /></picture></div>`;
        return templateFallbacks ? replaceDataVariablesInHtml(htmlString, templateFallbacks) : htmlString;
      }
    }
    if ([TemplateITemEnum.HANDWRITTEN].includes(type)) {
      if (side === TemplateViewSide.FRONT) {
        const htmlString =
          (data as HandwrittenTemplateCustom).front_template ||
          `<div class="no-template-img"><picture><img src="${FileImageOutLinedSvg}" alt="preview-template" class="no-template-img" /></picture></div>`;
        return templateFallbacks ? replaceDataVariablesInHtml(htmlString, templateFallbacks) : htmlString;
      }
      if (side === TemplateViewSide.BACK) {
        const htmlString =
          (data && 'card_type' in data && data?.card_type === CardTypeEnum.Flat
            ? data?.message_template
            : (data as HandwrittenTemplateCustom).back_template) ||
          `<div class="no-template-img"><picture><img src="${FileImageOutLinedSvg}" alt="preview-template" class="no-template-img" /></picture></div>`;
        return templateFallbacks ? replaceDataVariablesInHtml(htmlString, templateFallbacks) : htmlString;
      }

      if (side === TemplateViewSide.INSIDE) {
        const htmlString =
          (data as HandwrittenTemplateCustom).message_template ||
          `<div class="no-template-img"><picture><img src="${FileImageOutLinedSvg}" alt="preview-template" class="no-template-img" /></picture></div>`;
        return templateFallbacks ? replaceDataVariablesInHtml(htmlString, templateFallbacks) : htmlString;
      }
    }

    return '';
  };
  const convertMedia = (data) => {
    if (!data.digital_ad_type || data.digital_ad_type !== TemplateITemEnum.DIGITAL_AD_VIDEO) return;
    return data?.mp4_convert_status !== CommonStatusEnums.COMPLETED ? data?.media_file : data?.mp4_file;
  };

  return { renderPreviewData, convertMedia };
};

export default useHandlePreviewSingleTemplate;
