import { TitleToolBoxPremiumDataType } from '@/utils/enum';
export interface ToolBoxPremiumDataItem {
  label: string;
  key: TitleToolBoxPremiumDataType;
  matches?: number;
  price?: number;
  priceWithoutFee?: number;
  checked?: boolean;
  loading?: boolean;
  disabled?: boolean;
  sortIndex: number;
}

// Define the type for the initialStatePremiumData array
export type ToolBoxPremiumData = ToolBoxPremiumDataItem[];

// Export the initial state as a variable of the defined type
export const initialStatePremiumData: ToolBoxPremiumData = [
  {
    label: 'Notice of Default',
    key: TitleToolBoxPremiumDataType.NOTICE,
    matches: 0,
    price: 0,
    loading: false,
    sortIndex: 1,
  },
  {
    label: 'Divorce',
    key: TitleToolBoxPremiumDataType.DIVORCEES,
    matches: 0,
    price: 0,
    loading: false,
    sortIndex: 4,
  },
  {
    label: 'Affidavits of Death',
    key: TitleToolBoxPremiumDataType.AFFIDAVITS_OF_DEATH,
    matches: 0,
    price: 0,
    loading: false,
    sortIndex: 5,
  },
];
