import { PostgridTemplateType } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postgrid/models';
import { ActionBase } from '..';
import CampaignAction from './actions';
import { CampaignState } from './model';

const initialState: CampaignState = {
  postgridFilter: { search: '', campaignType: PostgridTemplateType.ANY, sortBy: undefined },
  postgridSessionVar: {
    agent: {
      original_vars: [],
    },
    listing_of_my_mailing_list: {
      original_vars: [],
    },
    my_listings: {
      original_vars: [],
    },
    not_my_listings: {
      original_vars: [],
    },
    neighborhood_mls: {
      original_vars: [],
    },
    mailing_list_and_owner: {
      original_vars: [],
    },
    past_client: {
      listing: { original_vars: [] },
      buyer: { original_vars: [] },
      double_ended: { original_vars: [] },
    },
    image_variables: {
      original_vars: [],
    },
    mls_status: [],
  },
  listPostgridSessionVar: [],
  listTags: [],
  imageVariableDefaultImage: {},
  notExistMLId: [],
};

const campaignReducer = (state = initialState, action: ActionBase): CampaignState => {
  if (!action.type.includes('@campaign')) return state;
  switch (action.type) {
    case CampaignAction.POSTGRID_FILTER:
      return {
        ...state,
        postgridFilter: action.payload,
      };
    case CampaignAction.POSTGRID_SESSION_VAR:
      return {
        ...state,
        postgridSessionVar: action.payload,
      };
    case CampaignAction.ALL_POSTGRID_SESSION_VAR:
      return {
        ...state,
        listPostgridSessionVar: action.payload,
      };
    case CampaignAction.LIST_TAGS:
      return {
        ...state,
        listTags: action.payload,
      };
    case CampaignAction.IMAGE_VARIABLE_DEFAULT_IMAGE:
      return {
        ...state,
        imageVariableDefaultImage: action.payload,
      };
    case CampaignAction.NOT_EXIST_ML_ID:
      return {
        ...state,
        notExistMLId: action.payload,
      };
    default:
      return state;
  }
};

export default campaignReducer;
